:root {
    --document__font-size: 2.5rem;
    --document__subtitle-font-size: 1.2rem;
    --document__in-rubric-font-size: 1.7em;

    --document__height: 145px;
    --document__in-rubric-height: 64px;
    --document__thumb-width: 100px;
    --document__thumb-height: 100px;
    --document__thumb-width-rubric: 60px;
    --document__thumb-height-rubric: 60px;

    --document__color: var(--color-blue-marine);
    --document__color-hover: var(--color-blue-light-blue);

    @media screen and (max-width: 1024px) {
        --document__font-size: 2.2rem;
        --document__height: 120px;
    }

    @media screen and (max-width: 768px) {
        --document__font-size: 1.8rem;
        --document__height: 100px;
    }
    @media screen and (max-width: 500px) {
        --document__height: 80px;
    }
}


// Documents normal
.documents {

    .document {
        position: relative;
        border-bottom: 1px solid var(--color-grayscale-gray3);
    
        &:first-child { border-top: 1px solid var(--color-grayscale-gray3); }
    
        &__link {
            position: relative;
            cursor: pointer;
            padding: 20px 80px 20px 40px;
            min-height: var(--document__height);
            display: flex;
            align-items: center;
            text-decoration: none;

            @media screen and (max-width: 768px) { padding: 20px 60px 20px 20px; }
            @media screen and (max-width: 500px) { padding: 15px 50px 15px 20px; }
        }

        &__picture {
            width: var(--document__thumb-width);
            height: var(--document__thumb-height);
            margin-right: 40px;
            overflow: hidden;
            flex-shrink: 0;
            @media screen and (max-width: 600px)  { display: none; }
        }

        &__picture-img {
            transform: scale(1);
            transition: transform 0.3s;
        }

        &__subtitle {
            margin-bottom: 4px;
            font-size: var(--document__subtitle-font-size);
            color: var(--color-blue-blue);
            font-weight: 700;
            line-height: 1.4em;
            text-transform: uppercase;
            letter-spacing: 0.1em;
        }
    
        &__title {
            line-height: 1.2;
            color: var(--document__color);
            font-size: var(--document__font-size);
            font-weight: 500;
            transition: color 0.3s;
        }
    
        &__icon {
            position: absolute;
            top: 50%;
            right: 60px;
            transform: translateY(-50%);

            @media screen and (max-width: 768px) { right: 20px; }
        }
    
        &__svg {
            width: 20px;
            height: 20px;
            fill: var(--document__color);
            transition: stroke 300ms;
            transition: fill 0.3s;

            @media screen and (max-width: 500px) { width: 16px; height: 16px; }
        }

        &__link:hover{
            .document__picture-img{
                transform: scale(1.1);
            }
            .document__title{
                color: var(--document__color-hover);
            }
            .document__svg {
                fill: var(--document__color-hover);
            }
        }
    }

    &--in-rubric{
        .document{
            border-color: var(--color-blue-marine);
            &:first-child{ border-color: var(--color-blue-marine); }

            .document__picture {
                width: var(--document__thumb-width-rubric);
                height: var(--document__thumb-height-rubric);
            }
            .document__link {
                padding: 25px 60px 25px 0;
                min-height: var( --document__in-rubric-height);

                @media screen and (max-width: 768px) { padding: 20px 50px 20px 0px; }
                @media screen and (max-width: 500px) { padding: 15px 40px 15px 0px; }
            }
            .document__title {
                font-size: var(--document__in-rubric-font-size);
                font-weight: 500;
            }
            .document__icon {
                right: 20px;

                @media screen and (max-width: 500px) { right: 10px; }
                @media screen and (max-width: 400px) { right: 8px; }
            }
            .document__svg {
                width: 18px;
                height: 18px;

                @media screen and (max-width: 500px) { width: 14px; height: 14px; }
            }
        }
    }

    &.documents-bottom-padding {
        padding-bottom: var(--y-spacing-70);
    }
}