.show-alerts .alert,
.show-alerts .alert-mobile {
    opacity: 1;
    visibility: visible;
    pointer-events: all;
}


.show-alerts .alert-mobile {
    top: 0;
}


// Container de page
.page {
    position: relative;
}


// Styles appliqués lorsqu'il y a un side-menu
.page-with-side-menu {
    display: flex;

    $pwsm: '.page-with-side-menu'; // Variable désignant le div contenant un .page-with-side-menu

    
    // Conteneur
    #{$pwsm}__container {
        width: calc(100% - var(--side-section-width));
        display: flex;
        flex-direction: column;
        @media screen and (max-width: 1000px) { width: 100%; }


        // Toutes les sections à l'intérieur du conteneur
        section {
            width: 100%;
        }

        &--contact {
            width: calc(100% - var(--side-section-contact-width));

            @media screen and (max-width: 1000px) { width: 100%; }
        }
    }
}


// Page d'événement détail
.page--events-detail {

    .main {

        &__content {
            padding-top: var(--y-spacing-80);
        }
    }
}