:root{
    --form__grid-gap-x: 80px;
    --form__grid-gap-y: 50px;
    @media screen and (max-width: 900px) { 
        --form__grid-gap-x: 0;
    }
}

.form {

    &__grid{
        margin-left: calc(var(--form__grid-gap-x) / -2);
        margin-right: calc(var(--form__grid-gap-x) / -2);
        margin-top: calc(var(--form__grid-gap-y) * -1);
        display: flex;
        flex-wrap: wrap;
    }

    &__column{
        padding-left: calc(var(--form__grid-gap-x) / 2);
        padding-right: calc(var(--form__grid-gap-x) / 2);
        margin-top: var(--form__grid-gap-y);
        width: 100%;

        &:empty{ margin-top: 0; }
    }

    &__column--half {
        width: 50%;
        @media screen and (max-width: 900px) { width: 100%; }
    }

    &__column--half-width-side-pagination {
        width: 50%;
        @media screen and (max-width: 1200px) { width: 100%; }
    }

    &__column--field + &__column--field {
        margin-top: 10px;
    }

    .form__column--field.form__column--half + .form__column--field.form__column--half {
        margin-top: var(--form__grid-gap-y);

        @media screen and (max-width: 900px) { margin-top: 10px; }
    }

    .form__column--field.form__column--half-width-side-pagination + .form__column--field.form__column--half-width-side-pagination {
        margin-top: var(--form__grid-gap-y);

        @media screen and (max-width: 1200px) { margin-top: 10px; }
    }

    .oc-loading {
        pointer-events: none;

        &::after {
            background: transparent;
            border: .2em solid white;
            border-right-color: transparent;
            transition: none;
            position: initial;
            top: initial;
            right: initial;
            bottom: initial;
            left: initial;
            z-index: initial;
        }
    }
}

#applicationFormWrapper, #messageWrapper {
    h3 {
        font-size: 20px;
    }
}

