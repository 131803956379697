@use 'components';


//--------------------
// Variables globales
//--------------------
:root {
    
}


//-------------------
// Variables locales
//-------------------
.page-title {

}


// ----------------
// Page title
// ----------------
.page-title {
    $pt: '.page-title'; // Variable désignant le page-title


    // Tous les tops de page ------------ |

    //Conteneur
    #{$pt}__container {
        width: 100%;
        position: relative;
        z-index: 5;

        &--credit {
            font-family: var(--font-family-1); // Maison Neue
            font-size: 1.4rem;
            font-weight: 700;
            color: var(--color-base-white);
            position: absolute;
            right: 0;
            bottom: 0;
            background: rgba(37, 46, 63, .7);
            padding: 10px;
        }
    }


    // Gros titre | H1
    #{$pt}__title {
        @extend .huge-title;
        color: var(--color-base-white);
    }

    // Suggestions de recherche | Voulez-vous dire
    #{$pt}__suggestions {
        position: absolute;
        bottom: 60px;
        font-family: var(--font-family-1); // Maison Neue
        font-size: 1.6rem;
        font-weight: 700;
        color: var(--color-base-white);

        a {
            color: var(--color-base-white);
            text-decoration: none;
            box-shadow: inset 0 -0.1em 0 0 #fff;
            transition: box-shadow 0.3s;

            &:hover {
                box-shadow: inset 0 -1.1em 0 0 rgba($color: #fff, $alpha: 0.2);
            }
        }
    }


    // Top de page avec image en pleine largeur (Détail, Feuille de style, Nous joindre) ------------- |
    &--image-full-width {

        // Filtre de couleur sur l'image
        #{$pt}__filter {
            background: var(--color-blue-marine);
            background: linear-gradient(180deg, rgba(37,46,63,1) 0%, rgba(255,255,255,0) 80%);
            pointer-events: none;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 5;
        }


        // Conteneur de l'image full-width
        #{$pt}__picture {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }


        // Conteneur du titre
        #{$pt}__title-container {
            width: 100%;
            padding: 0 var(--x-spacing-120) 230px var(--x-spacing-120);
            padding-top: calc(130px + var(--header-height));
            position: relative;
            z-index: 10;

            @media screen and (max-width: 600px) {
                padding-top: calc(90px + var(--header-height));
                padding-bottom: 150px;
            }
        }


        // Bouton de partage
        #{$pt}__share {
            position: absolute;
            bottom: 40px;
            left: var(--x-spacing-120);
            z-index: 10;

            @media screen and (max-width: 600px) { display: none; }
        }


        // Crédit photo
        #{$pt}__credit {
            font-family: var(--font-family-1); // Maison Neue
            font-size: 1.4rem;
            font-weight: 700;
            color: var(--color-base-white);
            position: absolute;
            right: 0;
            bottom: 0;
            background: rgba(37, 46, 63, .7);
            padding: 10px;

            // Seulement dans la page de contact
            &--contact {
                right: calc(var(--side-section-contact-width));

                @media screen and (max-width: 1000px) { right: 40px; }
                @media screen and (max-width: 768px) { right: 30px; }
                @media screen and (max-width: 600px) {
                    right: auto;
                    left: 25px;
                }
            }

            @media screen and (max-width: 768px) { right: 30px; }
            @media screen and (max-width: 600px) {
                right: auto;
                left: 25px;
            }
        }
    }


    // Top de page avec juste un titre (Résultats de recherche, Événements - Liste, Actualités - Liste) ------------- |
    &--no-image {


        // Conteneur
        #{$pt}__container {
            background: var(--color-blue-marine);
            padding-top: calc(var(--y-spacing-100) + var(--header-height));
            padding-right: var(--x-spacing-120);
            padding-bottom: var(--y-spacing-120);
            padding-left: var(--x-spacing-120);

            @media screen and (max-height: 800px) { 
                padding-top: calc(var(--y-spacing-80) + var(--header-height)); 
                padding-bottom: var(--y-spacing-100);
            }
            @media screen and (max-height: 700px) { 
                padding-top: calc(var(--y-spacing-50) + var(--header-height)); 
                padding-bottom: var(--y-spacing-80);
            }
        }


        // Barre de recherche (Seulement dans la page de résultats de recherche) **
        #{$pt}__search-results-form {
            margin-bottom: var(--y-spacing-60);

            $sf: '.search-field'; // Variable désignant le search-field

            #{$sf} {
                border-bottom: 1px solid var(--color-base-white);
            }


            // Input
            #{$sf}__input {
                color: var(--color-base-white);
                background-color: var(--color-blue-marine);
            }


            // Le X 
            #{$sf}__x {
                background: var(--color-blue-darker-marine);

                &:before, &:after {
                    background: var(--color-base-white);
                }

                &:hover {
                    background: var(--color-blue-light-blue);
                }
            }


            // Bouton pour lancer la recherche
            #{$sf}__button {
                background: var(--color-base-white);
        
                &:hover {
                    background: var(--color-blue-darker-marine);
                }
            }


            // Réglages de l'autofill
            :-webkit-autofill,
            :-webkit-autofill:hover, 
            :-webkit-autofill:focus, 
            :-webkit-autofill:active {
                -webkit-box-shadow: 0 0 0 100px var(--color-blue-marine) inset !important;
                -webkit-text-fill-color: var(--color-base-white);
            }
        }


        // Titre
        #{$pt}__title {
            width: 100%;
        }


        // Bouton de partage
        #{$pt}__share {
            margin-top: 40px;

            @media screen and (max-width: 600px) { display: none; }
        }


        // Mot ou mots recherchés (Seulement dans la page de résultats de recherche) **
        #{$pt}__search-item {
            font-style: italic;
            color: var(--color-blue-light-blue);
        }
    }


    // Top de page avec une image et un titre (50/50) ( Événements - Détail, Actualités - Détail)
    &--50-50 {


        // Conteneur
        #{$pt}__container {
            min-height: 750px;
            display: flex;

            @media screen and (max-width: 1300px) { min-height: 730px; }
            @media screen and (max-width: 1200px) { min-height: 680px; }
            @media screen and (max-width: 1100px) { min-height: 660px; }
            @media screen and (max-width: 1000px) { min-height: 640px; }
            @media screen and (max-width: 900px) { min-height: 600px; }
            @media screen and (max-width: 800px) { min-height: 580px; }
            @media screen and (max-width: 768px) { 
                min-height: 0; 
                flex-direction: column-reverse;
            }
        }


        // Conteneur du conteneur de l'image (AJout d'un filtre sur l'image)
        #{$pt}__picture-container {
            width: 50%;
            position: relative;

            @media screen and (min-width: 1601px) { width: 720px;}
            @media screen and (max-width: 768px) { width: 100%; }
        }


        // Filtre (Dégradé)
        #{$pt}__gradient-filter {
            background: rgb(68,68,68);
            background: linear-gradient(180deg, rgba(68,68,68,0.7) 0%, rgba(68,68,68,0) 20%);
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 5;

            @media screen and (max-width: 768px) { display: none; }
        }


        // Conteneur de l'image 50%
        #{$pt}__picture {
            width: 100%;
            height: 100%;
        }


        // Conteneur du titre
        #{$pt}__title-container {
            width: 50%;
            background: var(--color-blue-marine);
            padding-top: calc(var(--y-spacing-140) + var(--header-height));
            padding-right: var(--x-spacing-80);
            padding-bottom: var(--y-spacing-140);
            padding-left: var(--x-spacing-120);
            position: relative;

            @media screen and (min-width: 1601px) { width: calc(100% - 720px); }
            @media screen and (max-width: 768px) { 
                width: 100%; 
                padding-top: calc(var(--y-spacing-100) + var(--header-height));
                padding-bottom: var(--y-spacing-100);        
            }
        }


        // Surtitre
        #{$pt}__surtitle {
            font-family: var(--font-family-1);  // Maison Neue
            font-size: 1.2rem;
            font-weight: 700;
            line-height: 1.4;
            letter-spacing: 0.1em;
            text-transform: uppercase;
            color: var(--color-blue-blue);
            margin-bottom: 25px;
        }


        // Catégorie
        #{$pt}__category {
            color: var(--color-grayscale-gray9);
        }


        // Date
        #{$pt}__date {
            font-size: 1.2rem;
        }


        // Titre
        #{$pt}__title {
            font-size: 7rem;

            @media screen and (max-height: 800px) { 
                font-size: 6rem;
                margin-bottom: 40px; 
            }
            @media screen and (max-height: 700px) { font-size: 5rem; }
            @media screen and (max-width: 1200px) { font-size: 6rem; }
            @media screen and (max-width: 1000px) { font-size: 5rem; }
            @media screen and (max-width: 900px) { font-size: 4.5rem; }
            @media screen and (max-width: 768px) { margin-bottom: 40px; }
            @media screen and (max-width: 600px) { margin-bottom: 0; }
            @media screen and (max-width: 425px) { font-size: 4rem; }
        }


        // Bouton de partage
        #{$pt}__share {
            position: absolute;
            bottom: var(--y-spacing-50);
            left: var(--x-spacing-120);

            @media screen and (max-height: 800px) {
                position: relative;
                bottom: initial;
                left: initial;
            }
            @media screen and (max-width: 768px) { 
                position: relative;
                bottom: initial;
                left: initial;
            }
            @media screen and (max-width: 600px) { display: none; }
        }

        &--no-image {
            #{$pt}__container {
                min-height: initial;
            }
            #{$pt}__title-container {
                width: 100%;
            }
            #{$pt}__share {
                margin-top: 40px;
                position: relative;
                bottom: initial;
                left: initial;
            }
        }
    }
}