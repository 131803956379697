@use 'components';


//--------------------
// Variables globales
//--------------------
:root {
    
}


//-------------------
// Variables locales
//-------------------
.overlay-popup {
    --x-height: 18px;
}


// ----------------
// Overlay | Popup
// ----------------
.overlay-popup {
    pointer-events: none;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 30;

    $ovp: '.overlay-popup'; // Variable désignant l'overlay popup


    // Fond transparent
    #{$ovp}__background {
        background: rgba($color: #000000, $alpha: 0.3);
        opacity: 0;
        visibility: hidden;
        transition: opacity 600ms, visibility 600ms;
        pointer-events: none;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
    }


    // Conteneur
    #{$ovp}__container {
        width: 600px;
        background: var(--color-base-white);
        opacity: 0;
        visibility: hidden;
        transition: opacity 600ms, visibility 600ms;
        pointer-events: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 120px 95px 70px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;

        @media screen and (max-width: 768px) {
            width: 500px;
            padding: 100px 50px;
        }

        @media screen and (max-width: 600px) {
            width: 400px;
            padding: 100px 50px 50px;
        }

        @media screen and (max-width: 500px) {
            width: 300px;
            padding: 100px 25px 25px;
        }

        @media screen and (max-width: 400px) { 
            width: 100%; 
            max-width: calc(100% - (var(--x-spacing-40) * 2));
        }
    }


    // X
    #{$ovp}__close {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        background: var(--color-blue-marine);
        transition: background-color 300ms;
        position: absolute;
        top: 30px;
        right: 30px;
        cursor: pointer;

        &::after, &::before {
            content: "";
            width: 14px;
            height: 1px;
            background: var(--color-base-white);
            opacity: 1;
            transition: opacity 300ms;
            position: absolute;
            top: 50%;
            left: 50%;
        }

        &::after { transform: translate(-50%,-50%) rotate(-45deg); }
        &::before { transform: translate(-50%,-50%) rotate(45deg); }

        &:hover { 
            background: var(--color-blue-light-blue); 
        }

        @media screen and (max-width: 500px) { 
            width: 40px;
            height: 40px;
            top: 25px; 
        }
    }


    // Texte
    #{$ovp}__text { text-align: center; }


    // Titre
    #{$ovp}__title {
        font-size: 3.5rem;
        font-weight: 700;
        color: var(--color-blue-marine);
        line-height: 1.1;
        margin-bottom: 40px;

        @media screen and (max-width: 600px) { font-size: 25px; }
        @media screen and (max-width: 500px) { font-size: 20px; }
    }


    // Description
    #{$ovp}__infos {
        font-size: 1.7rem;
        color: var(--color-blue-marine);
        line-height: 1.5;
        margin-bottom: 50px;

        @media screen and (max-width: 600px) { 
            font-size: 1.4rem;
            margin-bottom: 20px;
        }
    }
}


// Overlay | Actif
.show-overlay-popup {


    // Overlay de recherche | Actif
    .overlay-popup {
        $ovp: '.overlay-popup'; // Variable désignant l'overlay popup


        // Fond transparent | Actif
        #{$ovp}__background {
            opacity: 1;
            visibility: visible;
            pointer-events: all;
        }


        // Conteneur | Actif
        #{$ovp}__container {
            opacity: 1;
            visibility: visible;
            pointer-events: all;
        }
    }
}


// Overlay | Fermeture
.show-overlay-popup.closing-overlay-popup {


    // Overlay de partage | Fermeture
    .overlay-popup {
        $ovp: '.overlay-popup'; // Variable désignant l'overlay popup


        // Fond transparent | Fermeture
        #{$ovp}__background {
            opacity: 0;
            visibility: hidden;
            pointer-events: none;
        }


        // Conteneur | Fermeture
        #{$ovp}__container {
            opacity: 0;
            visibility: hidden;
            pointer-events: none;
        }
    }
}