:root {
    // Bouton primaire
    --button-background: var(--color-blue-marine);
    --button-color: var(--color-base-white);
    --button-border-color: var(--color-blue-marine);

    --button-background-hover: var(--color-blue-light-blue);
    --button-color-hover: var(--color-base-white);
    --button-border-color-hover: var(--color-blue-light-blue);

    // Bouton secondaire
    --button-secondary-background: transparent;
    --button-secondary-color: var(--color-blue-marine);
    --button-secondary-border-color: var(--color-blue-marine);

    --button-secondary-background-hover: var(--color-blue-marine);
    --button-secondary-color-hover: var(--color-base-white);
    --button-secondary-border-color-hover:  var(--color-blue-marine);
}

.button,
.button--secondary {
    font-family: var(--font-family-1); // Maison Neue
    color: var(--button-color);
    letter-spacing: 0.1em;
    position: relative;
    font-weight: 700;
    font-size: 1.1rem;
    padding: 24px 40px;
    text-transform: uppercase;
    transition: color 0.3s, background-color 0s, border 0.3s;
    display: inline-flex;
    align-items: center;
    text-decoration: none;
    line-height: 1.3em;
    cursor: pointer;
    z-index: 1;
    border: 0;

    &::before {
        content: '';
        background: var(--button-background);
        border: 1px solid var(--button-border-color);
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -2;
    }

    &::after {
        content: '';
        background: var(--button-background-hover);
        border: 0px solid var(--button-border-color-hover);
        transition: border 300ms;
        position: absolute;
        top: 100%;
        right: 0;
        bottom: 0;
        left: 0;
        transition: top 300ms;
        z-index: -1;
    }

    svg {
        content: '';
        fill: var(--button-color);
        display: inline-block;
        margin-left: 10px;
        transition: fill 0.3s;
        flex-shrink: 0;
        transform: translateY(-2px);
    }

    &[target="_blank"],
    &[href$=".pdf"],
    &[href$=".doc"],
    &[href$=".zip"] {
        svg{
            width: 11px;
            height: 11px;
        }
    }

    &[href$=".pdf"],
    &[href$=".doc"],
    &[href$=".zip"] {
        svg{
            width: 12px;
            height: 12px;
        }
    }

    &:hover {
        color: var(--button-color-hover);

        &::after {
            top: 0;
            border: 1px solid var(--button-border-color-hover);
        }

        svg {
            fill: var(--button-color-hover);
        }
    }

    &--white {
        // Bouton primaire
        --button-background: var(--color-base-white);
        --button-color: var(--color-blue-marine);
        --button-border-color: var(--color-base-white);

        --button-background-hover: var(--color-blue-light-blue);
        --button-color-hover: var(--color-base-white);
        --button-border-color-hover: var(--color-blue-light-blue);

        // Bouton secondaire
        --button-secondary-background: transparent;
        --button-secondary-color: var(--color-base-white);
        --button-secondary-border-color: var(--color-base-white);

        --button-secondary-background-hover: var(--color-blue-light-blue);
        --button-secondary-color-hover: var(--color-base-white);
        --button-secondary-border-color-hover:  var(--color-blue-light-blue);
    }
}

// Bouton secondaire
.button--secondary {
    color: var(--button-secondary-color);

    &::before {
        background: transparent;
    }

    svg {
        fill: var(--button-secondary-color);
    }

    &:hover {
        color: var(--button-secondary-color-hover);

        svg {
            fill: var(--button-secondary-color-hover);
        }
    }
}
