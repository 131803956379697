//--------------------
// Variables globales
//--------------------
:root {
    
}


//-------------------
// Variables locales
//-------------------
.events {
    --image-width: 477px;

    @media screen and (max-width: 1000px) { --image-width: 425px; }
    @media screen and (max-width: 900px) { --image-width: 400px; }
    @media screen and (max-width: 800px) { --image-width: 375px; }
    @media screen and (max-width: 700px) { --image-width: 100%; }
}


// ----------------
// Events
// ----------------
.events {
    $ev: '.events'; // Variable désignant l'événement


    // Conteneur
    #{$ev}__container {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: var(--y-spacing-80);

        @media screen and (max-width: 700px) { 
            flex-direction: column; 
            justify-content: initial;
        }
    }


    // Boîte contenant l'image (nécessaire lorsqu'on utilise un padding en pourcentage)
    #{$ev}__picture-container {
        width: var(--image-width);
        align-self: flex-start;

        @media screen and (min-width: 1601px) { margin-right: 120px; }
        @media screen and (max-width: 700px) { 
            margin-right: 0;
            margin-bottom: 30px; 
        }
    }


    // Conteneur de l'image
    #{$ev}__picture {
        width: 100%;
        padding-top: 113%;
        position: relative;
        overflow: hidden;
    }


    // Image
    #{$ev}__img {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transform: scale(1);
        transition: transform 600ms var(--ease-out-quart);
    }


    // Effet d'hover du conteneur sur l'image
    #{$ev}__container:hover #{$ev}__img {
        transform: scale(1.1);
    }


    // Block de texte
    #{$ev}__text-block {
        width: calc(100% - var(--image-width));
        padding-left: var(--x-spacing-120);
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        @media screen and (max-width: 700px) { 
            width: 100%; 
            padding-left: 0;
        }
    }


    // Surtitre
    #{$ev}__surtitle {
        font-family: var(--font-family-1);  // Maison Neue
        font-size: 1.2rem;
        font-weight: 700;
        letter-spacing: 0.1em;
        line-height: 1.4;
        text-transform: uppercase;
        color: var(--color-blue-blue);
        margin-bottom: 30px;

        @media screen and (max-width: 700px) { margin-bottom: 20px; }
    }


    // Catégorie
    #{$ev}__category {
        font-size: 1.2rem;
        color: var(--color-grayscale-gray9);
    }


    // Titre
    #{$ev}__title {
        font-family: var(--font-family-2); // Canela
        font-size: 6rem;
        line-height: 1.1;
        color: var(--color-blue-marine);
        transition: color 300ms;
        margin-bottom: 50px;

        @media screen and (max-width: 1024px) { font-size: 5rem; }
        @media screen and (max-width: 768px) { font-size: 4.5rem; }
        @media screen and (max-width: 700px) { margin-bottom: 20px; }
        @media screen and (max-width: 600px) { font-size: 4rem; }
        @media screen and (max-width: 425px) { font-size: 3.5rem; }
    }


    // Effet d'hover du conteneur sur le titre
    #{$ev}__container--not-featured:hover #{$ev}__title {
        color: var(--color-blue-blue);
    }


    // Heure et lieu
    #{$ev}__hours, #{$ev}__place {
        font-family: var(--font-family-1); // Maison Neue
        font-size: 1.7rem;
        line-height: 1.4;
        color: var(--color-blue-marine);
        display: flex;
        align-items: center;
    }


    // Heure
    #{$ev}__hours {
        margin-bottom: 25px;

        @media screen and (max-width: 700px) { margin-bottom: 15px; }
    }


    // Svg
    #{$ev}__svg {
        height: 17px;
        stroke: var(--color-blue-marine);
        stroke-width: 2px;
        fill: none;
        margin-right: 15px;
        transform: translateY(-2px);
        flex-shrink: 0;


        // Horloge
        &--clock {
            width: 17px;
        }


        // Pointer
        &--marker {
            width: 12px;
        }
    }


    // Lien
    #{$ev}__link {
        font-family: var(--font-family-1); // Maison Neue
        font-size: 1.1rem;
        font-weight: 700;
        letter-spacing: 0.1em;
        color: var(--color-blue-marine);
        transition: color 300ms;
        text-transform: uppercase;
        padding-bottom: 9px;
        margin-top: 45px;
        position: relative;

        &:after {
            content: '';
            width: 100%;
            transition: width 300ms var(--ease-in-cubic);
            height: 2px;
            background: var(--color-blue-light-blue);
            position: absolute;
            bottom: 0;
            left: 0;
        }
    }


    // Effet d'hover du bloc de texte sur le lien
    #{$ev}__text-block:hover #{$ev}__link {
        color: var(--color-blue-light-blue);

        &:after {
            width: 65%;
        }
    }
}