@use 'components';

.no-results{
    color: var(--color-blue-marine);

    &__main {
        @extend .large-title;
    }

    &__sub {
        @extend .medium-title;
        padding-top: var(--y-spacing-30);
    }

    &__button {
        margin-top: var(--y-spacing-30);
    }

    &--search {
        padding-top: 70px;
    }

    &--events-and-news {
        padding-bottom: 80px;
    }
}