@use 'components';


//--------------------
// Variables globales
//--------------------
:root {
    
}


//-------------------
// Variables locales
//-------------------
.home-showcase {
    --image-width: 400px;
}


// ----------------
// Home Showcase
// ----------------
.home-showcase {
    $hs: '.home-showcase';


    // Conteneur
    #{$hs}__container {
        width: 100%;
        position: relative;
    }


    // Conteneur de l'image de fond
    #{$hs}__background {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }


    // Contenu
    #{$hs}__content {
        width: 100%;
        padding: var(--y-spacing-160) 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        @media screen and (max-height: 800px) { padding: var(--y-spacing-100) 25px; }
        @media screen and (max-height: 700px) { padding: var(--y-spacing-50) 25px; }
    }


    // Conteneur de l'image
    #{$hs}__picture {
        width: var(--image-width);
        height: 619px;
        background-color: #242931;

        @media screen and (max-height: 800px) { height: 600px; }
        @media screen and (max-height: 700px) { height: 580px; }
        @media screen and (max-width: 500px) { 
            width: 100%;
            height: auto;
        }
    }

    // Image
    #{$hs}__img {
        opacity: 0.6;
    }


    // Bloc de texte
    #{$hs}__text-block {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        display: flex;
        flex-direction: column;
        align-items: center;

        @media screen and (max-width: 500px) { 
            width: calc(100% - 50px); 
            padding: 0 25px;
        }

        @media screen and (max-width: 400px) { padding: 0 15px; }
    }


    // Surtitre
    #{$hs}__surtitle {
        font-family: var(--font-family-1); // Maison Neue
        font-size: 1.2rem;
        font-weight: 700;
        line-height: 1.4;
        letter-spacing: 0.1em;
        color: var(--color-base-white);
        text-transform: uppercase;
        text-align: center;
        margin-bottom: 20px;
    }


    // Titre
    #{$hs}__title {
        font-family: var(--font-family-2); // Canela
        font-size: 6rem;
        line-height: 1.08em;
        color: var(--color-base-white);
        text-align: center;
        margin-bottom: 55px;

        @media screen and (max-height: 800px) { font-size: 5rem; }
        @media screen and (max-width: 1024px) { font-size: 5rem; }
        @media screen and (max-width: 768px) { font-size: 4rem; }
        @media screen and (max-width: 500px) { 
            font-size: 3.4rem; 
            margin-bottom: 45px;
        }
        @media screen and (max-width: 400px) { margin-bottom: 35px; }
    }
}