@use 'components';
@use '../../vendors' as *;


//--------------------
// Variables globales
//--------------------
:root {
    --overlay-fastlinks-width: 500px;

    @media screen and (max-width: 768px) { --overlay-fastlinks-width: 446px }
}


//-------------------
// Variables locales
//-------------------
.overlay-fastlinks {
    --close-button-dimension: 56px;
    @include media('<=1200px') { --close-button-dimension: 52px; }
    @include media('<=1024px') { --close-button-dimension: 48px; }
    @include media('<=600px')  { --close-button-dimension: 44px; }
}


// ----------------
// Overlay | Fastlinks
// ----------------
.overlay-fastlinks {
    pointer-events: none;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 30;
    overflow-x: hidden;

    @media screen and (max-width: 700px) { position: fixed; }

    $ovf: '.overlay-fastlinks'; // Variable désignant l'overlay fastlinks


    // Fond transparent
    #{$ovf}__background {
        background: rgba($color: #000000, $alpha: 0.3);
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
        position: absolute;
        top: 0;
        right: var(--overlay-fastlinks-width);
        bottom: 0;
        left: 0;
        z-index: -1;

        @media screen and (max-width: 500px) { display: none; }
    }


    // Conteneur
    #{$ovf}__container {
        width: var(--overlay-fastlinks-width);
        height: calc((var(--vh, 1vh) * 100) - var(--header-height));
        padding: var(--y-spacing-80) var(--x-spacing-60) var(--y-spacing-50);
        background: var(--color-base-white);
        box-shadow: inset 0px 10px 15px -10px rgba(0,0,0,0.23);
        -webkit-box-shadow: inset 0px 10px 15px -10px rgba(0,0,0,0.23);
        -moz-box-shadow: inset 0px 10px 15px -10px rgba(0,0,0,0.23);
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        visibility: hidden;
        pointer-events: none;
        position: absolute;
        top: var(--header-height);
        right: calc(var(--overlay-fastlinks-width) * -1);
        transition: right 600ms var(--ease-out-cubic), visibility 600ms;
        overflow-y: auto;
        overflow-x: hidden;

        &::-webkit-scrollbar {
            -webkit-appearance: none;
            width: 5px;
            height: 5px;
            background-color: transparent;
        }
        &::-webkit-scrollbar-thumb{
            background-color: var(--color-grayscale-gray4);
            border-radius: 5px;
        }

        @media screen and (max-width: 700px) {
            height: calc((var(--vh, 1vh) * 100));
            position: fixed;
            top: 0;
        }
        @media screen and (max-width: 500px) {
            width: 100%;
            right: auto;
            left: 100%; 
            transition: left 600ms var(--ease-out-cubic), visibility 600ms;
        }
    }


    // Liste de liens
    #{$ovf}__list {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        opacity: 0;
        transform: translateX(50px);
        transition: opacity 800ms, transform 800ms var(--ease-out-quart);

        @media screen and (max-width: 600px) { padding-top: 30px; }
    }


    // Conteneur du lien
    #{$ovf}__list-item {
        display: flex;
        align-items: flex-start;
        margin-bottom: 35px;

        &:last-child {
            margin-bottom: 45px;
        }

        @media screen and (max-width: 1024px) { margin-bottom: 25px; }
        @media screen and (max-width: 768px) { margin-bottom: 15px; }
    }


    // Lien
    #{$ovf}__link {
        font-family: var(--font-family-2); // Canela
        font-size: var(--small-title-font-size); // 25px
        font-weight: 500;
        line-height: 1.4em;
        color: var(--color-grayscale-gray10);
        transition: color 300ms;
        display: flex;
        align-items: flex-start;

        &:hover {
            color: var(--color-blue-marine);
        }

        @media screen and (max-width: 700px) { color: var(--color-blue-marine); }
    }


    // Svg
    #{$ovf}__svg {
        stroke: var(--color-blue-marine);
        stroke-width: 4.5px;
        fill: none;
        margin-right: 35px;
        transform: translateY(calc(15px - 50%));
        width: 22px;
        height: 22px;
        min-width: 22px;
        min-height: 22px;

        @media screen and (max-width: 768px) { transform: translateY(calc(13px - 50%)); }
        @media screen and (max-width: 425px) { transform: translateY(calc(12px - 50%)); }
    }


    // Bouton de fermeture
    #{$ovf}__close {
        height: 12px;
        font-family: var(--font-family-1); // Maison Neue
        font-size: 1.1rem;
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: 0.1em;
        line-height: 15px;
        color: var(--color-blue-marine);
        opacity: 1;
        transition: opacity 300ms;
        cursor: pointer;
        padding-right: 35px;
        position: relative;

        &::after, &::before {
            content: "";
            width: 14px;
            height: 1px;
            background: var(--color-blue-marine);
            opacity: 1;
            transition: opacity 300ms;
            position: absolute;
            top: 50%;
            right: 0;
        }

        &::after { transform: translateY(-50%) rotate(-45deg); }
        &::before { transform: translateY(-50%) rotate(45deg); }

        &:hover {
            opacity: 0.5;

            &::after, &::before {
                opacity: 0.5;
            }
        }

        @media screen and (max-width: 700px) {
            display: none;
        }
    }


    // Bouton de fermeture | Mobile
    #{$ovf}__close-mobile {
        width: var(--close-button-dimension);
        height: var(--close-button-dimension);
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: calc((var(--vh, 1vh) * 100) - var(--close-button-dimension) - 20px);
        right: 20px;
        visibility: hidden;
        pointer-events: none;
        
        &:before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: var(--color-base-white);
            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
            transition: background-color 300ms;
        }

        &:hover:before {
            background-color: var(--color-blue-light-blue);
        }

        @media screen and (min-width: 700px) { display: none; }
        @media screen and (max-width: 600px) {
            top: calc((var(--vh, 1vh) * 100) - var(--close-button-dimension) - 15px);
            right: 15px;
        }
    }


    // + 
    #{$ovf}__plus {
        width: 16px;
        height: 16px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        transition: transform 300ms;

        &::before,
        &::after {
            content: '';
            width: 16px;
            height: 2px;
            background: var(--color-blue-light-blue);
            transition: background 300ms;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
        }

        &::after {
            transform: translate(-50%,-50%) rotate(90deg);
        }
    }
}


// Overlay | Actif
.show-overlay-fastlinks {

    // Effet de l'ouverture sur un ou plusieurs élément du header
    .header {
        $h: '.header'; // Variable désignant le header

        // Boîte
        #{$h}__box {
            
            &:first-child {

                &:before {
                    opacity: 1;
                    visibility: visible;
                }
            }
        }


        // Texte dans les boîtes
        #{$h}__box-text {
            
            &:first-child {
    
                span {
                    &:first-child {
                        opacity: 0;
                    }
    
                    &:not(:first-child) {
                        opacity: 1;
                    }
                }
            }
        }
    
        // Le plus
        #{$h}__plus {
            transform: translateY(-3px) rotate(45deg);
        }
    }


    // Overlay des liens rapides | Actif
    .overlay-fastlinks {
        $ovf: '.overlay-fastlinks'; // Variable désignant l'overlay fastlinks


        // Bouton de fermeture | Mobile | Actif
        #{$ovf}__close-mobile {
            visibility: visible;
            pointer-events: all;
            transition: visibility 300ms;
        }


        // + | Actif
        #{$ovf}__plus {
            transform: translate(-50%, -50%) rotate(45deg);
        }


        // Fond transparent | Actif
        #{$ovf}__background {
            opacity: 1;
            visibility: visible;
            transition: opacity 300ms 450ms, visibility 300ms 450ms;
            pointer-events: all;
        }


        // Conteneur | Actif
        #{$ovf}__container {
            visibility: visible;
            right: 0;
            pointer-events: all;

            @media screen and (max-width: 500px) { 
                right: auto;
                left: 0; 
            }
        }


        // Liste de lien | Actif
        #{$ovf}__list {
            opacity: 1;
            transform: translateX(0);
        }
    }
}


// Overlay | Fermeture
.show-overlay-fastlinks.closing-overlay-fastlinks {

    // Effet de la fermeture sur un ou plusieurs élément du header
    .header {
        $h: '.header'; // Variable désignant le header


        // Boîte
        #{$h}__box {
            
            &:first-child {

                &:before {
                    opacity: 0;
                    visibility: hidden;
                }
            }
        }


        // Texte dans les boîtes
        #{$h}__box-text {
            
            &:first-child {
    
                span {
                    &:first-child {
                        opacity: 1;
                    }
    
                    &:not(:first-child) {
                        opacity: 0;
                    }
                }
            }
        }
    
        // Le plus
        #{$h}__plus {
            transform: translateY(-3px) rotate(0deg);
        }
    }


    // Overlay de liens rapides | Fermeture
    .overlay-fastlinks {
        $ovf: '.overlay-fastlinks'; // Variable désignant l'overlay fastlinks


        // Bouton de fermeture | Mobile | Fermeture
        #{$ovf}__close-mobile {
            visibility: hidden;
            pointer-events: none;
        }


        // + | Fermeture
        #{$ovf}__plus {
            transform: translate(-50%, -50%);
        }


        // Fond transparent | Fermeture
        #{$ovf}__background {
            opacity: 0;
            visibility: hidden;
            transition: opacity 200ms, visibility 200ms;
            pointer-events: none;
        }


        // Conteneur | Fermeture
        #{$ovf}__container {
            visibility: hidden;
            right: calc(var(--overlay-fastlinks-width) * -1);
            pointer-events: none;

            @media screen and (max-width: 500px) { 
                right: auto;
                left: 100%; 
            }
        }


        // Liste de lien | Fermeture
        #{$ovf}__list {
            opacity: 0;
            transform: translateX(50px);
        }
    }
}


// Quand l'alerte mobile est présente
.show-alerts {

    // Overlay de liens rapides
    .overlay-fastlinks {
        $ovf: '.overlay-fastlinks'; // Variable désignant l'overlay fastlinks

        @media screen and (max-width: 800px) {
            
            
            // Conteneur
            #{$ovf}__container {
                height: calc((var(--vh, 1vh) * 100)
                - var(--header-height) 
                - var(--alert-mobile-height));
    
                top: calc(var(--header-height) + var(--alert-mobile-height));
    
                @media screen and (max-width: 700px) {
                    height: calc((var(--vh, 1vh) * 100));
                    top: 0;
                }
            }
    
    
            // Fond transparent
            #{$ovf}__background {
                top: var(--alert-mobile-height);
            }
        }
    }
}