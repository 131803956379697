@use 'components';


//--------------------
// Variables globales
//--------------------
:root {
    --alert-mobile-height: 45px;
}


//-------------------
// Variables locales
//-------------------
.alert {
    
}


//-------------------
// Variables locales
//-------------------
.alert-mobile {
    --left-section-width: 116px;
    
    @media screen and (max-width: 768px) { --left-section-width: 90px; }
    @media screen and (max-width: 700px) { --left-section-width: 37px; }


    --right-section-width: 62px;
    
    @media screen and (max-width: 768px) { --right-section-width: 37px; }
    @media screen and (max-width: 600px) { --right-section-width: 32px; }
}


// ----------------
// Alert
// ----------------
.alert {
    opacity: 0;
    visibility: hidden;
    transition: opacity 300ms, visibility 300ms;
    pointer-events: none;
    position: absolute;
    top: calc(var(--header-height) + 30px);
    right: 40px;
    z-index: 20;

    @media screen and (max-width: 800px) { display: none; }

    &--no-alert {
        display: none;
    }

    $al: '.alert'; // Variable désignant l'alerte


    // Conteneur
    #{$al}__container {
        width: 320px;
        height: auto;
        background: var(--color-red-red);
        padding: 30px;
        position: relative;
    }


    // X
    #{$al}__x {
        width: 12px;
        height: 12px;
        cursor: pointer;
        position: absolute;
        top: 20px;
        right: 20px;
    }


    // Les lignes du X
    #{$al}__line {
        width: 1px;
        height: 100%;
        background: var(--color-base-white);
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;

        &:first-child {
            transform: translate(-50%,-50%) rotate(-45deg);
        }

        &:nth-child(2) {
            transform: translate(-50%,-50%) rotate(45deg);
        }
    }


    // Nav
    #{$al}__slider-nav {
        display: flex;
        align-items: center;
        margin-bottom: 35px;
    }


    // Svg
    #{$al}__svg {

        // Avertissement
        &--warning {
            width: 22px;
            height: 20px;
            stroke: var(--color-base-white);
            stroke-width: 2px;
            fill: none;
            margin-right: 20px;
        }

        // Flèches
        &--chevron {
            width: 10px;
            height: 6px;
            stroke: var(--color-base-white);
            stroke-width: 3px;
            fill: none;
        }
    }


    // Les nombres du nav
    #{$al}__slider-number {
        margin-right: 20px;
        transform: translateY(2px);

        // Tous les éléments
        .slideNumber .current,
        .slash,
        .slideNumber .total {
            font-family: var(--font-family-1); // Maison Neue
            font-size: 1.4rem;
            font-weight: 500;
            color: var(--color-base-white);
        }
    }


    // Conteneur des flèches
    #{$al}__arrows-box {
        display: flex;
        align-items: center;
    }


    // Flèche | Précédent
    #{$al}__arrows-box-arrow-prev {
        width: 15px;
        height: 10px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        transition: transform 400ms var(--ease-out-cubic);

        svg {
            transform: rotate(90deg);
        }

        &:hover {
            transform: translateX(-5px);
        }
    }


    // Flèche | Suivant
    #{$al}__arrows-box-arrow-next {
        width: 15px;
        height: 10px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        transition: transform 400ms var(--ease-out-cubic);

        svg {
            transform: rotate(-90deg);
        }

        &:hover {
            transform: translateX(5px);
        }
    }


    // Slide
    #{$al}__slider-slide {
        &--link {
            opacity: 1;
            transition: opacity 300ms;

            &:hover {
                opacity: 0.5;
            }
        }
    }


    // Date
    #{$al}__date {
        font-family: var(--font-family-1); // Maison Neue
        font-size: 1.2rem;
        letter-spacing: 0.1em;
        font-weight: 500;
        color: var(--color-base-white);
        text-transform: uppercase;
        margin-bottom: 10px;
    }


    // Titre
    #{$al}__title {
        font-family: var(--font-family-1); // Maison Neue
        font-size: 1.7rem;
        font-weight: 500;
        line-height: 1.6em;
        color: var(--color-base-white);
    }
}



// ----------------
// Alert - Mobile
// ----------------
.alert-mobile {
    width: 100%;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    position: absolute;
    top: calc(var(--alert-mobile-height) * -1);
    transition: opacity 300ms, visibility 300ms, top 3000ms var(--ease-out-cubic);
    left: 0;
    z-index: 20;
    display: none;

    @media screen and (max-width: 800px) { display: block; }
    
    $alm: '.alert-mobile'; // Variable désignant l'alerte mobile


    // Conteneur
    #{$alm}__container {
        width: 100%;
        height: var(--alert-mobile-height);
        padding: 5px var(--x-spacing-50);
        background: var(--color-red-red);
        display: flex;
        justify-content: space-between;
    }


    // Section de gauche
    #{$alm}__left-section {
        width: var(--left-section-width);
        padding-right: var(--x-spacing-50);
        border-right: 1px solid rgba($color: #fff, $alpha: 0.5);

        @media screen and (max-width: 600px) { display: none; }
    }


    // Nav
    #{$alm}__slider-nav {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;

        @media screen and (max-width: 700px) { display: none; }
    }


    // Conteneur des chiffres
    #{$alm}__slider-number .slideNumber {
        display: flex;
    }


    // Svg
    #{$alm}__svg {

        // Avertissement
        &--warning {
            width: 22px;
            height: 20px;
            stroke: var(--color-base-white);
            stroke-width: 2px;
            fill: none;
            margin-right: 20px;
        }
    }


    // Les nombres du nav
    #{$alm}__slider-number {
        transform: translateY(2px);

        // Tous les éléments
        .slideNumber .current,
        .slash,
        .slideNumber .total {
            font-family: var(--font-family-1); // Maison Neue
            font-size: 1.4rem;
            font-weight: 500;
            color: var(--color-base-white);
        }
    }


    // Section du centre
    #{$alm}__center-section {
        width: calc(100% 
        - var(--left-section-width) 
        - var(--right-section-width) 
        - (var(--x-spacing-50) * 2));
        height: 100%;

        @media screen and (max-width: 600px) {
            width: calc(100% 
            - var(--right-section-width) 
            - var(--x-spacing-50));
        }
    }


    // Slider
    #{$alm}__slider {
        height: 100%;

        .slick-list,
        .slick-track,
        .slick-slide {
            height: 100%!important;
        }

        .slick-slide div {
            height: 100%!important;
        }
    }


    // Slide
    #{$alm}__slider-slide {
        width: 100%;
        height: 100%;
        display: flex!important;
        align-items: center;
    }


    // Date
    #{$alm}__date {
        font-family: var(--font-family-1); // Maison Neue
        font-size: 1.1rem;
        font-weight: 500;
        color: var(--color-base-white);
        text-transform: uppercase;
        margin-right: 20px;
        white-space: nowrap;
        transform: translateY(1px);
    }


    // Titre
    #{$alm}__title {
        font-family: var(--font-family-1); // Maison Neue
        font-size: 1.4rem;
        font-weight: 500;
        line-height: 1em;
        color: var(--color-base-white);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        @media screen and (max-width: 500px) { font-size: 1.3rem; }
    }


    // Section de droite
    #{$alm}__right-section {
        width: var(--right-section-width);
        height: 100%;
        padding-left: var(--x-spacing-50);
        border-left: 1px solid rgba($color: #fff, $alpha: 0.5);
        position: relative;
    }


    // X
    #{$alm}__x {
        width: 12px;
        height: 12px;
        cursor: pointer;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
    }


    // Les lignes du X
    #{$alm}__line {
        width: 1px;
        height: 100%;
        background: var(--color-base-white);
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;

        &:first-child {
            transform: translate(-50%,-50%) rotate(-45deg);
        }

        &:nth-child(2) {
            transform: translate(-50%,-50%) rotate(45deg);
        }
    }
}