@use 'components';


//--------------------
// Variables globales
//--------------------
:root {

}


//-------------------
// Variables locales
//-------------------
.overlay-search {
    
}


// ----------------
// Overlay | Search
// ----------------
.overlay-search {
    pointer-events: none;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 30;

    $ovs: '.overlay-search'; // Variable désignant l'overlay search


    // Fond transparent
    #{$ovs}__background {
        background: rgba($color: #000000, $alpha: 0.3);
        opacity: 0;
        visibility: hidden;
        transition: opacity 300ms, visibility 300ms;
        pointer-events: none;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
    }


    // Conteneur
    #{$ovs}__container {
        height: 0;
        background: var(--color-base-white);
        visibility: hidden;
        pointer-events: none;
        overflow: hidden;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        transition: height 600ms var(--ease-out-cubic), visibility 600ms;
    }


    // Contenu
    #{$ovs}__content {
        width: 100%;
        height: calc(100% - 85px);
        padding-top: calc(var(--y-spacing-170) - 85px);
        padding-right: var(--x-spacing-200);
        padding-bottom: var(--y-spacing-110);
        padding-left: var(--x-spacing-200);
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        overflow-y: scroll;

        scrollbar-width: none; // Enlève la barre de défilement

        &::-webkit-scrollbar { display: none; } // Enlève la barre de défilement 
    }


    // Conteneur du bouton de fermeture
    #{$ovs}__button-container {
        width: 100%;
        height: 85px;
        opacity: 0;
        position: relative;
        transform: translateY(-50px);
        transition: opacity 300ms, transform 300ms var(--ease-out-quart);
    }


    // Bouton de fermeture
    #{$ovs}__close {
        height: 12px;
        font-family: var(--font-family-1); // Maison Neue
        font-size: 1.1rem;
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: 0.1em;
        line-height: 15px;
        color: var(--color-blue-marine);
        opacity: 1;
        transition: opacity 300ms;
        cursor: pointer;
        padding-right: 35px;
        position: absolute;
        right: var(--x-spacing-50);
        bottom: 25px;
        

        &::after, &::before {
            content: "";
            width: 14px;
            height: 1px;
            background: var(--color-blue-marine);
            opacity: 1;
            transition: opacity 300ms;
            position: absolute;
            top: 50%;
            right: 0;
        }

        &::after { transform: translateY(-50%) rotate(-45deg); }
        &::before { transform: translateY(-50%) rotate(45deg); }

        &:hover {
            opacity: 0.5;

            &::after, &::before {
                opacity: 0.5;
            }
        }
    }


    // Barre de recherche
    #{$ovs}__search-form {
        width: 100%;
        opacity: 0;
        transform: translateY(-50px);
        margin-bottom: var(--y-spacing-70);
        transition: opacity 300ms, transform 300ms var(--ease-out-quart);

        $sf: '.search-field'; // Variable désignant le search-field


        // Input
        #{$sf}__input {
            color: var(--color-blue-marine);

            &::placeholder {
                color: var(--color-blue-marine);
                opacity: 0.5;
            }
        }


        // Le X 
        #{$sf}__x {
            background: var(--color-grayscale-gray1);

            &:before, &:after {
                background: var(--color-blue-marine);
                transition: background 300ms;
            }

            &:hover {
                background: var((--color-blue-light-blue));

                &:before, &:after {
                    background: var(--color-base-white);
                }
            }
        }


        // Bouton pour lancer la recherche
        #{$sf}__button {
            background: var(--color-blue-darker-marine);
    
            &:hover {
                background: var(--color-blue-light-blue);
            }
        }


        // Réglages de l'autofill
        :-webkit-autofill,
        :-webkit-autofill:hover, 
        :-webkit-autofill:focus, 
        :-webkit-autofill:active {
            -webkit-box-shadow: 0 0 0 100px var(--color-base-white) inset !important;
            -webkit-text-fill-color: var(--color-blue-marine);
        }
    }


    // Liste de résultats populaires
    #{$ovs}__list {
        padding-right: 25px;
        padding-bottom: var(--y-spacing-60);
        opacity: 0;
        transform: translateY(-50px);
        transition: opacity 300ms, transform 300ms var(--ease-out-quart);
    }


    // Conteneur des liens 
    #{$ovs}__list-item {
        margin-bottom: 20px;

        &:last-child {
            margin-bottom: 0;
        }
    }


    // Liens
    #{$ovs}__link {
        font-family: var(--font-family-1); // Maison Neue
        font-size: 2.2rem;
        line-height: 1.4;
        color: var(--color-blue-marine);
        transition: color 300ms;

        mark {  
            transition: color 300ms;
        }

        &:hover {
            color: var(--color-grayscale-gray11);

            mark {
                color: var(--color-grayscale-gray11);    
            }
        }

        @media screen and (max-width: 768px) { font-size: 2.1rem; }
        @media screen and (max-width: 600px) { font-size: 2rem; }
        @media screen and (max-width: 500px) { font-size: 1.9rem; }
        @media screen and (max-width: 400px) { font-size: 1.8rem; }
    }


    // Bouton 
    #{$ovs}__button {
        opacity: 0;
        transform: translateY(-50px);
        transition: opacity 300ms, transform 300ms var(--ease-out-quart);
    }
}


// Overlay | Actif
.show-overlay-search {


    // Overlay de recherche | Actif
    .overlay-search {
        $ovs: '.overlay-search'; // Variable désignant l'overlay search


        // Fond transparent | Actif
        #{$ovs}__background {
            opacity: 1;
            visibility: visible;
            pointer-events: all;
        }


        // Conteneur | Actif
        #{$ovs}__container {
            height: 768px;
            visibility: visible;
            pointer-events: all;

            @media screen and (max-width: 600px) { height: calc((var(--vh, 1vh) * 100)); }

            @media screen and (max-height: 768px) { height: calc((var(--vh, 1vh) * 100)); }

            @media screen and (max-width: 600px) and (max-height: 600px) { height: calc((var(--vh, 1vh) * 100)); } 
            @media screen and (max-width: 600px) and (max-height: 768px) { height: calc((var(--vh, 1vh) * 100)); } 
        }


        // Conteneur du bouton de fermeture | Actif
        #{$ovs}__button-container {
            opacity: 1;
            transform: translateY(0);
        }


        // Barre de recherche | Actif
        #{$ovs}__search-form {
            opacity: 1;
            transform: translateY(0);
        }


        // Liste de résultats populaires | Actif
        #{$ovs}__list {
            opacity: 1;
            transform: translateY(0);
        }


        // Bouton | Actif
        #{$ovs}__button {
            opacity: 1;
            transform: translateY(0);
        }
    }
}


// Overlay | Fermeture
.show-overlay-search.closing-overlay-search {


    // Overlay de recherche | Fermeture
    .overlay-search {
        $ovs: '.overlay-search'; // Variable désignant l'overlay search


        // Fond transparent | Fermeture
        #{$ovs}__background {
            opacity: 0;
            visibility: hidden;
            pointer-events: none;
        }


        // Conteneur | Fermeture
        #{$ovs}__container {
            height: 0;
            visibility: hidden;
            pointer-events: none;
        }


        // Conteneur du bouton de fermeture | Actif
        #{$ovs}__button-container {
            opacity: 0;
            transform: translateY(-50px);
        }


        // Barre de recherche | Fermeture
        #{$ovs}__search-form {
            opacity: 0;
            transform: translateY(-50px);
        }


        // Liste de résultats populaires | Fermeture
        #{$ovs}__list {
            opacity: 0;
            transform: translateY(-50px);
        }


        // Bouton | Fermeture
        #{$ovs}__button {
            opacity: 0;
            transform: translateY(-50px);
        }
    }
}