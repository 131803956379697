//--------------------
// Variables globales
//--------------------
:root {
    
}


//-------------------
// Variables locales
//-------------------
.similar-news {
    --img-width: 247px;

    @media screen and (max-width: 800px) { --img-width: 225px; }
    @media screen and (max-width: 700px) { --img-width: 200px; }
}


// ----------------
// Similar news
// ----------------
.similar-news {
    $sn: '.similar-news'; // Variable désignant l'actualité similaire


    // Conteneur
    #{$sn}__news-container {
        width: 100%;
        display: inline-flex;
        padding-bottom: var(--y-spacing-40);
        border-bottom: 1px solid var(--color-grayscale-gray3);

        @media screen and (max-width: 600px) { 
            flex-direction: column; 
            align-items: flex-start;
        }
    }



    // Boîte contenant l'image (nécessaire lorsqu'on utilise un padding en pourcentage)
    #{$sn}__picture-box {
        width: var(--img-width);
        margin-right: 60px;

        @media screen and (max-width: 800px) { margin-right: 50px; }
        @media screen and (max-width: 700px) { margin-right: 40px; }
        @media screen and (max-width: 600px) {
            width: 100%;
            margin-right: 0;
            margin-bottom: 30px;
        }
    }


    // Conteneur de l'image
    #{$sn}__picture {
        width: 100%;
        padding-top: 104%; // Se base sur la largeur du parent
        position: relative;
        overflow: hidden;
    }


    // Image
    #{$sn}__img {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transform: scale(1);
        transition: transform 600ms var(--ease-out-quart);
    }


    // Effet d'hover du conteneur sur l'image
    #{$sn}__news-container:hover #{$sn}__img {
        transform: scale(1.1);
    }


    // Boîte de texte
    #{$sn}__text-block {
        width: calc(100% - var(--img-width));
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;

        @media screen and (max-width: 600px) { width: 100%; }
    }


    // Surtitre
    #{$sn}__surtitle {
        font-family: var(--font-family-1); // Maison Neue
        font-size: 1.2rem;
        font-weight: 700;
        line-height: 1.4;
        letter-spacing: 0.1em;
        color: var(--color-blue-blue);
        text-transform: uppercase;
        margin-bottom: 25px;

        @media screen and (max-width: 400px) { margin-bottom: 20px; }
    }


    // Titre
    #{$sn}__title {
        font-family: var(--font-family-2); // Canela
        font-size: 3.5rem;
        font-weight: 500;
        color: var(--color-blue-marine);
        transition: color 300ms;

        @media screen and (max-width: 900px) { font-size: 3rem; }
        @media screen and (max-width: 600px) { font-size: 2.5rem; }
        @media screen and (max-width: 400px) { font-size: 2rem; }
    }


    // Effet d'hover du conteneur sur le titre
    #{$sn}__news-container:hover #{$sn}__title {
        color: var(--color-blue-blue);
    }
}