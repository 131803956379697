@use 'components';


//--------------------
// Variables globales
//--------------------
:root {
    
}


//-------------------
// Variables locales
//-------------------
.overlay-calendar {
    --overlay-calendar-width: 600px;
    --top-section-height: 70px;
}


// ----------------
// Overlay | Calendar
// ----------------
.overlay-calendar {
    pointer-events: none;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 30;
    overflow-x: hidden;

    $ovc: '.overlay-calendar'; // Variable désignant l'overlay calendar


    // Fond transparent
    #{$ovc}__background {
        background: rgba($color: #000000, $alpha: 0.3);
        opacity: 0;
        visibility: hidden;
        transition: opacity 300ms, visibility 300ms;
        pointer-events: none;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
    }


    // Conteneur
    #{$ovc}__container {
        width: var(--overlay-calendar-width);
        height: calc((var(--vh, 1vh) * 100));
        background: var(--color-base-white);
        box-shadow: inset 0px 10px 15px -10px rgba(0,0,0,0.04);
        -webkit-box-shadow: inset 0px 10px 15px -10px rgba(0,0,0,0.04);
        -moz-box-shadow: inset 0px 10px 15px -10px rgba(0,0,0,0.04);
        visibility: hidden;
        pointer-events: none;
        overflow-y: auto;
        overflow-x: hidden;
        position: fixed;
        top: 0;
        right: calc(var(--overlay-calendar-width) * -1);
        transition: right 600ms var(--ease-out-cubic), visibility 600ms;

        @media screen and (max-width: 700px) { 
            width: 100%; 
            right: auto;
            left: 100%;
            transition: left 600ms var(--ease-out-cubic), visibility 600ms;
        }
    }


    // Section du haut
    #{$ovc}__top-section {
        width: 100%;
        height: var(--top-section-height);
        background: var(--color-blue-darker-marine);
        position: relative;
    }


    // Bouton de fermeture
    #{$ovc}__close {
        height: 12px;
        font-family: var(--font-family-1); // Maison Neue
        font-size: 1.1rem;
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: 0.1em;
        line-height: 15px;
        color: var(--color-base-white);
        opacity: 1;
        transition: opacity 300ms;
        cursor: pointer;
        padding-right: 35px;
        position: absolute;
        top: 50%;
        right: var(--x-spacing-40);
        transform: translateY(-50%);

        &::after, &::before {
            content: "";
            width: 14px;
            height: 1px;
            background: var(--color-base-white);
            opacity: 1;
            transition: opacity 300ms;
            position: absolute;
            top: 50%;
            right: 0;
        }

        &::after { transform: translateY(-50%) rotate(-45deg); }
        &::before { transform: translateY(-50%) rotate(45deg); }

        &:hover {
            opacity: 0.5;

            &::after, &::before {
                opacity: 0.5;
            }
        }
    }


    // Section du bas 
    #{$ovc}__bottom-section {
        height: calc(100% - var(--top-section-height));
        padding: 50px var(--x-spacing-60);
        background: var(--color-base-white);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        @media screen and (max-height: 700px) { 
            height: auto;
            justify-content: initial; 
        }

        @media screen and (max-width: 600px) {
            height: calc(100% - var(--top-section-height));
            justify-content: center;
        }

        @media screen and (max-height: 600px) { 
            height: auto;
            justify-content: initial; 
        }

        @media screen and (max-width: 500px) {
            height: calc(100% - var(--top-section-height));
            justify-content: center;
        }

        @media screen and (max-height: 500px) { 
            height: auto;
            justify-content: initial; 
        }
    }
}


// Overlay | Actif
.show-overlay-calendar {
    $ovc: '.overlay-calendar'; // Variable désignant l'overlay calendar


    // Overlay de calendrier | Actif
    .overlay-calendar {


        // Fond transparent | Actif
        #{$ovc}__background {
            opacity: 1;
            visibility: visible;
            pointer-events: all;
        }


        // Conteneur | Actif
        #{$ovc}__container {
            right: 0;
            visibility: visible;
            pointer-events: all;

            @media screen and (max-width: 700px) {  
                right: auto;
                left: 0;
            }
        }
    }
}


// Overlay | Fermeture
.show-overlay-calendar.closing-overlay-calendar {
    $ovc: '.overlay-calendar'; // Variable désignant l'overlay calendar


    // Overlay de calendrier | Fermeture
    .overlay-calendar {
    

        // Fond transparent | Fermeture
        #{$ovc}__background {
            opacity: 0;
            visibility: hidden;
            pointer-events: none;
        }


        // Conteneur | Fermeture
        #{$ovc}__container {
            right: calc(var(--overlay-calendar-width) * -1);
            visibility: hidden;
            pointer-events: none;

            @media screen and (max-width: 700px) {  
                right: auto;
                left: 100%;
            }
        }
    }
}