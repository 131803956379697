// -------------
// Importations
// -------------
@use '../../vendors' as *;


@mixin back-to-top-and-links($selector: '.back-to-top-and-links') {

    // ------------------
    // Variables locales
    // ------------------
    #{$selector} {
        --back-to-top-and-links__arrow-link--dimension: 56px;
        @include media('<=1200px') { --back-to-top-and-links__arrow-link--dimension: 52px; }
        @include media('<=1024px') { --back-to-top-and-links__arrow-link--dimension: 48px; }
        @include media('<=600px')  { --back-to-top-and-links__arrow-link--dimension: 44px; }
        --back-to-top-and-links__circle-link--margin-bottom: 10px;
    }

    // -----------------
    // back-to-top-and-links
    // -----------------
    #{$selector} {
        display: flex;
        align-items: flex-end;
        position: absolute;
        width: var(--back-to-top-and-links__arrow-link--dimension);
        top: 0;
        right: 25px;
        bottom: calc(0px - (var(--back-to-top-and-links__arrow-link--dimension) * 0.5));
        pointer-events: none;
        z-index: 10;

        @include media('<=768px') { right: 20px; }
        @include media('<=700px') { bottom: calc(-55px - (var(--back-to-top-and-links__arrow-link--dimension) * 0.5)); }
        @include media('<=600px') { right: 15px; }

        &__container {
            position: sticky;
            bottom: 30px;

            @include media('<=1024px') { bottom: 25px; }
            @include media('<=768px')  { bottom: 20px; }
            @include media('<=600px')  { bottom: 15px; }
        }

        &__on-off-content {
            order: 1;
            opacity: 0;
            transition: opacity 300ms;
        }
        
        &.displayed &__on-off-content {
            opacity: 1;
            pointer-events: all;
            transition: opacity 300ms ease 300ms;
        }

        &__visible-content {
            order: 2;
            position: relative;
            pointer-events: all;
            top: calc(var(--back-to-top-and-links__arrow-link--dimension) + var(--back-to-top-and-links__circle-link--margin-bottom));
            transition: top 300ms var(--ease-out-cubic) 300ms;

            @include media('>1000px') { display: none; }
        }
        
        &.displayed &__visible-content {
            top: 0px;
            transition: top 300ms var(--ease-out-cubic);
        }

        &__circle-link {
            margin-bottom: var(--back-to-top-and-links__circle-link--margin-bottom);
        }

        &__circle-link,
        &__circle-fastlinks,
        &__arrow-link {
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            width: var(--back-to-top-and-links__arrow-link--dimension);
            height: var(--back-to-top-and-links__arrow-link--dimension);
            cursor: pointer;
            
            &:before {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                background-color: var(--color-base-white);
                box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
                transition: background-color 300ms;
            }

            &::after {
                content: '';
                background: var(--color-blue-light-blue);
                border: 0px solid var(--color-blue-light-blue);
                transition: border 300ms;
                position: absolute;
                top: 100%;
                right: 0;
                bottom: 0;
                left: 0;
                transition: top 300ms;
                z-index: 1;
            }

            &:hover {

                &::after { 
                    top: 0; 
                    border: 1px solid var(--color-blue-light-blue);
                }
        
                svg {
                    fill: var(--color-base-white);
                }
            }
        }

        &__circle-link {
            &--fastlinks {
                @include media('>700px') { display: none; }
            }
        }

        &__plus {
            width: 16px;
            height: 16px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
    
            &::before,
            &::after {
                content: '';
                width: 16px;
                height: 2px;
                background: var(--color-blue-light-blue);
                transition: background-color 300ms;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%,-50%);
            }
    
            &::after {
                transform: translate(-50%,-50%) rotate(90deg);
                transition: opacity 300ms;
            }
        }

        &__arrow-svg {
            width: 8px;
            height: 24px;
            stroke: var(--color-blue-light-blue);
            stroke-width: 1px;
            fill: var(--color-blue-light-blue);
            transition: stroke 300ms, fill 300ms;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%) rotate(180deg);
            z-index: 2;

            @include media('<=600px') { height: 18px; }
        }

        &__arrow-link:hover &__arrow-svg {
            stroke: var(--color-base-white);
            fill: var(--color-base-white);
        }

        &__svg {
            position: relative;
            width: 20px;
            height: 20px;
            stroke: var(--color-blue-light-blue);
            transition: stroke 300ms;
            z-index: 2;
        }

        &__circle-link:hover &__svg {
            stroke: var(--color-base-white);
        }
    }
}

@include back-to-top-and-links();



