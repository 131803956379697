// |--------------------------------------------------------------------------
// | Root
// |--------------------------------------------------------------------------
// |
// | Déclaration de tout ce qui doit se retrouver dans :root.
// |--------------------------------------------------------------------------

@use '../abstracts' as *;

:root {

    // Générer les variables CSS de couleur
    @each $color, $shades in $colors {
        @each $shade, $value in $shades {
            --color-#{$color}-#{$shade}: #{$value};
        }
    }

    // Générer les variables CSS de easing
    @each $direction, $types in $easings {
        @each $type, $value in $types {
            --ease-#{$direction}-#{$type}: #{$value};
        }
    }

    // Générer les variables CSS de font-size
    @each $title, $sizes in $font-sizes {
        @each $media-query, $size in $sizes {
            @if $media-query == 'base' {
                --#{$title}-font-size: #{$size};
            } @else {
                @media screen and (max-width: #{$media-query}px) {
                    --#{$title}-font-size: #{$size};
                }
            }
        }
    }

    // Générer les variables CSS des espacements sur l'axe des X
    @each $x-spacing, $spacings in $x-spacings {
        @each $media-query, $spacing in $spacings {
            @if $media-query == 'base' {
                --x-spacing-#{$x-spacing}: #{$spacing};
            } @else {
                @media screen and (max-width: #{$media-query}px) {
                    --x-spacing-#{$x-spacing}: #{$spacing};
                }
            }
        }
    }

    // Générer les variables CSS des espacements sur l'axe des Y
    @each $y-spacing, $spacings in $y-spacings {
        @each $media-query, $spacing in $spacings {
            @if $media-query == 'base' {
                --y-spacing-#{$y-spacing}: #{$spacing};
            } @else {
                @media screen and (max-width: #{$media-query}px) {
                    --y-spacing-#{$y-spacing}: #{$spacing};
                }
            }
        }
    }

    // |----------------------------------------------------------------------
    // | TODO: Ajouter les variables des polices de caractères
    // |----------------------------------------------------------------------
        --font-family-1: 'Roboto', sans-serif;
        --font-family-2: 'Canela', sans-serif;
    // |----------------------------------------------------------------------
}