@use '../titles/large-title';


//--------------------
// Variables globales
//--------------------
:root {
    
}


//-------------------
// Variables locales
//-------------------
.jobs {
    
}

// ----------------
// Jobs
// ----------------
.jobs {
    position: relative;
    cursor: pointer;
    padding: 20px 80px 20px 40px;
    padding-right: 180px;
    min-height: var(--rubric__height);
    border-bottom: 1px solid var(--color-grayscale-gray3);
    display: flex;
    align-items: center;
    
    &:first-child { border-top: 1px solid var(--color-grayscale-gray3); }
    
    @media screen and (max-width: 600px) { padding: 20px 130px 20px 20px; }

    $j: '.jobs'; // Variable désignant l'emplois


    #{$j}__subtitle {
        margin-bottom: 4px;
        font-size: var(--rubric__subtitle-font-size);
        color: var(--color-blue-light-blue);
        font-weight: 500;
        line-height: 1.7em;
        text-transform: uppercase;
        letter-spacing: 0.2em;
    }

    #{$j}__title {
        font-size: var(--rubric__font-size);
        line-height: 1.6;
        color: var(--rubric__color);
        font-weight: 500;
        transition: color 0.3s;
    }


    &:hover #{$j}__title {
        color:var(--rubric__color-hover);
    }


    #{$j}__link {
        font-family: var(--font-family-1); // Maison Neue
        font-size: 1.1em;
        font-weight: 700;
        letter-spacing: 0.1em;
        color: var(--color-blue-marine);
        text-transform: uppercase;
        text-decoration: none;
        transition: color 300ms, box-shadow 0.3s, transform 800ms var(--ease-out-cubic);
        padding-bottom: 6px;
        display: block;
        position: absolute;
        top: 50%;
        right: 60px;
        transform: translateY(-50%);

        &::after {
            content: '';
            width: 100%;
            transition: width 300ms;
            height: 2px;
            background: var(--color-blue-light-blue);
            position: absolute;
            bottom: 0;
            left: 0;
        }
        

        @media screen and (max-width: 600px) { right: 20px; }
    }


    &:hover #{$j}__link {
        color: var(--color-blue-light-blue);

        &::after {  
            width: 75%;
        }
    }
}