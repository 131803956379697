:root{
    --form__label-color: var(--color-blue-marine);
    --form__label-size: 1.7rem;
}

.form-label{
    display: block;
    padding-bottom: 5px;
    font-size: var(--form__label-size);
    line-height: 1em;
    color: var(--form__label-color);
}
