@use 'components';
@use 'sass:math';

:root {
    --dynamic__p-font-line-height: 1.6em; //Doit inclure le 'em' autrement les bullets des listes ne seront pas alignées

    --dynamic__table-header-background: var(--color-blue-marine);
    --dynamic__table-header-color: white;
    --dynamic__table-border-color: var(--color-grayscale-gray3);
    --dynamic__table-secondary-header-background: var(--color-grayscale-gray1);

    --dynamic__p-margin: 25px;
    --dynamic__p-margin-under-button: 5px; //Marge sous les boutons si ils tombent sur 2 lignes
    --dynamic__p-margin-button: 40px; //Plus grosse marge pour paragraphe contenant des boutons
}


.dynamic {

    // Couleur de base du texte
    color: var(--color-blue-marine);


    // Espacement du premier et du dernier élément de la zone dynamique
    > :first-child { 
        margin-top: 0 !important; 
    }
    > :last-child { margin-bottom: 0 !important; }

    .intro:first-child {
        p {
            margin-top: 0!important;
        }
    }

    .intro:last-child {
        p {
            margin-bottom: 0!important;
        }
    }


    // H1
    h1 {
        @extend .huge-title;
        color: var(--color-base-white);
        margin-bottom: 100px;
        margin-top: 100px;

        @media screen and (max-width: 1024px) {
            margin-top: 50px;
            margin-bottom: 50px;
        }
    }


    // H2
    h2 {
        @extend .large-title;
        margin-top: 65px;
        margin-bottom: 30px;
    }


    // H3
    h3 {
        @extend .medium-title;
        margin-top: 65px;
        margin-bottom: 30px;
    }


    // H4
    h4 {
        @extend .small-title;
        margin-top: 65px;
        margin-bottom: 30px;
    }


    // Titres un en-dessous de l'autre
    h2 + h3,
    h3 + h4,
    h4 + h5,
    h5 + h6 {
        margin-top: 0;
    }

    // Paragraphe de type intro
    .intro p {
        font-size: var(--introduction-font-size);
        line-height: 1.6em; //80px/70px
        margin-top: 65px;
        margin-bottom: 65px;
    }


    // Paragraphe
    p {
        font-family: var(--font-family-1); // Maison Neue
        font-size: var(--paragraph-font-size);
        margin-top: var(--dynamic__p-margin);
        margin-bottom: var(--dynamic__p-margin);
        line-height: var(--dynamic__p-font-line-height);

        //40px
        &.buttonMargin{
            margin-bottom: calc(var(--dynamic__p-margin) - var(--dynamic__p-margin-under-button));
            .button,.button--secondary{ // Ajouter une marge sous les boutons
                margin-bottom: var(--dynamic__p-margin-under-button)
            }
            &--first{ margin-top: var(--dynamic__p-margin-button) }
            &--last{ margin-bottom: calc(var(--dynamic__p-margin-button) - var(--dynamic__p-margin-under-button) ) }
        }
    }


    // Texte en gras
    strong,b {
        font-weight: 700;
    }


    // Texte en italique
    em,i {
        font-style: italic;
    }


    // Exposant
    sup {
        font-size: 0.7em;
        vertical-align: baseline;
        position: relative;
        top: -0.3em;
    }


    // Indice
    sub {
        font-size: 0.7em;
        vertical-align: baseline;
        position: relative;
        top: 0.3em; 
    }


    // Liens
    a:not(.button):not(.button--secondary){
        color: var(--color-blue-marine);
        text-decoration: none;

        span {
            box-shadow: inset 0 -0.1em 0 0 var(--color-blue-blue);
            transition: box-shadow 0.3s;
        }

        &:hover span {
            box-shadow: inset 0 -0.1em 0 0 var(--color-blue-marine);
        }

        //Icones liens externes et téléchargement
        svg {
            margin-left: 0.25em;
            margin-right: 0.35em;
            width: math.div(11, 17) + em;
            height: math.div(11, 17) + em;
            fill: var(--color-blue-marine);
        }

        &[href$=".pdf"],
        &[href$=".doc"],
        &[href$=".zip"]{
            svg {
                width: math.div(12, 17) + em;
                height: math.div(12, 17) + em;
            }
        }
    }

    h2,
    h3,
    h4,
    pre,
    blockquote {
        a:not(.button):not(.button--secondary) {
            font-family: inherit;
            font-size: inherit;
            font-weight: inherit;
            line-height: inherit;
            color: inherit;
        }
    }

    h2,
    h3,
    h4,
    blockquote {
        a:not(.button):not(.button--secondary) {
            svg {
                margin-left: 0.3em;
                margin-right: 0.3em;
            }
        }
    }

    p:not(.intro p), li {
        a:not(.button):not(.button--secondary) {
            font-family: inherit;
            font-size: inherit;
            font-weight: 500;
            line-height: inherit;
            color: inherit;
        }
    }


    // Listes
    ol,
    ul {
        margin-top: 30px;
        margin-bottom: 30px;

        li {
            &:not(:first-child) { margin-top: 25px; }

            font-size: var(--paragraph-font-size);
            line-height: var(--dynamic__p-font-line-height);

            ol, ul {
                margin-top: 20px;
                margin-bottom: 0;
                width: 100%;
                li {
                    &:not(:first-child) { margin-top: 20px; }

                    padding-bottom: 0;
                    border: none;
                }
            }
        }
    }


    // Liste ordonnée
    ol {
        --ordered-list-padding: 50px;
        @media screen and (max-width: 768px) { --ordered-list-padding: 25px; }
        @media screen and (max-width: 500px) { --ordered-list-padding: 20px; }
        list-style: none;
        counter-reset: li;

        & > li {
            width: calc(100% - var(--ordered-list-padding));
            position: relative;
            left: var(--ordered-list-padding);

            &::before {
                content: counter(li) ".";
                counter-increment: li;
                display: inline-block;
                position: absolute;
                transform: translateX(calc(0px - var(--ordered-list-padding)));
                font-weight: 500;
            }

            ol > li {
                &::before {
                    content: counter(li, lower-alpha) ".";
                }

                ol > li {
                    @media screen and (min-width: 769px) { padding-left: 40px; }

                    &::before {
                        content: counter(li, lower-roman) ".";
                    }
                }
            }
        }
    }


    // Liste non-ordonnée
    ul {
        --unordered-list-padding: 20px;
        @media screen and (max-width: 500px) { --unordered-list-padding: 15px; }

        & > li {
            width: calc(100% - var(--unordered-list-padding));
            position: relative;
            left: var(--unordered-list-padding);

            &::before {
                content: "";
                width: 6px;
                height: 6px;
                position: absolute;
                top: calc(var(--dynamic__p-font-line-height) / 2);
                transform: translate(calc(0px - var(--unordered-list-padding)), -50%);
                background-color: var(--color-blue-light-blue);
                border: 1px solid var(--color-blue-light-blue);
                border-radius: 50%;
                box-sizing: border-box;
            }

            & > ul li {
                &::before {
                    background-color: transparent;
                }

                & > ul li::before {
                    background-color: var(--color-blue-light-blue);
                }
            }
        }
    }


    // Citation
    blockquote {
        position: relative;
        padding-left: 30px;
        margin-top: 35px;
        margin-bottom: 35px;
        border-left: 10px solid var(--color-blue-light-blue);

        p {
            font-size: 2.2rem;
            font-weight: 500;
            line-height: 1.5em;
            color: var(--color-blue-marine);

            @media screen and (max-width: 1024px) { font-size: 2rem; }
            @media screen and (max-width: 768px) { font-size: 1.8rem; }
            @media screen and (max-width: 425px) { font-size: 1.6rem; }
        }
    }


    // Note
    pre {
        font-family: var(--font-family-1); // Maison Neue
        white-space: normal;
        font-size: 1.4rem;
        line-height: 1.714285714285714em; // 24px/14px
        color: var(--color-grayscale-gray4);
        margin-top: 25px;
        margin-bottom: 25px;
    }


    // Zone d'emphase
    .emphasis {
        border: 1px solid var(--color-grayscale-gray3);
        margin-top: 30px;
        margin-bottom: 65px;
        padding: 45px 40px;

        & > :first-child{ margin-top: 0; }
        & > :last-child{ margin-bottom: 0}
    }


    // Zone d'emphase dans un tableau
    .table-wrapper,
    .emphasis {
        // Modifier la marge pour les notes sous les tableaux et bloc d'emphases
        & + pre {
            margin-top: -45px;
        }
        // Modifier la marge pour les tableaux sous les tableaux
        & + .table-wrapper,
        & + .emphasis {
            margin-top: -35px;
        }
    }


    // Tableau
    .table-wrapper {
        overflow-x: auto;
        margin-top: 30px;
        margin-bottom: 65px;

        table {
            overflow-x: hidden;
            width: 100%;
            min-width: 600px;
            border-collapse: collapse;

            tr {
                th,td {
                    padding: 20px 35px;
                    line-height: 1.5625em; // 25px/16px
                    border: 1px solid var(--dynamic__table-border-color);
                    font-size: 1.7rem;

                    @media screen and (max-width: 768px) { font-size: 1.6rem; }
                    @media screen and (max-width: 375px) { font-size: 1.5rem; }

                    & > :first-child{ margin-top: 0; }
                    //& > :last-child{ margin-bottom: 0}

                    & > p, & > pre, & > strong, & > em, & > a, & > span, & > sub, & > sup, & > ul, & > ol {
                        &:last-child {
                            margin-bottom: 0 !important;
                        }
                    }
                }

                th {
                    background-color: var(--dynamic__table-header-background);
                    font-weight: normal;
                    color: var(--dynamic__table-header-color);
                    border-top-color: var(--dynamic__table-header-background);
                    border-bottom-color: var(--dynamic__table-header-background);
                    text-align: left;

                    &:first-child{ border-left-color: var(--dynamic__table-header-background)}
                    &:last-child{ border-right-color: var(--dynamic__table-header-background)}
                }

                td {
                  &.header{
                    background-color: var(--dynamic__table-header-background);
                    color: var(--dynamic__table-header-color);
                  }  
                  &.secondary-header{
                    background-color: var(--dynamic__table-secondary-header-background);
                  }  
                }
            }
        }
    }


    // Vidéos
    .videoWrapper {
        max-width: 560px;
        .ytVideo {
            position: relative;
            padding-bottom: 56.25%;
            height: 0;
            iframe {
                position: absolute;
                top: 0;
                left: 0;
                width: 100% !important;
                height: 100% !important;
            }
        }
    }


    /* ----- Image - No Caption ----- */

    img {

        position: relative;
        max-width: 100%;

        &.fr-dib {

            /* block */

            display: block;
            margin: 30px auto;
            float: none;
            vertical-align: top;

            &.fr-fil {
                margin-left: 0;
            }

            &.fr-fir {
                margin-right: 0;
            }
        }

        &.fr-dii {

            /* Inline */

            display: inline-block;
            float: none;
            vertical-align: bottom;

            &.fr-fil {
                float: left;
                margin: 10px 20px 10px 0;
            }

            &.fr-fir {
                float: right;
                margin: 10px 0 10px 20px;
            }
        }
    }

    /* ----- Image - With Caption ----- */

    .fr-img-caption {

        img {
            width: 100%;
        }

        &.fr-dib {

            /* block */

            display: block;
            margin: 30px auto;
            float: none;
            vertical-align: middle;
            text-align: center;

            &.fr-fil {
                margin-left: 0;
                text-align: left;
            }

            &.fr-fir {
                margin-right: 0;
                text-align: right;
            }

            .fr-img-wrap {

                .fr-inner {
                    font-family: var(--font-family-1); // Maison Neue
                    white-space: normal;
                    font-size: 1.4rem;
                    line-height: 1.714285714285714em; // 24px/14px
                    color: var(--color-grayscale-gray4);
                    margin-top: 25px;
                    margin-bottom: 25px;
                }
            }
        }

        &.fr-dii {

            /* Inline */

            display: inline-block;
            float: none;
            vertical-align: bottom;
            text-align: center;

            &.fr-fil {
                float: left;
                margin-left: 0;
                text-align: left;
            }

            &.fr-fir {
                float: right;
                margin-right: 0;
                text-align: right;
            }
        }
    }

    span.fr-fic.oc-img-rounded img,
    img.fr-fic.oc-img-rounded {
        border-radius: 100%;
        background-clip: padding-box;
    }

    span.fr-fic.oc-img-bordered img,
    img.fr-fic.oc-img-bordered {
        border: solid 10px #ccc;
    }
}