// |--------------------------------------------------------------------------
// | Les Font-face
// |--------------------------------------------------------------------------
// |
// | La déclaration des polices de caractères
// |--------------------------------------------------------------------------

// |--------------------------------------------------------------------------
// | TODO: Ajouter les variables des polices de caractères
// |--------------------------------------------------------------------------
    

// |----Font1 ---------------------------------------------------------------|

    // Normal
    @font-face {
        font-family: 'Roboto';
        src: url('../fonts/Roboto-Regular.ttf');
        font-weight: 400;
        font-style: normal;
        font-display: swap;
    }

    // Normal | Italique
    @font-face {
        font-family: 'Roboto';
        src: url('../fonts/Roboto-Italic.ttf');
        font-weight: 400;
        font-style: italic;
        font-display: swap;
    }

    // Medium
    @font-face {
        font-family: 'Roboto';
        src: url('../fonts/Roboto-Medium.ttf');
        font-weight: 500;
        font-style: normal;
        font-display: swap;
    }

    // Medium | Italique
    @font-face {
        font-family: 'Roboto';
        src: url('../fonts/Roboto-MediumItalic.ttf');
        font-weight: 500;
        font-style: italic;
        font-display: swap;
    }

    // Bold
    @font-face {
        font-family: 'Roboto';
        src: url('../fonts/Roboto-Bold.ttf');
        font-weight: 700;
        font-style: normal;
        font-display: swap;
    }

    // Bold | Italique
    @font-face {
        font-family: 'Roboto';
        src: url('../fonts/Roboto-BoldItalic.ttf');
        font-weight: 700;
        font-style: italic;
        font-display: swap;
    }


// |----Font2 ---------------------------------------------------------------|

    // Normal
    @font-face {
        font-family: 'Canela';
        src: url('../fonts/Canela-Regular.otf');
        font-weight: 400;
        font-style: normal;
        font-display: swap;
    }

    // Normal | Italique
    @font-face {
        font-family: 'Canela';
        src: url('../fonts/Canela-RegularItalic.otf');
        font-weight: 400;
        font-style: italic;
        font-display: swap;
    }

    // Medium
    @font-face {
        font-family: 'Canela';
        src: url('../fonts/Canela-Medium.otf');
        font-weight: 500;
        font-style: normal;
        font-display: swap;
    }

    // Medium | Italique
    @font-face {
        font-family: 'Canela';
        src: url('../fonts/Canela-MediumItalic.otf');
        font-weight: 500;
        font-style: italic;
        font-display: swap;
    }

    // Bold
    @font-face {
        font-family: 'Canela';
        src: url('../fonts/Canela-Bold.otf');
        font-weight: 700;
        font-style: normal;
        font-display: swap;
    }

    // Bold | Italique
    @font-face {
        font-family: 'Canela';
        src: url('../fonts/Canela-BoldItalic.otf');
        font-weight: 700;
        font-style: italic;
        font-display: swap;
    }

// |--------------------------------------------------------------------------