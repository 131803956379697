//--------------------
// Variables globales
//--------------------
:root {
    --header-height: 95px;

    @media screen and (max-width: 900px) { --header-height: 85px }
    @media screen and (max-width: 768px) { --header-height: 75px }
    @media screen and (max-width: 600px) { --header-height: 65px }
}


//-------------------
// Variables locales
//-------------------
.header {

}


// ----------------
// Header
// ----------------
.header {
    transition: padding 300ms var(--ease-out-cubic);

    $h: '.header'; // Variable désignant le header

    
    // Conteneur
    #{$h}__container {
        width: 100%;
        height: var(--header-height);
        display: flex;
        justify-content: space-between;
        position: absolute;
        z-index: 10;
    }


    // Container du logo
    #{$h}__logo-container {
        height: 100%;
        padding: 20px 25px 20px 60px;
        display: flex;
        align-items: center;
        position: relative;

        @media screen and (max-width: 1024px) { padding-left: 50px; }
        @media screen and (max-width: 768px) { padding-left: 30px; }
        @media screen and (max-width: 600px) { padding-left: 25px; }
    }


    // Logo
    #{$h}__logo {
        width: 153px;
        height: 56px;

        @media screen and (max-width: 900px) {  
            width: 127px;
            height: 46px;
        }
    }


    // Conteneur du deuxième logo
    #{$h}__second-logo-container {
        position: absolute;
        top: 50%;
        left: 60px;
        z-index: 5;
        transform: translateY(-50%);

        @media screen and (max-width: 1024px) { left: 50px; }
        @media screen and (max-width: 768px) { left: 30px; }
        @media screen and (max-width: 600px) { left: 25px; }
    }


    // Section droite
    #{$h}__boxes {
        width: var(--overlay-fastlinks-width);
        display: flex;

        @media screen and (max-width: 700px) { width: auto; }
    }


    // Boîte 
    #{$h}__box {
        height: 100%;
        padding: 20px 38px;
        display: flex;
        align-items: center;
        cursor: pointer;
        position: relative;

        &:before {
            content: "";
            width: 100%;
            height: 4px;
            background: var(--color-blue-light-blue);
            opacity: 0;
            visibility: hidden;
            transition: opacity 300ms, visibility 300ms;
            position: absolute;
            bottom: 0;
            left: 0;
        }

        &:hover {
            &:before {
                opacity: 1;
                visibility: visible;
            }
        }

        @media screen and (max-width: 768px) { padding: 20px 30px; }
        @media screen and (max-width: 700px) { 
            width: var(--header-height);
            height: 100%;
            padding: 0; 
            justify-content: center;
        }

        &:first-child {
            position: relative;

            &::after {
                content: "";
                width: 1px;
                height: 36px;
                background: var(--color-grayscale-gray3);
                position: absolute;
                top: 50%;
                right: 0;
                transform: translateY(-50%);
            }

            @media screen and (max-width: 700px) { display: none; }
        }

        &--white {
            background: var(--color-base-white);
        }

        &--blue {
            background: var(--color-blue-darker-marine);
            flex: 1;
        }
    }


    // Texte dans les boîtes
    #{$h}__box-text {
        font-family: var(--font-family-1); // Maison Neue
        font-size: 1.1rem;
        font-weight: 700;
        line-height: 1;
        text-transform: uppercase;
        letter-spacing: 0.1em;
        margin-right: 10px;

        &--blue {
            color: var(--color-blue-marine); 
        }

        &--white {
            color: var(--color-base-white);
        }

        &:first-child {
            position: relative;

            span {
                transition: opacity 300ms;
                
                &:first-child {
                    opacity: 1;
                }

                &:not(:first-child) {
                    opacity: 0;
                    position: absolute;
                    top: 0;
                    left: 0;
                }
            }
        }

        @media screen and (max-width: 700px) { display: none; }
    }
    

    // Les icônes
    #{$h}__box-svg {
        stroke-width: 2px;
        transform: translateY(-2px);

        // Search
        &--search {
            width: 14px;
            height: 14px;
            stroke: var(--color-blue-marine);
            fill: none;
        }

        // Hamburger
        &--hamburger {
            width: 14px;
            height: 11px;
            stroke: var(--color-base-white);
        }

        @media screen and (max-width: 700px) { 

            // Search
            &--search {
                width: 18px;
                height: 18px;
            }
    
            // Hamburger
            &--hamburger {
                width: 18px;
                height: 15px;
            }
        }
    }

    // Le +
    #{$h}__plus {
        width: 10px;
        height: 10px;
        position: relative;
        transform: translateY(-3px) rotate(0);
        transition: transform 600ms var(--ease-out-quart);

        &::after, &::before {
            content: "";
            width: 11px;
            height: 1px;
            background: var(--color-blue-marine);
            position: absolute;
            top: 50%;
            right: 0;
        }
        
        &::before {
            transform: translateY(-50%);
        }

        &::after {
            transform: translateY(-50%) rotate(-90deg);
        }
    }
}


// Quand l'alerte mobile est présente
.show-alerts {
    @media screen and (max-width: 800px) {
        .header {
            padding-top: var(--alert-mobile-height);
        }
    }
}