@use 'components';


//--------------------
// Variables globales
//--------------------
:root {
    
}


//-------------------
// Variables locales
//-------------------
.featured-news {
    
}


// ----------------
// Featured News
// ----------------
.featured-news {
    $fn: '.featured-news'; // Variable désignant les featured-news


    // Conteneur
    #{$fn}__container {
        padding-top: var(--y-spacing-160);
        background: var(--color-blue-marine);

        @media screen and (max-width: 1024px) { padding-top: 60px; }
    }


    // Bloc de texte
    #{$fn}__text-block {
        padding-right: var(--x-spacing-160);
        padding-bottom: var(--y-spacing-150);
        padding-left: var(--x-spacing-160);

        @media screen and (max-height: 800px) { padding-bottom: var(--y-spacing-80); }
        @media screen and (max-height: 700px) { padding-bottom: var(--y-spacing-50); }
    }


    // Surtitre
    #{$fn}__surtitle {
        font-family: var(--font-family-1); // Maison Neue
        font-size: 1.2rem;
        font-weight: 700;
        letter-spacing: 0.1em;
        line-height: 1.4;
        color: var(--color-blue-blue);
        text-transform: uppercase;
        padding-top: 30px;
        border-top: 1px solid rgba($color: #fff, $alpha: 0.2);
        margin-bottom: var(--y-spacing-70);

        @media screen and (max-height: 700px) { margin-bottom: var(--y-spacing-50);; }
        @media screen and (max-width: 1024px) {
            padding-top: 20px;
        }
    }


    // Titre 
    #{$fn}__title {
        font-family: var(--font-family-2); // Canela
        font-size: 11.5rem;
        line-height: 1;
        color: var(--color-base-white);

        @media screen and (max-height: 800px) { font-size: 9rem; }
        @media screen and (max-height: 700px) { font-size: 8rem; }
        @media screen and (max-width: 1024px) { font-size: 9rem; }
        @media screen and (max-width: 768px) { font-size: 8rem; }
        @media screen and (max-width: 425px) { font-size: 5rem; }
    }


    // Liste des actus
    #{$fn}__list {
        padding-right: var(--x-spacing-160);
        padding-bottom: var(--y-spacing-160);
        padding-left: var(--x-spacing-160);
        background: var(--color-grayscale-gray14);
        display: flex;
        flex-direction: column;
        position: relative;

        $n: '.news'; // Variable désignant l'actualité

        &:before {
            content: '';
            width: 100%;
            height: 70px;
            position: absolute;
            top: 0;
            left: 0;
            background: var(--color-blue-marine);

            @media screen and (max-width: 600px) { height: 95px; }
        }

        @media screen and (max-width: 1024px) { padding-bottom: 70px; }


        // Effet d'hover du conteneur sur le titre
        #{$n}__container:hover #{$n}__title {
            color: var(--color-blue-light-blue);
        }
    }


    // Actus
    #{$fn}__list .news {
        margin-bottom: var(--y-spacing-90);

        @media screen and (max-height: 800px) { margin-bottom: var(--y-spacing-80); }
        @media screen and (max-height: 700px) { margin-bottom: var(--y-spacing-70); }
        @media screen and (max-width: 1024px) { margin-bottom: 60px; }

        $n: '.news'; // Variable désignant l'actualité

        // Conteneur de l'image
        #{$n}__picture {
            @media screen and (max-height: 800px) { margin-bottom: 30px; }
            @media screen and (max-height: 700px) { margin-bottom: 25px; }
        }

        // Catégorie / Date (Surtitre)
        #{$n}__surtitle {
            @media screen and (max-height: 800px) { margin-bottom: 20px; }
            @media screen and (max-height: 700px) { margin-bottom: 15px; }
        }

        // Titre
        #{$n}__title {
            @media screen and (max-height: 800px) { font-size: 3.2rem; }
            @media screen and (max-height: 700px) { font-size: 2.9rem; }
        }
    }


    // Bouton
    #{$fn}__button {
        align-self: center;

        @media screen and (max-width: 600px) { align-self: flex-start; }
    }
}