@use 'components';
@use 'sass:math';

//--------------------
// Variables globales
//--------------------
:root {
    
}


//-------------------
// Variables locales
//-------------------
.infos {
    
}


// ----------------
// Infos
// ----------------
.infos {
    width: calc(100% - var(--side-menu-section-width));
    
    $in: '.infos'; // Variable désignant les infos


    // Conteneur
    #{$in}__container {
        padding-left: var(--x-spacing-120);
        padding-right: var(--x-spacing-120);
        padding-top: var(--y-spacing-100);

        @media screen and (min-width: 1601px) { padding-right: 120px; }
    }


    // Texte
    #{$in}__text {
        border-top: solid 1px var(--color-blue-marine);
        padding-top: var(--y-spacing-40);
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }


    // Heure, lieu et prix
    #{$in}__hours, #{$in}__place, #{$in}__price {
        font-family: var(--font-family-1); // Maison Neue
        font-size: 1.7rem;
        line-height: 1.4;
        color: var(--color-blue-marine);
        margin-bottom: 20px;
        display: flex;
        align-items: center;
    }


    #{$in}__link-container {
        display: block;
    }


    // Heure et lieu
    #{$in}__hours, #{$in}__place {
        margin-right: 50px;

        a {
            color: var(--color-blue-marine);
            text-decoration: none;
            line-height: 1.15;

            
            span {
                box-shadow: inset 0 -0.1em 0 0 var(--color-blue-lighter-blue);
                transition: box-shadow 0.3s;
            }
            
            &:hover span {
                box-shadow: inset 0 -1.1em 0 0 var(--color-blue-lighter-blue);
            }

            //Icones liens externes et téléchargement
            svg {
                margin-left: 0.25em;
                margin-right: 0.35em;
                width: math.div(11, 17) + em;
                height: math.div(11, 17) + em;
                fill: var(--color-blue-marine);
            }

            &[href$=".pdf"],
            &[href$=".doc"],
            &[href$=".zip"]{
                svg {
                    width: math.div(12, 17) + em;
                    height: math.div(12, 17) + em;
                }
            }
        }
    }


    // Svg
    #{$in}__svg {
        stroke: var(--color-blue-marine);
        stroke-width: 2px;
        fill: none;
        margin-right: 20px;
        transform: translateY(-2px);
        flex-shrink: 0;


        // Horloge
        &--clock {
            width: 17px;
            height: 17px;
        }


        // Pointer
        &--marker {
            width: 17px;
            height: 17px;
        }


        // Dollar
        &--dollar {
            width: 17px;
            height: 17px;
        }
    }
}