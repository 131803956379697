@use 'components';


//--------------------
// Variables globales
//--------------------
:root {
    
}


//-------------------
// Variables locales
//-------------------
.errors {
    
}


// ----------------
// Pages d'erreurs
// ----------------
.errors {
    width: 100%;
    padding-top: 100px;
    padding-bottom: 100px;
    display: flex;
    justify-content: center;

    $err: '.errors'; // Variable désignant errors


    #{$err}__container {
        background: var(--color-blue-marine);
        padding: 50px 100px;
        display: flex;
        flex-direction: column;
        align-items: center;

        @media screen and (max-width: 768px) {
            width: 100%;
            padding: 50px;
        }

        @media screen and (max-width: 768px) {
            width: 100%;
            padding: 50px 25px;
        }
    }

    #{$err}__home {
        display: flex;
        align-items: center;
        margin-bottom: 25px;
    }

    #{$err}__link {
        width: 15px;
        height: 15px;
        min-width: 15px;
        min-height: 15px;
        margin-right: 15px;
        transform: translateY(-2px);
    }

    #{$err}__svg {
        width: 100%;
        height: 100%;
        fill: var(--color-base-white);
        stroke: none;

        &:hover { fill: var(--color-blue-light-blue); }
        transition: fill 300ms;
    }

    #{$err}__title {
        @extend .large-title;
        color: var(--color-base-white);
        text-align: center;
        padding-bottom: 50px;
    }

    #{$err}__text {
        @extend .paragraph;
        color: var(--color-base-white);
    }

    #{$err}__btn-home {
        @extend .paragraph;
        color: var(--color-base-white);
        transition: color 300ms;
        margin-top: 50px;

        &:hover {
            color: var(--color-blue-light-blue);
        }
    }
}