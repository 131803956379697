@use 'components';


//--------------------
// Variables globales
//--------------------
:root {
    
}


//-------------------
// Variables locales
//-------------------
.featured-events {
    --home-image-width: 641px;

    @media screen and (max-height: 800px) { --home-image-width: 500px; }
    @media screen and (max-height: 700px) { --home-image-width: 441px; }
    @media screen and (max-width: 1300px) { --home-image-width: 600px; }
    @media screen and (max-width: 1200px) { --home-image-width: 541px; }
    @media screen and (max-width: 1100px) { --home-image-width: 500px; }
    @media screen and (max-width: 1000px) { --home-image-width: 441px; }
    @media screen and (max-width: 900px) { --home-image-width: 400px; }
    @media screen and (max-width: 800px) { --home-image-width: 341px; }
    @media screen and (max-width: 700px) { --home-image-width: 100%; }
}


// ----------------
// Featured Events
// ----------------
.featured-events {
    position: relative;
    
    $fe: '.featured-events'; // Variable désignant les featured-events


    // Conteneur
    #{$fe}__container {
        @media screen and (max-width: 700px) { 
            padding: 0 var(--x-spacing-30); 
            padding-bottom: var(--y-spacing-190);
        }


        $ev: '.events'; // Variable désignant l'événement


        // Conteneur
        #{$ev}__container {
            padding-right: var(--x-spacing-120);
            padding-bottom: var(--y-spacing-190);
            margin-bottom: 0;
            align-items: flex-start;
            justify-content: flex-start;

            @media screen and (max-height: 800px) { padding-bottom: var(--y-spacing-170); }
            @media screen and (max-height: 700px) { padding-bottom: var(--y-spacing-150); }
            @media screen and (max-width: 700px) { 
                padding-bottom: 0; 
                margin-bottom: 50px;
            }
        }


        // Annulation de l'effet d'hover du conteneur sur l'image
        #{$ev}__container:hover #{$ev}__img {
            transform: none;
        }

        
        // Boîte contenant l'image (nécessaire lorsqu'on utilise un padding en pourcentage)
        #{$ev}__picture-container {
            width: var(--home-image-width);
            position: relative;

            &:before {
                content: '';
                width: 0%;
                height: 100%;
                transition: width 500ms var(--ease-in-out-cubic);
                background: var(--color-blue-marine);
                position: absolute;
                top: 0;
                left: 0;
                z-index: 10;
            }
        }
        

        // Effet de transition sur la grosse image
        #{$ev}__picture-container.cover {
            &:before {
                width: 100%;
            }
        }


        // Surtitre
        #{$ev}__surtitle {
            margin-bottom: 30px;

            @media screen and (max-height: 800px) { margin-bottom: 25px; }
            @media screen and (max-height: 700px) { margin-bottom: 20px; }
        }


        // Titre
        #{$ev}__title {
            font-size: 6rem;
            margin-bottom: 50px;

            @media screen and (max-height: 800px) { 
                font-size: 5rem;
                margin-bottom: 40px; 
            }
            @media screen and (max-height: 700px) { 
                font-size: 4.5rem;
                margin-bottom: 30px; 
            }
            @media screen and (max-width: 1024px) { font-size: 5rem; }
            @media screen and (max-width: 768px) { font-size: 4.5rem; }
            @media screen and (max-width: 700px) { margin-bottom: 20px; }
            @media screen and (max-width: 600px) { font-size: 4rem; }
            @media screen and (max-width: 425px) { font-size: 3.5rem; }
        }
    }


    // Boîte contenant l'image (nécessaire lorsqu'on utilise un padding en pourcentage)
    #{$fe}__next-picture-container {
        width: 270px;
        position: absolute;
        right: 0;
        bottom: 0;

        &:before {
            content: '';
            width: 0%;
            height: 100%;
            transition: width 500ms var(--ease-in-out-cubic);
            background: var(--color-blue-marine);
            position: absolute;
            top: 0;
            left: 0;
            z-index: 10;
        }

        @media screen and (max-width: 1300px) { width: 250px; }
        @media screen and (max-width: 1200px) { width: 230px; }
        @media screen and (max-width: 1100px) { width: 210px; }
        @media screen and (max-width: 1000px) { width: 190px; }
        @media screen and (max-width: 900px) { width: 170px; }
        @media screen and (max-width: 800px) { width: 150px; }
        @media screen and (max-width: 700px) { display: none; }
    }


    // Effet de transition sur la grosse image
    #{$fe}__next-picture-container.cover {
        &:before {
            width: 100%;
        }
    }


    // Conteneur de l'image
    #{$fe}__next-picture {
        width: 100%;
        padding-top: 113%;
        position: relative;
        overflow: hidden;
    }


    // Image
    #{$fe}__img {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }


    // Slider
    #{$fe}__slider {
        width: calc(100% - var(--home-image-width));
        padding-left: var(--x-spacing-120);

        @media screen and (min-width: 1601px) { padding-left: 0; }
        @media screen and (max-width: 700px) { 
            width: 100%; 
            padding-left: 0;
        }
    }


    // Slide
    #{$fe}__slider-slide {

        $ev: '.events'; // Variable désignant l'événement


        // Block de texte
        #{$ev}__text-block {
            width: 100%;
            padding-top: 120px;
            padding-right: var(--x-spacing-120);
            padding-left: 0;
            
            @media screen and (max-width: 1000px) { padding-top: 60px; }
            @media screen and (max-width: 800px) { padding-top: 30px; }
        }


        // Titre
        #{$ev}__title {
            transition: color 300ms;
        }


        // Effet d'hover du bloc de texte sur le lien
        #{$ev}__text-block:hover #{$ev}__title {
            color: var(--color-blue-light-blue);
        }
    }


    // Nav
    #{$fe}__slider-nav {
        width: 155px;
        height: 78px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: var(--color-blue-light-blue);
        position: absolute;
        top: 0;
        right: 0;
        z-index: 5;

        @media screen and (max-width: 900px) { 
            width: 125px; 
            height: 68px;
        }

        @media screen and (max-width: 600px) { 
            width: 100px; 
            height: 50px;
        }
    }


    // Conteneur des flèches
        #{$fe}__arrows-box {
        display: flex;
        align-items: center;
    }


    // Flèche | Précédent
    #{$fe}__arrows-box-arrow-prev {
        width: 45px;
        height: 19px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        transition: transform 400ms var(--ease-out-cubic);

        svg {
            transform: rotate(90deg);
        }

        &:hover {
            transform: translateX(-10px);
        }

        @media screen and (max-width: 900px) { width: 35px; }
        @media screen and (max-width: 600px) { width: 25px; }
    }


    // Séparateur
    #{$fe}__arrows-box-separator {
        width: 1px;
        height: 19px;
        background: var(--color-base-white);
    }


    // Flèche | Suivant
    #{$fe}__arrows-box-arrow-next {
        width: 45px;
        height: 19px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        transition: transform 400ms var(--ease-out-cubic);

        svg {
            transform: rotate(-90deg);
        }

        &:hover {
            transform: translateX(10px);
        }

        @media screen and (max-width: 900px) { width: 35px; }
        @media screen and (max-width: 600px) { width: 25px; }
    }


    // Les svg
    #{$fe}__svg {
        stroke: var(--color-base-white);

        // Chevron
        &--chevron {
            width: 19px;
            height: 11px;
            stroke-width: 2px;
            fill: none;
        }

        // Flèche
        &--arrow {
            width: 8px;
            height: 24px;
            fill: var(--color-base-white);
            position: absolute;
            top: 0;
            right: 0;

            animation-name: bouncing;
            animation-duration: 1s;
            animation-iteration-count: infinite;
            animation-timing-function: ease-out;
        }

        // Animation de la flèche
        @keyframes bouncing {
            0% { top: 0%; }
            50% { top: 25%; }
            100% { top: 0; }
        }

        // Calendrier
        &--calendar {
            width: 16px;
            height: 16px;
            stroke: var(--color-base-white);
            stroke-width: 2px;
            fill: none;
            margin-right: 15px;
            margin-left: 0;
        }
    }


    // Bouton
    #{$fe}__button {
        position: absolute;
        bottom: calc(var(--y-spacing-190) / 2);
        left: var(--x-spacing-120);
        transform: translateY(50%);

        @media screen and (max-height: 800px) { bottom: calc(var(--y-spacing-170) / 2); }
        @media screen and (max-height: 700px) { bottom: calc(var(--y-spacing-150) / 2); }

        span {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &:hover {
            svg {
                fill: none;
            }
        }

        @media screen and (max-width: 700px) {
            position: relative;
            bottom: initial;
            left: initial;
            transform: none;
        }
    }
}