@use '../titles/medium-title';
@use '../texts/paragraph';


//--------------------
// Variables globales
//--------------------
:root {
    
}


//-------------------
// Variables locales
//-------------------
.side-contact {

}


// ----------------
// Side contact
// ----------------
.side-contact {
    $sc: '.side-contact'; // Variable désignant le side-contact


    // Conteneur
    #{$sc}__container {
        width: 100%;
        padding: 0 var(--x-spacing-110);

        @media screen and (min-width: 1601px) { padding: 0 110px; }
    }


    // Titre
    #{$sc}__title {
        @extend .medium-title;
        color: var(--color-base-white);
        margin-bottom: 45px;

        @media screen and (max-width: 768px) { margin-bottom: 30px; }
    }


    // Liens (Téléphone et email) | Adresse | Heures
    #{$sc}__link, #{$sc}__address, #{$sc}__hours {
        @extend .paragraph;
        color: var(--color-base-white);
        display: flex;
        align-items: center;
    }


    // Liens (Téléphone et email)
    #{$sc}__link {
        margin-bottom: 20px;
        transition: color 300ms;

        &:hover {
            color: var(--color-blue-light-blue);
        }
    }


    // Adresse
    #{$sc}__address {
        margin-bottom: 55px;

        @media screen and (max-width: 1024px) { margin-bottom: 50px; }
        @media screen and (max-width: 768px) { margin-bottom: 30px; }
    }


    // Svg
    #{$sc}__svg {
        stroke: var(--color-base-white);
        stroke-width: 2px;
        fill: none;
        margin-right: 30px;


        // Téléphone
        &--phone {
            width: 19px;
            height: 19px;
        }


        // Télécopieur
        &--fax {
            width: 18px;
            height: 17px;
        }


        // Courriel
        &--mail {
            width: 18px;
            height: 13px;
        }


        // Pointer
        &--marker {
            width: 14px;
            height: 18px;
            margin-right: 35px;
        }


        // Horloge
        &--clock {
            width: 17px;
            height: 17px;
        }
    }


    // Bouton
    #{$sc}__button {
        margin-bottom: var(--y-spacing-80);
    }
}