@use '../titles/large-title';


//--------------------
// Variables globales
//--------------------
:root {
    
}


//-------------------
// Variables locales
//-------------------
.news {
    
}


// ----------------
// News
// ----------------
.news {
    width: 33.33%;
    padding: 0 var(--news-list-gap);
    margin-bottom: var(--y-spacing-120);

    @media screen and (max-width: 900px)  { width: 50%; }
    @media screen and (max-width: 600px)  { width: 100%; }

    $n: '.news'; // Variable désignant l'actualité


    // Conteneur
    #{$n}__container {
        display: flex;
        flex-direction: column;
    }


    // Conteneur de l'image
    #{$n}__picture {
        width: 100%;
        padding-top: 104%; // Se base sur la largeur du parent
        position: relative;
        margin-bottom: 45px;
        overflow: hidden;

        @media screen and (max-width: 1000px) { margin-bottom: 40px; }
        @media screen and (max-width: 900px) { margin-bottom: 35px; }
        @media screen and (max-width: 800px) { margin-bottom: 30px; }
        @media screen and (max-width: 600px) { margin-bottom: 25px; }
        @media screen and (max-width: 400px) { margin-bottom: 20px; }
    }


    // Image
    #{$n}__img {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transform: scale(1);
        transition: transform 600ms var(--ease-out-quart);
        will-change: transform;
    }


    // Effet d'hover du conteneur sur l'image
    #{$n}__container:hover #{$n}__img {
        transform: scale(1.1);
    }


    // Catégorie / Date (Surtitre)
    #{$n}__surtitle {
        font-family: var(--font-family-1);  // Maison Neue
        font-size: 1.2em;
        font-weight: 700;
        letter-spacing: 0.1em;
        line-height: 1.4;
        text-transform: uppercase;
        color: var(--color-blue-blue);
        margin-bottom: 25px;

        @media screen and (max-width: 400px) { margin-bottom: 20px; }
    }


    // Titre
    #{$n}__title {
        font-family: var(--font-family-2);  // Canela
        font-size: 3.5rem;
        font-weight: 500;
        line-height: 1.15em;
        color: var(--color-blue-marine);
        transition: color 300ms;
        padding-right: 10px;

        @media screen and (max-width: 1024px) {
            font-size: var(--large-title-font-size);
        }
    }


    // Effet d'hover du conteneur sur le titre
    #{$n}__container:hover #{$n}__title {
        color: var(--color-blue-blue);
    }
}