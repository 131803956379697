//--------------------
// Variables globales
//--------------------
:root {
    
}


//-------------------
// Variables locales
//-------------------
.similar-events {
    --img-width: 247px;

    @media screen and (max-width: 800px) { --img-width: 225px; }
    @media screen and (max-width: 700px) { --img-width: 200px; }
}


// ----------------
// Similar events
// ----------------
.similar-events {
    $se: '.similar-events'; // Variable désignant l'événement similaire

    // Conteneur
    #{$se}__events-container {
        width: 100%;
        display: inline-flex;
        padding-bottom: var(--y-spacing-40);
        border-bottom: 1px solid var(--color-grayscale-gray3);

        @media screen and (max-width: 600px) {
            padding-bottom: 0;
            border: none; 
            flex-direction: column; 
            align-items: flex-start;
        }
    }



    // Boîte contenant l'image (nécessaire lorsqu'on utilise un padding en pourcentage)
    #{$se}__picture-box {
        width: var(--img-width);
        margin-right: 60px;

        @media screen and (max-width: 800px) { margin-right: 50px; }
        @media screen and (max-width: 700px) { margin-right: 40px; }
        @media screen and (max-width: 600px) {
            width: 100%;
            margin-right: 0;
            margin-bottom: 30px;
        }
    }


    // Conteneur de l'image
    #{$se}__picture {
        width: 100%;
        padding-top: 104%; // Se base sur la largeur du parent
        position: relative;
        overflow: hidden;
    }


    // Image
    #{$se}__img {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transform: scale(1);
        transition: transform 600ms var(--ease-out-quart);
    }


    // Effet d'hover du conteneur sur l'image
    #{$se}__events-container:hover #{$se}__img {
        transform: scale(1.1);
    }


    // Boîte de texte
    #{$se}__text-block {
        width: calc(100% - var(--img-width));
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;

        @media screen and (max-width: 600px) { width: 100%; }
    }


    // Surtitre
    #{$se}__surtitle {
        font-family: var(--font-family-1); // Maison Neue
        font-size: 1.2rem;
        font-weight: 700;
        line-height: 1.4;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: var(--color-blue-blue);
        margin-bottom: 25px;

        @media screen and (max-width: 400px) { margin-bottom: 20px; }
    }


    // Catégorie
    #{$se}__category {
        font-size: 1.2rem;
        color: var(--color-grayscale-gray9);
    }


    // Titre
    #{$se}__title {
        font-family: var(--font-family-2); // Canela
        font-size: 3.5rem;
        font-weight: 500;
        color: var(--color-blue-marine);
        transition: color 300ms;

        @media screen and (max-width: 900px) { font-size: 3rem; }
        @media screen and (max-width: 600px) { 
            font-size: 4rem;
            margin-bottom: 20px;
        }
        @media screen and (max-width: 425px) { font-size: 3.5rem; }
    }


    // Effet d'hover du conteneur sur le titre
    #{$se}__events-container:hover #{$se}__title {
        color: var(--color-blue-blue);
    }


    #{$se}__infos {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        @media screen and (max-width: 600px) { 
            flex-direction: column; 
            align-items: flex-start;
            flex-wrap: initial;
        }
    }


    // Heure et lieu
    #{$se}__hours, #{$se}__place {
        font-family: var(--font-family-1); // Maison Neue
        font-size: 1.7rem;
        line-height: 1.4;
        color: var(--color-blue-marine);
        padding-top: var(--y-spacing-40);
        display: flex;
        align-items: center;

        @media screen and (max-width: 600px) { padding-top: 0; }
    }


    // Heure
    #{$se}__hours {
        margin-right: 40px;

        @media screen and (max-width: 600px) { margin-bottom: 15px; }
    }


    // Svg
    #{$se}__svg {
        height: 17px;
        stroke: var(--color-blue-marine);
        stroke-width: 2px;
        fill: none;
        margin-right: 15px;
        transform: translateY(-2px);

        // Horloge
        &--clock {
            width: 17px;
        }


        // Pointer
        &--marker {
            width: 12px;
        }
    }
}