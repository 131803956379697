@use 'components';


//--------------------
// Variables globales
//--------------------
:root {
    
}


//-------------------
// Variables locales
//-------------------
.pagination {

}


// ----------------
// Pagination
// ----------------
.pagination {
    $p: '.pagination'; // Variable désignant la pagination


    // Conteneur
    #{$p}__container {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-right: 25px;
        padding-bottom: var(--y-spacing-120);
        padding-left: 25px;

        &--jobs {
            padding-bottom: 0;

            @media screen and (max-width: 500px) { padding: 20px 25px; }
        }

        @media screen and (max-width: 500px) { justify-content: space-between; }
    }


    // Flèche | Précédent
    #{$p}__prev {
        transform: rotate(90deg);

        &:hover {
            #{$p}__svg {
                stroke: var(--color-blue-light-blue);
            }
        }
    }


    // Flèche | Suivant
    #{$p}__next {
        transform: rotate(-90deg);

        &:hover {
            #{$p}__svg {
                stroke: var(--color-blue-light-blue);
            }
        }
    }

    #{$p}__inactive {
        opacity: 0.5;
        pointer-events: none;
    }


    // Flèches | Svg
    #{$p}__svg {
        width: 16px;
        height: 10px;
        stroke: var(--color-blue-marine);
        transition: stroke 300ms;
        stroke-width: 2px;
        fill: none;
    }


    // Liste des numéros
    #{$p}__numbers {
        padding: 0 70px;
        display: flex;
        align-items: center;

        @media screen and (max-width: 500px) { padding: 0 25px; }
    }


    // Numéro de page
    #{$p}__number {
        width: 39px;
        height: 39px;
        border-radius: 50%;
        margin-right: 20px;
        position: relative;

        &:last-child {
            margin-right: 0;
        }

        &--active {
            background: var(--color-blue-marine);

            #{$p}__page-link {
                color: var(--color-base-white);
                pointer-events: none;
                cursor: default;
            }
        }

        @media screen and (max-width: 500px) { 
            width: 35px;
            height: 35px;
            margin-right: 10px; 
        }
    }


    // Lien de page
    #{$p}__page-link {
        font-family: var(--font-family-1); // Maison Neue
        font-size: 1.7em;
        color: var(--color-blue-marine);
        transition: color 300ms;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);

        &:hover {
            color: var(--color-blue-light-blue);
        }

        @media screen and (max-width: 500px) { font-size: 1.5em; }
    }
}