// |--------------------------------------------------------------------------
// | La variable des délais de scrollfire
// |--------------------------------------------------------------------------
// |
// | Mapping des délais à l'intérieur de la variable $scrollfire-delays. Ce
// | mapping génère les classes dans le fichier base/root.scss
// |--------------------------------------------------------------------------

$scrollfire-delays: (
    '50': 50ms,
    '100': 100ms,
    '150': 150ms,
    '200': 200ms,
    '250': 250ms,
    '300': 300ms,
    '350': 350ms,
    '400': 400ms,
    '450': 450ms,
    '500': 500ms,
    '550': 550ms,
    '600': 600ms,
    '650': 650ms,
    '700': 700ms,
    '750': 750ms,
    '800': 800ms,
    '850': 850ms,
    '900': 900ms,
    '950': 950ms,
    '1000': 1000ms,
);