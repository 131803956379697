@use 'components';


//--------------------
// Variables globales
//--------------------
:root {
    
}


//-------------------
// Variables locales
//-------------------
.footer {

}


// ----------------
// Footer
// ----------------
.footer {
    $f: '.footer'; // Variable désignant le footer


    // Conteneur
    #{$f}__container {
        width: 100%;
        background: var(--color-blue-marine);
        border-top: 1px solid rgba($color: #fff, $alpha: 0.2);
        display: flex;
        justify-content: space-between;
        position: relative;
        z-index: 5;

        &--home {
            background: #212c3f;
        }

        @media screen and (max-width: 800px) { 
            flex-direction: column;
            justify-content: initial;
            align-items: flex-start;
        }
    }


    // Côté gauche
    #{$f}__left-side {
        padding-top: var(--y-spacing-50);
        padding-bottom: var(--y-spacing-100);
        border-right: 1px solid rgba($color: #fff, $alpha: 0.2);

        @media screen and (max-width: 800px) { 
            width: 100%;
            padding-bottom: 0;
            border: 0; 
        }
    }


    // Côté gauche | Section du haut
    #{$f}__left-side-top {
        display: flex;
        justify-content: flex-start;
        border-bottom: 1px solid rgba($color: #fff, $alpha: 0.2);
        padding-right: var(--x-spacing-180);
        padding-bottom: var(--y-spacing-60);
        padding-left: var(--x-spacing-140);

        @media screen and (min-width: 1601px) { 
            padding-right: 180px;
            padding-left: 140px; 
        }
    }


    // Logo
    #{$f}__logo {
        width: 229px;
        height: 83px;

        @media screen and (max-width: 800px) {
            width: 146px;
            height: 52px;
        }
    }


    // Côté gauche | Section du bas
    #{$f}__left-side-bottom {
        display: flex;
        justify-content: flex-start;
        padding-top: var(--y-spacing-80);
        padding-right: var(--x-spacing-180);
        padding-left: var(--x-spacing-140);

        @media screen and (min-width: 1601px) { 
            padding-right: 180px;
            padding-left: 140px; 
        }
    }


    // Boîte des infos de contact
    #{$f}__contact-box {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        @media screen and (max-width: 800px) { display: none; }
    }


    // Surtitre | Zone contact
    #{$f}__contact-surtitle {
        font-family: var(--font-family-1); // Maison Neue
        font-size: 1.2em;
        font-weight: 700;
        letter-spacing: 0.1em;
        line-height: 1.4;
        color: var(--color-blue-blue);
        text-transform: uppercase;
        margin-bottom: 30px;
    }


    // Élements d'adresse
    #{$f}__address-item {
        font-family: var(--font-family-1); // Maison Neue
        font-size: 2em;
        color: var(--color-base-white);
        margin-bottom: 40px;

        &:nth-child(2) {
            margin-bottom: 15px;
        }
    }


    // Autres infos de contact
    #{$f}__other-infos {
        display: flex;
        align-items: flex-end;
    }


    // Téléphone et address email | Conteneur
    #{$f}__phone-and-email {
        margin-right: 35px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }


    // Téléphone et address email
    #{$f}__phone, #{$f}__email {
        font-family: var(--font-family-1); // Maison Neue
        font-size: 1.7em;
        color: var(--color-base-white);
        text-decoration: none;
        position: relative;
        transition: color 300ms;

        &:hover {
            color: var(--color-blue-light-blue);
        }
    }


    // Téléphone uniquement 
    #{$f}__phone {
        margin-bottom: 10px;
    }


    // Facebook | Conteneur
    #{$f}__facebook-box {
        width: 40px;
        height: 40px;
        border: 1px solid rgba($color: #fff, $alpha: 0.2);
        position: relative;

        &:after {
            content: '';
            background: var(--color-base-white);
            position: absolute;
            right: 0;  
            bottom: 0;
            left: 0;
            top: 100%;
            transition: top 300ms;
            z-index: 5;
        }

        &:hover {
            &::after {
                top: 0;
            }
        }
    }


    // Facebook | Svg
    #{$f}__svg {
        fill: var(--color-base-white);
        transition: fill 300ms;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        z-index: 10;

        &--facebook {
            width: 6px;
            height: 15px;
        }
    }

    // Effet d'hover sur les svg
    #{$f}__facebook-box:hover #{$f}__svg {
        fill: var(--color-blue-light-blue);
    }


    // Côté droit
    #{$f}__right-side {
        width: 100%;
        padding-top: var(--y-spacing-100);
        padding-right: var(--x-spacing-200);
        padding-bottom: var(--y-spacing-40);
        padding-left: var(--x-spacing-140);
        display: flex;
        flex-direction: column;
        //align-items: center;
        justify-content: space-between;

        @media screen and (min-width: 1601px) { padding-right: 200px; }
        @media screen and (max-width: 1600px) { align-items: flex-start; }
        @media screen and (max-width: 800px) { padding-top: 0; }
    }


    // Boîte de l'infolettre
    #{$f}__newsletter-box {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }


    // Surtitre | Zone infolettre
    #{$f}__newsletter-surtitle {
        font-family: var(--font-family-1); // Maison Neue
        font-size: 1.2em;
        font-weight: 700;
        letter-spacing: 0.1em;
        line-height: 1.4;
        color: var(--color-blue-blue);
        text-transform: uppercase;
        margin-bottom: 35px;
    }


    // Titre | Zone infolettre
    #{$f}__newsletter-title {
        @extend .large-title;
        font-weight: 400;
        color: var(--color-base-white);
        margin-bottom: 65px;

        @media screen and (max-width: 1600px) { max-width: 350px; }
        @media screen and (max-width: 800px) { max-width: 100%; }
        @media screen and (max-width: 500px) { margin-bottom: 30px; }
    }


    // Formulaire | Zone infolettre
    #{$f}__newsletter-form {
        width: 100%;
        margin-bottom: var(--y-spacing-100);
        display: flex;
        align-items: flex-end;

        @media screen and (max-width: 500px) {
            flex-direction: column;
            align-items: flex-start;
        }

        $tf: '.text-field'; // Variable désignant le text-field


        // Formulaire
        #{$tf} {
            width: 100%;
            margin-right: 20px;


            // Couleur du placeholder
            ::-webkit-input-placeholder { color: var(--color-base-white); }
            ::-moz-placeholder { color: var(--color-base-white); }
            :-ms-input-placeholder {  color: var(--color-base-white); }
            :-moz-placeholder { color: var(--color-base-white); }


            // Réglages de l'autofill
            :-webkit-autofill,
            :-webkit-autofill:hover, 
            :-webkit-autofill:focus, 
            :-webkit-autofill:active {
                -webkit-box-shadow: 0 0 0 100px var(--color-blue-marine) inset !important;
                -webkit-text-fill-color: var(--color-base-white);
                border-bottom: 2px solid var(--color-blue-light-blue) !important;
                margin-bottom: 0 !important;
            }

            @media screen and (max-width: 500px) { margin-bottom: 30px; }
        }


        // Input
        #{$tf}__input {
            color: var(--color-base-white);
            background: transparent;
            padding: 18px 0;
            border-bottom: 1px solid rgba($color: #fff, $alpha: 0.2);
            margin-bottom: -1px;

            &:focus {
                border-bottom: 2px solid var(--color-blue-light-blue);
            }
        }
    }

    // Buttons
    #{$f}__newsletter-buttons {
        margin: 0 -10px calc(var(--y-spacing-100) + -10px);
    }

    #{$f}__newsletter-button {
        margin: 0 10px 10px;
    }

    // Section du copyright
    #{$f}__copyright {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        @media screen and (max-width: 500px) {
            flex-direction: column;
            justify-content: initial;
            align-items: flex-start;
        }
    }



    // Copyright et lien Blanko
    #{$f}__copyright-item, #{$f}__blanko-link {
        font-family: var(--font-family-1); // Maison Neue
        font-size: 1.2em;
        color: var(--color-base-white);
    }
    

    // Copyright 
    #{$f}__copyright-item {
        margin-right: 75px;
        line-height: 1.7;

        @media screen and (max-width: 500px) { 
            margin-right: 0;
            margin-bottom: 20px; 
        }
    }


    // Lien Blanko
    #{$f}__blanko-link {
        transition: color 300ms;

        &:hover {
            color: var(--color-blue-light-blue);
        }
    }
}