@use 'components';


//--------------------
// Variables globales
//--------------------
:root {
    
}


//-------------------
// Variables locales
//-------------------
.results-block {

}


// ----------------
// Résultats de recherche | Section
// ----------------
.results-block {
    $rb: '.results-block'; // Variable désignant le block de résultats


    // Conteneur
    #{$rb}__container {
        width: 100%;
        padding-top: var(--y-spacing-50);
        padding-right: var(--x-spacing-120);
        padding-bottom: var(--y-spacing-120);
        padding-left: var(--x-spacing-120);
    }


    // Élément de la liste
    #{$rb}__list-item {
        $r: '.result'; // Variable désignant le résultat

        &:last-child {
            #{$r} {
                border-bottom: 1px solid var(--color-grayscale-gray3);
            }
        }
    }
}