@use 'components';


//--------------------
// Variables globales
//--------------------
:root {
    
}


//-------------------
// Variables locales
//-------------------
.similar-section {

}


// ----------------
// Similar section
// ----------------
.similar-section {
    $sms: '.similar-section'; // Variable désignant la section des éléments similaires


    // Conteneur
    #{$sms}__container {
        padding-right: var(--x-spacing-120);
        padding-left: var(--x-spacing-120);
        padding-bottom: var(--y-spacing-160);
    }


    // Titre
    #{$sms}__title {
        @extend .large-title;
        font-weight: 500;
        margin-bottom: var(--y-spacing-70);
    }
}