@use 'components';

.main {
    width: calc(100% - var(--side-section-width));
    color: var(--color-blue-marine);

    &--jobs,
    &--full {
        width: 100%;
    }

    @media screen and (max-width: 1000px) { width: 100%; }

    &__content {
        padding-left: var(--x-spacing-120);
        padding-right: var(--x-spacing-120);
        padding-top: var(--y-spacing-100);
        padding-bottom: var(--y-spacing-100);

        &--jobs {
            padding-right: var(--x-spacing-160);
            padding-left: var(--x-spacing-160);
        }

        &--jobs-detail, &--no-padding-top {
            padding-top: 0;
        }

        @media screen and (min-width: 1601px) { padding-right: 120px; }
    }

    &__content-side {
        max-width: calc(100% - var(--side-section-width));
    }

    &__content + &__content {
        padding-top: 0;
    }

    &__title {
        @extend .large-title;
        margin-bottom: var(--y-spacing-50);
    }

    &__documents-desc {
        margin-top: -20px;
        margin-bottom: var(--y-spacing-50);
    }
}
