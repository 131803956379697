@import '../libraries/overlay.min.css';
.fade {
  opacity: 0;
  transition-property: opacity;
  transition-duration: 800ms;
}

.fade--fire {
  opacity: 1;
}

.fade-up, .header .header__container, .footer .footer__left-side-top,
.footer .footer__left-side-bottom,
.footer .footer__right-side, .page-title .page-title__surtitle,
.page-title .page-title__title,
.page-title .page-title__share,
.page-title .page-title__credit,
.page-title .page-title__search-results-form, .main .main__content, .showcase .showcase__container, .filter .filter__title,
.filter .filter__drag-and-slide-container,
.filter .filter__calendar-button-text,
.filter .filter__svg-container,
.filter .filter__arrows-container, .pagination .pagination__container, .pagination-mobile .pagination-mobile__container, .side-pagination .side-pagination__container, .similar-section .similar-section__container, .home-banner .home-banner__container, .featured-news .featured-news__text-block,
.featured-news .featured-news__list, .featured-events, .home-showcase .home-showcase__content, .contact-block .contact-block__left,
.contact-block .contact-block__right, .side-contact .side-contact__container, .news .news__container, .events .events__container, .infos .infos__container, .result, .side-menu .side-menu__container, .jobs-list .jobs-list__container, .sections .sections__list-item, .alert .alert__container, .errors .errors__container {
  opacity: 0;
  transform: translateY(50px);
  transition-property: transform, opacity;
  transition-duration: 800ms;
  transition-timing-function: var(--ease-out-cubic);
}
@media screen and (max-width: 768px) {
  .fade-up, .header .header__container, .footer .footer__left-side-top,
  .footer .footer__left-side-bottom,
  .footer .footer__right-side, .page-title .page-title__surtitle,
  .page-title .page-title__title,
  .page-title .page-title__share,
  .page-title .page-title__credit,
  .page-title .page-title__search-results-form, .main .main__content, .showcase .showcase__container, .filter .filter__title,
  .filter .filter__drag-and-slide-container,
  .filter .filter__calendar-button-text,
  .filter .filter__svg-container,
  .filter .filter__arrows-container, .pagination .pagination__container, .pagination-mobile .pagination-mobile__container, .side-pagination .side-pagination__container, .similar-section .similar-section__container, .home-banner .home-banner__container, .featured-news .featured-news__text-block,
  .featured-news .featured-news__list, .featured-events, .home-showcase .home-showcase__content, .contact-block .contact-block__left,
  .contact-block .contact-block__right, .side-contact .side-contact__container, .news .news__container, .events .events__container, .infos .infos__container, .result, .side-menu .side-menu__container, .jobs-list .jobs-list__container, .sections .sections__list-item, .alert .alert__container, .errors .errors__container {
    transform: translateY(30px);
  }
}

.fade-up--fire, .header .header__container[data-fire], .footer .footer__left-side-top[data-fire],
.footer .footer__left-side-bottom[data-fire],
.footer .footer__right-side[data-fire], .page-title .page-title__surtitle[data-fire],
.page-title .page-title__title[data-fire],
.page-title .page-title__share[data-fire],
.page-title .page-title__credit[data-fire],
.page-title .page-title__search-results-form[data-fire], .main .main__content[data-fire], .showcase .showcase__container[data-fire], .filter .filter__title[data-fire],
.filter .filter__drag-and-slide-container[data-fire],
.filter .filter__calendar-button-text[data-fire],
.filter .filter__svg-container[data-fire],
.filter .filter__arrows-container[data-fire], .pagination .pagination__container[data-fire], .pagination-mobile .pagination-mobile__container[data-fire], .side-pagination .side-pagination__container[data-fire], .similar-section .similar-section__container[data-fire], .home-banner .home-banner__container[data-fire], .featured-news .featured-news__text-block[data-fire],
.featured-news .featured-news__list[data-fire], .featured-events[data-fire], .home-showcase .home-showcase__content[data-fire], .contact-block .contact-block__left[data-fire],
.contact-block .contact-block__right[data-fire], .side-contact .side-contact__container[data-fire], .news .news__container[data-fire], .events .events__container[data-fire], .infos .infos__container[data-fire], .result[data-fire], .side-menu .side-menu__container[data-fire], .jobs-list .jobs-list__container[data-fire], .sections .sections__list-item[data-fire], .alert .alert__container[data-fire], .errors .errors__container[data-fire] {
  opacity: 1;
  transform: none;
}

.fade-down {
  opacity: 0;
  transform: translateY(-50px);
  transition-property: transform, opacity;
  transition-duration: 1000ms;
  transition-timing-function: var(--ease-out-quart);
}

.fade-down--fire {
  opacity: 1;
  transform: none;
}

html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

body {
  line-height: 1;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

nav ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  text-decoration: none;
  cursor: pointer;
}

ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
}

mark {
  background-color: #e9f0f6;
  color: var(--color-blue-marine);
}

del {
  text-decoration: line-through;
}

abbr[title], dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0;
}

input, select {
  vertical-align: middle;
  -webkit-appearance: none;
}

/*** Blanko ***/
* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input[type=search]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

input[type=search]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-results-button,
input[type=search]::-webkit-search-results-decoration {
  display: none;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
}

ol, ul {
  list-style: none;
}

input:focus {
  outline: none;
}

a {
  -webkit-tap-highlight-color: transparent;
}

.slick-slide, .slick-slide a, .slick-slide:focus {
  outline: none !important;
}

.slick-loading .slick-list {
  background: none;
}

.stripe-loading-indicator .stripe, .stripe-loading-indicator .stripe-loaded {
  display: none !important;
}

@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto-Regular.ttf");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto-Italic.ttf");
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto-Medium.ttf");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto-MediumItalic.ttf");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto-Bold.ttf");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto-BoldItalic.ttf");
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Canela";
  src: url("../fonts/Canela-Regular.otf");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Canela";
  src: url("../fonts/Canela-RegularItalic.otf");
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Canela";
  src: url("../fonts/Canela-Medium.otf");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Canela";
  src: url("../fonts/Canela-MediumItalic.otf");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Canela";
  src: url("../fonts/Canela-Bold.otf");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Canela";
  src: url("../fonts/Canela-BoldItalic.otf");
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}
:root {
  --color-base-black: #000;
  --color-base-white: #fff;
  --color-error-base: #eb0000;
  --color-red-red: #dd4d4d;
  --color-blue-marine: #252e3f;
  --color-blue-darker-marine: #171e2b;
  --color-blue-blue: #749fc2;
  --color-blue-light-blue: #91b5d2;
  --color-blue-light-blue2: #c1d5e5;
  --color-blue-light-blue3: #91b0d0;
  --color-blue-lighter-blue: #c8dae8;
  --color-grayscale-gray1: #f7f9fb;
  --color-grayscale-gray2: #eaeaea;
  --color-grayscale-gray3: #d6d8d9;
  --color-grayscale-gray4: #919499;
  --color-grayscale-gray5: #818489;
  --color-grayscale-gray6: #808080;
  --color-grayscale-gray7: #676d79;
  --color-grayscale-gray8: #a9a9a9;
  --color-grayscale-gray9: #838894;
  --color-grayscale-gray10: #afb2b8;
  --color-grayscale-gray11: #a8abb2;
  --color-grayscale-gray12: #95989e;
  --color-grayscale-gray13: #eef0f1;
  --color-grayscale-gray14: #edf0f3;
  --ease-in-sine: cubic-bezier(0.12, 0, 0.39, 0);
  --ease-in-quad: cubic-bezier(0.11, 0, 0.5, 0);
  --ease-in-cubic: cubic-bezier(0.32, 0, 0.67, 0);
  --ease-in-quart: cubic-bezier(0.5, 0, 0.75, 0);
  --ease-in-quint: cubic-bezier(0.64, 0, 0.78, 0);
  --ease-in-expo: cubic-bezier(0.7, 0, 0.84, 0);
  --ease-in-circ: cubic-bezier(0.55, 0, 1, 0.45);
  --ease-in-back: cubic-bezier(0.36, 0, 0.66, -0.56);
  --ease-out-sine: cubic-bezier(0.61, 1, 0.88, 1);
  --ease-out-quad: cubic-bezier(0.5, 1, 0.89, 1);
  --ease-out-cubic: cubic-bezier(0.33, 1, 0.68, 1);
  --ease-out-quart: cubic-bezier(0.25, 1, 0.5, 1);
  --ease-out-quint: cubic-bezier(0.22, 1, 0.36, 1);
  --ease-out-expo: cubic-bezier(0.16, 1, 0.3, 1);
  --ease-out-circ: cubic-bezier(0, 0.55, 0.45, 1);
  --ease-out-back: cubic-bezier(0.34, 1.56, 0.64, 1);
  --ease-in-out-sine: cubic-bezier(0.37, 0, 0.63, 1);
  --ease-in-out-quad: cubic-bezier(0.45, 0, 0.55, 1);
  --ease-in-out-cubic: cubic-bezier(0.65, 0, 0.35, 1);
  --ease-in-out-quart: cubic-bezier(0.76, 0, 0.24, 1);
  --ease-in-out-quint: cubic-bezier(0.83, 0, 0.17, 1);
  --ease-in-out-expo: cubic-bezier(0.87, 0, 0.13, 1);
  --ease-in-out-circ: cubic-bezier(0.85, 0, 0.15, 1);
  --ease-in-out-back: cubic-bezier(0.68, -0.6, 0.32, 1.6);
  --huge-title-font-size: 9rem;
  --huge-title-smaller-font-size: 7rem;
  --large-title-font-size: 4rem;
  --medium-title-font-size: 3rem;
  --small-title-font-size: 2.5rem;
  --paragraph-font-size: 1.7rem;
  --introduction-font-size: 2.5rem;
  --x-spacing-200: 20vw;
  --x-spacing-180: 18vw;
  --x-spacing-160: 16vw;
  --x-spacing-140: 14vw;
  --x-spacing-120: 12vw;
  --x-spacing-110: 11vw;
  --x-spacing-80: 80px;
  --x-spacing-70: 70px;
  --x-spacing-60: 60px;
  --x-spacing-50: 50px;
  --x-spacing-40: 40px;
  --x-spacing-30: 30px;
  --y-spacing-200: 200px;
  --y-spacing-190: 190px;
  --y-spacing-170: 170px;
  --y-spacing-160: 160px;
  --y-spacing-150: 150px;
  --y-spacing-140: 140px;
  --y-spacing-130: 130px;
  --y-spacing-120: 120px;
  --y-spacing-110: 110px;
  --y-spacing-100: 100px;
  --y-spacing-90: 90px;
  --y-spacing-80: 80px;
  --y-spacing-70: 70px;
  --y-spacing-60: 60px;
  --y-spacing-50: 50px;
  --y-spacing-40: 40px;
  --y-spacing-30: 30px;
  --font-family-1: "Roboto", sans-serif;
  --font-family-2: "Canela", sans-serif;
}
@media screen and (max-width: 1024px) {
  :root {
    --huge-title-font-size: 8rem;
  }
}
@media screen and (max-width: 768px) {
  :root {
    --huge-title-font-size: 7rem;
  }
}
@media screen and (max-width: 425px) {
  :root {
    --huge-title-font-size: 4rem;
  }
}
@media screen and (max-width: 1024px) {
  :root {
    --huge-title-smaller-font-size: 5rem;
  }
}
@media screen and (max-width: 768px) {
  :root {
    --huge-title-smaller-font-size: 3.5rem;
  }
}
@media screen and (max-width: 425px) {
  :root {
    --huge-title-smaller-font-size: 3rem;
  }
}
@media screen and (max-width: 1024px) {
  :root {
    --large-title-font-size: 3.2rem;
  }
}
@media screen and (max-width: 768px) {
  :root {
    --large-title-font-size: 3rem;
  }
}
@media screen and (max-width: 425px) {
  :root {
    --large-title-font-size: 2.8rem;
  }
}
@media screen and (max-width: 768px) {
  :root {
    --medium-title-font-size: 2.6rem;
  }
}
@media screen and (max-width: 425px) {
  :root {
    --medium-title-font-size: 2.4rem;
  }
}
@media screen and (max-width: 768px) {
  :root {
    --small-title-font-size: 2.2rem;
  }
}
@media screen and (max-width: 425px) {
  :root {
    --small-title-font-size: 2rem;
  }
}
@media screen and (max-width: 768px) {
  :root {
    --paragraph-font-size: 1.6rem;
  }
}
@media screen and (max-width: 425px) {
  :root {
    --paragraph-font-size: 1.5rem;
  }
}
@media screen and (max-width: 768px) {
  :root {
    --introduction-font-size: 2.3rem;
  }
}
@media screen and (max-width: 425px) {
  :root {
    --introduction-font-size: 2.2rem;
  }
}
@media screen and (max-width: 1600px) {
  :root {
    --x-spacing-200: 200px;
  }
}
@media screen and (max-width: 1400px) {
  :root {
    --x-spacing-200: 160px;
  }
}
@media screen and (max-width: 1200px) {
  :root {
    --x-spacing-200: 120px;
  }
}
@media screen and (max-width: 1024px) {
  :root {
    --x-spacing-200: 50px;
  }
}
@media screen and (max-width: 768px) {
  :root {
    --x-spacing-200: 30px;
  }
}
@media screen and (max-width: 600px) {
  :root {
    --x-spacing-200: 25px;
  }
}
@media screen and (max-width: 1600px) {
  :root {
    --x-spacing-180: 180px;
  }
}
@media screen and (max-width: 1400px) {
  :root {
    --x-spacing-180: 140px;
  }
}
@media screen and (max-width: 1200px) {
  :root {
    --x-spacing-180: 100px;
  }
}
@media screen and (max-width: 1024px) {
  :root {
    --x-spacing-180: 50px;
  }
}
@media screen and (max-width: 768px) {
  :root {
    --x-spacing-180: 30px;
  }
}
@media screen and (max-width: 600px) {
  :root {
    --x-spacing-180: 25px;
  }
}
@media screen and (max-width: 1600px) {
  :root {
    --x-spacing-160: 160px;
  }
}
@media screen and (max-width: 1400px) {
  :root {
    --x-spacing-160: 130px;
  }
}
@media screen and (max-width: 1200px) {
  :root {
    --x-spacing-160: 110px;
  }
}
@media screen and (max-width: 1024px) {
  :root {
    --x-spacing-160: 50px;
  }
}
@media screen and (max-width: 768px) {
  :root {
    --x-spacing-160: 30px;
  }
}
@media screen and (max-width: 600px) {
  :root {
    --x-spacing-160: 25px;
  }
}
@media screen and (max-width: 1600px) {
  :root {
    --x-spacing-140: 140px;
  }
}
@media screen and (max-width: 1400px) {
  :root {
    --x-spacing-140: 120px;
  }
}
@media screen and (max-width: 1200px) {
  :root {
    --x-spacing-140: 100px;
  }
}
@media screen and (max-width: 1024px) {
  :root {
    --x-spacing-140: 50px;
  }
}
@media screen and (max-width: 768px) {
  :root {
    --x-spacing-140: 30px;
  }
}
@media screen and (max-width: 600px) {
  :root {
    --x-spacing-140: 25px;
  }
}
@media screen and (max-width: 1600px) {
  :root {
    --x-spacing-120: 120px;
  }
}
@media screen and (max-width: 1400px) {
  :root {
    --x-spacing-120: 100px;
  }
}
@media screen and (max-width: 1200px) {
  :root {
    --x-spacing-120: 80px;
  }
}
@media screen and (max-width: 1024px) {
  :root {
    --x-spacing-120: 50px;
  }
}
@media screen and (max-width: 768px) {
  :root {
    --x-spacing-120: 30px;
  }
}
@media screen and (max-width: 600px) {
  :root {
    --x-spacing-120: 25px;
  }
}
@media screen and (max-width: 1600px) {
  :root {
    --x-spacing-110: 110px;
  }
}
@media screen and (max-width: 1400px) {
  :root {
    --x-spacing-110: 90px;
  }
}
@media screen and (max-width: 1200px) {
  :root {
    --x-spacing-110: 70px;
  }
}
@media screen and (max-width: 1024px) {
  :root {
    --x-spacing-110: 50px;
  }
}
@media screen and (max-width: 768px) {
  :root {
    --x-spacing-110: 30px;
  }
}
@media screen and (max-width: 600px) {
  :root {
    --x-spacing-110: 25px;
  }
}
@media screen and (max-width: 1400px) {
  :root {
    --x-spacing-80: 70px;
  }
}
@media screen and (max-width: 1200px) {
  :root {
    --x-spacing-80: 60px;
  }
}
@media screen and (max-width: 1024px) {
  :root {
    --x-spacing-80: 50px;
  }
}
@media screen and (max-width: 768px) {
  :root {
    --x-spacing-80: 30px;
  }
}
@media screen and (max-width: 600px) {
  :root {
    --x-spacing-80: 25px;
  }
}
@media screen and (max-width: 1200px) {
  :root {
    --x-spacing-70: 60px;
  }
}
@media screen and (max-width: 1024px) {
  :root {
    --x-spacing-70: 50px;
  }
}
@media screen and (max-width: 768px) {
  :root {
    --x-spacing-70: 30px;
  }
}
@media screen and (max-width: 600px) {
  :root {
    --x-spacing-70: 25px;
  }
}
@media screen and (max-width: 1024px) {
  :root {
    --x-spacing-60: 50px;
  }
}
@media screen and (max-width: 768px) {
  :root {
    --x-spacing-60: 30px;
  }
}
@media screen and (max-width: 600px) {
  :root {
    --x-spacing-60: 25px;
  }
}
@media screen and (max-width: 768px) {
  :root {
    --x-spacing-50: 30px;
  }
}
@media screen and (max-width: 600px) {
  :root {
    --x-spacing-50: 25px;
  }
}
@media screen and (max-width: 768px) {
  :root {
    --x-spacing-40: 30px;
  }
}
@media screen and (max-width: 600px) {
  :root {
    --x-spacing-40: 25px;
  }
}
@media screen and (max-width: 600px) {
  :root {
    --x-spacing-30: 25px;
  }
}
@media screen and (max-width: 1024px) {
  :root {
    --y-spacing-200: 160px;
  }
}
@media screen and (max-width: 768px) {
  :root {
    --y-spacing-200: 120px;
  }
}
@media screen and (max-width: 500px) {
  :root {
    --y-spacing-200: 50px;
  }
}
@media screen and (max-width: 1024px) {
  :root {
    --y-spacing-190: 150px;
  }
}
@media screen and (max-width: 768px) {
  :root {
    --y-spacing-190: 120px;
  }
}
@media screen and (max-width: 500px) {
  :root {
    --y-spacing-190: 50px;
  }
}
@media screen and (max-width: 1024px) {
  :root {
    --y-spacing-170: 140px;
  }
}
@media screen and (max-width: 768px) {
  :root {
    --y-spacing-170: 110px;
  }
}
@media screen and (max-width: 500px) {
  :root {
    --y-spacing-170: 50px;
  }
}
@media screen and (max-width: 1024px) {
  :root {
    --y-spacing-160: 120px;
  }
}
@media screen and (max-width: 768px) {
  :root {
    --y-spacing-160: 100px;
  }
}
@media screen and (max-width: 500px) {
  :root {
    --y-spacing-160: 50px;
  }
}
@media screen and (max-width: 1024px) {
  :root {
    --y-spacing-150: 110px;
  }
}
@media screen and (max-width: 768px) {
  :root {
    --y-spacing-150: 90px;
  }
}
@media screen and (max-width: 500px) {
  :root {
    --y-spacing-150: 50px;
  }
}
@media screen and (max-width: 1024px) {
  :root {
    --y-spacing-140: 100px;
  }
}
@media screen and (max-width: 768px) {
  :root {
    --y-spacing-140: 90px;
  }
}
@media screen and (max-width: 500px) {
  :root {
    --y-spacing-140: 50px;
  }
}
@media screen and (max-width: 1024px) {
  :root {
    --y-spacing-130: 110px;
  }
}
@media screen and (max-width: 768px) {
  :root {
    --y-spacing-130: 90px;
  }
}
@media screen and (max-width: 500px) {
  :root {
    --y-spacing-130: 50px;
  }
}
@media screen and (max-width: 1024px) {
  :root {
    --y-spacing-120: 90px;
  }
}
@media screen and (max-width: 768px) {
  :root {
    --y-spacing-120: 80px;
  }
}
@media screen and (max-width: 500px) {
  :root {
    --y-spacing-120: 50px;
  }
}
@media screen and (max-width: 1024px) {
  :root {
    --y-spacing-110: 90px;
  }
}
@media screen and (max-width: 768px) {
  :root {
    --y-spacing-110: 80px;
  }
}
@media screen and (max-width: 500px) {
  :root {
    --y-spacing-110: 50px;
  }
}
@media screen and (max-width: 1024px) {
  :root {
    --y-spacing-100: 90px;
  }
}
@media screen and (max-width: 768px) {
  :root {
    --y-spacing-100: 80px;
  }
}
@media screen and (max-width: 500px) {
  :root {
    --y-spacing-100: 50px;
  }
}
@media screen and (max-width: 1024px) {
  :root {
    --y-spacing-90: 80px;
  }
}
@media screen and (max-width: 768px) {
  :root {
    --y-spacing-90: 70px;
  }
}
@media screen and (max-width: 500px) {
  :root {
    --y-spacing-90: 50px;
  }
}
@media screen and (max-width: 1024px) {
  :root {
    --y-spacing-80: 70px;
  }
}
@media screen and (max-width: 768px) {
  :root {
    --y-spacing-80: 60px;
  }
}
@media screen and (max-width: 500px) {
  :root {
    --y-spacing-80: 50px;
  }
}
@media screen and (max-width: 1024px) {
  :root {
    --y-spacing-70: 60px;
  }
}
@media screen and (max-width: 768px) {
  :root {
    --y-spacing-70: 50px;
  }
}
@media screen and (max-width: 500px) {
  :root {
    --y-spacing-70: 40px;
  }
}
@media screen and (max-width: 1024px) {
  :root {
    --y-spacing-60: 50px;
  }
}
@media screen and (max-width: 768px) {
  :root {
    --y-spacing-60: 40px;
  }
}
@media screen and (max-width: 1024px) {
  :root {
    --y-spacing-50: 40px;
  }
}
@media screen and (max-width: 768px) {
  :root {
    --y-spacing-50: 30px;
  }
}
@media screen and (max-width: 768px) {
  :root {
    --y-spacing-40: 30px;
  }
}
@media screen and (max-width: 768px) {
  :root {
    --y-spacing-30: 25px;
  }
}

html {
  font-size: 62.5%;
  overflow-y: scroll;
  font-family: "Roboto";
}

.fancybox-caption__body {
  font-family: "Roboto";
}

picture {
  display: block;
  font-size: 0;
}
picture img {
  width: 100%;
  height: 100%;
}
picture [data-object-fit=cover] {
  object-fit: cover;
  font-family: "object-fit: cover;";
}

.js-scrollfire-delay-enabled .scrollfire-delay-50 {
  transition-delay: 50ms;
}
.js-scrollfire-delay-enabled .scrollfire-delay-100 {
  transition-delay: 100ms;
}
.js-scrollfire-delay-enabled .scrollfire-delay-150 {
  transition-delay: 150ms;
}
.js-scrollfire-delay-enabled .scrollfire-delay-200 {
  transition-delay: 200ms;
}
.js-scrollfire-delay-enabled .scrollfire-delay-250 {
  transition-delay: 250ms;
}
.js-scrollfire-delay-enabled .scrollfire-delay-300 {
  transition-delay: 300ms;
}
.js-scrollfire-delay-enabled .scrollfire-delay-350 {
  transition-delay: 350ms;
}
.js-scrollfire-delay-enabled .scrollfire-delay-400 {
  transition-delay: 400ms;
}
.js-scrollfire-delay-enabled .scrollfire-delay-450 {
  transition-delay: 450ms;
}
.js-scrollfire-delay-enabled .scrollfire-delay-500 {
  transition-delay: 500ms;
}
.js-scrollfire-delay-enabled .scrollfire-delay-550 {
  transition-delay: 550ms;
}
.js-scrollfire-delay-enabled .scrollfire-delay-600 {
  transition-delay: 600ms;
}
.js-scrollfire-delay-enabled .scrollfire-delay-650 {
  transition-delay: 650ms;
}
.js-scrollfire-delay-enabled .scrollfire-delay-700 {
  transition-delay: 700ms;
}
.js-scrollfire-delay-enabled .scrollfire-delay-750 {
  transition-delay: 750ms;
}
.js-scrollfire-delay-enabled .scrollfire-delay-800 {
  transition-delay: 800ms;
}
.js-scrollfire-delay-enabled .scrollfire-delay-850 {
  transition-delay: 850ms;
}
.js-scrollfire-delay-enabled .scrollfire-delay-900 {
  transition-delay: 900ms;
}
.js-scrollfire-delay-enabled .scrollfire-delay-950 {
  transition-delay: 950ms;
}
.js-scrollfire-delay-enabled .scrollfire-delay-1000 {
  transition-delay: 1000ms;
}

.first-loading {
  display: none;
}

.default-transition {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
}
.default-transition__container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: white;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
  opacity: 1;
}

.huge-title, .page-title .page-title__title, .home-banner .home-banner__title, .dynamic h1 {
  font-family: var(--font-family-2);
  font-size: var(--huge-title-font-size);
  font-weight: 400;
  line-height: 1.1;
}

.large-title, .main__title, .no-results__main, .errors .errors__title, .similar-section .similar-section__title, .showcase .showcase__title, .dynamic h2, .footer .footer__newsletter-title {
  font-family: var(--font-family-2);
  font-size: var(--large-title-font-size);
  font-weight: 500;
  line-height: 1.1em;
}

.medium-title, .overlay-share .overlay-share__title, .no-results__sub, .contact-block .contact-block__title, .events-list__date--label, .dynamic h3, .side-contact .side-contact__title {
  font-family: var(--font-family-2);
  font-size: var(--medium-title-font-size);
  font-weight: 500;
  line-height: 1.3em;
}

.small-title, .dynamic h4 {
  font-family: var(--font-family-1);
  font-size: var(--small-title-font-size);
  font-weight: 700;
  line-height: 1.4em;
}

.paragraph, .errors .errors__text, .errors .errors__btn-home, .contact-block .contact-block__link, .contact-block .contact-block__address, .contact-block .contact-block__hours, .result .result__text, .side-contact .side-contact__link, .side-contact .side-contact__address, .side-contact .side-contact__hours {
  font-family: var(--font-family-1);
  font-size: var(--paragraph-font-size);
  font-weight: 400;
  line-height: 1.6;
}

:root {
  --button-background: var(--color-blue-marine);
  --button-color: var(--color-base-white);
  --button-border-color: var(--color-blue-marine);
  --button-background-hover: var(--color-blue-light-blue);
  --button-color-hover: var(--color-base-white);
  --button-border-color-hover: var(--color-blue-light-blue);
  --button-secondary-background: transparent;
  --button-secondary-color: var(--color-blue-marine);
  --button-secondary-border-color: var(--color-blue-marine);
  --button-secondary-background-hover: var(--color-blue-marine);
  --button-secondary-color-hover: var(--color-base-white);
  --button-secondary-border-color-hover: var(--color-blue-marine);
}

.button,
.button--secondary {
  font-family: var(--font-family-1);
  color: var(--button-color);
  letter-spacing: 0.1em;
  position: relative;
  font-weight: 700;
  font-size: 1.1rem;
  padding: 24px 40px;
  text-transform: uppercase;
  transition: color 0.3s, background-color 0s, border 0.3s;
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  line-height: 1.3em;
  cursor: pointer;
  z-index: 1;
  border: 0;
}
.button::before,
.button--secondary::before {
  content: "";
  background: var(--button-background);
  border: 1px solid var(--button-border-color);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -2;
}
.button::after,
.button--secondary::after {
  content: "";
  background: var(--button-background-hover);
  border: 0px solid var(--button-border-color-hover);
  transition: border 300ms;
  position: absolute;
  top: 100%;
  right: 0;
  bottom: 0;
  left: 0;
  transition: top 300ms;
  z-index: -1;
}
.button svg,
.button--secondary svg {
  content: "";
  fill: var(--button-color);
  display: inline-block;
  margin-left: 10px;
  transition: fill 0.3s;
  flex-shrink: 0;
  transform: translateY(-2px);
}
.button[target=_blank] svg, .button[href$=".pdf"] svg, .button[href$=".doc"] svg, .button[href$=".zip"] svg,
.button--secondary[target=_blank] svg,
.button--secondary[href$=".pdf"] svg,
.button--secondary[href$=".doc"] svg,
.button--secondary[href$=".zip"] svg {
  width: 11px;
  height: 11px;
}
.button[href$=".pdf"] svg, .button[href$=".doc"] svg, .button[href$=".zip"] svg,
.button--secondary[href$=".pdf"] svg,
.button--secondary[href$=".doc"] svg,
.button--secondary[href$=".zip"] svg {
  width: 12px;
  height: 12px;
}
.button:hover,
.button--secondary:hover {
  color: var(--button-color-hover);
}
.button:hover::after,
.button--secondary:hover::after {
  top: 0;
  border: 1px solid var(--button-border-color-hover);
}
.button:hover svg,
.button--secondary:hover svg {
  fill: var(--button-color-hover);
}
.button--white,
.button--secondary--white {
  --button-background: var(--color-base-white);
  --button-color: var(--color-blue-marine);
  --button-border-color: var(--color-base-white);
  --button-background-hover: var(--color-blue-light-blue);
  --button-color-hover: var(--color-base-white);
  --button-border-color-hover: var(--color-blue-light-blue);
  --button-secondary-background: transparent;
  --button-secondary-color: var(--color-base-white);
  --button-secondary-border-color: var(--color-base-white);
  --button-secondary-background-hover: var(--color-blue-light-blue);
  --button-secondary-color-hover: var(--color-base-white);
  --button-secondary-border-color-hover: var(--color-blue-light-blue);
}

.button--secondary {
  color: var(--button-secondary-color);
}
.button--secondary::before {
  background: transparent;
}
.button--secondary svg {
  fill: var(--button-secondary-color);
}
.button--secondary:hover {
  color: var(--button-secondary-color-hover);
}
.button--secondary:hover svg {
  fill: var(--button-secondary-color-hover);
}

:root {
  --form__grid-gap-x: 80px;
  --form__grid-gap-y: 50px;
}
@media screen and (max-width: 900px) {
  :root {
    --form__grid-gap-x: 0;
  }
}

.form__grid {
  margin-left: calc(var(--form__grid-gap-x) / -2);
  margin-right: calc(var(--form__grid-gap-x) / -2);
  margin-top: calc(var(--form__grid-gap-y) * -1);
  display: flex;
  flex-wrap: wrap;
}
.form__column {
  padding-left: calc(var(--form__grid-gap-x) / 2);
  padding-right: calc(var(--form__grid-gap-x) / 2);
  margin-top: var(--form__grid-gap-y);
  width: 100%;
}
.form__column:empty {
  margin-top: 0;
}
.form__column--half {
  width: 50%;
}
@media screen and (max-width: 900px) {
  .form__column--half {
    width: 100%;
  }
}
.form__column--half-width-side-pagination {
  width: 50%;
}
@media screen and (max-width: 1200px) {
  .form__column--half-width-side-pagination {
    width: 100%;
  }
}
.form__column--field + .form__column--field {
  margin-top: 10px;
}
.form .form__column--field.form__column--half + .form__column--field.form__column--half {
  margin-top: var(--form__grid-gap-y);
}
@media screen and (max-width: 900px) {
  .form .form__column--field.form__column--half + .form__column--field.form__column--half {
    margin-top: 10px;
  }
}
.form .form__column--field.form__column--half-width-side-pagination + .form__column--field.form__column--half-width-side-pagination {
  margin-top: var(--form__grid-gap-y);
}
@media screen and (max-width: 1200px) {
  .form .form__column--field.form__column--half-width-side-pagination + .form__column--field.form__column--half-width-side-pagination {
    margin-top: 10px;
  }
}
.form .oc-loading {
  pointer-events: none;
}
.form .oc-loading::after {
  background: transparent;
  border: 0.2em solid white;
  border-right-color: transparent;
  transition: none;
  position: initial;
  top: initial;
  right: initial;
  bottom: initial;
  left: initial;
  z-index: initial;
}

#applicationFormWrapper h3, #messageWrapper h3 {
  font-size: 20px;
}

.form-error {
  font-family: var(--font-family-1);
  margin-top: 8px;
  font-size: 1rem;
  color: var(--color-error-base);
}

:root {
  --form__label-color: var(--color-blue-marine);
  --form__label-size: 1.7rem;
}

.form-label {
  display: block;
  padding-bottom: 5px;
  font-size: var(--form__label-size);
  line-height: 1em;
  color: var(--form__label-color);
}

:root {
  --form-note__color: var(--color-grayscale-gray4);
}

.form-note {
  margin-top: 8px;
  color: var(--form-note__color);
  font-size: 1.4rem;
}
.form-note--bottom-padding {
  margin-bottom: 13px;
}

:root {
  --text-field__color: var(--color-blue-marine);
  --text-field__color-placeholder: var(--color-grayscale-gray6);
  --text-field__font-size: 1.6rem;
  --text-field__background: var(--color-base-white);
}

.text-field {
  position: relative;
}
.text-field__input {
  width: 100%;
  padding: 8px 0;
  font-size: var(--text-field__font-size);
  line-height: 1.6em;
  color: var(--text-field__color);
  border: 0;
  border-radius: 0;
  border-bottom: 1px solid var(--color-blue-marine);
  margin-bottom: 1px;
  font-family: var(--font-family-1);
  background-color: var(--text-field__background);
  -webkit-tap-highlight-color: transparent;
  -webkit-appearance: none;
}
.text-field__input:focus {
  border-bottom: 2px solid var(--color-blue-light-blue);
  margin-bottom: 0px;
}
.text-field__input {
  box-shadow: 0 0 0 100px var(--text-field__background) inset;
}
.text-field textarea {
  height: 45px;
  outline: none;
  resize: none;
  overflow: hidden;
}
.text-field textarea[style] {
  margin-bottom: 0;
}
.text-field__error {
  position: absolute;
  left: 0;
  top: 100%;
}
.text-field__input.error {
  border-color: var(--color-error-base);
}
.text-field ::-webkit-input-placeholder {
  color: var(--text-field__color-placeholder);
}
.text-field ::-moz-placeholder {
  color: var(--text-field__color-placeholder);
}
.text-field :-ms-input-placeholder {
  color: var(--text-field__color-placeholder);
}
.text-field :-moz-placeholder {
  color: var(--text-field__color-placeholder);
}
.text-field :-webkit-autofill,
.text-field :-webkit-autofill:hover,
.text-field :-webkit-autofill:focus,
.text-field :-webkit-autofill:active {
  -webkit-text-fill-color: var(--text-field__color);
  font-size: var(--text-field__font-size) !important;
  border-bottom: 2px solid var(--color-blue-light-blue) !important;
  margin-bottom: 0 !important;
}
.text-field :-webkit-autofill::first-line {
  font-size: var(--text-field__font-size);
  font-family: var(--font-family-1);
}
.text-field .datepickerWrapper {
  width: 350px;
  position: absolute;
  top: calc(100% + 10px);
  left: 0;
  background-color: #fff;
  opacity: 0;
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.15);
  z-index: 100;
  visibility: hidden;
  pointer-events: none;
  transition: opacity 300ms, visibility 0ms ease 300ms;
}
.text-field .datepickerWrapper.show {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
  transition: opacity 300ms;
}

:root {
  --file-field__background: var(--color-grayscale-gray1);
  --file-field__background-hover: var(--color-blue-light-blue);
  --file-field__color: var(--color-blue-marine);
  --file-field__color-hover: var(--color-base-white);
  --file-field__font-size: 1.4rem;
  --file-field__svg-size: 14px;
}

.file-field {
  position: relative;
}
.file-field__input {
  position: absolute;
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  z-index: -1;
}
.file-field__label {
  display: block;
  position: relative;
  width: 100%;
  padding: 25px 60px 25px 40px;
  cursor: pointer;
  background-color: var(--file-field__background);
  transition: color 300ms, background 300ms;
}
.file-field__label:hover {
  background: var(--file-field__background-hover);
}
.file-field__text {
  font-family: var(--font-family-1);
  font-size: var(--file-field__font-size);
  font-weight: 500;
  line-height: 1.5em;
  color: var(--file-field__color);
  transition: color 300ms;
}
.file-field__svg {
  position: absolute;
  width: var(--file-field__svg-size);
  height: var(--file-field__svg-size);
  top: 50%;
  right: 30px;
  transform: translate(0, -50%);
  fill: var(--file-field__color);
  transition: fill 300ms, opacity 300ms;
}
.file-field__x {
  position: absolute;
  width: var(--file-field__svg-size);
  height: var(--file-field__svg-size);
  top: 50%;
  transform: translate(0, -50%);
  right: 20px;
  opacity: 0;
  z-index: 1;
  cursor: pointer;
  pointer-events: none;
  transition: opacity 300ms, visibility 0ms ease 300ms;
}
.file-field__x:before, .file-field__x:after {
  content: "";
  position: absolute;
  width: 16px;
  height: 2px;
  top: 50%;
  left: 50%;
  background: var(--file-field__color);
  transition: background 300ms;
}
@media screen and (max-width: 600px) {
  .file-field__x:before, .file-field__x:after {
    width: 20px;
  }
}
.file-field__error {
  position: absolute;
}
.file-field__x:before {
  transform: translate(-50%, -50%) rotate(45deg);
}
.file-field__x:after {
  transform: translate(-50%, -50%) rotate(-45deg);
}
[data-file]:not([data-file=""]) .file-field__label {
  pointer-events: none;
}
[data-file]:not([data-file=""]) .file-field__svg {
  opacity: 0;
}
[data-file]:not([data-file=""]) .file-field__x {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
}
.file-field__label:hover .file-field__text {
  color: var(--file-field__color-hover);
}
.file-field__label:hover .file-field__svg {
  fill: var(--file-field__color-hover);
}
.file-field__x:hover:before, .file-field__x:hover:after {
  background: var(--file-field__color-hover);
}

:root {
  --checkbox-field__master-label-color: var(--color-grayscale-gray4);
  --checkbox-field__label-color: var(--color-blue-marine);
  --checkbox-field__box-border: 1px solid var(--color-grayscale-gray8);
  --checkbox-field__box-border-active: 1px solid var(--color-blue-light-blue);
  --checkbox-field__box-color: var(--color-base-white);
  --checkbox-field__box-color-active: var(--color-base-white);
  --checkbox-field__box-background-color: var(--color-base-white);
  --checkbox-field__box-background-color-active: var(--color-blue-light-blue);
}

.checkbox-field__main-label {
  display: block;
  font-size: 1.5rem;
  line-height: 1em;
  color: var(--checkbox-field__master-label-color);
  margin-bottom: 30px;
}
.checkbox-field__label {
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 20px;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.checkbox-field__label:last-child {
  margin-bottom: 0;
}
.checkbox-field__text {
  flex: 1;
  font-size: 1.7rem;
  color: var(--checkbox-field__label-color);
  line-height: 1em;
  order: 2;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.checkbox-field__input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.checkbox-field__box {
  border-radius: 3px;
  border: var(--checkbox-field__box-border);
  background-color: var(--checkbox-field__box-background-color);
  position: relative;
  width: 18px;
  height: 18px;
  margin-right: 15px;
  order: 1;
  transition: background-color 225ms, border 225ms;
}
.checkbox-field__box::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale(0);
  transition: transform 225ms;
  width: 12px;
  height: 12px;
  background: url(../medias/images/icons/checkmark.svg) no-repeat center/contain;
}
.checkbox-field__error {
  position: absolute;
}
.checkbox-field__input:checked ~ .checkbox-field__box {
  border: var(--checkbox-field__box-border-active);
  background-color: var(--checkbox-field__box-background-color-active);
}
.checkbox-field__input:checked ~ .checkbox-field__box::before {
  transform: translate(-50%, -50%) scale(1);
}
.checkbox-field--inline .checkbox-field__options {
  margin-bottom: -20px;
}
.checkbox-field--inline .checkbox-field__label {
  display: inline-flex;
  margin: 0 30px 20px 0;
}

.checkbox-field__header + .checkbox-field__options {
  margin-top: 15px;
}

:root {
  --radio-field__master-label-color: var(--color-grayscale-gray4);
  --radio-field__label-color: var(--color-blue-marine);
  --radio-field__dot-border: 1px solid var(--color-grayscale-gray8);
  --radio-field__dot-border-active: 1px solid var(--color-grayscale-gray8);
  --radio-field__dot-color: var(--color-blue-light-blue);
  --radio-field__dot-color-active: var(--color-blue-light-blue);
  --radio-field__dot-background-color: var(--color-base-white);
  --radio-field__dot-background-color-active: var(--color-base-white);
}

.radio-field__main-label {
  display: block;
  font-size: 1.5rem;
  line-height: 1em;
  color: var(--radio-field__master-label-color);
  margin-bottom: 30px;
}
.radio-field__label {
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 20px;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.radio-field__label:last-child {
  margin-bottom: 0;
}
.radio-field__text {
  flex: 1;
  font-size: 1.7rem;
  line-height: 1em;
  color: var(--radio-field__label-color);
  order: 2;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.radio-field__input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.radio-field__box {
  position: relative;
  width: 20px;
  height: 20px;
  margin-right: 15px;
  order: 1;
  transition: background 225ms, border 225ms;
  border-radius: 50%;
  border: var(--radio-field__dot-border);
  background: var(--radio-field__dot-background-color);
}
.radio-field__box::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale(0);
  transition: transform 225ms;
  width: 10px;
  height: 10px;
  background-color: var(--radio-field__dot-color);
  border-radius: 50%;
}
.radio-field__error {
  position: absolute;
}
.radio-field__input:checked ~ .radio-field__box {
  border: var(--radio-field__dot-border-active);
  background-color: var(--radio-field__dot-background-color-active);
}
.radio-field__input:checked ~ .radio-field__box::before {
  transform: translate(-50%, -50%) scale(1);
  background-color: var(--radio-field__dot-color-active);
}
.radio-field--inline .radio-field__options {
  margin-bottom: -20px;
}
.radio-field--inline .radio-field__label {
  display: inline-flex;
  margin: 0 30px 20px 0;
}

.radio-field__header + .radio-field__options {
  margin-top: 15px;
}

:root {
  --select-field__label-font-size: 1.7rem;
  --select-field__label-color: var(--color-blue-marine);
  --select-field__label-color-active: var(--color-grayscale-gray4);
  --select-field__label-background: #edf0f3;
  --select-field__label-background-active: var(--color-base-white);
  --select-field__label-background-hover: rgba(200, 218, 232, 0.7);
  --select-field__label-pointer-color: var(--color-blue-marine);
  --select-field__label-pointer-color-active: var(--color-blue-marine);
  --select-field__option-color: var(--color-blue-marine);
  --select-field__option-background: rgba(200, 218, 232, 0.3);
  --select-field__option-color-hover: var(--color-blue-marine);
  --select-field__option-background-hover: rgba(200, 218, 232, 0.6);
  --select-field__option-color-selected: var(--color-blue-marine);
  --select-field__option-background-selected: var(--color-blue-light-blue2);
  --select-field__scrollbar-color: var(--color-blue-marine);
  --select-field__scrollbar-background-color: var(--select-field__option-background);
}

.select-field {
  max-width: calc(50% - var(--form__grid-gap-x) / 2);
}
.select-field .tail-select {
  position: relative;
  z-index: 1;
}
.select-field .tail-select svg {
  position: absolute;
  width: 14px;
  height: 14px;
  top: 50%;
  right: 30px;
  transform: translateY(-50%);
  transition: transform 500ms, stroke 500ms;
  stroke: var(--select-field__label-pointer-color);
  stroke-width: 4px;
  fill: none;
}
.select-field .form-label {
  padding-bottom: 15px;
}
.select-field .select-label {
  padding: 20px 60px 20px 40px;
  background: #edf0f3;
  transition: background 500ms, border-color 500ms;
  cursor: pointer;
  user-select: none;
}
.select-field .select-label:hover {
  background: rgba(200, 218, 232, 0.7);
}
.select-field .label-inner {
  display: block;
  position: relative;
  font-size: var(--select-field__label-font-size);
  line-height: 1.5em;
  color: var(--select-field__label-color);
  transition: color 500ms;
}
.select-field .select-dropdown {
  width: auto;
  padding-top: 65px;
  opacity: 0;
  background: white;
  z-index: -1;
  visibility: hidden;
  pointer-events: none;
  box-shadow: 0px 0px 15px 2px rgba(0, 0, 0, 0.04);
  -webkit-box-shadow: 0px 0px 15px 2px rgba(0, 0, 0, 0.04);
  -moz-box-shadow: 0px 0px 15px 2px rgba(0, 0, 0, 0.04);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  transition: opacity 500ms var(--ease-in-out-quart), visibility 500ms;
}
.select-field .dropdown-inner {
  overflow: auto;
  max-height: 0;
  transition: max-height 500ms var(--ease-in-out-quart);
}
.select-field .dropdown-inner::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 5px;
  height: 5px;
  background-color: var(--select-field__scrollbar-background-color);
}
.select-field .dropdown-inner::-webkit-scrollbar-thumb {
  background-color: var(--select-field__scrollbar-color);
}
.select-field .dropdown-option {
  padding: 20px 40px;
  font-family: var(--select-field__option-font-family);
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.5em;
  color: var(--select-field__option-color);
  background: var(--select-field__option-background);
  cursor: pointer;
  transition: background-color 300ms, color 300ms;
  user-select: none;
}
.select-field .dropdown-option:hover {
  color: var(--select-field__option-color-hover);
  background: var(--select-field__option-background-hover);
}
.select-field .dropdown-option.selected {
  color: var(--select-field__option-color-selected);
  background: var(--select-field__option-background-selected);
}
.select-field select {
  width: 100%;
  padding: 20px 60px 20px 40px;
  font-size: var(--select-field__label-font-size);
  font-weight: 500;
  line-height: 1.5em;
  text-transform: var(--select-field__label-text-transform);
  letter-spacing: var(--select-field__label-letter-spacing);
  color: var(--select-field__label-color);
  border: 0;
  outline: none;
  -webkit-appearance: none;
  background: url("../medias/images/icons/chevron.svg") no-repeat calc(100% - 20px) 50%/12px 12px var(--select-field__label-background);
}
.select-field select[data-select-hidden="0"] {
  display: block !important;
  height: 0;
  opacity: 0;
  padding: 0;
  margin: 0;
}
.select-field select::-ms-expand {
  display: none;
}
.select-field .tail-select.active svg {
  transform: translateY(-50%) rotate(-180deg);
  stroke: var(--select-field__label-pointer-color-active);
}
.select-field .tail-select.active .select-label {
  background: rgba(200, 218, 232, 0.7);
}
.select-field .tail-select.active .select-dropdown {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
}
.select-field .tail-select.active .dropdown-inner {
  max-height: 195px !important;
}
.select-field__error {
  position: absolute;
}

:root {
  --title-field__size: 2.5em;
  --subtitle-field__size: 2em;
}

.title-field {
  margin-bottom: -20px;
}
.title-field__heading {
  font-size: var(--title-field__size);
  line-height: 1.4em;
  font-weight: 700;
  color: var(--color-blue-blue);
}

.subtitle-field {
  margin-bottom: -20px;
}
.subtitle-field__heading {
  font-size: var(--subtitle-field__size);
  line-height: 1.4em;
  font-weight: 700;
  color: var(--color-blue-marine);
}

:root {
  --search-field__color: var(--color-blue-marine);
  --search-field__color-placeholder: var(--color-grayscale-gray6);
  --search-field__font-size: 4rem;
  --search-field__background: var(--color-base-white);
  --search-btn-width: 60px;
}
@media screen and (max-width: 1200px) {
  :root {
    --search-field__font-size: 3.5rem;
  }
}
@media screen and (max-width: 1100px) {
  :root {
    --search-field__font-size: 3.4rem;
  }
}
@media screen and (max-width: 1000px) {
  :root {
    --search-field__font-size: 3.3rem;
  }
}
@media screen and (max-width: 900px) {
  :root {
    --search-field__font-size: 3.2rem;
  }
}
@media screen and (max-width: 800px) {
  :root {
    --search-field__font-size: 3.1rem;
  }
}
@media screen and (max-width: 700px) {
  :root {
    --search-field__font-size: 3rem;
  }
}
@media screen and (max-width: 600px) {
  :root {
    --search-field__font-size: 2.9rem;
  }
}
@media screen and (max-width: 500px) {
  :root {
    --search-field__font-size: 2.8rem;
  }
}
@media screen and (max-width: 400px) {
  :root {
    --search-field__font-size: 2.5rem;
  }
}
@media screen and (max-width: 360px) {
  :root {
    --search-field__font-size: 2.4rem;
  }
}
@media screen and (max-width: 600px) {
  :root {
    --search-btn-width: 50px ;
  }
}

.search-field {
  height: 105px;
  text-align: right;
  margin-bottom: 0;
  position: relative;
  padding-bottom: 20px;
  border-bottom: 1px solid var(--color-blue-marine);
  display: flex;
  align-items: flex-end;
}
.search-field__input {
  width: 100%;
  padding-right: 125px;
  font-family: var(--font-family-2);
  font-size: var(--search-field__font-size);
  color: var(--search-field__color);
  border: 0;
  border-radius: 0;
  border: none;
  background-color: var(--search-field__background);
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-appearance: none;
}
@media screen and (max-width: 700px) {
  .search-field__input {
    padding-right: 115px;
  }
}
@media screen and (max-width: 600px) {
  .search-field__input {
    padding-right: 100px;
  }
}
@media screen and (max-width: 500px) {
  .search-field__input {
    padding-right: 80px;
  }
}
.search-field[data-inputvalue]:not([data-inputvalue=""]) .search-field__x {
  opacity: 1;
  visibility: visible;
  transition: opacity 300ms, background-color 300ms, visibility 0ms ease 300ms;
}
.search-field__x {
  width: 25px;
  height: 25px;
  opacity: 0;
  visibility: hidden;
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: calc(25px + var(--search-btn-width));
  transform: translateY(-50%);
}
.search-field__x:before, .search-field__x:after {
  content: "";
  position: absolute;
  width: 12px;
  height: 1px;
  top: 50%;
  left: 50%;
  background: var(--search-field__color);
}
.search-field__x:before {
  transform: translate(-50%, -50%) rotate(45deg);
}
.search-field__x:after {
  transform: translate(-50%, -50%) rotate(-45deg);
}
@media screen and (max-width: 600px) {
  .search-field__x {
    top: initial;
    bottom: 20px;
  }
}
.search-field__button {
  width: var(--search-btn-width);
  height: var(--search-btn-width);
  background: var(--search-field__background);
  transition: background-color 300ms;
  position: absolute;
  right: 0;
  bottom: 20px;
}
.search-field__button:hover {
  background: var(--search-field__color);
}
.search-field__svg--arrow, .search-field__svg--arrow-overlay {
  width: 9px;
  height: 24px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-90deg);
}
.search-field__svg--arrow {
  fill: var(--color-blue-light-blue);
  stroke: var(--color-blue-light-blue);
}
.search-field__svg--arrow-overlay {
  fill: var(--color-base-white);
  stroke: var(--color-base-white);
}
.search-field ::-webkit-input-placeholder {
  color: var(--search-field__color-placeholder);
}
.search-field ::-moz-placeholder {
  color: var(--search-field__color-placeholder);
}
.search-field :-ms-input-placeholder {
  color: var(--search-field__color-placeholder);
}
.search-field :-moz-placeholder {
  color: var(--search-field__color-placeholder);
}
.search-field :-webkit-autofill,
.search-field :-webkit-autofill:hover,
.search-field :-webkit-autofill:focus,
.search-field :-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 100px var(--search-field__background) inset !important;
  -webkit-text-fill-color: var(--search-field__color);
  font-size: var(--search-field__font-size) !important;
  border-bottom: 2px solid var(--color-blue-light-blue) !important;
  margin-bottom: 0 !important;
}
.search-field :-webkit-autofill::first-line {
  font-size: var(--search-field__font-size);
  font-family: var(--font-family-2);
}

.control-multi-file-uploader,
.c-multiple-files-field,
.multiple-files-field {
  --multiple-files-field-container-background-color: #f6f6f6;
  --multiple-files-field-container-border-color: #b2b2b2;
  --multiple-files-field-container-border-radius: 0;
  --multiple-files-field-button-height: 100px;
  --multiple-files-field-button-font-size: 1.5rem;
  --multiple-files-field-button-font-weight: 500;
  --multiple-files-field-button-line-height: 1.3em;
  --multiple-files-field-button-color: var(--color-blue-marine);
  --multiple-files-field-button-svg-size: 10px;
  --multiple-files-field-button-svg-color: var(--color-blue-marine);
  --multiple-files-field-button-background-color: #f6f6f6;
  --multiple-files-field-button-background-color-hover: #d4d4d4;
  --multiple-files-field-file-background-color: #f6f6f6;
  --multiple-files-field-file-preview-size: 100px;
  --multiple-files-field-file-preview-border-radius: 0;
  --multiple-files-field-file-detail-font-size: var(--fz-14);
  --multiple-files-field-file-detail-font-weight: 400;
  --multiple-files-field-file-detail-line-height: 1.5em;
  --multiple-files-field-file-detail-color: var(--color-blue-marine);
  --multiple-files-field-file-x-color: rgb(var(--rgb-white));
  --multiple-files-field-file-x-background-color: rgb(var(--rgb-3));
  --multiple-files-field-file-x-background-color-hover: var(--color-blue-marine);
  --multiple-files-field-file-x-background-size: 20px;
}

.control-multi-file-uploader {
  background-color: var(--multiple-files-field-container-background-color);
  border: 1px dashed var(--multiple-files-field-container-border-color);
  border-radius: var(--multiple-files-field-container-border-radius);
  overflow: hidden;
}
.control-multi-file-uploader .container {
  position: relative;
  width: 100%;
  height: 100%;
}
.control-multi-file-uploader .dz-clickable {
  height: var(--multiple-files-field-button-height);
  background-color: var(--multiple-files-field-button-background-color);
  cursor: pointer;
  transition-property: color, background-color;
  transition-duration: 300ms;
}
.control-multi-file-uploader .dz-clickable:hover {
  background-color: var(--multiple-files-field-button-background-color-hover);
}
.control-multi-file-uploader .content {
  font-size: var(--multiple-files-field-button-font-size);
}
.control-multi-file-uploader .content .placeholder {
  position: absolute;
  top: 50px;
  right: 20px;
  left: 20px;
  line-height: var(--multiple-files-field-button-line-height);
  font-weight: var(--multiple-files-field-button-font-weight);
  color: var(--multiple-files-field-button-color);
  text-align: center;
  pointer-events: none;
  transform: translate(0, -50%);
  transition-property: color, background-color;
  transition-duration: 300ms;
}
.control-multi-file-uploader .content .placeholder__svg {
  width: var(--multiple-files-field-button-svg-size);
  height: var(--multiple-files-field-button-svg-size);
  fill: var(--multiple-files-field-button-svg-color);
  margin-left: 10px;
  margin-bottom: -1px;
}
.control-multi-file-uploader .content .placeholder .upload-full-text {
  display: block;
}
@media screen and (max-width: 560px) {
  .control-multi-file-uploader .content .placeholder .upload-full-text {
    display: none;
  }
}
.control-multi-file-uploader .content .placeholder .upload-truncated-text {
  display: none;
}
@media screen and (max-width: 560px) {
  .control-multi-file-uploader .content .placeholder .upload-truncated-text {
    display: block;
  }
}
.control-multi-file-uploader .content .dz-preview {
  display: flex;
  align-items: center;
  position: relative;
  min-height: 100px;
  padding-left: 120px;
  padding-right: 35px;
  margin: 20px;
}
.control-multi-file-uploader .content .dz-preview .thumbnail {
  position: absolute;
  width: var(--multiple-files-field-file-preview-size);
  height: var(--multiple-files-field-file-preview-size);
  top: 0;
  left: 0;
}
.control-multi-file-uploader .content .dz-preview .thumbnail {
  border-radius: var(--multiple-files-field-file-preview-border-radius);
  overflow: hidden;
}
.control-multi-file-uploader .content .dz-preview .dz-details {
  flex-wrap: wrap;
  font-size: var(--multiple-files-field-file-detail-font-size);
  line-height: var(--multiple-files-field-file-detail-line-height);
  color: var(--multiple-files-field-file-detail-color);
}
.control-multi-file-uploader .content .dz-preview .dz-details .dz-filename,
.control-multi-file-uploader .content .dz-preview .dz-details .dz-size {
  width: 100%;
  word-break: break-all;
  margin: 6px 0;
}
.control-multi-file-uploader .content .dz-preview .action-panel {
  position: absolute;
  width: var(--multiple-files-field-file-x-background-size);
  height: var(--multiple-files-field-file-x-background-size);
  top: 10px;
  right: 10px;
  color: var(--multiple-files-field-file-x-color);
  background: var(--multiple-files-field-file-x-background-color);
  border-radius: 50%;
  cursor: pointer;
  z-index: 4;
  transition-property: color, background-color;
  transition-duration: 300ms;
}
.control-multi-file-uploader .content .dz-preview .action-panel:hover {
  background-color: var(--multiple-files-field-file-x-background-color-hover);
}
.control-multi-file-uploader .content .dz-preview .action-panel a {
  position: absolute;
  top: 50%;
  left: 50%;
  color: var(--multiple-files-field-file-x-color);
  transform: translate(-50%, -50%);
}
.control-multi-file-uploader .content .dz-preview .dz-error-mark {
  display: none;
}
.control-multi-file-uploader .content .dz-preview .dz-error-message {
  position: absolute;
  bottom: 5px;
  color: #458a91;
}

.c-multiple-files-field {
  position: relative;
}
.c-multiple-files-field__container {
  border: 1px dashed var(--multiple-files-field-container-border-color);
  border-radius: var(--multiple-files-field-container-border-radius);
  overflow: hidden;
}
.c-multiple-files-field__button-input {
  position: absolute;
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  z-index: -1;
}
.c-multiple-files-field__button-label {
  display: flex;
  width: 100%;
  min-height: var(--multiple-files-field-button-height);
  padding: 20px 20px;
  align-items: center;
  justify-content: center;
  background-color: var(--multiple-files-field-button-background-color);
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.c-multiple-files-field__button-input[disabled] + .c-multiple-files-field__button-label {
  pointer-events: none;
}
.c-multiple-files-field__button-label:hover {
  background-color: var(--multiple-files-field-button-background-color-hover);
}
.c-multiple-files-field__button-label-text {
  font-size: var(--multiple-files-field-button-font-size);
  font-weight: var(--multiple-files-field-button-font-weight);
  line-height: var(--multiple-files-field-button-line-height);
  text-align: center;
  color: var(--multiple-files-field-button-color);
}
.c-multiple-files-field__button-input[disabled] + .c-multiple-files-field__button-label .c-multiple-files-field__button-label-text {
  opacity: 0.6;
}
.c-multiple-files-field__button-label-svg {
  width: var(--multiple-files-field-button-svg-size);
  height: var(--multiple-files-field-button-svg-size);
  fill: var(--multiple-files-field-button-svg-color);
  margin-left: 10px;
  margin-bottom: -1px;
}
.c-multiple-files-field__file {
  height: 0px;
  background-color: var(--multiple-files-field-file-background-color);
  opacity: 0;
  overflow: hidden;
}
.c-multiple-files-field__file-container {
  display: flex;
  align-items: center;
  padding: 20px;
}
.c-multiple-files-field__file-preview-item {
  padding-right: 20px;
}
.c-multiple-files-field__file-preview-picture {
  width: var(--multiple-files-field-file-preview-size);
  height: var(--multiple-files-field-file-preview-size);
  border-radius: var(--multiple-files-field-file-preview-border-radius);
  overflow: hidden;
}
.c-multiple-files-field__file-text-item {
  flex: 1;
  padding-right: 20px;
}
.c-multiple-files-field__file-title, .c-multiple-files-field__file-size {
  display: block;
  font-size: var(--multiple-files-field-file-detail-font-size);
  font-weight: var(--multiple-files-field-file-detail-font-weight);
  line-height: var(--multiple-files-field-file-detail-line-height);
  color: var(--multiple-files-field-file-detail-color);
  word-break: break-all;
}
.c-multiple-files-field__file-title {
  margin-bottom: 6px;
}
.c-multiple-files-field__file-delete-item {
  align-self: flex-start;
  padding-top: 10px;
  padding-right: 10px;
}
.c-multiple-files-field__file-delete-link {
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--multiple-files-field-file-x-background-size);
  height: var(--multiple-files-field-file-x-background-size);
  background-color: var(--multiple-files-field-file-x-background-color);
  border-radius: 50%;
  transition: background-color 0.3s ease;
}
.c-multiple-files-field__file-delete-link:hover {
  background-color: var(--multiple-files-field-file-x-background-color-hover);
}
.c-multiple-files-field__file-delete-x {
  position: relative;
  width: 8px;
  height: 8px;
}
.c-multiple-files-field__file-delete-x:before, .c-multiple-files-field__file-delete-x:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  top: 50%;
  left: 50%;
  background-color: var(--multiple-files-field-file-x-color);
  transition: background-color 300ms;
}
.c-multiple-files-field__file-delete-x:before {
  transform: translate(-50%, -50%) rotate(45deg);
}
.c-multiple-files-field__file-delete-x:after {
  transform: translate(-50%, -50%) rotate(-45deg);
}

.form {
  --timepicker-color: rgb(var(--rgb-blue-marine));
  --timepicker-background-color: #f7f8f8;
}

.text-field--timepicker input::-webkit-inner-spin-button, .text-field--timepicker input::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

.form .bootstrap-datetimepicker-widget table td span:hover {
  background: none !important;
}
.form .bootstrap-datetimepicker-widget table td span {
  color: var(--timepicker-color);
}
.form .bootstrap-datetimepicker-widget table td span:hover {
  opacity: 0.6;
}
.form .bootstrap-datetimepicker-widget table td {
  width: 0 !important;
}
.form .glyphicon {
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: "Glyphicons Halflings";
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.form .glyphicon-time::before {
  content: "\e023";
}
.form .glyphicon-chevron-left::before {
  content: "\e079";
}
.form .glyphicon-chevron-right::before {
  content: "\e080";
}
.form .glyphicon-chevron-up::before {
  content: "\e113";
}
.form .glyphicon-chevron-down::before {
  content: "\e114";
}
.form .glyphicon-calendar::before {
  content: "\e109";
}
.form .timepicker .btn {
  display: inline-block;
  margin-bottom: 0;
  line-height: 1.42857143;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: relative !important;
}
.form .timepicker .btn::before {
  content: url("../medias/images/icons/pointer.svg");
  position: absolute !important;
  display: block;
  width: 14px;
  height: 14px;
  background-size: 16px 16px;
  background-position: center center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  opacity: 1;
  transition: opacity 0.3s ease;
  will-change: transform;
}
.form .timepicker .btn[data-action=incrementHours]::before {
  transform: translate(-50%, -50%) rotate(180deg);
}
.form .timepicker .btn[data-action=incrementMinutes]::before {
  transform: translate(-50%, -50%) rotate(180deg);
}
.form .timepicker .btn:hover::before {
  opacity: 0.5;
}
.form .timepicker .btn::before {
  background: transparent;
}
.form .collapse {
  display: none;
}
.form .collapse.in {
  display: block;
}
.form .dropdown-menu {
  position: absolute;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  font-size: 14px;
  text-align: left;
  list-style: none;
  background-color: var(--timepicker-background-color);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border-radius: 4px;
}
.form .list-unstyled {
  padding-left: 0;
  list-style: none;
}
.form .bootstrap-datetimepicker-widget {
  list-style: none;
  height: 225px;
}
.form .bootstrap-datetimepicker-widget.dropdown-menu {
  display: block;
  margin: 2px 0;
  padding: 4px;
  width: 19em;
  top: calc(100% + 20px) !important;
}
@media (min-width: 768px) {
  .form .bootstrap-datetimepicker-widget.dropdown-menu.timepicker-sbs {
    width: 38em;
  }
}
@media (min-width: 992px) {
  .form .bootstrap-datetimepicker-widget.dropdown-menu.timepicker-sbs {
    width: 38em;
  }
}
@media (min-width: 1200px) {
  .form .bootstrap-datetimepicker-widget.dropdown-menu.timepicker-sbs {
    width: 38em;
  }
}
.form .bootstrap-datetimepicker-widget.dropdown-menu.bottom::before {
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #ccc;
  border-bottom-color: rgba(0, 0, 0, 0.2);
  top: -7px;
  left: 7px;
}
.form .bootstrap-datetimepicker-widget.dropdown-menu.bottom::after {
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid white;
  top: -6px;
  left: 8px;
}
.form .bootstrap-datetimepicker-widget.dropdown-menu.top::before {
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 7px solid #ccc;
  border-top-color: rgba(0, 0, 0, 0.2);
  bottom: -7px;
  left: 6px;
}
.form .bootstrap-datetimepicker-widget.dropdown-menu.top::after {
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid white;
  bottom: -6px;
  left: 7px;
}
.form .bootstrap-datetimepicker-widget.dropdown-menu.pull-right::before {
  left: auto;
  right: 6px;
}
.form .bootstrap-datetimepicker-widget.dropdown-menu.pull-right::after {
  left: auto;
  right: 7px;
}
.form .bootstrap-datetimepicker-widget .list-unstyled {
  margin: 0;
}
.form .bootstrap-datetimepicker-widget a[data-action] {
  padding: 6px 0;
}
.form .bootstrap-datetimepicker-widget a[data-action]:active {
  box-shadow: none;
}
.form .bootstrap-datetimepicker-widget .timepicker-hour,
.form .bootstrap-datetimepicker-widget .timepicker-minute,
.form .bootstrap-datetimepicker-widget .timepicker-second {
  width: 54px;
  font-weight: bold;
  font-size: 1.2em;
  margin: 0;
}
.form .bootstrap-datetimepicker-widget button[data-action] {
  padding: 6px;
}
.form .bootstrap-datetimepicker-widget .btn[data-action=showHours]::after {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
.form .bootstrap-datetimepicker-widget .btn[data-action=showMinutes]::after {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
.form .bootstrap-datetimepicker-widget .btn[data-action=togglePeriod]::after {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  content: "Toggle AM/PM";
}
.form .bootstrap-datetimepicker-widget .btn[data-action=clear]::after {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  content: "Clear the picker";
}
.form .bootstrap-datetimepicker-widget .btn[data-action=today]::after {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  content: "Set the date to today";
}
.form .bootstrap-datetimepicker-widget .picker-switch {
  text-align: center;
}
.form .bootstrap-datetimepicker-widget .picker-switch::after {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  content: "Toggle Date and Time Screens";
}
.form .bootstrap-datetimepicker-widget .picker-switch td {
  padding: 0;
  margin: 0;
  height: auto;
  width: auto;
  line-height: inherit;
}
.form .bootstrap-datetimepicker-widget .picker-switch td span {
  line-height: 2.5;
  height: 2.5em;
  width: 100%;
}
.form .bootstrap-datetimepicker-widget table {
  width: 100%;
  margin: 0;
}
.form .bootstrap-datetimepicker-widget table td,
.form .bootstrap-datetimepicker-widget table th {
  color: var(--timepicker-color);
  text-align: center;
  border-radius: 4px;
}
.form .bootstrap-datetimepicker-widget table th {
  height: 20px;
  line-height: 20px;
  width: 20px;
}
.form .bootstrap-datetimepicker-widget table th.picker-switch {
  width: 145px;
}
.form .bootstrap-datetimepicker-widget table th.disabled,
.form .bootstrap-datetimepicker-widget table th.disabled:hover {
  background: none;
  color: #777777;
  cursor: not-allowed;
}
.form .bootstrap-datetimepicker-widget table th.prev::after {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  content: "Previous Month";
}
.form .bootstrap-datetimepicker-widget table th.next::after {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  content: "Next Month";
}
.form .bootstrap-datetimepicker-widget table thead tr:first-child th {
  cursor: pointer;
}
.form .bootstrap-datetimepicker-widget table td {
  height: 54px;
  line-height: 54px;
  width: 54px;
}
.form .bootstrap-datetimepicker-widget table td.cw {
  font-size: 0.8em;
  height: 20px;
  line-height: 20px;
  color: #777777;
}
.form .bootstrap-datetimepicker-widget table td.day {
  height: 20px;
  line-height: 20px;
  width: 20px;
}
.form .bootstrap-datetimepicker-widget table td.day:hover,
.form .bootstrap-datetimepicker-widget table td.hour:hover,
.form .bootstrap-datetimepicker-widget table td.minute:hover,
.form .bootstrap-datetimepicker-widget table td.second:hover {
  background: #eeeeee;
  cursor: pointer;
}
.form .bootstrap-datetimepicker-widget table td.old,
.form .bootstrap-datetimepicker-widget table td.new {
  color: #777777;
}
.form .bootstrap-datetimepicker-widget table td.today {
  position: relative;
}
.form .bootstrap-datetimepicker-widget table td.today:before {
  content: "";
  display: inline-block;
  border: solid transparent;
  border-width: 0 0 7px 7px;
  border-bottom-color: #337ab7;
  border-top-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  bottom: 4px;
  right: 4px;
}
.form .bootstrap-datetimepicker-widget table td.active,
.form .bootstrap-datetimepicker-widget table td.active:hover {
  background-color: #337ab7;
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}
.form .bootstrap-datetimepicker-widget table td.active.today:before {
  border-bottom-color: #fff;
}
.form .bootstrap-datetimepicker-widget table td.disabled,
.form .bootstrap-datetimepicker-widget table td.disabled:hover {
  background: none;
  color: #777777;
  cursor: not-allowed;
}
.form .bootstrap-datetimepicker-widget table td span {
  display: inline-block;
  width: 54px;
  height: 54px;
  line-height: 54px;
  margin: 2px 1.5px;
  cursor: pointer;
  border-radius: 4px;
}
.form .bootstrap-datetimepicker-widget table td span:hover {
  background: #eeeeee;
}
.form .bootstrap-datetimepicker-widget table td span.active {
  background-color: #337ab7;
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}
.form .bootstrap-datetimepicker-widget table td span.old {
  color: #777777;
}
.form .bootstrap-datetimepicker-widget table td span.disabled,
.form .bootstrap-datetimepicker-widget table td span.disabled:hover {
  background: none;
  color: #777777;
  cursor: not-allowed;
}
.form .bootstrap-datetimepicker-widget.usetwentyfour td.hour {
  height: 27px;
  line-height: 27px;
}
.form .bootstrap-datetimepicker-widget.wider {
  width: 21em;
}
.form .bootstrap-datetimepicker-widget .datepicker-decades .decade {
  line-height: 1.8em !important;
}
.form .input-group.date .input-group-addon {
  cursor: pointer;
}
.form .sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

:root {
  --gallery__overlay-color: var(--color-blue-marine);
  --gallery__overlay-last-opacity: 0.8;
  --gallery__overlay-opacity-hover: 0.5;
  --gallery__height: 400px;
  --gallery__width: 920px;
  --gallery__margin: 20px;
}
@media screen and (max-width: 768px) {
  :root {
    --gallery__height: 335px;
    --gallery__margin: 15px;
  }
}
@media screen and (max-width: 500px) {
  :root {
    --gallery__height: 200px;
    --gallery__margin: 10px;
  }
}

.gallery {
  display: flex;
  flex-flow: row nowrap;
  max-width: var(--gallery__width);
  height: var(--gallery__height);
}
.gallery__left {
  flex: 1;
  margin-right: var(--gallery__margin);
}
.gallery__right {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  width: calc((var(--gallery__height) - var(--gallery__margin)) / 2);
}
.gallery__block {
  position: relative;
  height: 100%;
}
.gallery__block:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: var(--gallery__overlay-color);
  opacity: 0;
  transition: opacity 300ms;
  pointer-events: none;
}
.gallery__link {
  display: block;
  height: 100%;
  outline: none;
}
.gallery__block {
  display: none;
}
.gallery__block-1, .gallery__block-2, .gallery__block-3 {
  display: block;
}
.gallery__block-1 .gallery__link, .gallery__block-2 .gallery__link, .gallery__block-3 .gallery__link {
  display: block;
  height: 100%;
}
.gallery__block-2, .gallery__block-3 {
  height: calc((var(--gallery__height) - var(--gallery__margin)) / 2);
}
.gallery__block-3:before {
  opacity: var(--gallery__overlay-last-opacity);
}
.gallery__block:hover:before {
  opacity: var(--gallery__overlay-opacity-hover);
}
.gallery__picture {
  width: 100%;
  height: 100%;
}
.gallery__plus {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
}
.gallery__plus::before, .gallery__plus::after {
  content: "";
  position: absolute;
  background-color: white;
  height: 2px;
  width: 100%;
  transform: translate(-50%, -50%) rotate(0deg);
  top: 50%;
  left: 50%;
}
.gallery__plus::after {
  transform: translate(-50%, -50%) rotate(90deg);
}

:root {
  --rubric__font-size: 2.5rem;
  --rubric__subtitle-font-size: 1.2rem;
  --rubric__height: 145px;
  --rubric__thumb-width: 100px;
  --rubric__thumb-height: 100px;
  --rubric__color: var(--color-blue-marine);
  --rubric__color-hover: var(--color-blue-light-blue);
  --rubric__plus-size: 20px;
  --rubric__plus-color:var(--color-blue-marine);
  --rubric__plus-color-hover: var(--color-blue-light-blue);
}
@media screen and (max-width: 1024px) {
  :root {
    --rubric__font-size: 2.3rem;
    --rubric__height: 120px;
  }
}
@media screen and (max-width: 768px) {
  :root {
    --rubric__font-size: 2.2rem;
    --rubric__height: 100px;
  }
}

.rubric {
  position: relative;
  border-bottom: 1px solid var(--color-grayscale-gray3);
  background: transparent;
  transition: border-bottom 700ms, background 700ms var(--ease-in-out-quart) 100ms;
}
.rubric:first-child {
  border-top: 1px solid var(--color-grayscale-gray3);
}
.rubric__header {
  position: relative;
  cursor: pointer;
  padding: 20px 80px 20px 40px;
  padding-right: 115px;
  min-height: var(--rubric__height);
  display: flex;
  align-items: center;
}
@media screen and (max-width: 600px) {
  .rubric__header {
    padding: 20px 60px 20px 20px;
  }
}
.rubric__picture {
  width: var(--rubric__thumb-width);
  height: var(--rubric__thumb-height);
  margin-right: 40px;
  overflow: hidden;
  flex-shrink: 0;
}
@media screen and (max-width: 600px) {
  .rubric__picture {
    display: none;
  }
}
.rubric__picture-img {
  transition: transform 0.3s;
  transform: scale(1);
}
.rubric__subtitle {
  margin-bottom: 4px;
  font-size: var(--rubric__subtitle-font-size);
  color: var(--color-blue-blue);
  font-weight: 700;
  line-height: 1.4em;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}
.rubric__title {
  font-size: var(--rubric__font-size);
  line-height: 1.6;
  color: var(--rubric__color);
  font-weight: 500;
  transition: color 0.3s;
}
.rubric__plus {
  position: absolute;
  width: var(--rubric__plus-size);
  height: var(--rubric__plus-size);
  top: 50%;
  right: 60px;
  transform: translateY(-50%);
}
@media screen and (max-width: 600px) {
  .rubric__plus {
    right: 20px;
  }
}
.rubric__plus::before, .rubric__plus::after {
  content: "";
  position: absolute;
  width: var(--rubric__plus-size);
  height: 2px;
  background-color: var(--rubric__plus-color);
  left: 50%;
  top: 50%;
  transition: background-color 0.3s, opacity 0.3s;
  transform: translate(-50%, -50%);
  opacity: 1;
}
.rubric__plus::after {
  transform: translate(-50%, -50%) rotate(90deg);
}
.rubric__header:hover .rubric__picture-img {
  transform: scale(1.1);
}
.rubric__header:hover .rubric__title {
  color: var(--rubric__color-hover);
}
.rubric__header:hover .rubric__plus::before, .rubric__header:hover .rubric__plus::after {
  background-color: var(--rubric__plus-color-hover);
}
.rubric__content {
  height: 0;
  will-change: height;
  overflow: hidden;
}
@media screen and (max-width: 1024px) {
  .rubric__content {
    padding: 0;
  }
}
.rubric__transition {
  padding: 0 40px;
  opacity: 0;
  transform: translateY(100px);
  transition: opacity 700ms var(--ease-in-out-quart), transform 700ms var(--ease-in-out-quart);
}
@media screen and (max-width: 600px) {
  .rubric__transition {
    padding: 0 20px;
  }
}
.rubric__transition > :first-child {
  margin-top: 0;
}
.rubric__dynamic h3 {
  margin-top: 50px;
  margin-bottom: 25px;
  line-height: 1.45em;
  font-weight: 500;
  font-size: 2.2rem;
}
@media screen and (max-width: 1024px) {
  .rubric__dynamic h3 {
    font-size: 2.1rem;
  }
}
@media screen and (max-width: 768px) {
  .rubric__dynamic h3 {
    font-size: 2rem;
  }
}
.rubric__dynamic h4 {
  margin-top: 50px;
  margin-bottom: 25px;
  line-height: 1.5em;
  font-size: 1.8rem;
  font-weight: 700;
}
.rubric__dynamic .table-wrapper table tbody tr td.header {
  background-color: var(--dynamic__table-header-background);
}
.rubric__dynamic .table-wrapper table tbody tr td.secondary-header {
  background-color: var(--color-base-white);
}
.rubric__dynamic, .rubric__document, .rubric__gallery, .rubric__share {
  padding-bottom: var(--y-spacing-60);
}
.rubric__document:last-child {
  padding-bottom: var(--y-spacing-90);
}
.rubric__share {
  padding-bottom: 45px;
}
.rubric__share .share .share__text {
  color: var(--rubric__color);
}

/*
|--------------------------------------------------------------------------
| RUBRIQUE OUVERTE
|--------------------------------------------------------------------------
*/
.rubric.js-accordion-opened {
  background: var(--color-grayscale-gray1);
}
.rubric.js-accordion-opened .rubric__transition {
  opacity: 1;
  transform: none;
  transition: opacity 700ms var(--ease-in-out-quart) 100ms, transform 700ms var(--ease-in-out-quart) 100ms;
}
.rubric.js-accordion-opened .rubric__plus::after {
  opacity: 0;
}

:root {
  --document__font-size: 2.5rem;
  --document__subtitle-font-size: 1.2rem;
  --document__in-rubric-font-size: 1.7em;
  --document__height: 145px;
  --document__in-rubric-height: 64px;
  --document__thumb-width: 100px;
  --document__thumb-height: 100px;
  --document__thumb-width-rubric: 60px;
  --document__thumb-height-rubric: 60px;
  --document__color: var(--color-blue-marine);
  --document__color-hover: var(--color-blue-light-blue);
}
@media screen and (max-width: 1024px) {
  :root {
    --document__font-size: 2.2rem;
    --document__height: 120px;
  }
}
@media screen and (max-width: 768px) {
  :root {
    --document__font-size: 1.8rem;
    --document__height: 100px;
  }
}
@media screen and (max-width: 500px) {
  :root {
    --document__height: 80px;
  }
}

.documents .document {
  position: relative;
  border-bottom: 1px solid var(--color-grayscale-gray3);
}
.documents .document:first-child {
  border-top: 1px solid var(--color-grayscale-gray3);
}
.documents .document__link {
  position: relative;
  cursor: pointer;
  padding: 20px 80px 20px 40px;
  min-height: var(--document__height);
  display: flex;
  align-items: center;
  text-decoration: none;
}
@media screen and (max-width: 768px) {
  .documents .document__link {
    padding: 20px 60px 20px 20px;
  }
}
@media screen and (max-width: 500px) {
  .documents .document__link {
    padding: 15px 50px 15px 20px;
  }
}
.documents .document__picture {
  width: var(--document__thumb-width);
  height: var(--document__thumb-height);
  margin-right: 40px;
  overflow: hidden;
  flex-shrink: 0;
}
@media screen and (max-width: 600px) {
  .documents .document__picture {
    display: none;
  }
}
.documents .document__picture-img {
  transform: scale(1);
  transition: transform 0.3s;
}
.documents .document__subtitle {
  margin-bottom: 4px;
  font-size: var(--document__subtitle-font-size);
  color: var(--color-blue-blue);
  font-weight: 700;
  line-height: 1.4em;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}
.documents .document__title {
  line-height: 1.2;
  color: var(--document__color);
  font-size: var(--document__font-size);
  font-weight: 500;
  transition: color 0.3s;
}
.documents .document__icon {
  position: absolute;
  top: 50%;
  right: 60px;
  transform: translateY(-50%);
}
@media screen and (max-width: 768px) {
  .documents .document__icon {
    right: 20px;
  }
}
.documents .document__svg {
  width: 20px;
  height: 20px;
  fill: var(--document__color);
  transition: stroke 300ms;
  transition: fill 0.3s;
}
@media screen and (max-width: 500px) {
  .documents .document__svg {
    width: 16px;
    height: 16px;
  }
}
.documents .document__link:hover .document__picture-img {
  transform: scale(1.1);
}
.documents .document__link:hover .document__title {
  color: var(--document__color-hover);
}
.documents .document__link:hover .document__svg {
  fill: var(--document__color-hover);
}
.documents--in-rubric .document {
  border-color: var(--color-blue-marine);
}
.documents--in-rubric .document:first-child {
  border-color: var(--color-blue-marine);
}
.documents--in-rubric .document .document__picture {
  width: var(--document__thumb-width-rubric);
  height: var(--document__thumb-height-rubric);
}
.documents--in-rubric .document .document__link {
  padding: 25px 60px 25px 0;
  min-height: var(--document__in-rubric-height);
}
@media screen and (max-width: 768px) {
  .documents--in-rubric .document .document__link {
    padding: 20px 50px 20px 0px;
  }
}
@media screen and (max-width: 500px) {
  .documents--in-rubric .document .document__link {
    padding: 15px 40px 15px 0px;
  }
}
.documents--in-rubric .document .document__title {
  font-size: var(--document__in-rubric-font-size);
  font-weight: 500;
}
.documents--in-rubric .document .document__icon {
  right: 20px;
}
@media screen and (max-width: 500px) {
  .documents--in-rubric .document .document__icon {
    right: 10px;
  }
}
@media screen and (max-width: 400px) {
  .documents--in-rubric .document .document__icon {
    right: 8px;
  }
}
.documents--in-rubric .document .document__svg {
  width: 18px;
  height: 18px;
}
@media screen and (max-width: 500px) {
  .documents--in-rubric .document .document__svg {
    width: 14px;
    height: 14px;
  }
}
.documents.documents-bottom-padding {
  padding-bottom: var(--y-spacing-70);
}

.share {
  display: inline-flex;
  align-items: center;
}
.share .share__svg-container {
  width: 50px;
  height: 50px;
  background: var(--color-base-white);
  transition: background 300ms;
  margin-right: 17px;
  position: relative;
}
.share .share__svg-container::after {
  content: "";
  background: var(--button-background-hover);
  border: 0px solid var(--button-border-color-hover);
  transition: border 300ms;
  position: absolute;
  top: 100%;
  right: 0;
  bottom: 0;
  left: 0;
  transition: top 300ms;
  z-index: 1;
}
@media screen and (max-width: 600px) {
  .share .share__svg-container {
    width: 40px;
    height: 40px;
  }
}
.share:hover .share__svg-container::after {
  top: 0;
  border: 1px solid var(--color-blue-light-blue);
}
.share .share__svg {
  width: 14px;
  height: 14px;
  fill: var(--color-blue-light-blue);
  transition: fill 300ms;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}
.share:hover .share__svg {
  fill: var(--color-base-white);
}
.share .share__text {
  font-family: var(--font-family-1);
  font-size: 1.4rem;
  font-weight: 700;
  color: var(--color-base-white);
}

:root {
  --calendar-header-background-color: white;
  --calendar-header-color: var(--color-blue-marine);
  --calendar-main-background-color: white;
  --calendar-main-color: var(--color-blue-marine);
  --calendar-main-day-cell-size: 3.7rem;
  --calendar-main-month-year-cell-size: calc(var(--calendar-main-day-cell-size) * 1.7);
  --calendar-day-previous-or-next-color: var(--form-note__color);
  --calendar-day-previous-or-next-background-color: transparent;
  --calendar-day-previous-or-next-dot-or-underline-background-color: var(--form-note__color);
  --calendar-month-year-previous-or-next-color: var(--form-note__color);
  --calendar-day-color: var(--color-blue-marine);
  --calendar-day-background-color: transparent;
  --calendar-day-dot-or-underline-background-color: rgb(var(--rgb-3));
  --calendar-month-year-color: rgb(var(--rgb-1));
  --calendar-day-color-hover: rgb(var(--rgb-1));
  --calendar-day-background-color-hover: var(--color-blue-lighter-blue);
  --calendar-day-dot-or-underline-background-color-hover: rgb(var(--rgb-3));
  --calendar-month-year-color-hover: var(--color-blue-marine);
  --calendar-today-color: var(--color-blue-marine);
  --calendar-today-background-color: var(--color-blue-light-blue);
  --calendar-today-dot-or-underline-background-color: white;
  --calendar-today-color-hover: var(--color-blue-marine);
  --calendar-today-background-color-hover: var(--color-blue-lighter-blue);
  --calendar-today-dot-or-underline-background-color-hover: white;
  --calendar-day-active-color: white;
  --calendar-day-active-background-color: var(--color-blue-marine);
  --calendar-day-active-dot-or-underline-background-color: white;
  --calendar-day-active-color-hover: rgb(var(--rgb-1));
  --calendar-day-active-background-color-hover: var(--color-blue-marine);
  --calendar-day-active-dot-or-underline-background-color-hover: rgb(var(--rgb-3));
}
@media screen and (max-width: 1024px) {
  :root {
    --calendar-main-day-cell-size: 3.4rem;
  }
}

@media screen and (max-width: 425px) {
  .datepickerWrapper .datepicker-picker .datepicker-header {
    padding-left: 10px;
    padding-right: 10px;
  }
}
.datepickerWrapper .datepicker-picker .datepicker-main {
  --calendar-main-background-color: white;
}
@media screen and (max-width: 425px) {
  .datepickerWrapper .datepicker-picker .datepicker-main {
    padding-top: 28px;
    padding-bottom: 18px;
    padding-left: 10px;
    padding-right: 10px;
  }
}
.datepickerWrapper .datepicker-picker .day::after {
  display: none;
}

.datepicker-picker {
  display: inline-block;
}
.datepicker-picker span {
  display: block;
  flex: 1;
  border: 0;
  cursor: default;
  text-align: center;
  -webkit-touch-callout: none;
  user-select: none;
}
.datepicker-picker .datepicker-header {
  background-color: var(--calendar-header-background-color);
  padding: 24px 34px;
}
.datepicker-picker .datepicker-main {
  background-color: var(--calendar-main-background-color);
  padding: 34px 34px 30px;
}

.datepicker-controls {
  display: flex;
}
.datepicker-controls .button {
  display: inline-flex;
  position: relative;
  align-items: center;
  justify-content: center;
  margin: 0;
  cursor: pointer;
  white-space: nowrap;
  font-size: 1.7rem;
  font-weight: 700;
  color: var(--calendar-header-color);
  border: 0;
  border-radius: 0;
  padding: 0;
  background-color: var(--calendar-header-background-color);
  text-transform: none;
}
.datepicker-controls .button::before, .datepicker-controls .button::after {
  content: normal;
}
.datepicker-controls .button:focus, .datepicker-controls .button:active {
  outline: none;
}
.datepicker-controls .button[disabled] {
  cursor: not-allowed;
}
.datepicker-controls .view-switch {
  flex: auto;
}
.datepicker-controls .prev-btn,
.datepicker-controls .next-btn {
  width: 14.2857142857%;
}
.datepicker-controls .prev-btn > svg,
.datepicker-controls .next-btn > svg {
  width: 15px;
  height: 12px;
  fill: var(--calendar-header-color);
}
.datepicker-controls .prev-btn.disabled,
.datepicker-controls .next-btn.disabled {
  visibility: hidden;
}
.datepicker-controls .prev-btn > svg {
  transform: rotate(90deg);
}
.datepicker-controls .next-btn > svg {
  transform: rotate(-90deg);
}

.datepicker-view {
  display: flex;
}
.datepicker-view .days-of-week {
  display: flex;
  margin-bottom: 10px;
}
.datepicker-view .dow {
  flex-basis: 14.2857142857%;
  font-size: 1.2rem;
  font-weight: 700;
  color: var(--calendar-main-color);
}

.datepicker-grid {
  display: flex;
  flex-wrap: wrap;
}

.datepicker-cell {
  height: var(--calendar-main-day-cell-size);
  line-height: var(--calendar-main-day-cell-size);
}
.datepicker-cell.day {
  position: relative;
  font-size: 1.2rem;
  font-weight: 500;
  color: var(--calendar-main-color);
  z-index: 1;
}
.datepicker-cell.day::before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: var(--calendar-main-day-cell-size);
  height: var(--calendar-main-day-cell-size);
  background-color: transparent;
  border-radius: 3px;
  transition: background-color 0.3s;
  z-index: -1;
}
.datepicker-cell.day::after {
  content: "";
  position: absolute;
  top: 8px;
  left: calc(50% + 12px);
  transform: translateX(-50%);
  width: 5px;
  height: 5px;
  background-color: transparent;
  border-radius: 50%;
  transition: background-color 0.3s;
  z-index: 1;
}
.datepicker-cell.month, .datepicker-cell.year, .datepicker-cell.decade {
  font-size: var(--fz-16);
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  color: var(--calendar-main-color);
}
.datepicker-cell.disabled {
  cursor: default;
}
.datepicker-cell.disabled::after {
  display: none;
}
.datepicker-cell:not(.disabled) {
  cursor: pointer;
}
.datepicker-cell:not(.disabled)::after {
  background-color: var(--calendar-day-dot-or-underline-background-color);
}
.datepicker-cell:not(.disabled):hover::before {
  background-color: var(--calendar-day-background-color-hover);
}
.datepicker-cell.day.today:not(.selected) {
  color: var(--calendar-today-color);
}
.datepicker-cell.day.today:not(.selected)::before {
  background-color: var(--calendar-today-background-color);
}
.datepicker-cell.day.today:not(.selected)::after {
  background-color: var(--calendar-today-dot-or-underline-background-color);
}
.datepicker-cell.day.today:not(.selected):hover:not(.disabled) {
  color: var(--calendar-today-color-hover);
}
.datepicker-cell.day.today:not(.selected):hover:not(.disabled)::before {
  background-color: var(--calendar-today-background-color-hover);
}
.datepicker-cell.day.today:not(.selected):hover:not(.disabled)::after {
  background-color: var(--calendar-today-dot-or-underline-background-color-hover);
}
.datepicker-cell.day.selected {
  color: var(--calendar-day-active-color);
}
.datepicker-cell.day.selected::before {
  background-color: var(--calendar-day-active-background-color);
}
.datepicker-cell.day.selected::after {
  background-color: var(--calendar-day-active-dot-or-underline-background-color);
}
.datepicker-cell.day.selected:hover {
  color: var(--calendar-day-active-color-hover);
}
.datepicker-cell.day.selected:hover::before {
  background-color: var(--calendar-day-active-background-color-hover);
}
.datepicker-cell.day.selected:hover::after {
  background-color: var(--calendar-day-active-dot-or-underline-background-color-hover);
}
.datepicker-cell.day.prev, .datepicker-cell.day.next {
  color: var(--calendar-day-previous-or-next-color);
}
.datepicker-cell.day.prev::before, .datepicker-cell.day.next::before {
  background-color: var(--calendar-day-previous-or-next-background-color);
}
.datepicker-cell.day.prev::after, .datepicker-cell.day.next::after {
  background-color: var(--calendar-day-previous-or-next-dot-or-underline-background-color);
}
.datepicker-cell.month.prev, .datepicker-cell.month.next, .datepicker-cell.year.prev, .datepicker-cell.year.next, .datepicker-cell.decade.prev, .datepicker-cell.decade.next {
  color: var(--calendar-month-year-previous-or-next-color);
}
.datepicker-cell.month.selected, .datepicker-cell.month:hover, .datepicker-cell.year.selected, .datepicker-cell.year:hover, .datepicker-cell.decade.selected, .datepicker-cell.decade:hover {
  color: var(--calendar-month-year-color-hover);
}

.datepicker-view .days .datepicker-cell {
  flex-basis: 14.2857142857%;
}
.datepicker-view.months .datepicker-cell, .datepicker-view.years .datepicker-cell, .datepicker-view.decades .datepicker-cell {
  flex-basis: 25%;
  height: var(--calendar-main-month-year-cell-size);
  line-height: var(--calendar-main-month-year-cell-size);
}

.side-menu {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 50px;
}
.side-menu .side-menu__container {
  width: 100%;
  padding: 45px 20px 60px 40px;
  box-shadow: 0px 0px 15px 10px rgba(0, 0, 0, 0.04);
  -webkit-box-shadow: 0px 0px 15px 10px rgba(0, 0, 0, 0.04);
  -moz-box-shadow: 0px 0px 15px 10px rgba(0, 0, 0, 0.04);
}
.side-menu .side-menu__title {
  font-family: var(--font-family-2);
  font-size: 2.5rem;
  color: var(--color-blue-marine);
  border-bottom: 1px solid var(--color-grayscale-gray3);
  padding-bottom: 20px;
  transition: color 300ms;
}
.side-menu .side-menu__title-container:hover .side-menu__title {
  color: var(--color-grayscale-gray5);
}
.side-menu .side-menu__list {
  padding: 35px 15px 0px;
  display: flex;
  flex-direction: column;
}
.side-menu .side-menu__list-item:not(:last-child) {
  margin-bottom: 20px;
}
.side-menu .side-menu__link {
  font-family: var(--font-family-1);
  font-size: 1.5em;
  color: var(--color-grayscale-gray5);
  transition: color 300ms;
  padding: 5px 0;
}
.side-menu .side-menu__link:hover {
  color: var(--color-blue-marine);
}
.side-menu .side-menu__link--active {
  color: var(--color-blue-light-blue);
  font-weight: 700;
  position: relative;
}
.side-menu .side-menu__link--active::before {
  content: "";
  width: 4px;
  height: 4px;
  background: var(--color-blue-light-blue);
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: -15px;
  transform: translateY(-50%);
}

.side-pagination {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 50px;
}
.side-pagination .side-pagination__container {
  width: 100%;
  padding: 45px 20px 60px 40px;
  box-shadow: 0px 0px 15px 10px rgba(0, 0, 0, 0.04);
  -webkit-box-shadow: 0px 0px 15px 10px rgba(0, 0, 0, 0.04);
  -moz-box-shadow: 0px 0px 15px 10px rgba(0, 0, 0, 0.04);
}
.side-pagination .side-pagination__title {
  font-family: var(--font-family-2);
  font-size: 2.5rem;
  color: var(--color-blue-marine);
  border-bottom: 1px solid var(--color-grayscale-gray3);
  padding-bottom: 20px;
}
.side-pagination .side-pagination__arrows-box {
  display: flex;
  align-items: center;
  margin-top: 20px;
  position: relative;
}
.side-pagination .side-pagination__arrows-box:after {
  content: "";
  width: 1px;
  height: 16px;
  background: var(--color-grayscale-gray3);
  position: absolute;
  top: 50%;
  left: 40px;
  transform: translateY(-50%);
}
.side-pagination .side-pagination__inactive {
  opacity: 0.3;
  pointer-events: none;
}
.side-pagination .side-pagination__prev {
  padding-right: 25px;
}
.side-pagination .side-pagination__next {
  padding-left: 25px;
}
.side-pagination .side-pagination__prev .side-pagination__svg {
  transform: rotate(90deg) translateX(1px);
}
.side-pagination .side-pagination__next .side-pagination__svg {
  transform: rotate(-90deg) translateX(-1px);
}
.side-pagination .side-pagination__svg {
  width: 16px;
  height: 13px;
  stroke: var(--color-blue-marine);
  stroke-width: 2px;
  fill: none;
  transition: stroke 300ms;
}
.side-pagination .side-pagination__svg:hover {
  stroke: var(--color-blue-light-blue);
}
.side-pagination .side-pagination__button {
  margin-top: 65px;
}

.side-contact .side-contact__container {
  width: 100%;
  padding: 0 var(--x-spacing-110);
}
@media screen and (min-width: 1601px) {
  .side-contact .side-contact__container {
    padding: 0 110px;
  }
}
.side-contact .side-contact__title {
  color: var(--color-base-white);
  margin-bottom: 45px;
}
@media screen and (max-width: 768px) {
  .side-contact .side-contact__title {
    margin-bottom: 30px;
  }
}
.side-contact .side-contact__link, .side-contact .side-contact__address, .side-contact .side-contact__hours {
  color: var(--color-base-white);
  display: flex;
  align-items: center;
}
.side-contact .side-contact__link {
  margin-bottom: 20px;
  transition: color 300ms;
}
.side-contact .side-contact__link:hover {
  color: var(--color-blue-light-blue);
}
.side-contact .side-contact__address {
  margin-bottom: 55px;
}
@media screen and (max-width: 1024px) {
  .side-contact .side-contact__address {
    margin-bottom: 50px;
  }
}
@media screen and (max-width: 768px) {
  .side-contact .side-contact__address {
    margin-bottom: 30px;
  }
}
.side-contact .side-contact__svg {
  stroke: var(--color-base-white);
  stroke-width: 2px;
  fill: none;
  margin-right: 30px;
}
.side-contact .side-contact__svg--phone {
  width: 19px;
  height: 19px;
}
.side-contact .side-contact__svg--fax {
  width: 18px;
  height: 17px;
}
.side-contact .side-contact__svg--mail {
  width: 18px;
  height: 13px;
}
.side-contact .side-contact__svg--marker {
  width: 14px;
  height: 18px;
  margin-right: 35px;
}
.side-contact .side-contact__svg--clock {
  width: 17px;
  height: 17px;
}
.side-contact .side-contact__button {
  margin-bottom: var(--y-spacing-80);
}

.filter {
  --filter-height: 80px;
}

.filter {
  box-shadow: 0px 0px 15px 10px rgba(0, 0, 0, 0.04);
  -webkit-box-shadow: 0px 0px 15px 10px rgba(0, 0, 0, 0.04);
  -moz-box-shadow: 0px 0px 15px 10px rgba(0, 0, 0, 0.04);
  display: flex;
  align-items: center;
  padding-right: 90px;
  position: relative;
}
.filter .filter__container {
  width: 100%;
  background: var(--color-base-white);
  padding-left: 25px;
  display: flex;
  align-items: center;
  overflow: hidden;
}
.filter .filter__title {
  font-family: var(--font-family-1);
  font-size: 1.1em;
  font-weight: 700;
  letter-spacing: 0.2em;
  color: var(--color-blue-marine);
  text-transform: uppercase;
  opacity: 0.6;
  white-space: nowrap;
  margin-right: 30px;
}
@media screen and (max-width: 600px) {
  .filter .filter__title {
    display: none;
  }
}
.filter .filter__slider-container {
  min-width: 0;
}
.filter .filter__slider {
  white-space: nowrap;
}
.filter .filter__slider-slide {
  width: auto !important;
  height: var(--filter-height);
  margin-right: 45px !important;
  display: inline-flex !important;
  align-items: center;
  position: relative;
}
.filter .filter__slider-slide::after {
  content: "";
  width: 100%;
  opacity: 0;
  visibility: hidden;
  height: 3px;
  background: var(--color-blue-light-blue);
  position: absolute;
  left: 0;
  bottom: 0;
}
.filter .filter__slider-slide.--active::after {
  opacity: 1;
  visibility: visible;
}
.filter .filter__slider-slide.--active .filter__category {
  opacity: 1;
}
.filter .filter__category {
  font-family: var(--font-family-1);
  font-size: 1.4em;
  font-weight: 500;
  line-height: 1.6;
  color: var(--color-blue-marine);
  opacity: 0.6;
  transition: color 300ms, opacity 300ms;
}
.filter .filter__category:hover {
  color: var(--color-blue-marine);
  opacity: 1;
}
.filter .filter__arrows {
  position: absolute;
  top: 50%;
  right: 25px;
  transform: translate3d(0, -50%, 0);
  display: none;
}
.filter .filter__arrows.--visible {
  display: block;
}
.filter .filter__arrows-container {
  display: flex;
  align-items: center;
}
.filter .filter__prev {
  transform: rotate(90deg) translateX(-1px);
  margin-right: 15px;
}
.filter .filter__next {
  transform: rotate(-90deg) translateX(1px);
}
.filter .filter__prev.--disable,
.filter .filter__next.--disable {
  opacity: 0.5;
  pointer-events: none;
}
.filter .filter__svg--chevron {
  width: 16px;
  height: 10px;
  stroke: var(--color-blue-marine);
  stroke-width: 2px;
  fill: none;
}
.filter .filter__calendar-button {
  height: var(--filter-height);
  background: var(--color-blue-light-blue);
  transition: background-color 300ms;
  color: var(--color-base-white);
  padding: 0 55px;
  margin-right: -35px;
  margin-left: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.filter .filter__calendar-button:hover {
  background: var(--color-blue-darker-marine);
}
@media screen and (max-width: 768px) {
  .filter .filter__calendar-button {
    padding: 0 30px;
    margin-right: -30px;
    margin-left: 30px;
  }
}
@media screen and (max-width: 600px) {
  .filter .filter__calendar-button {
    padding: 0 25px;
    margin-right: -25px;
    margin-left: 25px;
  }
}
@media screen and (max-width: 600px) {
  .filter .filter__calendar-button {
    margin-left: 15px;
  }
}
.filter .filter__svg--calendar {
  width: 16px;
  height: 16px;
  stroke: var(--color-base-white);
  stroke-width: 2px;
  fill: none;
  margin-right: 15px;
}
@media screen and (max-width: 768px) {
  .filter .filter__svg--calendar {
    width: 18px;
    height: 18px;
    margin-right: 0;
  }
}
.filter .filter__calendar-button-text {
  font-family: var(--font-family-1);
  font-size: 1.1rem;
  font-weight: 700;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: var(--color-base-white);
}
@media screen and (max-width: 768px) {
  .filter .filter__calendar-button-text {
    display: none;
  }
}

.result {
  padding-top: var(--y-spacing-40);
  padding-right: 15px;
  padding-bottom: var(--y-spacing-50);
  padding-left: 15px;
  border-top: 1px solid var(--color-grayscale-gray3);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.result .result__category {
  font-family: var(--font-family-1);
  font-size: 1.1em;
  font-weight: 700;
  letter-spacing: 0.1em;
  color: var(--color-blue-light-blue);
  text-transform: uppercase;
  margin-bottom: 20px;
}
.result .result__title {
  font-family: var(--font-family-1);
  font-size: 2.2em;
  line-height: 1.4;
  color: var(--color-blue-marine);
  margin-bottom: 20px;
}
.result .result__text {
  color: var(--color-blue-marine);
  margin-bottom: 40px;
}
.result .result__link {
  font-family: var(--font-family-1);
  font-size: 1.1em;
  font-weight: 700;
  letter-spacing: 0.1em;
  color: var(--color-blue-marine);
  text-transform: uppercase;
  text-decoration: none;
  transition: color 300ms, box-shadow 0.3s, transform 800ms var(--ease-out-cubic);
  padding-bottom: 6px;
  display: block;
  position: relative;
}
.result .result__link::after {
  content: "";
  width: 100%;
  transition: width 300ms;
  height: 2px;
  background: var(--color-blue-light-blue);
  position: absolute;
  bottom: 0;
  left: 0;
}
.result .result__link:hover {
  color: var(--color-blue-light-blue);
}
.result .result__link:hover::after {
  width: 75%;
}

.news {
  width: 33.33%;
  padding: 0 var(--news-list-gap);
  margin-bottom: var(--y-spacing-120);
}
@media screen and (max-width: 900px) {
  .news {
    width: 50%;
  }
}
@media screen and (max-width: 600px) {
  .news {
    width: 100%;
  }
}
.news .news__container {
  display: flex;
  flex-direction: column;
}
.news .news__picture {
  width: 100%;
  padding-top: 104%;
  position: relative;
  margin-bottom: 45px;
  overflow: hidden;
}
@media screen and (max-width: 1000px) {
  .news .news__picture {
    margin-bottom: 40px;
  }
}
@media screen and (max-width: 900px) {
  .news .news__picture {
    margin-bottom: 35px;
  }
}
@media screen and (max-width: 800px) {
  .news .news__picture {
    margin-bottom: 30px;
  }
}
@media screen and (max-width: 600px) {
  .news .news__picture {
    margin-bottom: 25px;
  }
}
@media screen and (max-width: 400px) {
  .news .news__picture {
    margin-bottom: 20px;
  }
}
.news .news__img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: scale(1);
  transition: transform 600ms var(--ease-out-quart);
  will-change: transform;
}
.news .news__container:hover .news__img {
  transform: scale(1.1);
}
.news .news__surtitle {
  font-family: var(--font-family-1);
  font-size: 1.2em;
  font-weight: 700;
  letter-spacing: 0.1em;
  line-height: 1.4;
  text-transform: uppercase;
  color: var(--color-blue-blue);
  margin-bottom: 25px;
}
@media screen and (max-width: 400px) {
  .news .news__surtitle {
    margin-bottom: 20px;
  }
}
.news .news__title {
  font-family: var(--font-family-2);
  font-size: 3.5rem;
  font-weight: 500;
  line-height: 1.15em;
  color: var(--color-blue-marine);
  transition: color 300ms;
  padding-right: 10px;
}
@media screen and (max-width: 1024px) {
  .news .news__title {
    font-size: var(--large-title-font-size);
  }
}
.news .news__container:hover .news__title {
  color: var(--color-blue-blue);
}

.events {
  --image-width: 477px;
}
@media screen and (max-width: 1000px) {
  .events {
    --image-width: 425px;
  }
}
@media screen and (max-width: 900px) {
  .events {
    --image-width: 400px;
  }
}
@media screen and (max-width: 800px) {
  .events {
    --image-width: 375px;
  }
}
@media screen and (max-width: 700px) {
  .events {
    --image-width: 100%;
  }
}

.events .events__container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: var(--y-spacing-80);
}
@media screen and (max-width: 700px) {
  .events .events__container {
    flex-direction: column;
    justify-content: initial;
  }
}
.events .events__picture-container {
  width: var(--image-width);
  align-self: flex-start;
}
@media screen and (min-width: 1601px) {
  .events .events__picture-container {
    margin-right: 120px;
  }
}
@media screen and (max-width: 700px) {
  .events .events__picture-container {
    margin-right: 0;
    margin-bottom: 30px;
  }
}
.events .events__picture {
  width: 100%;
  padding-top: 113%;
  position: relative;
  overflow: hidden;
}
.events .events__img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: scale(1);
  transition: transform 600ms var(--ease-out-quart);
}
.events .events__container:hover .events__img {
  transform: scale(1.1);
}
.events .events__text-block {
  width: calc(100% - var(--image-width));
  padding-left: var(--x-spacing-120);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
@media screen and (max-width: 700px) {
  .events .events__text-block {
    width: 100%;
    padding-left: 0;
  }
}
.events .events__surtitle {
  font-family: var(--font-family-1);
  font-size: 1.2rem;
  font-weight: 700;
  letter-spacing: 0.1em;
  line-height: 1.4;
  text-transform: uppercase;
  color: var(--color-blue-blue);
  margin-bottom: 30px;
}
@media screen and (max-width: 700px) {
  .events .events__surtitle {
    margin-bottom: 20px;
  }
}
.events .events__category {
  font-size: 1.2rem;
  color: var(--color-grayscale-gray9);
}
.events .events__title {
  font-family: var(--font-family-2);
  font-size: 6rem;
  line-height: 1.1;
  color: var(--color-blue-marine);
  transition: color 300ms;
  margin-bottom: 50px;
}
@media screen and (max-width: 1024px) {
  .events .events__title {
    font-size: 5rem;
  }
}
@media screen and (max-width: 768px) {
  .events .events__title {
    font-size: 4.5rem;
  }
}
@media screen and (max-width: 700px) {
  .events .events__title {
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 600px) {
  .events .events__title {
    font-size: 4rem;
  }
}
@media screen and (max-width: 425px) {
  .events .events__title {
    font-size: 3.5rem;
  }
}
.events .events__container--not-featured:hover .events__title {
  color: var(--color-blue-blue);
}
.events .events__hours, .events .events__place {
  font-family: var(--font-family-1);
  font-size: 1.7rem;
  line-height: 1.4;
  color: var(--color-blue-marine);
  display: flex;
  align-items: center;
}
.events .events__hours {
  margin-bottom: 25px;
}
@media screen and (max-width: 700px) {
  .events .events__hours {
    margin-bottom: 15px;
  }
}
.events .events__svg {
  height: 17px;
  stroke: var(--color-blue-marine);
  stroke-width: 2px;
  fill: none;
  margin-right: 15px;
  transform: translateY(-2px);
  flex-shrink: 0;
}
.events .events__svg--clock {
  width: 17px;
}
.events .events__svg--marker {
  width: 12px;
}
.events .events__link {
  font-family: var(--font-family-1);
  font-size: 1.1rem;
  font-weight: 700;
  letter-spacing: 0.1em;
  color: var(--color-blue-marine);
  transition: color 300ms;
  text-transform: uppercase;
  padding-bottom: 9px;
  margin-top: 45px;
  position: relative;
}
.events .events__link:after {
  content: "";
  width: 100%;
  transition: width 300ms var(--ease-in-cubic);
  height: 2px;
  background: var(--color-blue-light-blue);
  position: absolute;
  bottom: 0;
  left: 0;
}
.events .events__text-block:hover .events__link {
  color: var(--color-blue-light-blue);
}
.events .events__text-block:hover .events__link:after {
  width: 65%;
}

.jobs {
  position: relative;
  cursor: pointer;
  padding: 20px 80px 20px 40px;
  padding-right: 180px;
  min-height: var(--rubric__height);
  border-bottom: 1px solid var(--color-grayscale-gray3);
  display: flex;
  align-items: center;
}
.jobs:first-child {
  border-top: 1px solid var(--color-grayscale-gray3);
}
@media screen and (max-width: 600px) {
  .jobs {
    padding: 20px 130px 20px 20px;
  }
}
.jobs .jobs__subtitle {
  margin-bottom: 4px;
  font-size: var(--rubric__subtitle-font-size);
  color: var(--color-blue-light-blue);
  font-weight: 500;
  line-height: 1.7em;
  text-transform: uppercase;
  letter-spacing: 0.2em;
}
.jobs .jobs__title {
  font-size: var(--rubric__font-size);
  line-height: 1.6;
  color: var(--rubric__color);
  font-weight: 500;
  transition: color 0.3s;
}
.jobs:hover .jobs__title {
  color: var(--rubric__color-hover);
}
.jobs .jobs__link {
  font-family: var(--font-family-1);
  font-size: 1.1em;
  font-weight: 700;
  letter-spacing: 0.1em;
  color: var(--color-blue-marine);
  text-transform: uppercase;
  text-decoration: none;
  transition: color 300ms, box-shadow 0.3s, transform 800ms var(--ease-out-cubic);
  padding-bottom: 6px;
  display: block;
  position: absolute;
  top: 50%;
  right: 60px;
  transform: translateY(-50%);
}
.jobs .jobs__link::after {
  content: "";
  width: 100%;
  transition: width 300ms;
  height: 2px;
  background: var(--color-blue-light-blue);
  position: absolute;
  bottom: 0;
  left: 0;
}
@media screen and (max-width: 600px) {
  .jobs .jobs__link {
    right: 20px;
  }
}
.jobs:hover .jobs__link {
  color: var(--color-blue-light-blue);
}
.jobs:hover .jobs__link::after {
  width: 75%;
}

.similar-news {
  --img-width: 247px;
}
@media screen and (max-width: 800px) {
  .similar-news {
    --img-width: 225px;
  }
}
@media screen and (max-width: 700px) {
  .similar-news {
    --img-width: 200px;
  }
}

.similar-news .similar-news__news-container {
  width: 100%;
  display: inline-flex;
  padding-bottom: var(--y-spacing-40);
  border-bottom: 1px solid var(--color-grayscale-gray3);
}
@media screen and (max-width: 600px) {
  .similar-news .similar-news__news-container {
    flex-direction: column;
    align-items: flex-start;
  }
}
.similar-news .similar-news__picture-box {
  width: var(--img-width);
  margin-right: 60px;
}
@media screen and (max-width: 800px) {
  .similar-news .similar-news__picture-box {
    margin-right: 50px;
  }
}
@media screen and (max-width: 700px) {
  .similar-news .similar-news__picture-box {
    margin-right: 40px;
  }
}
@media screen and (max-width: 600px) {
  .similar-news .similar-news__picture-box {
    width: 100%;
    margin-right: 0;
    margin-bottom: 30px;
  }
}
.similar-news .similar-news__picture {
  width: 100%;
  padding-top: 104%;
  position: relative;
  overflow: hidden;
}
.similar-news .similar-news__img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: scale(1);
  transition: transform 600ms var(--ease-out-quart);
}
.similar-news .similar-news__news-container:hover .similar-news__img {
  transform: scale(1.1);
}
.similar-news .similar-news__text-block {
  width: calc(100% - var(--img-width));
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
@media screen and (max-width: 600px) {
  .similar-news .similar-news__text-block {
    width: 100%;
  }
}
.similar-news .similar-news__surtitle {
  font-family: var(--font-family-1);
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 1.4;
  letter-spacing: 0.1em;
  color: var(--color-blue-blue);
  text-transform: uppercase;
  margin-bottom: 25px;
}
@media screen and (max-width: 400px) {
  .similar-news .similar-news__surtitle {
    margin-bottom: 20px;
  }
}
.similar-news .similar-news__title {
  font-family: var(--font-family-2);
  font-size: 3.5rem;
  font-weight: 500;
  color: var(--color-blue-marine);
  transition: color 300ms;
}
@media screen and (max-width: 900px) {
  .similar-news .similar-news__title {
    font-size: 3rem;
  }
}
@media screen and (max-width: 600px) {
  .similar-news .similar-news__title {
    font-size: 2.5rem;
  }
}
@media screen and (max-width: 400px) {
  .similar-news .similar-news__title {
    font-size: 2rem;
  }
}
.similar-news .similar-news__news-container:hover .similar-news__title {
  color: var(--color-blue-blue);
}

.similar-events {
  --img-width: 247px;
}
@media screen and (max-width: 800px) {
  .similar-events {
    --img-width: 225px;
  }
}
@media screen and (max-width: 700px) {
  .similar-events {
    --img-width: 200px;
  }
}

.similar-events .similar-events__events-container {
  width: 100%;
  display: inline-flex;
  padding-bottom: var(--y-spacing-40);
  border-bottom: 1px solid var(--color-grayscale-gray3);
}
@media screen and (max-width: 600px) {
  .similar-events .similar-events__events-container {
    padding-bottom: 0;
    border: none;
    flex-direction: column;
    align-items: flex-start;
  }
}
.similar-events .similar-events__picture-box {
  width: var(--img-width);
  margin-right: 60px;
}
@media screen and (max-width: 800px) {
  .similar-events .similar-events__picture-box {
    margin-right: 50px;
  }
}
@media screen and (max-width: 700px) {
  .similar-events .similar-events__picture-box {
    margin-right: 40px;
  }
}
@media screen and (max-width: 600px) {
  .similar-events .similar-events__picture-box {
    width: 100%;
    margin-right: 0;
    margin-bottom: 30px;
  }
}
.similar-events .similar-events__picture {
  width: 100%;
  padding-top: 104%;
  position: relative;
  overflow: hidden;
}
.similar-events .similar-events__img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: scale(1);
  transition: transform 600ms var(--ease-out-quart);
}
.similar-events .similar-events__events-container:hover .similar-events__img {
  transform: scale(1.1);
}
.similar-events .similar-events__text-block {
  width: calc(100% - var(--img-width));
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
@media screen and (max-width: 600px) {
  .similar-events .similar-events__text-block {
    width: 100%;
  }
}
.similar-events .similar-events__surtitle {
  font-family: var(--font-family-1);
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 1.4;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: var(--color-blue-blue);
  margin-bottom: 25px;
}
@media screen and (max-width: 400px) {
  .similar-events .similar-events__surtitle {
    margin-bottom: 20px;
  }
}
.similar-events .similar-events__category {
  font-size: 1.2rem;
  color: var(--color-grayscale-gray9);
}
.similar-events .similar-events__title {
  font-family: var(--font-family-2);
  font-size: 3.5rem;
  font-weight: 500;
  color: var(--color-blue-marine);
  transition: color 300ms;
}
@media screen and (max-width: 900px) {
  .similar-events .similar-events__title {
    font-size: 3rem;
  }
}
@media screen and (max-width: 600px) {
  .similar-events .similar-events__title {
    font-size: 4rem;
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 425px) {
  .similar-events .similar-events__title {
    font-size: 3.5rem;
  }
}
.similar-events .similar-events__events-container:hover .similar-events__title {
  color: var(--color-blue-blue);
}
.similar-events .similar-events__infos {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
@media screen and (max-width: 600px) {
  .similar-events .similar-events__infos {
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: initial;
  }
}
.similar-events .similar-events__hours, .similar-events .similar-events__place {
  font-family: var(--font-family-1);
  font-size: 1.7rem;
  line-height: 1.4;
  color: var(--color-blue-marine);
  padding-top: var(--y-spacing-40);
  display: flex;
  align-items: center;
}
@media screen and (max-width: 600px) {
  .similar-events .similar-events__hours, .similar-events .similar-events__place {
    padding-top: 0;
  }
}
.similar-events .similar-events__hours {
  margin-right: 40px;
}
@media screen and (max-width: 600px) {
  .similar-events .similar-events__hours {
    margin-bottom: 15px;
  }
}
.similar-events .similar-events__svg {
  height: 17px;
  stroke: var(--color-blue-marine);
  stroke-width: 2px;
  fill: none;
  margin-right: 15px;
  transform: translateY(-2px);
}
.similar-events .similar-events__svg--clock {
  width: 17px;
}
.similar-events .similar-events__svg--marker {
  width: 12px;
}

.back-to-top-and-links {
  --back-to-top-and-links__arrow-link--dimension: 56px;
  --back-to-top-and-links__circle-link--margin-bottom: 10px;
}
@media (max-width: 1200px) {
  .back-to-top-and-links {
    --back-to-top-and-links__arrow-link--dimension: 52px;
  }
}
@media (max-width: 1024px) {
  .back-to-top-and-links {
    --back-to-top-and-links__arrow-link--dimension: 48px;
  }
}
@media (max-width: 600px) {
  .back-to-top-and-links {
    --back-to-top-and-links__arrow-link--dimension: 44px;
  }
}

.back-to-top-and-links {
  display: flex;
  align-items: flex-end;
  position: absolute;
  width: var(--back-to-top-and-links__arrow-link--dimension);
  top: 0;
  right: 25px;
  bottom: calc(0px - var(--back-to-top-and-links__arrow-link--dimension) * 0.5);
  pointer-events: none;
  z-index: 10;
}
@media (max-width: 768px) {
  .back-to-top-and-links {
    right: 20px;
  }
}
@media (max-width: 700px) {
  .back-to-top-and-links {
    bottom: calc(-55px - var(--back-to-top-and-links__arrow-link--dimension) * 0.5);
  }
}
@media (max-width: 600px) {
  .back-to-top-and-links {
    right: 15px;
  }
}
.back-to-top-and-links__container {
  position: sticky;
  bottom: 30px;
}
@media (max-width: 1024px) {
  .back-to-top-and-links__container {
    bottom: 25px;
  }
}
@media (max-width: 768px) {
  .back-to-top-and-links__container {
    bottom: 20px;
  }
}
@media (max-width: 600px) {
  .back-to-top-and-links__container {
    bottom: 15px;
  }
}
.back-to-top-and-links__on-off-content {
  order: 1;
  opacity: 0;
  transition: opacity 300ms;
}
.back-to-top-and-links.displayed .back-to-top-and-links__on-off-content {
  opacity: 1;
  pointer-events: all;
  transition: opacity 300ms ease 300ms;
}
.back-to-top-and-links__visible-content {
  order: 2;
  position: relative;
  pointer-events: all;
  top: calc(var(--back-to-top-and-links__arrow-link--dimension) + var(--back-to-top-and-links__circle-link--margin-bottom));
  transition: top 300ms var(--ease-out-cubic) 300ms;
}
@media (min-width: 1001px) {
  .back-to-top-and-links__visible-content {
    display: none;
  }
}
.back-to-top-and-links.displayed .back-to-top-and-links__visible-content {
  top: 0px;
  transition: top 300ms var(--ease-out-cubic);
}
.back-to-top-and-links__circle-link {
  margin-bottom: var(--back-to-top-and-links__circle-link--margin-bottom);
}
.back-to-top-and-links__circle-link, .back-to-top-and-links__circle-fastlinks, .back-to-top-and-links__arrow-link {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: var(--back-to-top-and-links__arrow-link--dimension);
  height: var(--back-to-top-and-links__arrow-link--dimension);
  cursor: pointer;
}
.back-to-top-and-links__circle-link:before, .back-to-top-and-links__circle-fastlinks:before, .back-to-top-and-links__arrow-link:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--color-base-white);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  transition: background-color 300ms;
}
.back-to-top-and-links__circle-link::after, .back-to-top-and-links__circle-fastlinks::after, .back-to-top-and-links__arrow-link::after {
  content: "";
  background: var(--color-blue-light-blue);
  border: 0px solid var(--color-blue-light-blue);
  transition: border 300ms;
  position: absolute;
  top: 100%;
  right: 0;
  bottom: 0;
  left: 0;
  transition: top 300ms;
  z-index: 1;
}
.back-to-top-and-links__circle-link:hover::after, .back-to-top-and-links__circle-fastlinks:hover::after, .back-to-top-and-links__arrow-link:hover::after {
  top: 0;
  border: 1px solid var(--color-blue-light-blue);
}
.back-to-top-and-links__circle-link:hover svg, .back-to-top-and-links__circle-fastlinks:hover svg, .back-to-top-and-links__arrow-link:hover svg {
  fill: var(--color-base-white);
}
@media (min-width: 701px) {
  .back-to-top-and-links__circle-link--fastlinks {
    display: none;
  }
}
.back-to-top-and-links__plus {
  width: 16px;
  height: 16px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.back-to-top-and-links__plus::before, .back-to-top-and-links__plus::after {
  content: "";
  width: 16px;
  height: 2px;
  background: var(--color-blue-light-blue);
  transition: background-color 300ms;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.back-to-top-and-links__plus::after {
  transform: translate(-50%, -50%) rotate(90deg);
  transition: opacity 300ms;
}
.back-to-top-and-links__arrow-svg {
  width: 8px;
  height: 24px;
  stroke: var(--color-blue-light-blue);
  stroke-width: 1px;
  fill: var(--color-blue-light-blue);
  transition: stroke 300ms, fill 300ms;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(180deg);
  z-index: 2;
}
@media (max-width: 600px) {
  .back-to-top-and-links__arrow-svg {
    height: 18px;
  }
}
.back-to-top-and-links__arrow-link:hover .back-to-top-and-links__arrow-svg {
  stroke: var(--color-base-white);
  fill: var(--color-base-white);
}
.back-to-top-and-links__svg {
  position: relative;
  width: 20px;
  height: 20px;
  stroke: var(--color-blue-light-blue);
  transition: stroke 300ms;
  z-index: 2;
}
.back-to-top-and-links__circle-link:hover .back-to-top-and-links__svg {
  stroke: var(--color-base-white);
}

:root {
  --header-height: 95px;
}
@media screen and (max-width: 900px) {
  :root {
    --header-height: 85px ;
  }
}
@media screen and (max-width: 768px) {
  :root {
    --header-height: 75px ;
  }
}
@media screen and (max-width: 600px) {
  :root {
    --header-height: 65px ;
  }
}

.header {
  transition: padding 300ms var(--ease-out-cubic);
}
.header .header__container {
  width: 100%;
  height: var(--header-height);
  display: flex;
  justify-content: space-between;
  position: absolute;
  z-index: 10;
}
.header .header__logo-container {
  height: 100%;
  padding: 20px 25px 20px 60px;
  display: flex;
  align-items: center;
  position: relative;
}
@media screen and (max-width: 1024px) {
  .header .header__logo-container {
    padding-left: 50px;
  }
}
@media screen and (max-width: 768px) {
  .header .header__logo-container {
    padding-left: 30px;
  }
}
@media screen and (max-width: 600px) {
  .header .header__logo-container {
    padding-left: 25px;
  }
}
.header .header__logo {
  width: 153px;
  height: 56px;
}
@media screen and (max-width: 900px) {
  .header .header__logo {
    width: 127px;
    height: 46px;
  }
}
.header .header__second-logo-container {
  position: absolute;
  top: 50%;
  left: 60px;
  z-index: 5;
  transform: translateY(-50%);
}
@media screen and (max-width: 1024px) {
  .header .header__second-logo-container {
    left: 50px;
  }
}
@media screen and (max-width: 768px) {
  .header .header__second-logo-container {
    left: 30px;
  }
}
@media screen and (max-width: 600px) {
  .header .header__second-logo-container {
    left: 25px;
  }
}
.header .header__boxes {
  width: var(--overlay-fastlinks-width);
  display: flex;
}
@media screen and (max-width: 700px) {
  .header .header__boxes {
    width: auto;
  }
}
.header .header__box {
  height: 100%;
  padding: 20px 38px;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
}
.header .header__box:before {
  content: "";
  width: 100%;
  height: 4px;
  background: var(--color-blue-light-blue);
  opacity: 0;
  visibility: hidden;
  transition: opacity 300ms, visibility 300ms;
  position: absolute;
  bottom: 0;
  left: 0;
}
.header .header__box:hover:before {
  opacity: 1;
  visibility: visible;
}
@media screen and (max-width: 768px) {
  .header .header__box {
    padding: 20px 30px;
  }
}
@media screen and (max-width: 700px) {
  .header .header__box {
    width: var(--header-height);
    height: 100%;
    padding: 0;
    justify-content: center;
  }
}
.header .header__box:first-child {
  position: relative;
}
.header .header__box:first-child::after {
  content: "";
  width: 1px;
  height: 36px;
  background: var(--color-grayscale-gray3);
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}
@media screen and (max-width: 700px) {
  .header .header__box:first-child {
    display: none;
  }
}
.header .header__box--white {
  background: var(--color-base-white);
}
.header .header__box--blue {
  background: var(--color-blue-darker-marine);
  flex: 1;
}
.header .header__box-text {
  font-family: var(--font-family-1);
  font-size: 1.1rem;
  font-weight: 700;
  line-height: 1;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  margin-right: 10px;
}
.header .header__box-text--blue {
  color: var(--color-blue-marine);
}
.header .header__box-text--white {
  color: var(--color-base-white);
}
.header .header__box-text:first-child {
  position: relative;
}
.header .header__box-text:first-child span {
  transition: opacity 300ms;
}
.header .header__box-text:first-child span:first-child {
  opacity: 1;
}
.header .header__box-text:first-child span:not(:first-child) {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
}
@media screen and (max-width: 700px) {
  .header .header__box-text {
    display: none;
  }
}
.header .header__box-svg {
  stroke-width: 2px;
  transform: translateY(-2px);
}
.header .header__box-svg--search {
  width: 14px;
  height: 14px;
  stroke: var(--color-blue-marine);
  fill: none;
}
.header .header__box-svg--hamburger {
  width: 14px;
  height: 11px;
  stroke: var(--color-base-white);
}
@media screen and (max-width: 700px) {
  .header .header__box-svg--search {
    width: 18px;
    height: 18px;
  }
  .header .header__box-svg--hamburger {
    width: 18px;
    height: 15px;
  }
}
.header .header__plus {
  width: 10px;
  height: 10px;
  position: relative;
  transform: translateY(-3px) rotate(0);
  transition: transform 600ms var(--ease-out-quart);
}
.header .header__plus::after, .header .header__plus::before {
  content: "";
  width: 11px;
  height: 1px;
  background: var(--color-blue-marine);
  position: absolute;
  top: 50%;
  right: 0;
}
.header .header__plus::before {
  transform: translateY(-50%);
}
.header .header__plus::after {
  transform: translateY(-50%) rotate(-90deg);
}

@media screen and (max-width: 800px) {
  .show-alerts .header {
    padding-top: var(--alert-mobile-height);
  }
}

.footer .footer__container {
  width: 100%;
  background: var(--color-blue-marine);
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 5;
}
.footer .footer__container--home {
  background: #212c3f;
}
@media screen and (max-width: 800px) {
  .footer .footer__container {
    flex-direction: column;
    justify-content: initial;
    align-items: flex-start;
  }
}
.footer .footer__left-side {
  padding-top: var(--y-spacing-50);
  padding-bottom: var(--y-spacing-100);
  border-right: 1px solid rgba(255, 255, 255, 0.2);
}
@media screen and (max-width: 800px) {
  .footer .footer__left-side {
    width: 100%;
    padding-bottom: 0;
    border: 0;
  }
}
.footer .footer__left-side-top {
  display: flex;
  justify-content: flex-start;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  padding-right: var(--x-spacing-180);
  padding-bottom: var(--y-spacing-60);
  padding-left: var(--x-spacing-140);
}
@media screen and (min-width: 1601px) {
  .footer .footer__left-side-top {
    padding-right: 180px;
    padding-left: 140px;
  }
}
.footer .footer__logo {
  width: 229px;
  height: 83px;
}
@media screen and (max-width: 800px) {
  .footer .footer__logo {
    width: 146px;
    height: 52px;
  }
}
.footer .footer__left-side-bottom {
  display: flex;
  justify-content: flex-start;
  padding-top: var(--y-spacing-80);
  padding-right: var(--x-spacing-180);
  padding-left: var(--x-spacing-140);
}
@media screen and (min-width: 1601px) {
  .footer .footer__left-side-bottom {
    padding-right: 180px;
    padding-left: 140px;
  }
}
.footer .footer__contact-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
@media screen and (max-width: 800px) {
  .footer .footer__contact-box {
    display: none;
  }
}
.footer .footer__contact-surtitle {
  font-family: var(--font-family-1);
  font-size: 1.2em;
  font-weight: 700;
  letter-spacing: 0.1em;
  line-height: 1.4;
  color: var(--color-blue-blue);
  text-transform: uppercase;
  margin-bottom: 30px;
}
.footer .footer__address-item {
  font-family: var(--font-family-1);
  font-size: 2em;
  color: var(--color-base-white);
  margin-bottom: 40px;
}
.footer .footer__address-item:nth-child(2) {
  margin-bottom: 15px;
}
.footer .footer__other-infos {
  display: flex;
  align-items: flex-end;
}
.footer .footer__phone-and-email {
  margin-right: 35px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.footer .footer__phone, .footer .footer__email {
  font-family: var(--font-family-1);
  font-size: 1.7em;
  color: var(--color-base-white);
  text-decoration: none;
  position: relative;
  transition: color 300ms;
}
.footer .footer__phone:hover, .footer .footer__email:hover {
  color: var(--color-blue-light-blue);
}
.footer .footer__phone {
  margin-bottom: 10px;
}
.footer .footer__facebook-box {
  width: 40px;
  height: 40px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  position: relative;
}
.footer .footer__facebook-box:after {
  content: "";
  background: var(--color-base-white);
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  top: 100%;
  transition: top 300ms;
  z-index: 5;
}
.footer .footer__facebook-box:hover::after {
  top: 0;
}
.footer .footer__svg {
  fill: var(--color-base-white);
  transition: fill 300ms;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
}
.footer .footer__svg--facebook {
  width: 6px;
  height: 15px;
}
.footer .footer__facebook-box:hover .footer__svg {
  fill: var(--color-blue-light-blue);
}
.footer .footer__right-side {
  width: 100%;
  padding-top: var(--y-spacing-100);
  padding-right: var(--x-spacing-200);
  padding-bottom: var(--y-spacing-40);
  padding-left: var(--x-spacing-140);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media screen and (min-width: 1601px) {
  .footer .footer__right-side {
    padding-right: 200px;
  }
}
@media screen and (max-width: 1600px) {
  .footer .footer__right-side {
    align-items: flex-start;
  }
}
@media screen and (max-width: 800px) {
  .footer .footer__right-side {
    padding-top: 0;
  }
}
.footer .footer__newsletter-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.footer .footer__newsletter-surtitle {
  font-family: var(--font-family-1);
  font-size: 1.2em;
  font-weight: 700;
  letter-spacing: 0.1em;
  line-height: 1.4;
  color: var(--color-blue-blue);
  text-transform: uppercase;
  margin-bottom: 35px;
}
.footer .footer__newsletter-title {
  font-weight: 400;
  color: var(--color-base-white);
  margin-bottom: 65px;
}
@media screen and (max-width: 1600px) {
  .footer .footer__newsletter-title {
    max-width: 350px;
  }
}
@media screen and (max-width: 800px) {
  .footer .footer__newsletter-title {
    max-width: 100%;
  }
}
@media screen and (max-width: 500px) {
  .footer .footer__newsletter-title {
    margin-bottom: 30px;
  }
}
.footer .footer__newsletter-form {
  width: 100%;
  margin-bottom: var(--y-spacing-100);
  display: flex;
  align-items: flex-end;
}
@media screen and (max-width: 500px) {
  .footer .footer__newsletter-form {
    flex-direction: column;
    align-items: flex-start;
  }
}
.footer .footer__newsletter-form .text-field {
  width: 100%;
  margin-right: 20px;
}
.footer .footer__newsletter-form .text-field ::-webkit-input-placeholder {
  color: var(--color-base-white);
}
.footer .footer__newsletter-form .text-field ::-moz-placeholder {
  color: var(--color-base-white);
}
.footer .footer__newsletter-form .text-field :-ms-input-placeholder {
  color: var(--color-base-white);
}
.footer .footer__newsletter-form .text-field :-moz-placeholder {
  color: var(--color-base-white);
}
.footer .footer__newsletter-form .text-field :-webkit-autofill,
.footer .footer__newsletter-form .text-field :-webkit-autofill:hover,
.footer .footer__newsletter-form .text-field :-webkit-autofill:focus,
.footer .footer__newsletter-form .text-field :-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 100px var(--color-blue-marine) inset !important;
  -webkit-text-fill-color: var(--color-base-white);
  border-bottom: 2px solid var(--color-blue-light-blue) !important;
  margin-bottom: 0 !important;
}
@media screen and (max-width: 500px) {
  .footer .footer__newsletter-form .text-field {
    margin-bottom: 30px;
  }
}
.footer .footer__newsletter-form .text-field__input {
  color: var(--color-base-white);
  background: transparent;
  padding: 18px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  margin-bottom: -1px;
}
.footer .footer__newsletter-form .text-field__input:focus {
  border-bottom: 2px solid var(--color-blue-light-blue);
}
.footer .footer__newsletter-buttons {
  margin: 0 -10px calc(var(--y-spacing-100) - 10px);
}
.footer .footer__newsletter-button {
  margin: 0 10px 10px;
}
.footer .footer__copyright {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
@media screen and (max-width: 500px) {
  .footer .footer__copyright {
    flex-direction: column;
    justify-content: initial;
    align-items: flex-start;
  }
}
.footer .footer__copyright-item, .footer .footer__blanko-link {
  font-family: var(--font-family-1);
  font-size: 1.2em;
  color: var(--color-base-white);
}
.footer .footer__copyright-item {
  margin-right: 75px;
  line-height: 1.7;
}
@media screen and (max-width: 500px) {
  .footer .footer__copyright-item {
    margin-right: 0;
    margin-bottom: 20px;
  }
}
.footer .footer__blanko-link {
  transition: color 300ms;
}
.footer .footer__blanko-link:hover {
  color: var(--color-blue-light-blue);
}

:root {
  --dynamic__p-font-line-height: 1.6em;
  --dynamic__table-header-background: var(--color-blue-marine);
  --dynamic__table-header-color: white;
  --dynamic__table-border-color: var(--color-grayscale-gray3);
  --dynamic__table-secondary-header-background: var(--color-grayscale-gray1);
  --dynamic__p-margin: 25px;
  --dynamic__p-margin-under-button: 5px;
  --dynamic__p-margin-button: 40px;
}

.dynamic {
  color: var(--color-blue-marine);
  /* ----- Image - No Caption ----- */
  /* ----- Image - With Caption ----- */
}
.dynamic > :first-child {
  margin-top: 0 !important;
}
.dynamic > :last-child {
  margin-bottom: 0 !important;
}
.dynamic .intro:first-child p {
  margin-top: 0 !important;
}
.dynamic .intro:last-child p {
  margin-bottom: 0 !important;
}
.dynamic h1 {
  color: var(--color-base-white);
  margin-bottom: 100px;
  margin-top: 100px;
}
@media screen and (max-width: 1024px) {
  .dynamic h1 {
    margin-top: 50px;
    margin-bottom: 50px;
  }
}
.dynamic h2 {
  margin-top: 65px;
  margin-bottom: 30px;
}
.dynamic h3 {
  margin-top: 65px;
  margin-bottom: 30px;
}
.dynamic h4 {
  margin-top: 65px;
  margin-bottom: 30px;
}
.dynamic h2 + h3,
.dynamic h3 + h4,
.dynamic h4 + h5,
.dynamic h5 + h6 {
  margin-top: 0;
}
.dynamic .intro p {
  font-size: var(--introduction-font-size);
  line-height: 1.6em;
  margin-top: 65px;
  margin-bottom: 65px;
}
.dynamic p {
  font-family: var(--font-family-1);
  font-size: var(--paragraph-font-size);
  margin-top: var(--dynamic__p-margin);
  margin-bottom: var(--dynamic__p-margin);
  line-height: var(--dynamic__p-font-line-height);
}
.dynamic p.buttonMargin {
  margin-bottom: calc(var(--dynamic__p-margin) - var(--dynamic__p-margin-under-button));
}
.dynamic p.buttonMargin .button, .dynamic p.buttonMargin .button--secondary {
  margin-bottom: var(--dynamic__p-margin-under-button);
}
.dynamic p.buttonMargin--first {
  margin-top: var(--dynamic__p-margin-button);
}
.dynamic p.buttonMargin--last {
  margin-bottom: calc(var(--dynamic__p-margin-button) - var(--dynamic__p-margin-under-button));
}
.dynamic strong, .dynamic b {
  font-weight: 700;
}
.dynamic em, .dynamic i {
  font-style: italic;
}
.dynamic sup {
  font-size: 0.7em;
  vertical-align: baseline;
  position: relative;
  top: -0.3em;
}
.dynamic sub {
  font-size: 0.7em;
  vertical-align: baseline;
  position: relative;
  top: 0.3em;
}
.dynamic a:not(.button):not(.button--secondary) {
  color: var(--color-blue-marine);
  text-decoration: none;
}
.dynamic a:not(.button):not(.button--secondary) span {
  box-shadow: inset 0 -0.1em 0 0 var(--color-blue-blue);
  transition: box-shadow 0.3s;
}
.dynamic a:not(.button):not(.button--secondary):hover span {
  box-shadow: inset 0 -0.1em 0 0 var(--color-blue-marine);
}
.dynamic a:not(.button):not(.button--secondary) svg {
  margin-left: 0.25em;
  margin-right: 0.35em;
  width: 0.6470588235em;
  height: 0.6470588235em;
  fill: var(--color-blue-marine);
}
.dynamic a:not(.button):not(.button--secondary)[href$=".pdf"] svg, .dynamic a:not(.button):not(.button--secondary)[href$=".doc"] svg, .dynamic a:not(.button):not(.button--secondary)[href$=".zip"] svg {
  width: 0.7058823529em;
  height: 0.7058823529em;
}
.dynamic h2 a:not(.button):not(.button--secondary),
.dynamic h3 a:not(.button):not(.button--secondary),
.dynamic h4 a:not(.button):not(.button--secondary),
.dynamic pre a:not(.button):not(.button--secondary),
.dynamic blockquote a:not(.button):not(.button--secondary) {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
}
.dynamic h2 a:not(.button):not(.button--secondary) svg,
.dynamic h3 a:not(.button):not(.button--secondary) svg,
.dynamic h4 a:not(.button):not(.button--secondary) svg,
.dynamic blockquote a:not(.button):not(.button--secondary) svg {
  margin-left: 0.3em;
  margin-right: 0.3em;
}
.dynamic p:not(.intro p) a:not(.button):not(.button--secondary), .dynamic li a:not(.button):not(.button--secondary) {
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
  line-height: inherit;
  color: inherit;
}
.dynamic ol,
.dynamic ul {
  margin-top: 30px;
  margin-bottom: 30px;
}
.dynamic ol li,
.dynamic ul li {
  font-size: var(--paragraph-font-size);
  line-height: var(--dynamic__p-font-line-height);
}
.dynamic ol li:not(:first-child),
.dynamic ul li:not(:first-child) {
  margin-top: 25px;
}
.dynamic ol li ol, .dynamic ol li ul,
.dynamic ul li ol,
.dynamic ul li ul {
  margin-top: 20px;
  margin-bottom: 0;
  width: 100%;
}
.dynamic ol li ol li, .dynamic ol li ul li,
.dynamic ul li ol li,
.dynamic ul li ul li {
  padding-bottom: 0;
  border: none;
}
.dynamic ol li ol li:not(:first-child), .dynamic ol li ul li:not(:first-child),
.dynamic ul li ol li:not(:first-child),
.dynamic ul li ul li:not(:first-child) {
  margin-top: 20px;
}
.dynamic ol {
  --ordered-list-padding: 50px;
  list-style: none;
  counter-reset: li;
}
@media screen and (max-width: 768px) {
  .dynamic ol {
    --ordered-list-padding: 25px;
  }
}
@media screen and (max-width: 500px) {
  .dynamic ol {
    --ordered-list-padding: 20px;
  }
}
.dynamic ol > li {
  width: calc(100% - var(--ordered-list-padding));
  position: relative;
  left: var(--ordered-list-padding);
}
.dynamic ol > li::before {
  content: counter(li) ".";
  counter-increment: li;
  display: inline-block;
  position: absolute;
  transform: translateX(calc(0px - var(--ordered-list-padding)));
  font-weight: 500;
}
.dynamic ol > li ol > li::before {
  content: counter(li, lower-alpha) ".";
}
@media screen and (min-width: 769px) {
  .dynamic ol > li ol > li ol > li {
    padding-left: 40px;
  }
}
.dynamic ol > li ol > li ol > li::before {
  content: counter(li, lower-roman) ".";
}
.dynamic ul {
  --unordered-list-padding: 20px;
}
@media screen and (max-width: 500px) {
  .dynamic ul {
    --unordered-list-padding: 15px;
  }
}
.dynamic ul > li {
  width: calc(100% - var(--unordered-list-padding));
  position: relative;
  left: var(--unordered-list-padding);
}
.dynamic ul > li::before {
  content: "";
  width: 6px;
  height: 6px;
  position: absolute;
  top: calc(var(--dynamic__p-font-line-height) / 2);
  transform: translate(calc(0px - var(--unordered-list-padding)), -50%);
  background-color: var(--color-blue-light-blue);
  border: 1px solid var(--color-blue-light-blue);
  border-radius: 50%;
  box-sizing: border-box;
}
.dynamic ul > li > ul li::before {
  background-color: transparent;
}
.dynamic ul > li > ul li > ul li::before {
  background-color: var(--color-blue-light-blue);
}
.dynamic blockquote {
  position: relative;
  padding-left: 30px;
  margin-top: 35px;
  margin-bottom: 35px;
  border-left: 10px solid var(--color-blue-light-blue);
}
.dynamic blockquote p {
  font-size: 2.2rem;
  font-weight: 500;
  line-height: 1.5em;
  color: var(--color-blue-marine);
}
@media screen and (max-width: 1024px) {
  .dynamic blockquote p {
    font-size: 2rem;
  }
}
@media screen and (max-width: 768px) {
  .dynamic blockquote p {
    font-size: 1.8rem;
  }
}
@media screen and (max-width: 425px) {
  .dynamic blockquote p {
    font-size: 1.6rem;
  }
}
.dynamic pre {
  font-family: var(--font-family-1);
  white-space: normal;
  font-size: 1.4rem;
  line-height: 1.7142857143em;
  color: var(--color-grayscale-gray4);
  margin-top: 25px;
  margin-bottom: 25px;
}
.dynamic .emphasis {
  border: 1px solid var(--color-grayscale-gray3);
  margin-top: 30px;
  margin-bottom: 65px;
  padding: 45px 40px;
}
.dynamic .emphasis > :first-child {
  margin-top: 0;
}
.dynamic .emphasis > :last-child {
  margin-bottom: 0;
}
.dynamic .table-wrapper + pre,
.dynamic .emphasis + pre {
  margin-top: -45px;
}
.dynamic .table-wrapper + .table-wrapper, .dynamic .table-wrapper + .emphasis,
.dynamic .emphasis + .table-wrapper,
.dynamic .emphasis + .emphasis {
  margin-top: -35px;
}
.dynamic .table-wrapper {
  overflow-x: auto;
  margin-top: 30px;
  margin-bottom: 65px;
}
.dynamic .table-wrapper table {
  overflow-x: hidden;
  width: 100%;
  min-width: 600px;
  border-collapse: collapse;
}
.dynamic .table-wrapper table tr th, .dynamic .table-wrapper table tr td {
  padding: 20px 35px;
  line-height: 1.5625em;
  border: 1px solid var(--dynamic__table-border-color);
  font-size: 1.7rem;
}
@media screen and (max-width: 768px) {
  .dynamic .table-wrapper table tr th, .dynamic .table-wrapper table tr td {
    font-size: 1.6rem;
  }
}
@media screen and (max-width: 375px) {
  .dynamic .table-wrapper table tr th, .dynamic .table-wrapper table tr td {
    font-size: 1.5rem;
  }
}
.dynamic .table-wrapper table tr th > :first-child, .dynamic .table-wrapper table tr td > :first-child {
  margin-top: 0;
}
.dynamic .table-wrapper table tr th > p:last-child, .dynamic .table-wrapper table tr th > pre:last-child, .dynamic .table-wrapper table tr th > strong:last-child, .dynamic .table-wrapper table tr th > em:last-child, .dynamic .table-wrapper table tr th > a:last-child, .dynamic .table-wrapper table tr th > span:last-child, .dynamic .table-wrapper table tr th > sub:last-child, .dynamic .table-wrapper table tr th > sup:last-child, .dynamic .table-wrapper table tr th > ul:last-child, .dynamic .table-wrapper table tr th > ol:last-child, .dynamic .table-wrapper table tr td > p:last-child, .dynamic .table-wrapper table tr td > pre:last-child, .dynamic .table-wrapper table tr td > strong:last-child, .dynamic .table-wrapper table tr td > em:last-child, .dynamic .table-wrapper table tr td > a:last-child, .dynamic .table-wrapper table tr td > span:last-child, .dynamic .table-wrapper table tr td > sub:last-child, .dynamic .table-wrapper table tr td > sup:last-child, .dynamic .table-wrapper table tr td > ul:last-child, .dynamic .table-wrapper table tr td > ol:last-child {
  margin-bottom: 0 !important;
}
.dynamic .table-wrapper table tr th {
  background-color: var(--dynamic__table-header-background);
  font-weight: normal;
  color: var(--dynamic__table-header-color);
  border-top-color: var(--dynamic__table-header-background);
  border-bottom-color: var(--dynamic__table-header-background);
  text-align: left;
}
.dynamic .table-wrapper table tr th:first-child {
  border-left-color: var(--dynamic__table-header-background);
}
.dynamic .table-wrapper table tr th:last-child {
  border-right-color: var(--dynamic__table-header-background);
}
.dynamic .table-wrapper table tr td.header {
  background-color: var(--dynamic__table-header-background);
  color: var(--dynamic__table-header-color);
}
.dynamic .table-wrapper table tr td.secondary-header {
  background-color: var(--dynamic__table-secondary-header-background);
}
.dynamic .videoWrapper {
  max-width: 560px;
}
.dynamic .videoWrapper .ytVideo {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
}
.dynamic .videoWrapper .ytVideo iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}
.dynamic img {
  position: relative;
  max-width: 100%;
}
.dynamic img.fr-dib {
  /* block */
  display: block;
  margin: 30px auto;
  float: none;
  vertical-align: top;
}
.dynamic img.fr-dib.fr-fil {
  margin-left: 0;
}
.dynamic img.fr-dib.fr-fir {
  margin-right: 0;
}
.dynamic img.fr-dii {
  /* Inline */
  display: inline-block;
  float: none;
  vertical-align: bottom;
}
.dynamic img.fr-dii.fr-fil {
  float: left;
  margin: 10px 20px 10px 0;
}
.dynamic img.fr-dii.fr-fir {
  float: right;
  margin: 10px 0 10px 20px;
}
.dynamic .fr-img-caption img {
  width: 100%;
}
.dynamic .fr-img-caption.fr-dib {
  /* block */
  display: block;
  margin: 30px auto;
  float: none;
  vertical-align: middle;
  text-align: center;
}
.dynamic .fr-img-caption.fr-dib.fr-fil {
  margin-left: 0;
  text-align: left;
}
.dynamic .fr-img-caption.fr-dib.fr-fir {
  margin-right: 0;
  text-align: right;
}
.dynamic .fr-img-caption.fr-dib .fr-img-wrap .fr-inner {
  font-family: var(--font-family-1);
  white-space: normal;
  font-size: 1.4rem;
  line-height: 1.7142857143em;
  color: var(--color-grayscale-gray4);
  margin-top: 25px;
  margin-bottom: 25px;
}
.dynamic .fr-img-caption.fr-dii {
  /* Inline */
  display: inline-block;
  float: none;
  vertical-align: bottom;
  text-align: center;
}
.dynamic .fr-img-caption.fr-dii.fr-fil {
  float: left;
  margin-left: 0;
  text-align: left;
}
.dynamic .fr-img-caption.fr-dii.fr-fir {
  float: right;
  margin-right: 0;
  text-align: right;
}
.dynamic span.fr-fic.oc-img-rounded img,
.dynamic img.fr-fic.oc-img-rounded {
  border-radius: 100%;
  background-clip: padding-box;
}
.dynamic span.fr-fic.oc-img-bordered img,
.dynamic img.fr-fic.oc-img-bordered {
  border: solid 10px #ccc;
}

:root {
  --mobile-slider-text-height: 0;
}

.home-banner {
  --tiny-slider-dimensions: 166px;
  --mobile-nav-height: 56px;
}
@media screen and (max-width: 900px) {
  .home-banner {
    --tiny-slider-dimensions: 133px ;
  }
}

.home-banner {
  background: var(--color-blue-marine);
}
.home-banner .home-banner__container {
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  min-height: calc(500px + var(--header-height));
  display: flex;
  position: relative;
  overflow: hidden;
}
@media screen and (max-width: 800px) {
  .home-banner .home-banner__container {
    padding-top: calc(var(--y-spacing-50) + var(--header-height));
    flex-direction: column;
    justify-content: flex-end;
  }
}
.home-banner .home-banner__text-slider {
  width: calc(50% + 120px);
  margin-bottom: 0 !important;
  padding-left: var(--x-spacing-160);
  position: absolute;
  top: var(--header-height);
  bottom: 0;
  left: 0;
  z-index: 5;
}
.home-banner .home-banner__text-slider .slick-slide div {
  font-size: 0;
}
@media screen and (max-width: 800px) {
  .home-banner .home-banner__text-slider {
    width: 100%;
    padding-right: var(--x-spacing-160);
    top: calc(var(--y-spacing-50) + var(--header-height));
    bottom: initial;
  }
  .home-banner .home-banner__text-slider .slick-list,
  .home-banner .home-banner__text-slider .slick-track,
  .home-banner .home-banner__text-slider .slick-slide {
    height: 100% !important;
  }
  .home-banner .home-banner__text-slider .slick-slide div {
    height: 100% !important;
  }
}
.home-banner .home-banner__text-slider-slide {
  width: 100%;
  height: calc(var(--vh, 1vh) * 100 - var(--header-height));
  min-height: 500px;
  padding-bottom: var(--tiny-slider-dimensions);
  display: flex !important;
  align-items: center;
}
@media screen and (max-width: 800px) {
  .home-banner .home-banner__text-slider-slide {
    height: 100%;
    min-height: 0px;
    align-items: initial;
    padding-bottom: 0;
  }
}
.home-banner .home-banner__text-block {
  position: relative;
}
@media screen and (max-width: 800px) {
  .home-banner .home-banner__text-block {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}
.home-banner .home-banner__title {
  color: var(--color-base-white);
  margin-bottom: 55px;
}
@media screen and (max-width: 1100px) {
  .home-banner .home-banner__title {
    margin-bottom: 20px;
  }
}
@media screen and (max-height: 700px) {
  .home-banner .home-banner__title {
    font-size: var(--huge-title-smaller-font-size);
    margin-bottom: 20px;
  }
}
@media screen and (max-height: 600px) {
  .home-banner .home-banner__title {
    font-size: 3.5rem;
  }
}
@media screen and (max-height: 500px) {
  .home-banner .home-banner__title {
    font-size: 3rem;
  }
}
@media screen and (max-width: 800px) and (max-height: 700px) {
  .home-banner .home-banner__title {
    margin-bottom: 20px;
  }
}
.home-banner .smaller {
  font-size: var(--huge-title-smaller-font-size);
}
.home-banner .home-banner__image-slider {
  width: 50%;
  margin-left: 50%;
  margin-bottom: 0 !important;
}
.home-banner .home-banner__image-slider .slick-slide div {
  font-size: 0;
}
@media screen and (max-width: 800px) {
  .home-banner .home-banner__image-slider {
    height: 50%;
    width: 100%;
    margin-left: 0;
    position: relative;
    z-index: 1;
  }
  .home-banner .home-banner__image-slider .slick-list,
  .home-banner .home-banner__image-slider .slick-track,
  .home-banner .home-banner__image-slider .slick-slide {
    height: 100% !important;
  }
  .home-banner .home-banner__image-slider .slick-slide div {
    height: 100% !important;
  }
}
.home-banner .home-banner__image-slider-slide {
  position: relative;
}
.home-banner .home-banner__filter {
  background: rgb(0, 0, 0);
  background: linear-gradient(270deg, rgba(0, 0, 0, 0) 77%, rgba(0, 0, 0, 0.5) 100%);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
@media screen and (max-width: 800px) {
  .home-banner .home-banner__filter {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0) 77%, rgba(0, 0, 0, 0.5) 100%);
  }
}
.home-banner .home-banner__picture {
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  min-height: calc(500px + var(--header-height));
}
@media screen and (max-width: 800px) {
  .home-banner .home-banner__picture {
    height: 100%;
    min-height: 0px;
  }
}
.home-banner .home-banner__credit {
  font-family: var(--font-family-1);
  font-size: 1.4rem;
  font-weight: 700;
  color: var(--color-base-white);
  position: absolute;
  right: 0;
  bottom: 0;
  background: rgba(37, 46, 63, 0.7);
  padding: 10px;
}
.home-banner .home-banner__tiny-slider-container {
  display: flex;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
}
@media screen and (max-width: 800px) {
  .home-banner .home-banner__tiny-slider-container {
    width: 100%;
    position: relative;
    bottom: initial;
    left: initial;
    transform: none;
  }
}
.home-banner .home-banner__tiny-slider .slick-list,
.home-banner .home-banner__tiny-slider .slick-track,
.home-banner .home-banner__tiny-slider .slick-slide {
  height: 100% !important;
}
.home-banner .home-banner__tiny-slider .slick-slide div {
  height: 100% !important;
}
.home-banner .home-banner__tiny-slider .slick-slide div {
  font-size: 0;
}
.home-banner .home-banner__tiny-slider {
  width: var(--tiny-slider-dimensions);
  height: var(--tiny-slider-dimensions);
}
@media screen and (max-width: 800px) {
  .home-banner .home-banner__tiny-slider {
    display: none;
  }
}
.home-banner .home-banner__next-picture {
  width: 100%;
  height: 100%;
}
.home-banner .home-banner__tiny-slider-nav {
  width: var(--tiny-slider-dimensions);
  height: var(--tiny-slider-dimensions);
  padding-left: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: var(--color-blue-light-blue);
}
@media screen and (max-width: 900px) {
  .home-banner .home-banner__tiny-slider-nav {
    padding-left: 20px;
  }
}
@media screen and (max-width: 800px) {
  .home-banner .home-banner__tiny-slider-nav {
    width: 100%;
    height: 56px;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 0 var(--x-spacing-50);
  }
}
.home-banner .home-banner__slider-number {
  margin-bottom: 20px;
}
.home-banner .home-banner__slider-number .slideNumber .current,
.home-banner .home-banner__slider-number .slash,
.home-banner .home-banner__slider-number .slideNumber .total {
  font-family: var(--font-family-2);
  font-weight: 500;
  color: var(--color-base-white);
}
.home-banner .home-banner__slider-number .slideNumber .current {
  font-size: 4rem;
}
@media screen and (max-width: 900px) {
  .home-banner .home-banner__slider-number .slideNumber .current {
    font-size: 3rem;
  }
}
.home-banner .home-banner__slider-number .slash {
  font-size: 2rem;
}
@media screen and (max-width: 900px) {
  .home-banner .home-banner__slider-number .slash {
    font-size: 1.7rem;
  }
}
.home-banner .home-banner__slider-number .slideNumber .total {
  font-size: 2rem;
}
@media screen and (max-width: 900px) {
  .home-banner .home-banner__slider-number .slideNumber .total {
    font-size: 1.7rem;
  }
}
@media screen and (max-width: 800px) {
  .home-banner .home-banner__slider-number {
    margin-bottom: 0;
    margin-right: 20px;
  }
}
.home-banner .home-banner__arrows-box {
  display: flex;
  align-items: center;
}
.home-banner .home-banner__arrows-box-arrow-prev {
  width: 45px;
  height: 19px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  transition: transform 400ms var(--ease-out-cubic);
}
.home-banner .home-banner__arrows-box-arrow-prev svg {
  transform: rotate(90deg);
}
.home-banner .home-banner__arrows-box-arrow-prev:hover {
  transform: translateX(-10px);
}
@media screen and (max-width: 900px) {
  .home-banner .home-banner__arrows-box-arrow-prev {
    width: 35px;
  }
}
.home-banner .home-banner__arrows-box-separator {
  width: 1px;
  height: 19px;
  background: var(--color-base-white);
}
.home-banner .home-banner__arrows-box-arrow-next {
  width: 45px;
  height: 19px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  transition: transform 400ms var(--ease-out-cubic);
}
.home-banner .home-banner__arrows-box-arrow-next svg {
  transform: rotate(-90deg);
}
.home-banner .home-banner__arrows-box-arrow-next:hover {
  transform: translateX(10px);
}
@media screen and (max-width: 900px) {
  .home-banner .home-banner__arrows-box-arrow-next {
    width: 35px;
  }
}
.home-banner .home-banner__svg {
  stroke: var(--color-base-white);
}
.home-banner .home-banner__svg--chevron {
  width: 19px;
  height: 11px;
  stroke-width: 2px;
  fill: none;
}
.home-banner .home-banner__svg--arrow {
  width: 8px;
  height: 24px;
  fill: var(--color-base-white);
  position: absolute;
  top: 0;
  right: 0;
  animation-name: bouncing;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-out;
}
@keyframes bouncing {
  0% {
    top: 0%;
  }
  50% {
    top: 25%;
  }
  100% {
    top: 0;
  }
}
.home-banner .home-banner__link {
  height: 24px;
  font-family: var(--font-family-1);
  font-size: 1.7rem;
  color: var(--color-base-white);
  opacity: 1;
  transition: opacity 300ms;
  padding-right: 30px;
  display: flex;
  align-items: center;
  position: absolute;
  bottom: var(--y-spacing-60);
  left: var(--x-spacing-160);
  z-index: 10;
}
.home-banner .home-banner__link:hover {
  opacity: 0.5;
}
@media screen and (max-width: 800px) {
  .home-banner .home-banner__link {
    display: none;
  }
}
@media screen and (max-height: 700px) {
  .home-banner .home-banner__link {
    bottom: 30px;
  }
}
@media screen and (max-height: 600px) {
  .home-banner .home-banner__link {
    bottom: 20px;
  }
}

@media screen and (max-width: 800px) {
  .show-alerts .home-banner .home-banner__container {
    height: calc(var(--vh, 1vh) * 100 - var(--alert-mobile-height));
  }
  .show-alerts .home-banner .home-banner__text-slider-slide {
    height: calc(var(--vh, 1vh) * 100 - var(--header-height) - var(--alert-mobile-height));
  }
}

.featured-news .featured-news__container {
  padding-top: var(--y-spacing-160);
  background: var(--color-blue-marine);
}
@media screen and (max-width: 1024px) {
  .featured-news .featured-news__container {
    padding-top: 60px;
  }
}
.featured-news .featured-news__text-block {
  padding-right: var(--x-spacing-160);
  padding-bottom: var(--y-spacing-150);
  padding-left: var(--x-spacing-160);
}
@media screen and (max-height: 800px) {
  .featured-news .featured-news__text-block {
    padding-bottom: var(--y-spacing-80);
  }
}
@media screen and (max-height: 700px) {
  .featured-news .featured-news__text-block {
    padding-bottom: var(--y-spacing-50);
  }
}
.featured-news .featured-news__surtitle {
  font-family: var(--font-family-1);
  font-size: 1.2rem;
  font-weight: 700;
  letter-spacing: 0.1em;
  line-height: 1.4;
  color: var(--color-blue-blue);
  text-transform: uppercase;
  padding-top: 30px;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  margin-bottom: var(--y-spacing-70);
}
@media screen and (max-height: 700px) {
  .featured-news .featured-news__surtitle {
    margin-bottom: var(--y-spacing-50);
  }
}
@media screen and (max-width: 1024px) {
  .featured-news .featured-news__surtitle {
    padding-top: 20px;
  }
}
.featured-news .featured-news__title {
  font-family: var(--font-family-2);
  font-size: 11.5rem;
  line-height: 1;
  color: var(--color-base-white);
}
@media screen and (max-height: 800px) {
  .featured-news .featured-news__title {
    font-size: 9rem;
  }
}
@media screen and (max-height: 700px) {
  .featured-news .featured-news__title {
    font-size: 8rem;
  }
}
@media screen and (max-width: 1024px) {
  .featured-news .featured-news__title {
    font-size: 9rem;
  }
}
@media screen and (max-width: 768px) {
  .featured-news .featured-news__title {
    font-size: 8rem;
  }
}
@media screen and (max-width: 425px) {
  .featured-news .featured-news__title {
    font-size: 5rem;
  }
}
.featured-news .featured-news__list {
  padding-right: var(--x-spacing-160);
  padding-bottom: var(--y-spacing-160);
  padding-left: var(--x-spacing-160);
  background: var(--color-grayscale-gray14);
  display: flex;
  flex-direction: column;
  position: relative;
}
.featured-news .featured-news__list:before {
  content: "";
  width: 100%;
  height: 70px;
  position: absolute;
  top: 0;
  left: 0;
  background: var(--color-blue-marine);
}
@media screen and (max-width: 600px) {
  .featured-news .featured-news__list:before {
    height: 95px;
  }
}
@media screen and (max-width: 1024px) {
  .featured-news .featured-news__list {
    padding-bottom: 70px;
  }
}
.featured-news .featured-news__list .news__container:hover .news__title {
  color: var(--color-blue-light-blue);
}
.featured-news .featured-news__list .news {
  margin-bottom: var(--y-spacing-90);
}
@media screen and (max-height: 800px) {
  .featured-news .featured-news__list .news {
    margin-bottom: var(--y-spacing-80);
  }
}
@media screen and (max-height: 700px) {
  .featured-news .featured-news__list .news {
    margin-bottom: var(--y-spacing-70);
  }
}
@media screen and (max-width: 1024px) {
  .featured-news .featured-news__list .news {
    margin-bottom: 60px;
  }
}
@media screen and (max-height: 800px) {
  .featured-news .featured-news__list .news .news__picture {
    margin-bottom: 30px;
  }
}
@media screen and (max-height: 700px) {
  .featured-news .featured-news__list .news .news__picture {
    margin-bottom: 25px;
  }
}
@media screen and (max-height: 800px) {
  .featured-news .featured-news__list .news .news__surtitle {
    margin-bottom: 20px;
  }
}
@media screen and (max-height: 700px) {
  .featured-news .featured-news__list .news .news__surtitle {
    margin-bottom: 15px;
  }
}
@media screen and (max-height: 800px) {
  .featured-news .featured-news__list .news .news__title {
    font-size: 3.2rem;
  }
}
@media screen and (max-height: 700px) {
  .featured-news .featured-news__list .news .news__title {
    font-size: 2.9rem;
  }
}
.featured-news .featured-news__button {
  align-self: center;
}
@media screen and (max-width: 600px) {
  .featured-news .featured-news__button {
    align-self: flex-start;
  }
}

.featured-events {
  --home-image-width: 641px;
}
@media screen and (max-height: 800px) {
  .featured-events {
    --home-image-width: 500px;
  }
}
@media screen and (max-height: 700px) {
  .featured-events {
    --home-image-width: 441px;
  }
}
@media screen and (max-width: 1300px) {
  .featured-events {
    --home-image-width: 600px;
  }
}
@media screen and (max-width: 1200px) {
  .featured-events {
    --home-image-width: 541px;
  }
}
@media screen and (max-width: 1100px) {
  .featured-events {
    --home-image-width: 500px;
  }
}
@media screen and (max-width: 1000px) {
  .featured-events {
    --home-image-width: 441px;
  }
}
@media screen and (max-width: 900px) {
  .featured-events {
    --home-image-width: 400px;
  }
}
@media screen and (max-width: 800px) {
  .featured-events {
    --home-image-width: 341px;
  }
}
@media screen and (max-width: 700px) {
  .featured-events {
    --home-image-width: 100%;
  }
}

.featured-events {
  position: relative;
}
@media screen and (max-width: 700px) {
  .featured-events .featured-events__container {
    padding: 0 var(--x-spacing-30);
    padding-bottom: var(--y-spacing-190);
  }
}
.featured-events .featured-events__container .events__container {
  padding-right: var(--x-spacing-120);
  padding-bottom: var(--y-spacing-190);
  margin-bottom: 0;
  align-items: flex-start;
  justify-content: flex-start;
}
@media screen and (max-height: 800px) {
  .featured-events .featured-events__container .events__container {
    padding-bottom: var(--y-spacing-170);
  }
}
@media screen and (max-height: 700px) {
  .featured-events .featured-events__container .events__container {
    padding-bottom: var(--y-spacing-150);
  }
}
@media screen and (max-width: 700px) {
  .featured-events .featured-events__container .events__container {
    padding-bottom: 0;
    margin-bottom: 50px;
  }
}
.featured-events .featured-events__container .events__container:hover .events__img {
  transform: none;
}
.featured-events .featured-events__container .events__picture-container {
  width: var(--home-image-width);
  position: relative;
}
.featured-events .featured-events__container .events__picture-container:before {
  content: "";
  width: 0%;
  height: 100%;
  transition: width 500ms var(--ease-in-out-cubic);
  background: var(--color-blue-marine);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
}
.featured-events .featured-events__container .events__picture-container.cover:before {
  width: 100%;
}
.featured-events .featured-events__container .events__surtitle {
  margin-bottom: 30px;
}
@media screen and (max-height: 800px) {
  .featured-events .featured-events__container .events__surtitle {
    margin-bottom: 25px;
  }
}
@media screen and (max-height: 700px) {
  .featured-events .featured-events__container .events__surtitle {
    margin-bottom: 20px;
  }
}
.featured-events .featured-events__container .events__title {
  font-size: 6rem;
  margin-bottom: 50px;
}
@media screen and (max-height: 800px) {
  .featured-events .featured-events__container .events__title {
    font-size: 5rem;
    margin-bottom: 40px;
  }
}
@media screen and (max-height: 700px) {
  .featured-events .featured-events__container .events__title {
    font-size: 4.5rem;
    margin-bottom: 30px;
  }
}
@media screen and (max-width: 1024px) {
  .featured-events .featured-events__container .events__title {
    font-size: 5rem;
  }
}
@media screen and (max-width: 768px) {
  .featured-events .featured-events__container .events__title {
    font-size: 4.5rem;
  }
}
@media screen and (max-width: 700px) {
  .featured-events .featured-events__container .events__title {
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 600px) {
  .featured-events .featured-events__container .events__title {
    font-size: 4rem;
  }
}
@media screen and (max-width: 425px) {
  .featured-events .featured-events__container .events__title {
    font-size: 3.5rem;
  }
}
.featured-events .featured-events__next-picture-container {
  width: 270px;
  position: absolute;
  right: 0;
  bottom: 0;
}
.featured-events .featured-events__next-picture-container:before {
  content: "";
  width: 0%;
  height: 100%;
  transition: width 500ms var(--ease-in-out-cubic);
  background: var(--color-blue-marine);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
}
@media screen and (max-width: 1300px) {
  .featured-events .featured-events__next-picture-container {
    width: 250px;
  }
}
@media screen and (max-width: 1200px) {
  .featured-events .featured-events__next-picture-container {
    width: 230px;
  }
}
@media screen and (max-width: 1100px) {
  .featured-events .featured-events__next-picture-container {
    width: 210px;
  }
}
@media screen and (max-width: 1000px) {
  .featured-events .featured-events__next-picture-container {
    width: 190px;
  }
}
@media screen and (max-width: 900px) {
  .featured-events .featured-events__next-picture-container {
    width: 170px;
  }
}
@media screen and (max-width: 800px) {
  .featured-events .featured-events__next-picture-container {
    width: 150px;
  }
}
@media screen and (max-width: 700px) {
  .featured-events .featured-events__next-picture-container {
    display: none;
  }
}
.featured-events .featured-events__next-picture-container.cover:before {
  width: 100%;
}
.featured-events .featured-events__next-picture {
  width: 100%;
  padding-top: 113%;
  position: relative;
  overflow: hidden;
}
.featured-events .featured-events__img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.featured-events .featured-events__slider {
  width: calc(100% - var(--home-image-width));
  padding-left: var(--x-spacing-120);
}
@media screen and (min-width: 1601px) {
  .featured-events .featured-events__slider {
    padding-left: 0;
  }
}
@media screen and (max-width: 700px) {
  .featured-events .featured-events__slider {
    width: 100%;
    padding-left: 0;
  }
}
.featured-events .featured-events__slider-slide .events__text-block {
  width: 100%;
  padding-top: 120px;
  padding-right: var(--x-spacing-120);
  padding-left: 0;
}
@media screen and (max-width: 1000px) {
  .featured-events .featured-events__slider-slide .events__text-block {
    padding-top: 60px;
  }
}
@media screen and (max-width: 800px) {
  .featured-events .featured-events__slider-slide .events__text-block {
    padding-top: 30px;
  }
}
.featured-events .featured-events__slider-slide .events__title {
  transition: color 300ms;
}
.featured-events .featured-events__slider-slide .events__text-block:hover .events__title {
  color: var(--color-blue-light-blue);
}
.featured-events .featured-events__slider-nav {
  width: 155px;
  height: 78px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-blue-light-blue);
  position: absolute;
  top: 0;
  right: 0;
  z-index: 5;
}
@media screen and (max-width: 900px) {
  .featured-events .featured-events__slider-nav {
    width: 125px;
    height: 68px;
  }
}
@media screen and (max-width: 600px) {
  .featured-events .featured-events__slider-nav {
    width: 100px;
    height: 50px;
  }
}
.featured-events .featured-events__arrows-box {
  display: flex;
  align-items: center;
}
.featured-events .featured-events__arrows-box-arrow-prev {
  width: 45px;
  height: 19px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  transition: transform 400ms var(--ease-out-cubic);
}
.featured-events .featured-events__arrows-box-arrow-prev svg {
  transform: rotate(90deg);
}
.featured-events .featured-events__arrows-box-arrow-prev:hover {
  transform: translateX(-10px);
}
@media screen and (max-width: 900px) {
  .featured-events .featured-events__arrows-box-arrow-prev {
    width: 35px;
  }
}
@media screen and (max-width: 600px) {
  .featured-events .featured-events__arrows-box-arrow-prev {
    width: 25px;
  }
}
.featured-events .featured-events__arrows-box-separator {
  width: 1px;
  height: 19px;
  background: var(--color-base-white);
}
.featured-events .featured-events__arrows-box-arrow-next {
  width: 45px;
  height: 19px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  transition: transform 400ms var(--ease-out-cubic);
}
.featured-events .featured-events__arrows-box-arrow-next svg {
  transform: rotate(-90deg);
}
.featured-events .featured-events__arrows-box-arrow-next:hover {
  transform: translateX(10px);
}
@media screen and (max-width: 900px) {
  .featured-events .featured-events__arrows-box-arrow-next {
    width: 35px;
  }
}
@media screen and (max-width: 600px) {
  .featured-events .featured-events__arrows-box-arrow-next {
    width: 25px;
  }
}
.featured-events .featured-events__svg {
  stroke: var(--color-base-white);
}
.featured-events .featured-events__svg--chevron {
  width: 19px;
  height: 11px;
  stroke-width: 2px;
  fill: none;
}
.featured-events .featured-events__svg--arrow {
  width: 8px;
  height: 24px;
  fill: var(--color-base-white);
  position: absolute;
  top: 0;
  right: 0;
  animation-name: bouncing;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-out;
}
@keyframes bouncing {
  0% {
    top: 0%;
  }
  50% {
    top: 25%;
  }
  100% {
    top: 0;
  }
}
.featured-events .featured-events__svg--calendar {
  width: 16px;
  height: 16px;
  stroke: var(--color-base-white);
  stroke-width: 2px;
  fill: none;
  margin-right: 15px;
  margin-left: 0;
}
.featured-events .featured-events__button {
  position: absolute;
  bottom: calc(var(--y-spacing-190) / 2);
  left: var(--x-spacing-120);
  transform: translateY(50%);
}
@media screen and (max-height: 800px) {
  .featured-events .featured-events__button {
    bottom: calc(var(--y-spacing-170) / 2);
  }
}
@media screen and (max-height: 700px) {
  .featured-events .featured-events__button {
    bottom: calc(var(--y-spacing-150) / 2);
  }
}
.featured-events .featured-events__button span {
  display: flex;
  align-items: center;
  justify-content: center;
}
.featured-events .featured-events__button:hover svg {
  fill: none;
}
@media screen and (max-width: 700px) {
  .featured-events .featured-events__button {
    position: relative;
    bottom: initial;
    left: initial;
    transform: none;
  }
}

.home-showcase {
  --image-width: 400px;
}

.home-showcase .home-showcase__container {
  width: 100%;
  position: relative;
}
.home-showcase .home-showcase__background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.home-showcase .home-showcase__content {
  width: 100%;
  padding: var(--y-spacing-160) 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
@media screen and (max-height: 800px) {
  .home-showcase .home-showcase__content {
    padding: var(--y-spacing-100) 25px;
  }
}
@media screen and (max-height: 700px) {
  .home-showcase .home-showcase__content {
    padding: var(--y-spacing-50) 25px;
  }
}
.home-showcase .home-showcase__picture {
  width: var(--image-width);
  height: 619px;
  background-color: #242931;
}
@media screen and (max-height: 800px) {
  .home-showcase .home-showcase__picture {
    height: 600px;
  }
}
@media screen and (max-height: 700px) {
  .home-showcase .home-showcase__picture {
    height: 580px;
  }
}
@media screen and (max-width: 500px) {
  .home-showcase .home-showcase__picture {
    width: 100%;
    height: auto;
  }
}
.home-showcase .home-showcase__img {
  opacity: 0.6;
}
.home-showcase .home-showcase__text-block {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media screen and (max-width: 500px) {
  .home-showcase .home-showcase__text-block {
    width: calc(100% - 50px);
    padding: 0 25px;
  }
}
@media screen and (max-width: 400px) {
  .home-showcase .home-showcase__text-block {
    padding: 0 15px;
  }
}
.home-showcase .home-showcase__surtitle {
  font-family: var(--font-family-1);
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 1.4;
  letter-spacing: 0.1em;
  color: var(--color-base-white);
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 20px;
}
.home-showcase .home-showcase__title {
  font-family: var(--font-family-2);
  font-size: 6rem;
  line-height: 1.08em;
  color: var(--color-base-white);
  text-align: center;
  margin-bottom: 55px;
}
@media screen and (max-height: 800px) {
  .home-showcase .home-showcase__title {
    font-size: 5rem;
  }
}
@media screen and (max-width: 1024px) {
  .home-showcase .home-showcase__title {
    font-size: 5rem;
  }
}
@media screen and (max-width: 768px) {
  .home-showcase .home-showcase__title {
    font-size: 4rem;
  }
}
@media screen and (max-width: 500px) {
  .home-showcase .home-showcase__title {
    font-size: 3.4rem;
    margin-bottom: 45px;
  }
}
@media screen and (max-width: 400px) {
  .home-showcase .home-showcase__title {
    margin-bottom: 35px;
  }
}

.page-title .page-title__container {
  width: 100%;
  position: relative;
  z-index: 5;
}
.page-title .page-title__container--credit {
  font-family: var(--font-family-1);
  font-size: 1.4rem;
  font-weight: 700;
  color: var(--color-base-white);
  position: absolute;
  right: 0;
  bottom: 0;
  background: rgba(37, 46, 63, 0.7);
  padding: 10px;
}
.page-title .page-title__title {
  color: var(--color-base-white);
}
.page-title .page-title__suggestions {
  position: absolute;
  bottom: 60px;
  font-family: var(--font-family-1);
  font-size: 1.6rem;
  font-weight: 700;
  color: var(--color-base-white);
}
.page-title .page-title__suggestions a {
  color: var(--color-base-white);
  text-decoration: none;
  box-shadow: inset 0 -0.1em 0 0 #fff;
  transition: box-shadow 0.3s;
}
.page-title .page-title__suggestions a:hover {
  box-shadow: inset 0 -1.1em 0 0 rgba(255, 255, 255, 0.2);
}
.page-title--image-full-width .page-title__filter {
  background: var(--color-blue-marine);
  background: linear-gradient(180deg, rgb(37, 46, 63) 0%, rgba(255, 255, 255, 0) 80%);
  pointer-events: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 5;
}
.page-title--image-full-width .page-title__picture {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.page-title--image-full-width .page-title__title-container {
  width: 100%;
  padding: 0 var(--x-spacing-120) 230px var(--x-spacing-120);
  padding-top: calc(130px + var(--header-height));
  position: relative;
  z-index: 10;
}
@media screen and (max-width: 600px) {
  .page-title--image-full-width .page-title__title-container {
    padding-top: calc(90px + var(--header-height));
    padding-bottom: 150px;
  }
}
.page-title--image-full-width .page-title__share {
  position: absolute;
  bottom: 40px;
  left: var(--x-spacing-120);
  z-index: 10;
}
@media screen and (max-width: 600px) {
  .page-title--image-full-width .page-title__share {
    display: none;
  }
}
.page-title--image-full-width .page-title__credit {
  font-family: var(--font-family-1);
  font-size: 1.4rem;
  font-weight: 700;
  color: var(--color-base-white);
  position: absolute;
  right: 0;
  bottom: 0;
  background: rgba(37, 46, 63, 0.7);
  padding: 10px;
}
.page-title--image-full-width .page-title__credit--contact {
  right: calc(var(--side-section-contact-width));
}
@media screen and (max-width: 1000px) {
  .page-title--image-full-width .page-title__credit--contact {
    right: 40px;
  }
}
@media screen and (max-width: 768px) {
  .page-title--image-full-width .page-title__credit--contact {
    right: 30px;
  }
}
@media screen and (max-width: 600px) {
  .page-title--image-full-width .page-title__credit--contact {
    right: auto;
    left: 25px;
  }
}
@media screen and (max-width: 768px) {
  .page-title--image-full-width .page-title__credit {
    right: 30px;
  }
}
@media screen and (max-width: 600px) {
  .page-title--image-full-width .page-title__credit {
    right: auto;
    left: 25px;
  }
}
.page-title--no-image .page-title__container {
  background: var(--color-blue-marine);
  padding-top: calc(var(--y-spacing-100) + var(--header-height));
  padding-right: var(--x-spacing-120);
  padding-bottom: var(--y-spacing-120);
  padding-left: var(--x-spacing-120);
}
@media screen and (max-height: 800px) {
  .page-title--no-image .page-title__container {
    padding-top: calc(var(--y-spacing-80) + var(--header-height));
    padding-bottom: var(--y-spacing-100);
  }
}
@media screen and (max-height: 700px) {
  .page-title--no-image .page-title__container {
    padding-top: calc(var(--y-spacing-50) + var(--header-height));
    padding-bottom: var(--y-spacing-80);
  }
}
.page-title--no-image .page-title__search-results-form {
  margin-bottom: var(--y-spacing-60);
}
.page-title--no-image .page-title__search-results-form .search-field {
  border-bottom: 1px solid var(--color-base-white);
}
.page-title--no-image .page-title__search-results-form .search-field__input {
  color: var(--color-base-white);
  background-color: var(--color-blue-marine);
}
.page-title--no-image .page-title__search-results-form .search-field__x {
  background: var(--color-blue-darker-marine);
}
.page-title--no-image .page-title__search-results-form .search-field__x:before, .page-title--no-image .page-title__search-results-form .search-field__x:after {
  background: var(--color-base-white);
}
.page-title--no-image .page-title__search-results-form .search-field__x:hover {
  background: var(--color-blue-light-blue);
}
.page-title--no-image .page-title__search-results-form .search-field__button {
  background: var(--color-base-white);
}
.page-title--no-image .page-title__search-results-form .search-field__button:hover {
  background: var(--color-blue-darker-marine);
}
.page-title--no-image .page-title__search-results-form :-webkit-autofill,
.page-title--no-image .page-title__search-results-form :-webkit-autofill:hover,
.page-title--no-image .page-title__search-results-form :-webkit-autofill:focus,
.page-title--no-image .page-title__search-results-form :-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 100px var(--color-blue-marine) inset !important;
  -webkit-text-fill-color: var(--color-base-white);
}
.page-title--no-image .page-title__title {
  width: 100%;
}
.page-title--no-image .page-title__share {
  margin-top: 40px;
}
@media screen and (max-width: 600px) {
  .page-title--no-image .page-title__share {
    display: none;
  }
}
.page-title--no-image .page-title__search-item {
  font-style: italic;
  color: var(--color-blue-light-blue);
}
.page-title--50-50 .page-title__container {
  min-height: 750px;
  display: flex;
}
@media screen and (max-width: 1300px) {
  .page-title--50-50 .page-title__container {
    min-height: 730px;
  }
}
@media screen and (max-width: 1200px) {
  .page-title--50-50 .page-title__container {
    min-height: 680px;
  }
}
@media screen and (max-width: 1100px) {
  .page-title--50-50 .page-title__container {
    min-height: 660px;
  }
}
@media screen and (max-width: 1000px) {
  .page-title--50-50 .page-title__container {
    min-height: 640px;
  }
}
@media screen and (max-width: 900px) {
  .page-title--50-50 .page-title__container {
    min-height: 600px;
  }
}
@media screen and (max-width: 800px) {
  .page-title--50-50 .page-title__container {
    min-height: 580px;
  }
}
@media screen and (max-width: 768px) {
  .page-title--50-50 .page-title__container {
    min-height: 0;
    flex-direction: column-reverse;
  }
}
.page-title--50-50 .page-title__picture-container {
  width: 50%;
  position: relative;
}
@media screen and (min-width: 1601px) {
  .page-title--50-50 .page-title__picture-container {
    width: 720px;
  }
}
@media screen and (max-width: 768px) {
  .page-title--50-50 .page-title__picture-container {
    width: 100%;
  }
}
.page-title--50-50 .page-title__gradient-filter {
  background: rgb(68, 68, 68);
  background: linear-gradient(180deg, rgba(68, 68, 68, 0.7) 0%, rgba(68, 68, 68, 0) 20%);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 5;
}
@media screen and (max-width: 768px) {
  .page-title--50-50 .page-title__gradient-filter {
    display: none;
  }
}
.page-title--50-50 .page-title__picture {
  width: 100%;
  height: 100%;
}
.page-title--50-50 .page-title__title-container {
  width: 50%;
  background: var(--color-blue-marine);
  padding-top: calc(var(--y-spacing-140) + var(--header-height));
  padding-right: var(--x-spacing-80);
  padding-bottom: var(--y-spacing-140);
  padding-left: var(--x-spacing-120);
  position: relative;
}
@media screen and (min-width: 1601px) {
  .page-title--50-50 .page-title__title-container {
    width: calc(100% - 720px);
  }
}
@media screen and (max-width: 768px) {
  .page-title--50-50 .page-title__title-container {
    width: 100%;
    padding-top: calc(var(--y-spacing-100) + var(--header-height));
    padding-bottom: var(--y-spacing-100);
  }
}
.page-title--50-50 .page-title__surtitle {
  font-family: var(--font-family-1);
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 1.4;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: var(--color-blue-blue);
  margin-bottom: 25px;
}
.page-title--50-50 .page-title__category {
  color: var(--color-grayscale-gray9);
}
.page-title--50-50 .page-title__date {
  font-size: 1.2rem;
}
.page-title--50-50 .page-title__title {
  font-size: 7rem;
}
@media screen and (max-height: 800px) {
  .page-title--50-50 .page-title__title {
    font-size: 6rem;
    margin-bottom: 40px;
  }
}
@media screen and (max-height: 700px) {
  .page-title--50-50 .page-title__title {
    font-size: 5rem;
  }
}
@media screen and (max-width: 1200px) {
  .page-title--50-50 .page-title__title {
    font-size: 6rem;
  }
}
@media screen and (max-width: 1000px) {
  .page-title--50-50 .page-title__title {
    font-size: 5rem;
  }
}
@media screen and (max-width: 900px) {
  .page-title--50-50 .page-title__title {
    font-size: 4.5rem;
  }
}
@media screen and (max-width: 768px) {
  .page-title--50-50 .page-title__title {
    margin-bottom: 40px;
  }
}
@media screen and (max-width: 600px) {
  .page-title--50-50 .page-title__title {
    margin-bottom: 0;
  }
}
@media screen and (max-width: 425px) {
  .page-title--50-50 .page-title__title {
    font-size: 4rem;
  }
}
.page-title--50-50 .page-title__share {
  position: absolute;
  bottom: var(--y-spacing-50);
  left: var(--x-spacing-120);
}
@media screen and (max-height: 800px) {
  .page-title--50-50 .page-title__share {
    position: relative;
    bottom: initial;
    left: initial;
  }
}
@media screen and (max-width: 768px) {
  .page-title--50-50 .page-title__share {
    position: relative;
    bottom: initial;
    left: initial;
  }
}
@media screen and (max-width: 600px) {
  .page-title--50-50 .page-title__share {
    display: none;
  }
}
.page-title--50-50--no-image .page-title__container {
  min-height: initial;
}
.page-title--50-50--no-image .page-title__title-container {
  width: 100%;
}
.page-title--50-50--no-image .page-title__share {
  margin-top: 40px;
  position: relative;
  bottom: initial;
  left: initial;
}

:root {
  --side-section-width: 360px;
  --side-section-contact-width: 520px;
}
@media screen and (max-width: 1300px) {
  :root {
    --side-section-contact-width: 500px;
  }
}
@media screen and (max-width: 1200px) {
  :root {
    --side-section-contact-width: 480px;
  }
}
@media screen and (max-width: 1100px) {
  :root {
    --side-section-contact-width: 460px;
  }
}
@media screen and (max-width: 1024px) {
  :root {
    --side-section-contact-width: 440px;
  }
}

@media screen and (max-width: 1000px) {
  .side-section {
    display: none;
  }
}
.side-section .side-section__container {
  width: var(--side-section-width);
  height: 100%;
  padding-top: 95px;
  padding-right: 40px;
  padding-bottom: var(--y-spacing-100);
  position: relative;
}
.side-section .side-section__container--contact {
  height: calc(100% + 190px);
  width: var(--side-section-contact-width);
  background: var(--color-blue-marine);
  padding-top: var(--y-spacing-80);
  padding-right: 0;
  margin-top: -190px;
  z-index: 5;
}

.showcase {
  padding-top: 20px;
  padding-right: var(--x-spacing-120);
  padding-left: var(--x-spacing-120);
  padding-bottom: var(--y-spacing-150);
}
.showcase--contact {
  position: relative;
}
.showcase--contact::before {
  content: "";
  width: var(--side-section-contact-width);
  height: calc(100% + var(--y-spacing-150));
  background: var(--color-blue-marine);
  position: absolute;
  top: 0;
  right: 0;
}
@media screen and (max-width: 1000px) {
  .showcase--contact::before {
    display: none;
  }
}
.showcase--home {
  position: relative;
}
.showcase--home:before {
  content: "";
  width: 100%;
  height: 215px;
  background: var(--color-grayscale-gray14);
  position: absolute;
  top: 0;
  left: 0;
}
.showcase .showcase__container {
  min-height: 560px;
  position: relative;
  display: block;
  box-shadow: 0px 0px 15px 10px rgba(0, 0, 0, 0.04);
  -webkit-box-shadow: 0px 0px 15px 10px rgba(0, 0, 0, 0.04);
  -moz-box-shadow: 0px 0px 15px 10px rgba(0, 0, 0, 0.04);
}
@media screen and (max-height: 800px) {
  .showcase .showcase__container {
    min-height: 540px;
  }
}
@media screen and (max-height: 700px) {
  .showcase .showcase__container {
    min-height: 520px;
  }
}
@media screen and (max-width: 1100px) {
  .showcase .showcase__container {
    min-height: 0;
  }
}
.showcase .showcase__filter {
  background: var(--color-blue-marine);
  background: linear-gradient(0deg, rgb(37, 46, 63) 0%, rgba(255, 255, 255, 0) 70%);
  pointer-events: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 5;
}
@media screen and (max-width: 400px) {
  .showcase .showcase__filter {
    right: 0;
    left: 0;
  }
}
.showcase .showcase__picture {
  background: var(--color-blue-marine);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
}
@media screen and (max-width: 400px) {
  .showcase .showcase__picture {
    right: 0;
    left: 0;
  }
}
.showcase .showcase__img {
  opacity: 0.7;
  transform: scale(1);
  transition: transform 600ms var(--ease-out-quart);
}
.showcase .showcase__container:hover .showcase__img {
  transform: scale(1.1);
}
.showcase .showcase__text-box {
  padding: 80px 60px;
  position: relative;
  z-index: 10;
}
@media screen and (max-width: 1024px) {
  .showcase .showcase__text-box {
    padding: 70px 50px;
  }
}
@media screen and (max-width: 768px) {
  .showcase .showcase__text-box {
    padding: 60px 30px;
  }
}
@media screen and (max-width: 600px) {
  .showcase .showcase__text-box {
    padding: 50px 25px;
  }
}
.showcase .showcase__category {
  font-family: var(--font-family-1);
  font-size: 1.2em;
  font-weight: 700;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: var(--color-base-white);
  padding-top: 30px;
  border-top: 1px solid var(--color-base-white);
  margin-bottom: 150px;
}
@media screen and (max-height: 800px) {
  .showcase .showcase__category {
    margin-bottom: 110px;
  }
}
@media screen and (max-height: 700px) {
  .showcase .showcase__category {
    margin-bottom: 100px;
  }
}
@media screen and (max-width: 1100px) {
  .showcase .showcase__category {
    margin-bottom: 130px;
  }
}
@media screen and (max-width: 1000px) {
  .showcase .showcase__category {
    margin-bottom: 120px;
  }
}
@media screen and (max-width: 900px) {
  .showcase .showcase__category {
    margin-bottom: 110px;
  }
}
@media screen and (max-width: 800px) {
  .showcase .showcase__category {
    margin-bottom: 100px;
  }
}
@media screen and (max-width: 700px) {
  .showcase .showcase__category {
    margin-bottom: 90px;
  }
}
@media screen and (max-width: 600px) {
  .showcase .showcase__category {
    margin-bottom: 80px;
  }
}
@media screen and (max-width: 500px) {
  .showcase .showcase__category {
    margin-bottom: 50px;
  }
}
@media screen and (max-width: 400px) {
  .showcase .showcase__category {
    margin-bottom: 30px;
  }
}
.showcase .showcase__title {
  font-weight: 400;
  color: var(--color-base-white);
  margin-bottom: 35px;
}
.showcase .showcase__credit {
  font-family: var(--font-family-1);
  font-size: 1.4rem;
  font-weight: 700;
  color: var(--color-base-white);
  position: absolute;
  right: 0;
  bottom: -20px;
  padding: 15px;
}
.showcase .showcase__button {
  pointer-events: none;
}
.showcase .showcase__container:hover .showcase__button {
  color: var(--button-color-hover);
}
.showcase .showcase__container:hover .showcase__button::after {
  top: 0;
  border: 1px solid var(--button-border-color-hover);
}
.showcase .showcase__container:hover .showcase__button svg {
  fill: var(--button-color-hover);
}

.results-block .results-block__container {
  width: 100%;
  padding-top: var(--y-spacing-50);
  padding-right: var(--x-spacing-120);
  padding-bottom: var(--y-spacing-120);
  padding-left: var(--x-spacing-120);
}
.results-block .results-block__list-item:last-child .result {
  border-bottom: 1px solid var(--color-grayscale-gray3);
}

.pagination .pagination__container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 25px;
  padding-bottom: var(--y-spacing-120);
  padding-left: 25px;
}
.pagination .pagination__container--jobs {
  padding-bottom: 0;
}
@media screen and (max-width: 500px) {
  .pagination .pagination__container--jobs {
    padding: 20px 25px;
  }
}
@media screen and (max-width: 500px) {
  .pagination .pagination__container {
    justify-content: space-between;
  }
}
.pagination .pagination__prev {
  transform: rotate(90deg);
}
.pagination .pagination__prev:hover .pagination__svg {
  stroke: var(--color-blue-light-blue);
}
.pagination .pagination__next {
  transform: rotate(-90deg);
}
.pagination .pagination__next:hover .pagination__svg {
  stroke: var(--color-blue-light-blue);
}
.pagination .pagination__inactive {
  opacity: 0.5;
  pointer-events: none;
}
.pagination .pagination__svg {
  width: 16px;
  height: 10px;
  stroke: var(--color-blue-marine);
  transition: stroke 300ms;
  stroke-width: 2px;
  fill: none;
}
.pagination .pagination__numbers {
  padding: 0 70px;
  display: flex;
  align-items: center;
}
@media screen and (max-width: 500px) {
  .pagination .pagination__numbers {
    padding: 0 25px;
  }
}
.pagination .pagination__number {
  width: 39px;
  height: 39px;
  border-radius: 50%;
  margin-right: 20px;
  position: relative;
}
.pagination .pagination__number:last-child {
  margin-right: 0;
}
.pagination .pagination__number--active {
  background: var(--color-blue-marine);
}
.pagination .pagination__number--active .pagination__page-link {
  color: var(--color-base-white);
  pointer-events: none;
  cursor: default;
}
@media screen and (max-width: 500px) {
  .pagination .pagination__number {
    width: 35px;
    height: 35px;
    margin-right: 10px;
  }
}
.pagination .pagination__page-link {
  font-family: var(--font-family-1);
  font-size: 1.7em;
  color: var(--color-blue-marine);
  transition: color 300ms;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.pagination .pagination__page-link:hover {
  color: var(--color-blue-light-blue);
}
@media screen and (max-width: 500px) {
  .pagination .pagination__page-link {
    font-size: 1.5em;
  }
}

:root {
  --news-list-gap: 47px;
}
@media screen and (max-width: 1400px) {
  :root {
    --news-list-gap: 40px;
  }
}
@media screen and (max-width: 1300px) {
  :root {
    --news-list-gap: 30px;
  }
}
@media screen and (max-width: 1200px) {
  :root {
    --news-list-gap: 20px;
  }
}
@media screen and (max-width: 950px) {
  :root {
    --news-list-gap: 10px;
  }
}
@media screen and (max-width: 500px) {
  :root {
    --news-list-gap: 0px;
  }
}

.news-list .news-list__container {
  padding-top: var(--y-spacing-100);
  padding-right: var(--x-spacing-160);
  padding-bottom: var(--y-spacing-40);
  padding-left: var(--x-spacing-160);
}
@media screen and (max-height: 800px) {
  .news-list .news-list__container {
    padding-top: var(--y-spacing-80);
  }
}
@media screen and (max-height: 700px) {
  .news-list .news-list__container {
    padding-top: var(--y-spacing-50);
  }
}
.news-list .news-list__content {
  width: calc(100% + var(--news-list-gap) * 2);
  margin-left: calc(0px - var(--news-list-gap));
  margin-right: calc(0px - var(--news-list-gap));
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}

.events-list .events-list__container {
  padding-top: var(--y-spacing-100);
  padding-right: var(--x-spacing-120);
  padding-bottom: var(--y-spacing-40);
  padding-left: var(--x-spacing-120);
}
@media screen and (max-height: 800px) {
  .events-list .events-list__container {
    padding-top: var(--y-spacing-80);
  }
}
@media screen and (max-height: 700px) {
  .events-list .events-list__container {
    padding-top: var(--y-spacing-50);
  }
}
.events-list__date {
  display: flex;
  justify-content: space-between;
  color: var(--color-blue-base);
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 50px;
}
.events-list__date--btn {
  padding-left: 30px;
}
@media (max-width: 500px) {
  .events-list__date--label {
    width: 100%;
  }
  .events-list__date--btn {
    width: 100%;
    padding-left: 0;
    padding-top: 30px;
  }
  .events-list__date--btn .button {
    display: inline-block;
    text-align: center;
    width: 100%;
  }
}

.jobs-list .jobs-list__container {
  padding-right: var(--x-spacing-160);
  padding-bottom: var(--y-spacing-120);
  padding-left: var(--x-spacing-160);
}

.similar-section .similar-section__container {
  padding-right: var(--x-spacing-120);
  padding-left: var(--x-spacing-120);
  padding-bottom: var(--y-spacing-160);
}
.similar-section .similar-section__title {
  font-weight: 500;
  margin-bottom: var(--y-spacing-70);
}

.pagination-mobile {
  width: 100%;
  padding-right: var(--x-spacing-120);
  padding-left: var(--x-spacing-120);
  margin-bottom: var(--y-spacing-160);
  display: none;
}
@media screen and (max-width: 1000px) {
  .pagination-mobile {
    display: block;
  }
}
.pagination-mobile .pagination-mobile__container {
  border-top: 1px solid var(--color-grayscale-gray3);
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media screen and (max-width: 400px) {
  .pagination-mobile .pagination-mobile__container {
    flex-direction: column;
    justify-content: initial;
    align-items: flex-start;
  }
}
.pagination-mobile .pagination-mobile__arrows-box {
  display: flex;
  align-items: center;
  position: relative;
}
.pagination-mobile .pagination-mobile__arrows-box:after {
  content: "";
  width: 2px;
  height: 16px;
  background: var(--color-grayscale-gray3);
  position: absolute;
  top: 50%;
  left: 40px;
  transform: translateY(-50%);
}
.pagination-mobile .pagination-mobile__inactive {
  opacity: 0.3;
  pointer-events: none;
}
.pagination-mobile .pagination-mobile__prev {
  padding-right: 25px;
}
.pagination-mobile .pagination-mobile__next {
  padding-left: 25px;
}
.pagination-mobile .pagination-mobile__prev .pagination-mobile__svg {
  transform: rotate(90deg) translateX(1px);
}
.pagination-mobile .pagination-mobile__next .pagination-mobile__svg {
  transform: rotate(-90deg) translateX(-1px);
}
.pagination-mobile .pagination-mobile__svg {
  width: 16px;
  height: 13px;
  stroke: var(--color-blue-marine);
  stroke-width: 2px;
  fill: none;
  transition: stroke 300ms;
}
.pagination-mobile .pagination-mobile__svg:hover {
  stroke: var(--color-blue-light-blue);
}
@media screen and (max-width: 400px) {
  .pagination-mobile .pagination-mobile__button {
    margin-top: 40px;
  }
}

.infos {
  width: calc(100% - var(--side-menu-section-width));
}
.infos .infos__container {
  padding-left: var(--x-spacing-120);
  padding-right: var(--x-spacing-120);
  padding-top: var(--y-spacing-100);
}
@media screen and (min-width: 1601px) {
  .infos .infos__container {
    padding-right: 120px;
  }
}
.infos .infos__text {
  border-top: solid 1px var(--color-blue-marine);
  padding-top: var(--y-spacing-40);
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.infos .infos__hours, .infos .infos__place, .infos .infos__price {
  font-family: var(--font-family-1);
  font-size: 1.7rem;
  line-height: 1.4;
  color: var(--color-blue-marine);
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}
.infos .infos__link-container {
  display: block;
}
.infos .infos__hours, .infos .infos__place {
  margin-right: 50px;
}
.infos .infos__hours a, .infos .infos__place a {
  color: var(--color-blue-marine);
  text-decoration: none;
  line-height: 1.15;
}
.infos .infos__hours a span, .infos .infos__place a span {
  box-shadow: inset 0 -0.1em 0 0 var(--color-blue-lighter-blue);
  transition: box-shadow 0.3s;
}
.infos .infos__hours a:hover span, .infos .infos__place a:hover span {
  box-shadow: inset 0 -1.1em 0 0 var(--color-blue-lighter-blue);
}
.infos .infos__hours a svg, .infos .infos__place a svg {
  margin-left: 0.25em;
  margin-right: 0.35em;
  width: 0.6470588235em;
  height: 0.6470588235em;
  fill: var(--color-blue-marine);
}
.infos .infos__hours a[href$=".pdf"] svg, .infos .infos__hours a[href$=".doc"] svg, .infos .infos__hours a[href$=".zip"] svg, .infos .infos__place a[href$=".pdf"] svg, .infos .infos__place a[href$=".doc"] svg, .infos .infos__place a[href$=".zip"] svg {
  width: 0.7058823529em;
  height: 0.7058823529em;
}
.infos .infos__svg {
  stroke: var(--color-blue-marine);
  stroke-width: 2px;
  fill: none;
  margin-right: 20px;
  transform: translateY(-2px);
  flex-shrink: 0;
}
.infos .infos__svg--clock {
  width: 17px;
  height: 17px;
}
.infos .infos__svg--marker {
  width: 17px;
  height: 17px;
}
.infos .infos__svg--dollar {
  width: 17px;
  height: 17px;
}

.contact-block {
  display: none;
}
@media screen and (max-width: 1000px) {
  .contact-block {
    display: block;
  }
}
.contact-block .contact-block__container {
  width: 100%;
  padding: var(--y-spacing-80) var(--x-spacing-110);
  background: var(--color-blue-marine);
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 600px) {
  .contact-block .contact-block__container {
    flex-direction: column;
    justify-content: initial;
  }
}
.contact-block .contact-block__left, .contact-block .contact-block__right {
  width: 50%;
}
@media screen and (max-width: 600px) {
  .contact-block .contact-block__left, .contact-block .contact-block__right {
    width: 100%;
  }
}
.contact-block .contact-block__title {
  color: var(--color-base-white);
  margin-bottom: 45px;
}
@media screen and (max-width: 768px) {
  .contact-block .contact-block__title {
    margin-bottom: 30px;
  }
}
.contact-block .contact-block__link, .contact-block .contact-block__address, .contact-block .contact-block__hours {
  color: var(--color-base-white);
  display: flex;
  align-items: center;
}
.contact-block .contact-block__link {
  margin-bottom: 20px;
}
.contact-block .contact-block__address {
  margin-bottom: 55px;
}
@media screen and (max-width: 1024px) {
  .contact-block .contact-block__address {
    margin-bottom: 50px;
  }
}
@media screen and (max-width: 768px) {
  .contact-block .contact-block__address {
    margin-bottom: 30px;
  }
}
.contact-block .contact-block__svg {
  stroke: var(--color-base-white);
  stroke-width: 2px;
  fill: none;
  margin-right: 30px;
}
.contact-block .contact-block__svg--phone {
  width: 19px;
  height: 19px;
}
.contact-block .contact-block__svg--fax {
  width: 18px;
  height: 17px;
}
.contact-block .contact-block__svg--mail {
  width: 18px;
  height: 13px;
}
.contact-block .contact-block__svg--marker {
  width: 14px;
  height: 18px;
  margin-right: 35px;
}
.contact-block .contact-block__svg--clock {
  width: 17px;
  height: 17px;
}
.contact-block .contact-block__button {
  margin-bottom: var(--y-spacing-80);
}

.errors {
  width: 100%;
  padding-top: 100px;
  padding-bottom: 100px;
  display: flex;
  justify-content: center;
}
.errors .errors__container {
  background: var(--color-blue-marine);
  padding: 50px 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media screen and (max-width: 768px) {
  .errors .errors__container {
    width: 100%;
    padding: 50px;
  }
}
@media screen and (max-width: 768px) {
  .errors .errors__container {
    width: 100%;
    padding: 50px 25px;
  }
}
.errors .errors__home {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
}
.errors .errors__link {
  width: 15px;
  height: 15px;
  min-width: 15px;
  min-height: 15px;
  margin-right: 15px;
  transform: translateY(-2px);
}
.errors .errors__svg {
  width: 100%;
  height: 100%;
  fill: var(--color-base-white);
  stroke: none;
  transition: fill 300ms;
}
.errors .errors__svg:hover {
  fill: var(--color-blue-light-blue);
}
.errors .errors__title {
  color: var(--color-base-white);
  text-align: center;
  padding-bottom: 50px;
}
.errors .errors__text {
  color: var(--color-base-white);
}
.errors .errors__btn-home {
  color: var(--color-base-white);
  transition: color 300ms;
  margin-top: 50px;
}
.errors .errors__btn-home:hover {
  color: var(--color-blue-light-blue);
}

:root {
  --alert-mobile-height: 45px;
}

.alert-mobile {
  --left-section-width: 116px;
  --right-section-width: 62px;
}
@media screen and (max-width: 768px) {
  .alert-mobile {
    --left-section-width: 90px;
  }
}
@media screen and (max-width: 700px) {
  .alert-mobile {
    --left-section-width: 37px;
  }
}
@media screen and (max-width: 768px) {
  .alert-mobile {
    --right-section-width: 37px;
  }
}
@media screen and (max-width: 600px) {
  .alert-mobile {
    --right-section-width: 32px;
  }
}

.alert {
  opacity: 0;
  visibility: hidden;
  transition: opacity 300ms, visibility 300ms;
  pointer-events: none;
  position: absolute;
  top: calc(var(--header-height) + 30px);
  right: 40px;
  z-index: 20;
}
@media screen and (max-width: 800px) {
  .alert {
    display: none;
  }
}
.alert--no-alert {
  display: none;
}
.alert .alert__container {
  width: 320px;
  height: auto;
  background: var(--color-red-red);
  padding: 30px;
  position: relative;
}
.alert .alert__x {
  width: 12px;
  height: 12px;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
}
.alert .alert__line {
  width: 1px;
  height: 100%;
  background: var(--color-base-white);
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
}
.alert .alert__line:first-child {
  transform: translate(-50%, -50%) rotate(-45deg);
}
.alert .alert__line:nth-child(2) {
  transform: translate(-50%, -50%) rotate(45deg);
}
.alert .alert__slider-nav {
  display: flex;
  align-items: center;
  margin-bottom: 35px;
}
.alert .alert__svg--warning {
  width: 22px;
  height: 20px;
  stroke: var(--color-base-white);
  stroke-width: 2px;
  fill: none;
  margin-right: 20px;
}
.alert .alert__svg--chevron {
  width: 10px;
  height: 6px;
  stroke: var(--color-base-white);
  stroke-width: 3px;
  fill: none;
}
.alert .alert__slider-number {
  margin-right: 20px;
  transform: translateY(2px);
}
.alert .alert__slider-number .slideNumber .current,
.alert .alert__slider-number .slash,
.alert .alert__slider-number .slideNumber .total {
  font-family: var(--font-family-1);
  font-size: 1.4rem;
  font-weight: 500;
  color: var(--color-base-white);
}
.alert .alert__arrows-box {
  display: flex;
  align-items: center;
}
.alert .alert__arrows-box-arrow-prev {
  width: 15px;
  height: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  transition: transform 400ms var(--ease-out-cubic);
}
.alert .alert__arrows-box-arrow-prev svg {
  transform: rotate(90deg);
}
.alert .alert__arrows-box-arrow-prev:hover {
  transform: translateX(-5px);
}
.alert .alert__arrows-box-arrow-next {
  width: 15px;
  height: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  transition: transform 400ms var(--ease-out-cubic);
}
.alert .alert__arrows-box-arrow-next svg {
  transform: rotate(-90deg);
}
.alert .alert__arrows-box-arrow-next:hover {
  transform: translateX(5px);
}
.alert .alert__slider-slide--link {
  opacity: 1;
  transition: opacity 300ms;
}
.alert .alert__slider-slide--link:hover {
  opacity: 0.5;
}
.alert .alert__date {
  font-family: var(--font-family-1);
  font-size: 1.2rem;
  letter-spacing: 0.1em;
  font-weight: 500;
  color: var(--color-base-white);
  text-transform: uppercase;
  margin-bottom: 10px;
}
.alert .alert__title {
  font-family: var(--font-family-1);
  font-size: 1.7rem;
  font-weight: 500;
  line-height: 1.6em;
  color: var(--color-base-white);
}

.alert-mobile {
  width: 100%;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  position: absolute;
  top: calc(var(--alert-mobile-height) * -1);
  transition: opacity 300ms, visibility 300ms, top 3000ms var(--ease-out-cubic);
  left: 0;
  z-index: 20;
  display: none;
}
@media screen and (max-width: 800px) {
  .alert-mobile {
    display: block;
  }
}
.alert-mobile .alert-mobile__container {
  width: 100%;
  height: var(--alert-mobile-height);
  padding: 5px var(--x-spacing-50);
  background: var(--color-red-red);
  display: flex;
  justify-content: space-between;
}
.alert-mobile .alert-mobile__left-section {
  width: var(--left-section-width);
  padding-right: var(--x-spacing-50);
  border-right: 1px solid rgba(255, 255, 255, 0.5);
}
@media screen and (max-width: 600px) {
  .alert-mobile .alert-mobile__left-section {
    display: none;
  }
}
.alert-mobile .alert-mobile__slider-nav {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}
@media screen and (max-width: 700px) {
  .alert-mobile .alert-mobile__slider-nav {
    display: none;
  }
}
.alert-mobile .alert-mobile__slider-number .slideNumber {
  display: flex;
}
.alert-mobile .alert-mobile__svg--warning {
  width: 22px;
  height: 20px;
  stroke: var(--color-base-white);
  stroke-width: 2px;
  fill: none;
  margin-right: 20px;
}
.alert-mobile .alert-mobile__slider-number {
  transform: translateY(2px);
}
.alert-mobile .alert-mobile__slider-number .slideNumber .current,
.alert-mobile .alert-mobile__slider-number .slash,
.alert-mobile .alert-mobile__slider-number .slideNumber .total {
  font-family: var(--font-family-1);
  font-size: 1.4rem;
  font-weight: 500;
  color: var(--color-base-white);
}
.alert-mobile .alert-mobile__center-section {
  width: calc(100% - var(--left-section-width) - var(--right-section-width) - var(--x-spacing-50) * 2);
  height: 100%;
}
@media screen and (max-width: 600px) {
  .alert-mobile .alert-mobile__center-section {
    width: calc(100% - var(--right-section-width) - var(--x-spacing-50));
  }
}
.alert-mobile .alert-mobile__slider {
  height: 100%;
}
.alert-mobile .alert-mobile__slider .slick-list,
.alert-mobile .alert-mobile__slider .slick-track,
.alert-mobile .alert-mobile__slider .slick-slide {
  height: 100% !important;
}
.alert-mobile .alert-mobile__slider .slick-slide div {
  height: 100% !important;
}
.alert-mobile .alert-mobile__slider-slide {
  width: 100%;
  height: 100%;
  display: flex !important;
  align-items: center;
}
.alert-mobile .alert-mobile__date {
  font-family: var(--font-family-1);
  font-size: 1.1rem;
  font-weight: 500;
  color: var(--color-base-white);
  text-transform: uppercase;
  margin-right: 20px;
  white-space: nowrap;
  transform: translateY(1px);
}
.alert-mobile .alert-mobile__title {
  font-family: var(--font-family-1);
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1em;
  color: var(--color-base-white);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media screen and (max-width: 500px) {
  .alert-mobile .alert-mobile__title {
    font-size: 1.3rem;
  }
}
.alert-mobile .alert-mobile__right-section {
  width: var(--right-section-width);
  height: 100%;
  padding-left: var(--x-spacing-50);
  border-left: 1px solid rgba(255, 255, 255, 0.5);
  position: relative;
}
.alert-mobile .alert-mobile__x {
  width: 12px;
  height: 12px;
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}
.alert-mobile .alert-mobile__line {
  width: 1px;
  height: 100%;
  background: var(--color-base-white);
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
}
.alert-mobile .alert-mobile__line:first-child {
  transform: translate(-50%, -50%) rotate(-45deg);
}
.alert-mobile .alert-mobile__line:nth-child(2) {
  transform: translate(-50%, -50%) rotate(45deg);
}

.no-results {
  color: var(--color-blue-marine);
}
.no-results__sub {
  padding-top: var(--y-spacing-30);
}
.no-results__button {
  margin-top: var(--y-spacing-30);
}
.no-results--search {
  padding-top: 70px;
}
.no-results--events-and-news {
  padding-bottom: 80px;
}

:root {
  --sections-list-gap: 47px;
}
@media screen and (max-width: 1400px) {
  :root {
    --sections-list-gap: 55px;
  }
}
@media screen and (max-width: 1300px) {
  :root {
    --sections-list-gap: 40px;
  }
}
@media screen and (max-width: 1200px) {
  :root {
    --sections-list-gap: 30px;
  }
}
@media screen and (max-width: 950px) {
  :root {
    --sections-list-gap: 20px;
  }
}
@media screen and (max-width: 500px) {
  :root {
    --sections-list-gap: 0px;
  }
}

.sections .sections__container {
  padding-top: var(--y-spacing-100);
  padding-right: var(--x-spacing-160);
  padding-bottom: var(--y-spacing-100);
  padding-left: var(--x-spacing-160);
}
@media screen and (max-height: 800px) {
  .sections .sections__container {
    padding-top: var(--y-spacing-80);
  }
}
@media screen and (max-height: 700px) {
  .sections .sections__container {
    padding-top: var(--y-spacing-50);
  }
}
.sections .sections__list {
  width: calc(100% + var(--sections-list-gap) * 2);
  margin-right: calc(0px - var(--sections-list-gap));
  margin-bottom: calc(0px - var(--y-spacing-130));
  margin-left: calc(0px - var(--sections-list-gap));
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}
.sections .sections__list-item {
  width: 50%;
  padding: 0 var(--sections-list-gap);
  margin-bottom: var(--y-spacing-130);
}
@media screen and (max-width: 1024px) {
  .sections .sections__list-item {
    margin-bottom: var(--y-spacing-110);
  }
}
@media screen and (max-width: 900px) {
  .sections .sections__list-item {
    margin-bottom: var(--y-spacing-80);
  }
}
@media screen and (max-width: 768px) {
  .sections .sections__list-item {
    margin-bottom: var(--y-spacing-70);
  }
}
@media screen and (max-width: 768px) {
  .sections .sections__list-item {
    width: 100%;
  }
}
@media screen and (max-width: 600px) {
  .sections .sections__list-item {
    margin-bottom: var(--y-spacing-60);
  }
}
.sections .sections__list-link {
  width: 100%;
  display: block;
}
.sections .sections__picture-container {
  width: 100%;
}
.sections .sections__picture {
  width: 100%;
  padding-top: 51%;
  position: relative;
  overflow: hidden;
}
.sections .sections__img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: scale(1);
  transition: transform 600ms var(--ease-out-quart);
}
.sections .sections__list-item:hover .sections__img {
  transform: scale(1.1);
}
.sections .sections__text-block {
  width: 100%;
  padding-top: 40px;
  padding-right: 25px;
}
.sections .sections__text {
  font-family: var(--font-family-2);
  font-size: 3.5rem;
  font-weight: 500;
  line-height: 1.25em;
  color: var(--color-blue-marine);
}
@media screen and (max-width: 1024px) {
  .sections .sections__text {
    font-size: 3.2rem;
  }
}
@media screen and (max-width: 900px) {
  .sections .sections__text {
    font-size: 3rem;
  }
}
@media screen and (max-width: 768px) {
  .sections .sections__text {
    font-size: 2.8rem;
  }
}
@media screen and (max-width: 600px) {
  .sections .sections__text {
    font-size: 2.6rem;
  }
}
.sections .sections__list-item:hover .sections__text {
  color: var(--color-blue-blue);
}

:root {
  --overlay-fastlinks-width: 500px;
}
@media screen and (max-width: 768px) {
  :root {
    --overlay-fastlinks-width: 446px ;
  }
}

.overlay-fastlinks {
  --close-button-dimension: 56px;
}
@media (max-width: 1200px) {
  .overlay-fastlinks {
    --close-button-dimension: 52px;
  }
}
@media (max-width: 1024px) {
  .overlay-fastlinks {
    --close-button-dimension: 48px;
  }
}
@media (max-width: 600px) {
  .overlay-fastlinks {
    --close-button-dimension: 44px;
  }
}

.overlay-fastlinks {
  pointer-events: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 30;
  overflow-x: hidden;
}
@media screen and (max-width: 700px) {
  .overlay-fastlinks {
    position: fixed;
  }
}
.overlay-fastlinks .overlay-fastlinks__background {
  background: rgba(0, 0, 0, 0.3);
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  position: absolute;
  top: 0;
  right: var(--overlay-fastlinks-width);
  bottom: 0;
  left: 0;
  z-index: -1;
}
@media screen and (max-width: 500px) {
  .overlay-fastlinks .overlay-fastlinks__background {
    display: none;
  }
}
.overlay-fastlinks .overlay-fastlinks__container {
  width: var(--overlay-fastlinks-width);
  height: calc(var(--vh, 1vh) * 100 - var(--header-height));
  padding: var(--y-spacing-80) var(--x-spacing-60) var(--y-spacing-50);
  background: var(--color-base-white);
  box-shadow: inset 0px 10px 15px -10px rgba(0, 0, 0, 0.23);
  -webkit-box-shadow: inset 0px 10px 15px -10px rgba(0, 0, 0, 0.23);
  -moz-box-shadow: inset 0px 10px 15px -10px rgba(0, 0, 0, 0.23);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  visibility: hidden;
  pointer-events: none;
  position: absolute;
  top: var(--header-height);
  right: calc(var(--overlay-fastlinks-width) * -1);
  transition: right 600ms var(--ease-out-cubic), visibility 600ms;
  overflow-y: auto;
  overflow-x: hidden;
}
.overlay-fastlinks .overlay-fastlinks__container::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 5px;
  height: 5px;
  background-color: transparent;
}
.overlay-fastlinks .overlay-fastlinks__container::-webkit-scrollbar-thumb {
  background-color: var(--color-grayscale-gray4);
  border-radius: 5px;
}
@media screen and (max-width: 700px) {
  .overlay-fastlinks .overlay-fastlinks__container {
    height: calc(var(--vh, 1vh) * 100);
    position: fixed;
    top: 0;
  }
}
@media screen and (max-width: 500px) {
  .overlay-fastlinks .overlay-fastlinks__container {
    width: 100%;
    right: auto;
    left: 100%;
    transition: left 600ms var(--ease-out-cubic), visibility 600ms;
  }
}
.overlay-fastlinks .overlay-fastlinks__list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  opacity: 0;
  transform: translateX(50px);
  transition: opacity 800ms, transform 800ms var(--ease-out-quart);
}
@media screen and (max-width: 600px) {
  .overlay-fastlinks .overlay-fastlinks__list {
    padding-top: 30px;
  }
}
.overlay-fastlinks .overlay-fastlinks__list-item {
  display: flex;
  align-items: flex-start;
  margin-bottom: 35px;
}
.overlay-fastlinks .overlay-fastlinks__list-item:last-child {
  margin-bottom: 45px;
}
@media screen and (max-width: 1024px) {
  .overlay-fastlinks .overlay-fastlinks__list-item {
    margin-bottom: 25px;
  }
}
@media screen and (max-width: 768px) {
  .overlay-fastlinks .overlay-fastlinks__list-item {
    margin-bottom: 15px;
  }
}
.overlay-fastlinks .overlay-fastlinks__link {
  font-family: var(--font-family-2);
  font-size: var(--small-title-font-size);
  font-weight: 500;
  line-height: 1.4em;
  color: var(--color-grayscale-gray10);
  transition: color 300ms;
  display: flex;
  align-items: flex-start;
}
.overlay-fastlinks .overlay-fastlinks__link:hover {
  color: var(--color-blue-marine);
}
@media screen and (max-width: 700px) {
  .overlay-fastlinks .overlay-fastlinks__link {
    color: var(--color-blue-marine);
  }
}
.overlay-fastlinks .overlay-fastlinks__svg {
  stroke: var(--color-blue-marine);
  stroke-width: 4.5px;
  fill: none;
  margin-right: 35px;
  transform: translateY(calc(15px - 50%));
  width: 22px;
  height: 22px;
  min-width: 22px;
  min-height: 22px;
}
@media screen and (max-width: 768px) {
  .overlay-fastlinks .overlay-fastlinks__svg {
    transform: translateY(calc(13px - 50%));
  }
}
@media screen and (max-width: 425px) {
  .overlay-fastlinks .overlay-fastlinks__svg {
    transform: translateY(calc(12px - 50%));
  }
}
.overlay-fastlinks .overlay-fastlinks__close {
  height: 12px;
  font-family: var(--font-family-1);
  font-size: 1.1rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  line-height: 15px;
  color: var(--color-blue-marine);
  opacity: 1;
  transition: opacity 300ms;
  cursor: pointer;
  padding-right: 35px;
  position: relative;
}
.overlay-fastlinks .overlay-fastlinks__close::after, .overlay-fastlinks .overlay-fastlinks__close::before {
  content: "";
  width: 14px;
  height: 1px;
  background: var(--color-blue-marine);
  opacity: 1;
  transition: opacity 300ms;
  position: absolute;
  top: 50%;
  right: 0;
}
.overlay-fastlinks .overlay-fastlinks__close::after {
  transform: translateY(-50%) rotate(-45deg);
}
.overlay-fastlinks .overlay-fastlinks__close::before {
  transform: translateY(-50%) rotate(45deg);
}
.overlay-fastlinks .overlay-fastlinks__close:hover {
  opacity: 0.5;
}
.overlay-fastlinks .overlay-fastlinks__close:hover::after, .overlay-fastlinks .overlay-fastlinks__close:hover::before {
  opacity: 0.5;
}
@media screen and (max-width: 700px) {
  .overlay-fastlinks .overlay-fastlinks__close {
    display: none;
  }
}
.overlay-fastlinks .overlay-fastlinks__close-mobile {
  width: var(--close-button-dimension);
  height: var(--close-button-dimension);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: calc(var(--vh, 1vh) * 100 - var(--close-button-dimension) - 20px);
  right: 20px;
  visibility: hidden;
  pointer-events: none;
}
.overlay-fastlinks .overlay-fastlinks__close-mobile:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--color-base-white);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  transition: background-color 300ms;
}
.overlay-fastlinks .overlay-fastlinks__close-mobile:hover:before {
  background-color: var(--color-blue-light-blue);
}
@media screen and (min-width: 700px) {
  .overlay-fastlinks .overlay-fastlinks__close-mobile {
    display: none;
  }
}
@media screen and (max-width: 600px) {
  .overlay-fastlinks .overlay-fastlinks__close-mobile {
    top: calc(var(--vh, 1vh) * 100 - var(--close-button-dimension) - 15px);
    right: 15px;
  }
}
.overlay-fastlinks .overlay-fastlinks__plus {
  width: 16px;
  height: 16px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: transform 300ms;
}
.overlay-fastlinks .overlay-fastlinks__plus::before, .overlay-fastlinks .overlay-fastlinks__plus::after {
  content: "";
  width: 16px;
  height: 2px;
  background: var(--color-blue-light-blue);
  transition: background 300ms;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.overlay-fastlinks .overlay-fastlinks__plus::after {
  transform: translate(-50%, -50%) rotate(90deg);
}

.show-overlay-fastlinks .header .header__box:first-child:before {
  opacity: 1;
  visibility: visible;
}
.show-overlay-fastlinks .header .header__box-text:first-child span:first-child {
  opacity: 0;
}
.show-overlay-fastlinks .header .header__box-text:first-child span:not(:first-child) {
  opacity: 1;
}
.show-overlay-fastlinks .header .header__plus {
  transform: translateY(-3px) rotate(45deg);
}
.show-overlay-fastlinks .overlay-fastlinks .overlay-fastlinks__close-mobile {
  visibility: visible;
  pointer-events: all;
  transition: visibility 300ms;
}
.show-overlay-fastlinks .overlay-fastlinks .overlay-fastlinks__plus {
  transform: translate(-50%, -50%) rotate(45deg);
}
.show-overlay-fastlinks .overlay-fastlinks .overlay-fastlinks__background {
  opacity: 1;
  visibility: visible;
  transition: opacity 300ms 450ms, visibility 300ms 450ms;
  pointer-events: all;
}
.show-overlay-fastlinks .overlay-fastlinks .overlay-fastlinks__container {
  visibility: visible;
  right: 0;
  pointer-events: all;
}
@media screen and (max-width: 500px) {
  .show-overlay-fastlinks .overlay-fastlinks .overlay-fastlinks__container {
    right: auto;
    left: 0;
  }
}
.show-overlay-fastlinks .overlay-fastlinks .overlay-fastlinks__list {
  opacity: 1;
  transform: translateX(0);
}

.show-overlay-fastlinks.closing-overlay-fastlinks .header .header__box:first-child:before {
  opacity: 0;
  visibility: hidden;
}
.show-overlay-fastlinks.closing-overlay-fastlinks .header .header__box-text:first-child span:first-child {
  opacity: 1;
}
.show-overlay-fastlinks.closing-overlay-fastlinks .header .header__box-text:first-child span:not(:first-child) {
  opacity: 0;
}
.show-overlay-fastlinks.closing-overlay-fastlinks .header .header__plus {
  transform: translateY(-3px) rotate(0deg);
}
.show-overlay-fastlinks.closing-overlay-fastlinks .overlay-fastlinks .overlay-fastlinks__close-mobile {
  visibility: hidden;
  pointer-events: none;
}
.show-overlay-fastlinks.closing-overlay-fastlinks .overlay-fastlinks .overlay-fastlinks__plus {
  transform: translate(-50%, -50%);
}
.show-overlay-fastlinks.closing-overlay-fastlinks .overlay-fastlinks .overlay-fastlinks__background {
  opacity: 0;
  visibility: hidden;
  transition: opacity 200ms, visibility 200ms;
  pointer-events: none;
}
.show-overlay-fastlinks.closing-overlay-fastlinks .overlay-fastlinks .overlay-fastlinks__container {
  visibility: hidden;
  right: calc(var(--overlay-fastlinks-width) * -1);
  pointer-events: none;
}
@media screen and (max-width: 500px) {
  .show-overlay-fastlinks.closing-overlay-fastlinks .overlay-fastlinks .overlay-fastlinks__container {
    right: auto;
    left: 100%;
  }
}
.show-overlay-fastlinks.closing-overlay-fastlinks .overlay-fastlinks .overlay-fastlinks__list {
  opacity: 0;
  transform: translateX(50px);
}

@media screen and (max-width: 800px) {
  .show-alerts .overlay-fastlinks .overlay-fastlinks__container {
    height: calc(var(--vh, 1vh) * 100 - var(--header-height) - var(--alert-mobile-height));
    top: calc(var(--header-height) + var(--alert-mobile-height));
  }
}
@media screen and (max-width: 800px) and (max-width: 700px) {
  .show-alerts .overlay-fastlinks .overlay-fastlinks__container {
    height: calc(var(--vh, 1vh) * 100);
    top: 0;
  }
}
@media screen and (max-width: 800px) {
  .show-alerts .overlay-fastlinks .overlay-fastlinks__background {
    top: var(--alert-mobile-height);
  }
}

.overlay-search {
  pointer-events: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 30;
}
.overlay-search .overlay-search__background {
  background: rgba(0, 0, 0, 0.3);
  opacity: 0;
  visibility: hidden;
  transition: opacity 300ms, visibility 300ms;
  pointer-events: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
}
.overlay-search .overlay-search__container {
  height: 0;
  background: var(--color-base-white);
  visibility: hidden;
  pointer-events: none;
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  transition: height 600ms var(--ease-out-cubic), visibility 600ms;
}
.overlay-search .overlay-search__content {
  width: 100%;
  height: calc(100% - 85px);
  padding-top: calc(var(--y-spacing-170) - 85px);
  padding-right: var(--x-spacing-200);
  padding-bottom: var(--y-spacing-110);
  padding-left: var(--x-spacing-200);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow-y: scroll;
  scrollbar-width: none;
}
.overlay-search .overlay-search__content::-webkit-scrollbar {
  display: none;
}
.overlay-search .overlay-search__button-container {
  width: 100%;
  height: 85px;
  opacity: 0;
  position: relative;
  transform: translateY(-50px);
  transition: opacity 300ms, transform 300ms var(--ease-out-quart);
}
.overlay-search .overlay-search__close {
  height: 12px;
  font-family: var(--font-family-1);
  font-size: 1.1rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  line-height: 15px;
  color: var(--color-blue-marine);
  opacity: 1;
  transition: opacity 300ms;
  cursor: pointer;
  padding-right: 35px;
  position: absolute;
  right: var(--x-spacing-50);
  bottom: 25px;
}
.overlay-search .overlay-search__close::after, .overlay-search .overlay-search__close::before {
  content: "";
  width: 14px;
  height: 1px;
  background: var(--color-blue-marine);
  opacity: 1;
  transition: opacity 300ms;
  position: absolute;
  top: 50%;
  right: 0;
}
.overlay-search .overlay-search__close::after {
  transform: translateY(-50%) rotate(-45deg);
}
.overlay-search .overlay-search__close::before {
  transform: translateY(-50%) rotate(45deg);
}
.overlay-search .overlay-search__close:hover {
  opacity: 0.5;
}
.overlay-search .overlay-search__close:hover::after, .overlay-search .overlay-search__close:hover::before {
  opacity: 0.5;
}
.overlay-search .overlay-search__search-form {
  width: 100%;
  opacity: 0;
  transform: translateY(-50px);
  margin-bottom: var(--y-spacing-70);
  transition: opacity 300ms, transform 300ms var(--ease-out-quart);
}
.overlay-search .overlay-search__search-form .search-field__input {
  color: var(--color-blue-marine);
}
.overlay-search .overlay-search__search-form .search-field__input::placeholder {
  color: var(--color-blue-marine);
  opacity: 0.5;
}
.overlay-search .overlay-search__search-form .search-field__x {
  background: var(--color-grayscale-gray1);
}
.overlay-search .overlay-search__search-form .search-field__x:before, .overlay-search .overlay-search__search-form .search-field__x:after {
  background: var(--color-blue-marine);
  transition: background 300ms;
}
.overlay-search .overlay-search__search-form .search-field__x:hover {
  background: var(--color-blue-light-blue);
}
.overlay-search .overlay-search__search-form .search-field__x:hover:before, .overlay-search .overlay-search__search-form .search-field__x:hover:after {
  background: var(--color-base-white);
}
.overlay-search .overlay-search__search-form .search-field__button {
  background: var(--color-blue-darker-marine);
}
.overlay-search .overlay-search__search-form .search-field__button:hover {
  background: var(--color-blue-light-blue);
}
.overlay-search .overlay-search__search-form :-webkit-autofill,
.overlay-search .overlay-search__search-form :-webkit-autofill:hover,
.overlay-search .overlay-search__search-form :-webkit-autofill:focus,
.overlay-search .overlay-search__search-form :-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 100px var(--color-base-white) inset !important;
  -webkit-text-fill-color: var(--color-blue-marine);
}
.overlay-search .overlay-search__list {
  padding-right: 25px;
  padding-bottom: var(--y-spacing-60);
  opacity: 0;
  transform: translateY(-50px);
  transition: opacity 300ms, transform 300ms var(--ease-out-quart);
}
.overlay-search .overlay-search__list-item {
  margin-bottom: 20px;
}
.overlay-search .overlay-search__list-item:last-child {
  margin-bottom: 0;
}
.overlay-search .overlay-search__link {
  font-family: var(--font-family-1);
  font-size: 2.2rem;
  line-height: 1.4;
  color: var(--color-blue-marine);
  transition: color 300ms;
}
.overlay-search .overlay-search__link mark {
  transition: color 300ms;
}
.overlay-search .overlay-search__link:hover {
  color: var(--color-grayscale-gray11);
}
.overlay-search .overlay-search__link:hover mark {
  color: var(--color-grayscale-gray11);
}
@media screen and (max-width: 768px) {
  .overlay-search .overlay-search__link {
    font-size: 2.1rem;
  }
}
@media screen and (max-width: 600px) {
  .overlay-search .overlay-search__link {
    font-size: 2rem;
  }
}
@media screen and (max-width: 500px) {
  .overlay-search .overlay-search__link {
    font-size: 1.9rem;
  }
}
@media screen and (max-width: 400px) {
  .overlay-search .overlay-search__link {
    font-size: 1.8rem;
  }
}
.overlay-search .overlay-search__button {
  opacity: 0;
  transform: translateY(-50px);
  transition: opacity 300ms, transform 300ms var(--ease-out-quart);
}

.show-overlay-search .overlay-search .overlay-search__background {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
}
.show-overlay-search .overlay-search .overlay-search__container {
  height: 768px;
  visibility: visible;
  pointer-events: all;
}
@media screen and (max-width: 600px) {
  .show-overlay-search .overlay-search .overlay-search__container {
    height: calc(var(--vh, 1vh) * 100);
  }
}
@media screen and (max-height: 768px) {
  .show-overlay-search .overlay-search .overlay-search__container {
    height: calc(var(--vh, 1vh) * 100);
  }
}
@media screen and (max-width: 600px) and (max-height: 600px) {
  .show-overlay-search .overlay-search .overlay-search__container {
    height: calc(var(--vh, 1vh) * 100);
  }
}
@media screen and (max-width: 600px) and (max-height: 768px) {
  .show-overlay-search .overlay-search .overlay-search__container {
    height: calc(var(--vh, 1vh) * 100);
  }
}
.show-overlay-search .overlay-search .overlay-search__button-container {
  opacity: 1;
  transform: translateY(0);
}
.show-overlay-search .overlay-search .overlay-search__search-form {
  opacity: 1;
  transform: translateY(0);
}
.show-overlay-search .overlay-search .overlay-search__list {
  opacity: 1;
  transform: translateY(0);
}
.show-overlay-search .overlay-search .overlay-search__button {
  opacity: 1;
  transform: translateY(0);
}

.show-overlay-search.closing-overlay-search .overlay-search .overlay-search__background {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}
.show-overlay-search.closing-overlay-search .overlay-search .overlay-search__container {
  height: 0;
  visibility: hidden;
  pointer-events: none;
}
.show-overlay-search.closing-overlay-search .overlay-search .overlay-search__button-container {
  opacity: 0;
  transform: translateY(-50px);
}
.show-overlay-search.closing-overlay-search .overlay-search .overlay-search__search-form {
  opacity: 0;
  transform: translateY(-50px);
}
.show-overlay-search.closing-overlay-search .overlay-search .overlay-search__list {
  opacity: 0;
  transform: translateY(-50px);
}
.show-overlay-search.closing-overlay-search .overlay-search .overlay-search__button {
  opacity: 0;
  transform: translateY(-50px);
}

.overlay-menu {
  --left-section-width: 440px;
  --image-width-mobile: 440px;
}
@media screen and (max-width: 900px) {
  .overlay-menu {
    --image-width-mobile: 420px;
  }
}
@media screen and (max-width: 800px) {
  .overlay-menu {
    --image-width-mobile: 400px;
  }
}
@media screen and (max-width: 700px) {
  .overlay-menu {
    --image-width-mobile: 380px;
  }
}

.overlay-menu {
  pointer-events: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 30;
}
.overlay-menu .overlay-menu__background {
  background: transparent;
  pointer-events: none;
  position: absolute;
  top: 0;
  right: 500px;
  bottom: 0;
  left: 0;
  z-index: -1;
}
.overlay-menu .overlay-menu__container {
  height: 0;
  background: var(--color-blue-marine);
  transition: height 600ms var(--ease-out-cubic);
  pointer-events: none;
  display: flex;
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}
@media screen and (max-width: 1000px) {
  .overlay-menu .overlay-menu__container {
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: none;
  }
  .overlay-menu .overlay-menu__container::-webkit-scrollbar {
    display: none;
  }
}
.overlay-menu .overlay-menu__close {
  height: 95px;
  background: var(--color-blue-darker-marine);
  padding: var(--x-spacing-40);
  padding-right: calc(var(--x-spacing-40) * 2);
  cursor: pointer;
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 5;
}
.overlay-menu .overlay-menu__close::after, .overlay-menu .overlay-menu__close::before {
  content: "";
  width: 14px;
  height: 1px;
  background: var(--color-base-white);
  opacity: 1;
  transition: opacity 300ms;
  position: absolute;
  top: 48%;
  right: var(--x-spacing-40);
}
.overlay-menu .overlay-menu__close::after {
  transform: translateY(-50%) rotate(-45deg);
}
.overlay-menu .overlay-menu__close::before {
  transform: translateY(-50%) rotate(45deg);
}
.overlay-menu .overlay-menu__close:hover::after, .overlay-menu .overlay-menu__close:hover::before {
  opacity: 0.5;
}
.overlay-menu .overlay-menu__close-text {
  font-family: var(--font-family-1);
  font-size: 1.1rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  line-height: 12px;
  color: var(--color-base-white);
  transition: color 300ms;
}
.overlay-menu .overlay-menu__close:hover .overlay-menu__close-text {
  color: rgba(255, 255, 255, 0.5);
}
.overlay-menu .overlay-menu__left {
  height: 100vh;
  width: var(--left-section-width);
}
@media screen and (max-width: 1000px) {
  .overlay-menu .overlay-menu__left {
    width: 100%;
    display: flex;
    order: 2;
  }
}
@media screen and (max-width: 600px) {
  .overlay-menu .overlay-menu__left {
    flex-direction: column;
  }
}
.overlay-menu .overlay-menu__text-box {
  min-height: 50%;
  background: var(--color-base-white);
  padding: 25px var(--x-spacing-60);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
@media screen and (max-width: 1000px) {
  .overlay-menu .overlay-menu__text-box {
    width: calc(100% - var(--image-width-mobile));
    height: auto;
    order: 1;
  }
}
@media screen and (max-width: 600px) {
  .overlay-menu .overlay-menu__text-box {
    width: 100%;
  }
}
.overlay-menu .overlay-menu__surtitle {
  font-family: var(--font-family-1);
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.6;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: var(--color-blue-light-blue);
  margin-bottom: 25px;
}
@media screen and (max-width: 500px) {
  .overlay-menu .overlay-menu__surtitle {
    margin-bottom: 20px;
  }
}
.overlay-menu .overlay-menu__title {
  font-family: var(--font-family-1);
  font-size: var(--medium-title-font-size);
  line-height: 1.3em;
  color: var(--color-blue-marine);
  margin-bottom: var(--y-spacing-40);
}
.overlay-menu .overlay-menu__link {
  font-family: var(--font-family-1);
  font-size: 1.1em;
  font-weight: 700;
  letter-spacing: 0.1em;
  color: var(--color-blue-marine);
  text-transform: uppercase;
  text-decoration: none;
  transition: color 300ms, box-shadow 0.3s, transform 800ms var(--ease-out-cubic);
  padding-bottom: 6px;
  display: block;
  position: relative;
}
.overlay-menu .overlay-menu__link::after {
  content: "";
  width: 100%;
  transition: width 300ms;
  height: 2px;
  background: var(--color-blue-light-blue);
  position: absolute;
  bottom: 0;
  left: 0;
}
.overlay-menu .overlay-menu__left:hover .overlay-menu__link {
  color: var(--color-blue-light-blue);
}
.overlay-menu .overlay-menu__left:hover .overlay-menu__link::after {
  width: 75%;
}
.overlay-menu .overlay-menu__picture-container {
  width: 100%;
  height: 50%;
}
@media screen and (max-width: 1000px) {
  .overlay-menu .overlay-menu__picture-container {
    width: var(--image-width-mobile);
    height: auto;
  }
}
@media screen and (max-width: 600px) {
  .overlay-menu .overlay-menu__picture-container {
    width: 100%;
    order: 2;
  }
}
.overlay-menu .overlay-menu__picture {
  padding-top: 102.5%;
  min-height: 100%;
  position: relative;
  overflow: hidden;
}
.overlay-menu .overlay-menu__img {
  transform: scale(1);
  transition: transform 600ms var(--ease-out-quart);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.overlay-menu .overlay-menu__left:hover .overlay-menu__img {
  transform: scale(1.1);
}
.overlay-menu .overlay-menu__right {
  width: calc(100% - var(--left-section-width));
  padding-top: var(--y-spacing-130);
  padding-right: var(--x-spacing-120);
  padding-bottom: var(--y-spacing-80);
  padding-left: var(--x-spacing-120);
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 800ms, transform 800ms var(--ease-out-quart);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: none;
}
.overlay-menu .overlay-menu__right::-webkit-scrollbar {
  display: none;
}
@media screen and (max-width: 1000px) {
  .overlay-menu .overlay-menu__right {
    width: 100%;
    overflow: initial;
  }
}
@media screen and (max-width: 768px) {
  .overlay-menu .overlay-menu__right {
    padding-top: 100px;
  }
}
.overlay-menu .overlay-menu__main-level-li {
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}
.overlay-menu .overlay-menu__main-level-button {
  min-height: 140px;
  padding: var(--y-spacing-40) var(--x-spacing-30);
  padding-right: 55px;
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
}
@media screen and (max-width: 700px) {
  .overlay-menu .overlay-menu__main-level-button {
    min-height: 0px;
  }
}
@media screen and (max-width: 400px) {
  .overlay-menu .overlay-menu__main-level-button {
    padding-left: 0;
  }
}
.overlay-menu .overlay-menu__main-level-text {
  display: flex;
}
.overlay-menu .overlay-menu__main-level-number {
  font-family: var(--font-family-1);
  font-size: 1.4rem;
  font-weight: 700;
  color: var(--color-base-white);
  transition: color 300ms;
  margin-right: var(--x-spacing-40);
  align-self: flex-start;
  transform: translateY(20px);
}
@media screen and (max-width: 1024px) {
  .overlay-menu .overlay-menu__main-level-number {
    transform: translateY(16px);
  }
}
@media screen and (max-width: 768px) {
  .overlay-menu .overlay-menu__main-level-number {
    transform: translateY(15px);
  }
}
@media screen and (max-width: 600px) {
  .overlay-menu .overlay-menu__main-level-number {
    transform: translateY(13px);
  }
}
@media screen and (max-width: 500px) {
  .overlay-menu .overlay-menu__main-level-number {
    transform: translateY(12px);
  }
}
@media screen and (max-width: 440px) {
  .overlay-menu .overlay-menu__main-level-number {
    transform: translateY(11px);
  }
}
@media screen and (max-width: 400px) {
  .overlay-menu .overlay-menu__main-level-number {
    transform: translateY(10px);
  }
}
@media screen and (max-width: 320px) {
  .overlay-menu .overlay-menu__main-level-number {
    transform: translateY(9px);
  }
}
.overlay-menu .overlay-menu__main-level-li:hover .overlay-menu__main-level-number {
  color: var(--color-blue-light-blue);
}
.overlay-menu .overlay-menu__main-level-title {
  font-family: var(--font-family-2);
  font-size: 5rem;
  font-weight: 300;
  line-height: 1.1em;
  color: var(--color-base-white);
  transition: color 300ms;
  align-self: center;
}
@media screen and (max-height: 700px) {
  .overlay-menu .overlay-menu__main-level-title {
    font-size: 4.8rem;
  }
}
@media screen and (max-width: 1024px) {
  .overlay-menu .overlay-menu__main-level-title {
    font-size: 4.2rem;
  }
}
@media screen and (max-width: 768px) {
  .overlay-menu .overlay-menu__main-level-title {
    font-size: 4rem;
  }
}
@media screen and (max-width: 600px) {
  .overlay-menu .overlay-menu__main-level-title {
    font-size: 3.8rem;
  }
}
@media screen and (max-width: 500px) {
  .overlay-menu .overlay-menu__main-level-title {
    font-size: 3.6rem;
  }
}
@media screen and (max-width: 440px) {
  .overlay-menu .overlay-menu__main-level-title {
    font-size: 3.4rem;
  }
}
@media screen and (max-width: 400px) {
  .overlay-menu .overlay-menu__main-level-title {
    font-size: 3.2rem;
  }
}
@media screen and (max-width: 320px) {
  .overlay-menu .overlay-menu__main-level-title {
    font-size: 3rem;
  }
}
.overlay-menu .overlay-menu__main-level-li:hover .overlay-menu__main-level-title {
  color: var(--color-blue-light-blue);
}
.overlay-menu .overlay-menu__main-level-plus {
  width: 16px;
  height: 16px;
  position: absolute;
  top: 50%;
  right: var(--x-spacing-30);
  transform: translateY(-50%);
}
.overlay-menu .overlay-menu__main-level-plus::before, .overlay-menu .overlay-menu__main-level-plus::after {
  content: "";
  width: 16px;
  height: 2px;
  background: var(--color-base-white);
  transition: background 300ms;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.overlay-menu .overlay-menu__main-level-plus::after {
  transform: translate(-50%, -50%) rotate(90deg);
  transition: opacity 300ms;
}
.overlay-menu .overlay-menu__main-level-li:hover .overlay-menu__main-level-plus::before, .overlay-menu .overlay-menu__main-level-li:hover .overlay-menu__main-level-plus::after {
  background: var(--color-blue-light-blue);
}
.overlay-menu .overlay-menu__second-level {
  height: 0;
  opacity: 0;
  transition: opacity 700ms var(--ease-out-quart);
  overflow: hidden;
}
.overlay-menu .overlay-menu__second-level-li {
  padding-bottom: 20px;
}
.overlay-menu .overlay-menu__second-level-li:last-child {
  padding-bottom: var(--y-spacing-60);
}
.overlay-menu .overlay-menu__second-level-button {
  font-family: var(--font-family-1);
  font-size: 3rem;
  font-weight: 700;
  line-height: 1.3em;
  color: var(--color-base-white);
  transition: color 300ms;
}
.overlay-menu .overlay-menu__second-level-button:hover {
  color: var(--color-blue-light-blue3);
}
@media screen and (max-height: 700px) {
  .overlay-menu .overlay-menu__second-level-button {
    font-size: 2.8rem;
  }
}
@media screen and (max-width: 768px) {
  .overlay-menu .overlay-menu__second-level-button {
    font-size: 2.6rem;
  }
}
@media screen and (max-width: 425px) {
  .overlay-menu .overlay-menu__second-level-button {
    font-size: 2.4rem;
  }
}
.overlay-menu .overlay-menu__third-level {
  height: 0;
  opacity: 0;
  margin-top: 0;
  transition: opacity 700ms var(--ease-out-quart), margin 700ms var(--ease-out-quart);
  overflow: hidden;
}
.overlay-menu .overlay-menu__third-level-li {
  padding-bottom: 15px;
}
.overlay-menu .overlay-menu__third-level-li:last-child {
  padding-bottom: var(--y-spacing-40);
}
.overlay-menu .overlay-menu__third-level-link {
  font-family: var(--font-family-1);
  font-size: 2rem;
  line-height: 1.4;
  color: var(--color-base-white);
  position: relative;
  transition: color 300ms;
}
.overlay-menu .overlay-menu__third-level-link:hover {
  color: var(--color-blue-light-blue);
}
@media screen and (max-height: 700px) {
  .overlay-menu .overlay-menu__third-level-link {
    font-size: 1.8rem;
  }
}
.overlay-menu .overlay-menu__contact-box {
  display: flex;
  align-items: flex-end;
  margin-top: var(--y-spacing-70);
}
@media screen and (max-width: 500px) {
  .overlay-menu .overlay-menu__contact-box {
    flex-direction: column;
    align-items: flex-start;
  }
}
.overlay-menu .overlay-menu__phone-and-email {
  margin-right: var(--x-spacing-70);
}
@media screen and (max-width: 500px) {
  .overlay-menu .overlay-menu__phone-and-email {
    margin-right: 0;
    margin-bottom: 30px;
  }
}
.overlay-menu .overlay-menu__phone, .overlay-menu .overlay-menu__email {
  font-family: var(--font-family-1);
  font-size: 1.7rem;
  color: var(--color-base-white);
  display: block;
}
.overlay-menu .overlay-menu__phone {
  margin-bottom: 15px;
  transition: color 300ms;
}
.overlay-menu .overlay-menu__phone:hover {
  color: var(--color-blue-light-blue);
}
.overlay-menu .overlay-menu__email {
  transition: color 300ms;
}
.overlay-menu .overlay-menu__email:hover {
  color: var(--color-blue-light-blue);
}

.show-overlay-menu .overlay-menu .overlay-menu__background {
  pointer-events: all;
}
.show-overlay-menu .overlay-menu .overlay-menu__container {
  height: calc(var(--vh, 1vh) * 100);
  pointer-events: all;
}
.show-overlay-menu .overlay-menu .overlay-menu__right {
  opacity: 1;
  transform: translateY(0);
}

.show-overlay-menu.closing-overlay-menu .overlay-menu .overlay-menu__background {
  pointer-events: none;
}
.show-overlay-menu.closing-overlay-menu .overlay-menu .overlay-menu__container {
  height: 0;
  pointer-events: none;
}
.show-overlay-menu.closing-overlay-menu .overlay-menu .overlay-menu__right {
  opacity: 0;
  transform: translateY(50px);
}

.overlay-menu .js-accordion-opened-main-level .overlay-menu__main-level-number {
  color: var(--color-blue-light-blue);
}
.overlay-menu .js-accordion-opened-main-level .overlay-menu__main-level-title {
  color: var(--color-blue-light-blue);
}
.overlay-menu .js-accordion-opened-main-level .overlay-menu__main-level-plus::before, .overlay-menu .js-accordion-opened-main-level .overlay-menu__main-level-plus:after {
  background: var(--color-blue-light-blue);
}
.overlay-menu .js-accordion-opened-main-level .overlay-menu__main-level-plus::after {
  opacity: 0;
}
.overlay-menu .js-accordion-opened-main-level .overlay-menu__second-level {
  opacity: 1;
}
.overlay-menu .js-accordion-opened-second-level .overlay-menu__second-level-button {
  color: var(--color-blue-light-blue3);
}
.overlay-menu .js-accordion-opened-second-level .overlay-menu__third-level {
  opacity: 1;
  margin-top: 20px;
}

.overlay-calendar {
  --overlay-calendar-width: 600px;
  --top-section-height: 70px;
}

.overlay-calendar {
  pointer-events: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 30;
  overflow-x: hidden;
}
.overlay-calendar .overlay-calendar__background {
  background: rgba(0, 0, 0, 0.3);
  opacity: 0;
  visibility: hidden;
  transition: opacity 300ms, visibility 300ms;
  pointer-events: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
}
.overlay-calendar .overlay-calendar__container {
  width: var(--overlay-calendar-width);
  height: calc(var(--vh, 1vh) * 100);
  background: var(--color-base-white);
  box-shadow: inset 0px 10px 15px -10px rgba(0, 0, 0, 0.04);
  -webkit-box-shadow: inset 0px 10px 15px -10px rgba(0, 0, 0, 0.04);
  -moz-box-shadow: inset 0px 10px 15px -10px rgba(0, 0, 0, 0.04);
  visibility: hidden;
  pointer-events: none;
  overflow-y: auto;
  overflow-x: hidden;
  position: fixed;
  top: 0;
  right: calc(var(--overlay-calendar-width) * -1);
  transition: right 600ms var(--ease-out-cubic), visibility 600ms;
}
@media screen and (max-width: 700px) {
  .overlay-calendar .overlay-calendar__container {
    width: 100%;
    right: auto;
    left: 100%;
    transition: left 600ms var(--ease-out-cubic), visibility 600ms;
  }
}
.overlay-calendar .overlay-calendar__top-section {
  width: 100%;
  height: var(--top-section-height);
  background: var(--color-blue-darker-marine);
  position: relative;
}
.overlay-calendar .overlay-calendar__close {
  height: 12px;
  font-family: var(--font-family-1);
  font-size: 1.1rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  line-height: 15px;
  color: var(--color-base-white);
  opacity: 1;
  transition: opacity 300ms;
  cursor: pointer;
  padding-right: 35px;
  position: absolute;
  top: 50%;
  right: var(--x-spacing-40);
  transform: translateY(-50%);
}
.overlay-calendar .overlay-calendar__close::after, .overlay-calendar .overlay-calendar__close::before {
  content: "";
  width: 14px;
  height: 1px;
  background: var(--color-base-white);
  opacity: 1;
  transition: opacity 300ms;
  position: absolute;
  top: 50%;
  right: 0;
}
.overlay-calendar .overlay-calendar__close::after {
  transform: translateY(-50%) rotate(-45deg);
}
.overlay-calendar .overlay-calendar__close::before {
  transform: translateY(-50%) rotate(45deg);
}
.overlay-calendar .overlay-calendar__close:hover {
  opacity: 0.5;
}
.overlay-calendar .overlay-calendar__close:hover::after, .overlay-calendar .overlay-calendar__close:hover::before {
  opacity: 0.5;
}
.overlay-calendar .overlay-calendar__bottom-section {
  height: calc(100% - var(--top-section-height));
  padding: 50px var(--x-spacing-60);
  background: var(--color-base-white);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
@media screen and (max-height: 700px) {
  .overlay-calendar .overlay-calendar__bottom-section {
    height: auto;
    justify-content: initial;
  }
}
@media screen and (max-width: 600px) {
  .overlay-calendar .overlay-calendar__bottom-section {
    height: calc(100% - var(--top-section-height));
    justify-content: center;
  }
}
@media screen and (max-height: 600px) {
  .overlay-calendar .overlay-calendar__bottom-section {
    height: auto;
    justify-content: initial;
  }
}
@media screen and (max-width: 500px) {
  .overlay-calendar .overlay-calendar__bottom-section {
    height: calc(100% - var(--top-section-height));
    justify-content: center;
  }
}
@media screen and (max-height: 500px) {
  .overlay-calendar .overlay-calendar__bottom-section {
    height: auto;
    justify-content: initial;
  }
}

.show-overlay-calendar .overlay-calendar .overlay-calendar__background {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
}
.show-overlay-calendar .overlay-calendar .overlay-calendar__container {
  right: 0;
  visibility: visible;
  pointer-events: all;
}
@media screen and (max-width: 700px) {
  .show-overlay-calendar .overlay-calendar .overlay-calendar__container {
    right: auto;
    left: 0;
  }
}

.show-overlay-calendar.closing-overlay-calendar .overlay-calendar .overlay-calendar__background {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}
.show-overlay-calendar.closing-overlay-calendar .overlay-calendar .overlay-calendar__container {
  right: calc(var(--overlay-calendar-width) * -1);
  visibility: hidden;
  pointer-events: none;
}
@media screen and (max-width: 700px) {
  .show-overlay-calendar.closing-overlay-calendar .overlay-calendar .overlay-calendar__container {
    right: auto;
    left: 100%;
  }
}

.overlay-share {
  pointer-events: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 30;
}
.overlay-share .overlay-share__background {
  background: rgba(0, 0, 0, 0.3);
  opacity: 0;
  visibility: hidden;
  transition: opacity 300ms, visibility 300ms;
  pointer-events: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
}
.overlay-share .overlay-share__container {
  padding: 85px;
  width: 520px;
  background: #fff;
  text-align: center;
  opacity: 0;
  box-shadow: 0px 0px 15px 10px rgba(0, 0, 0, 0.04);
  -webkit-box-shadow: 0px 0px 15px 10px rgba(0, 0, 0, 0.04);
  -moz-box-shadow: 0px 0px 15px 10px rgba(0, 0, 0, 0.04);
  visibility: hidden;
  pointer-events: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
  transition: opacity 800ms, transform 800ms var(--ease-in-out-quart), visibility 800ms;
}
@media screen and (max-width: 1200px) {
  .overlay-share .overlay-share__container {
    width: 490px;
  }
}
@media screen and (max-width: 1024px) {
  .overlay-share .overlay-share__container {
    width: 400px;
    padding: 50px;
  }
}
@media screen and (max-width: 600px) {
  .overlay-share .overlay-share__container {
    width: auto;
    right: 20px;
    left: 20px;
    padding: 50px 20px;
    transform: none;
  }
}
.overlay-share .overlay-share__title {
  color: var(--color-blue-marine);
  margin-bottom: 20px;
}
.overlay-share .overlay-share__close {
  width: 16px;
  height: 16px;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
}
.overlay-share .overlay-share__close:hover:before, .overlay-share .overlay-share__close:hover:after {
  background: var(--color-blue-light-blue);
}
.overlay-share .overlay-share__close:before, .overlay-share .overlay-share__close:after {
  content: "";
  width: 16px;
  height: 2px;
  background: var(--color-blue-marine);
  transition: background 300ms;
  position: absolute;
  top: 50%;
  left: 50%;
}
.overlay-share .overlay-share__close:before {
  transform: translate(-50%, -50%) rotate(45deg);
}
.overlay-share .overlay-share__close:after {
  transform: translate(-50%, -50%) rotate(-45deg);
}
.overlay-share .overlay-share__list {
  margin-top: 16px;
  position: relative;
}
.overlay-share .overlay-share__list-item {
  display: inline-block;
}
.overlay-share .overlay-share__list-item:not(:last-child) {
  margin-right: 20px;
}
@media screen and (max-width: 600px) {
  .overlay-share .overlay-share__list-item:not(:last-child) {
    margin-right: 10px;
  }
}
.overlay-share .overlay-share__link {
  width: 45px;
  height: 45px;
  background: var(--color-blue-marine);
  transition: background 300ms;
  cursor: pointer;
  display: block;
  position: relative;
}
.overlay-share .overlay-share__link:hover {
  background: var(--color-blue-light-blue);
}
.overlay-share .overlay-share__svg {
  width: 16px;
  height: 16px;
  fill: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.show-overlay-share .overlay-share .overlay-share__background {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
}
.show-overlay-share .overlay-share .overlay-share__container {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
  transform: translate(-50%, -50%);
}
@media screen and (max-width: 600px) {
  .show-overlay-share .overlay-share .overlay-share__container {
    transform: translateY(-50%);
  }
}

.show-overlay-share.closing-overlay-share .overlay-share .overlay-share__background {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}
.show-overlay-share.closing-overlay-share .overlay-share .overlay-share__container {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transform: translateX(-50%);
}
@media screen and (max-width: 600px) {
  .show-overlay-share.closing-overlay-share .overlay-share .overlay-share__container {
    transform: none;
  }
}

.overlay-popup {
  --x-height: 18px;
}

.overlay-popup {
  pointer-events: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 30;
}
.overlay-popup .overlay-popup__background {
  background: rgba(0, 0, 0, 0.3);
  opacity: 0;
  visibility: hidden;
  transition: opacity 600ms, visibility 600ms;
  pointer-events: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
}
.overlay-popup .overlay-popup__container {
  width: 600px;
  background: var(--color-base-white);
  opacity: 0;
  visibility: hidden;
  transition: opacity 600ms, visibility 600ms;
  pointer-events: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 120px 95px 70px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}
@media screen and (max-width: 768px) {
  .overlay-popup .overlay-popup__container {
    width: 500px;
    padding: 100px 50px;
  }
}
@media screen and (max-width: 600px) {
  .overlay-popup .overlay-popup__container {
    width: 400px;
    padding: 100px 50px 50px;
  }
}
@media screen and (max-width: 500px) {
  .overlay-popup .overlay-popup__container {
    width: 300px;
    padding: 100px 25px 25px;
  }
}
@media screen and (max-width: 400px) {
  .overlay-popup .overlay-popup__container {
    width: 100%;
    max-width: calc(100% - var(--x-spacing-40) * 2);
  }
}
.overlay-popup .overlay-popup__close {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: var(--color-blue-marine);
  transition: background-color 300ms;
  position: absolute;
  top: 30px;
  right: 30px;
  cursor: pointer;
}
.overlay-popup .overlay-popup__close::after, .overlay-popup .overlay-popup__close::before {
  content: "";
  width: 14px;
  height: 1px;
  background: var(--color-base-white);
  opacity: 1;
  transition: opacity 300ms;
  position: absolute;
  top: 50%;
  left: 50%;
}
.overlay-popup .overlay-popup__close::after {
  transform: translate(-50%, -50%) rotate(-45deg);
}
.overlay-popup .overlay-popup__close::before {
  transform: translate(-50%, -50%) rotate(45deg);
}
.overlay-popup .overlay-popup__close:hover {
  background: var(--color-blue-light-blue);
}
@media screen and (max-width: 500px) {
  .overlay-popup .overlay-popup__close {
    width: 40px;
    height: 40px;
    top: 25px;
  }
}
.overlay-popup .overlay-popup__text {
  text-align: center;
}
.overlay-popup .overlay-popup__title {
  font-size: 3.5rem;
  font-weight: 700;
  color: var(--color-blue-marine);
  line-height: 1.1;
  margin-bottom: 40px;
}
@media screen and (max-width: 600px) {
  .overlay-popup .overlay-popup__title {
    font-size: 25px;
  }
}
@media screen and (max-width: 500px) {
  .overlay-popup .overlay-popup__title {
    font-size: 20px;
  }
}
.overlay-popup .overlay-popup__infos {
  font-size: 1.7rem;
  color: var(--color-blue-marine);
  line-height: 1.5;
  margin-bottom: 50px;
}
@media screen and (max-width: 600px) {
  .overlay-popup .overlay-popup__infos {
    font-size: 1.4rem;
    margin-bottom: 20px;
  }
}

.show-overlay-popup .overlay-popup .overlay-popup__background {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
}
.show-overlay-popup .overlay-popup .overlay-popup__container {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
}

.show-overlay-popup.closing-overlay-popup .overlay-popup .overlay-popup__background {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}
.show-overlay-popup.closing-overlay-popup .overlay-popup .overlay-popup__container {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}

.main {
  width: calc(100% - var(--side-section-width));
  color: var(--color-blue-marine);
}
.main--jobs, .main--full {
  width: 100%;
}
@media screen and (max-width: 1000px) {
  .main {
    width: 100%;
  }
}
.main__content {
  padding-left: var(--x-spacing-120);
  padding-right: var(--x-spacing-120);
  padding-top: var(--y-spacing-100);
  padding-bottom: var(--y-spacing-100);
}
.main__content--jobs {
  padding-right: var(--x-spacing-160);
  padding-left: var(--x-spacing-160);
}
.main__content--jobs-detail, .main__content--no-padding-top {
  padding-top: 0;
}
@media screen and (min-width: 1601px) {
  .main__content {
    padding-right: 120px;
  }
}
.main__content-side {
  max-width: calc(100% - var(--side-section-width));
}
.main__content + .main__content {
  padding-top: 0;
}
.main__title {
  margin-bottom: var(--y-spacing-50);
}
.main__documents-desc {
  margin-top: -20px;
  margin-bottom: var(--y-spacing-50);
}

.show-alerts .alert,
.show-alerts .alert-mobile {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
}

.show-alerts .alert-mobile {
  top: 0;
}

.page {
  position: relative;
}

.page-with-side-menu {
  display: flex;
}
.page-with-side-menu .page-with-side-menu__container {
  width: calc(100% - var(--side-section-width));
  display: flex;
  flex-direction: column;
}
@media screen and (max-width: 1000px) {
  .page-with-side-menu .page-with-side-menu__container {
    width: 100%;
  }
}
.page-with-side-menu .page-with-side-menu__container section {
  width: 100%;
}
.page-with-side-menu .page-with-side-menu__container--contact {
  width: calc(100% - var(--side-section-contact-width));
}
@media screen and (max-width: 1000px) {
  .page-with-side-menu .page-with-side-menu__container--contact {
    width: 100%;
  }
}

.page--events-detail .main__content {
  padding-top: var(--y-spacing-80);
}