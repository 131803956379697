@use 'components';


//--------------------
// Variables globales
//--------------------
:root {
    
}


//-------------------
// Variables locales
//-------------------
.contact-block {
    
}


// ----------------
// Contact block
// ----------------
.contact-block {
    display: none;

    @media screen and (max-width: 1000px) { display: block; }

    $cb: '.contact-block'; // Variable désignant le contact-block


    // Conteneur
    #{$cb}__container {
        width: 100%;
        padding: var(--y-spacing-80) var(--x-spacing-110);
        background: var(--color-blue-marine);
        display: flex;
        justify-content: center;

        @media screen and (max-width: 600px) {  
            flex-direction: column;
            justify-content: initial;
        }
    }


    // Bloc de gauche et bloc de droite
    #{$cb}__left, #{$cb}__right {
        width: 50%;

        @media screen and (max-width: 600px) { width: 100%; }
    }


    // Titre
    #{$cb}__title {
        @extend .medium-title;
        color: var(--color-base-white);
        margin-bottom: 45px;

        @media screen and (max-width: 768px) { margin-bottom: 30px; }
    }


    // Liens (Téléphone et email) | Adresse | Heures
    #{$cb}__link, #{$cb}__address, #{$cb}__hours {
        @extend .paragraph;
        color: var(--color-base-white);
        display: flex;
        align-items: center;
    }


    // Liens (Téléphone et email)
    #{$cb}__link {
        margin-bottom: 20px;
    }


    // Adresse
    #{$cb}__address {
        margin-bottom: 55px;

        @media screen and (max-width: 1024px) { margin-bottom: 50px; }
        @media screen and (max-width: 768px) { margin-bottom: 30px; }
    }


    // Svg
    #{$cb}__svg {
        stroke: var(--color-base-white);
        stroke-width: 2px;
        fill: none;
        margin-right: 30px;


        // Téléphone
        &--phone {
            width: 19px;
            height: 19px;
        }


        // Télécopieur
        &--fax {
            width: 18px;
            height: 17px;
        }


        // Courriel
        &--mail {
            width: 18px;
            height: 13px;
        }


        // Pointer
        &--marker {
            width: 14px;
            height: 18px;
            margin-right: 35px;
        }


        // Horloge
        &--clock {
            width: 17px;
            height: 17px;
        }
    }


    // Bouton
    #{$cb}__button {
        margin-bottom: var(--y-spacing-80);
    }
}