@use '../texts/paragraph';


//--------------------
// Variables globales
//--------------------
:root {
    
}


//------------------- 
// Variables locales
//-------------------
.result {

}


// ----------------
// Résultat de recherche
// ----------------
.result {
    $r: '.result'; // Variable désignant le résultat

    padding-top: var(--y-spacing-40);
    padding-right: 15px;
    padding-bottom: var(--y-spacing-50);
    padding-left: 15px;
    border-top: 1px solid var(--color-grayscale-gray3);
    display: flex;
    flex-direction: column;
    align-items: flex-start;


    // La catégorie
    #{$r}__category {
        font-family: var(--font-family-1); // Maison Neue
        font-size: 1.1em;
        font-weight: 700;
        letter-spacing: 0.1em;
        color: var(--color-blue-light-blue);
        text-transform: uppercase;
        margin-bottom: 20px;
    }


    // Titre
    #{$r}__title {
        font-family: var(--font-family-1); // Maison Neue
        font-size: 2.2em;
        line-height: 1.4;
        color: var(--color-blue-marine);
        margin-bottom: 20px;
    }


    // Texte
    #{$r}__text {
        @extend .paragraph;
        color: var(--color-blue-marine);
        margin-bottom: 40px;
    }


    // Lien
    #{$r}__link {
        font-family: var(--font-family-1); // Maison Neue
        font-size: 1.1em;
        font-weight: 700;
        letter-spacing: 0.1em;
        color: var(--color-blue-marine);
        text-transform: uppercase;
        text-decoration: none;
        transition: color 300ms, box-shadow 0.3s, transform 800ms var(--ease-out-cubic);
        padding-bottom: 6px;
        display: block;
        position: relative;

        &::after {
            content: '';
            width: 100%;
            transition: width 300ms;
            height: 2px;
            background: var(--color-blue-light-blue);
            position: absolute;
            bottom: 0;
            left: 0;
        }

        &:hover{
            color: var(--color-blue-light-blue);

            &::after {  
                width: 75%;
            }
        }
    }
}