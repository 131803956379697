@use 'sass:math';

:root {
    // Générales
    --calendar-header-background-color: white;
    --calendar-header-color: var(--color-blue-marine);

    --calendar-main-background-color: white;
    --calendar-main-color: var(--color-blue-marine);
    --calendar-main-day-cell-size: 3.7rem;
    @media screen and (max-width: 1024px) { --calendar-main-day-cell-size: 3.4rem; }
    --calendar-main-month-year-cell-size: calc(var(--calendar-main-day-cell-size) * 1.7);

    // Dates des mois précédents ou suivant (.prev, .next)
    --calendar-day-previous-or-next-color: var(--form-note__color);
    --calendar-day-previous-or-next-background-color: transparent;
    --calendar-day-previous-or-next-dot-or-underline-background-color: var(--form-note__color);
    --calendar-month-year-previous-or-next-color: var(--form-note__color);


    // Date avec événement (:not(.disabled))
    --calendar-day-color: var(--color-blue-marine);
    --calendar-day-background-color: transparent;
    --calendar-day-dot-or-underline-background-color: rgb(var(--rgb-3));
    --calendar-month-year-color: rgb(var(--rgb-1));

    --calendar-day-color-hover: rgb(var(--rgb-1));
    --calendar-day-background-color-hover: var(--color-blue-lighter-blue);
    --calendar-day-dot-or-underline-background-color-hover: rgb(var(--rgb-3));
    --calendar-month-year-color-hover: var(--color-blue-marine);


    // Date d'aujourd'hui
    --calendar-today-color: var(--color-blue-marine);
    --calendar-today-background-color: var(--color-blue-light-blue);
    --calendar-today-dot-or-underline-background-color: white;

    --calendar-today-color-hover: var(--color-blue-marine);
    --calendar-today-background-color-hover: var(--color-blue-lighter-blue);
    --calendar-today-dot-or-underline-background-color-hover: white;


    // Date sélectionnée
    --calendar-day-active-color: white;
    --calendar-day-active-background-color: var(--color-blue-marine);
    --calendar-day-active-dot-or-underline-background-color: white;

    --calendar-day-active-color-hover: rgb(var(--rgb-1));
    --calendar-day-active-background-color-hover: var(--color-blue-marine);
    --calendar-day-active-dot-or-underline-background-color-hover: rgb(var(--rgb-3));
}


// Calendrier en popup dans champ date
.datepickerWrapper .datepicker-picker {
    .datepicker-header {
        @media screen and (max-width: 425px) {
            padding-left: 10px;
            padding-right: 10px;
        }
    }

    .datepicker-main {
        --calendar-main-background-color: white;

        @media screen and (max-width: 425px) {
            padding-top: 28px;
            padding-bottom: 18px;
            padding-left: 10px;
            padding-right: 10px;
        }
    }

    .day::after {
        display: none;
    }
}

.datepicker-picker {
    display: inline-block;

    span {
        display: block;
        flex: 1;
        border: 0;
        cursor: default;
        text-align: center;
        -webkit-touch-callout: none;
        user-select: none;
    }

    .datepicker-header {
        background-color: var(--calendar-header-background-color);
        padding: 24px 34px;
    }

    .datepicker-main {
        background-color: var(--calendar-main-background-color);
        padding: 34px 34px 30px;
    }
}

.datepicker-controls {
    display: flex;

    .button {
        display: inline-flex;
        position: relative;
        align-items: center;
        justify-content: center;
        margin: 0;
        cursor: pointer;
        white-space: nowrap;
        font-size: 1.7rem;
        font-weight: 700;
        color: var(--calendar-header-color);
        border: 0;
        border-radius: 0;
        padding: 0;
        background-color: var(--calendar-header-background-color);
        text-transform: none;

        &::before,
        &::after {
            content: normal;
        }

        &:focus,
        &:active {
            outline: none;
        }

        &[disabled] {
            cursor: not-allowed;
        }
    }

    .view-switch {
        flex: auto;
    }

    .prev-btn,
    .next-btn {
        width: percentage(math.div(1, 7));

        >svg {
            width: 15px;
            height: 12px;
            fill: var(--calendar-header-color);
        }

        &.disabled {
            visibility: hidden;
        }
    }

    .prev-btn>svg {
        transform: rotate(90deg);
    }

    .next-btn>svg {
        transform: rotate(-90deg);
    }
}

.datepicker-view {
    display: flex;

    .days-of-week {
        display: flex;
        margin-bottom: 10px;
    }

    .dow {
        flex-basis: percentage(math.div(1, 7));
        font-size: 1.2rem;
        font-weight: 700;
        color: var(--calendar-main-color);
    }
}

.datepicker-grid {
    display: flex;
    flex-wrap: wrap;
}

.datepicker-cell {
    height: var(--calendar-main-day-cell-size);
    line-height: var(--calendar-main-day-cell-size);

    &.day {
        position: relative;
        font-size: 1.2rem;
        font-weight: 500;
        color: var(--calendar-main-color);
        z-index: 1;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            width: var(--calendar-main-day-cell-size);
            height: var(--calendar-main-day-cell-size);
            background-color: transparent;
            border-radius: 3px;
            transition: background-color 0.3s;
            z-index: -1;
        }

        &::after {
            content: "";
            position: absolute;
            top: 8px;
            left: calc(50% + 12px);
            transform: translateX(-50%);
            width: 5px;
            height: 5px;
            background-color: transparent;
            border-radius: 50%;
            transition: background-color 0.3s;
            z-index: 1;
        }
    }

    &.month,
    &.year,
    &.decade {
        font-size: var(--fz-16);
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: .1em;
        color: var(--calendar-main-color);
    }

    &.disabled {
        cursor: default;

        &::after {
            display: none;
        }
    }

    // Date avec événement (:not(.disabled))
    &:not(.disabled) {
        cursor: pointer;

        &::after {
            background-color: var(--calendar-day-dot-or-underline-background-color);
        }

        &:hover {
            &::before {
                background-color: var(--calendar-day-background-color-hover);
            }
        }
    }

    // Date d'aujourd'hui
    &.day.today:not(.selected) {
        color: var(--calendar-today-color);

        &::before {
            background-color: var(--calendar-today-background-color);
        }

        &::after {
            background-color: var(--calendar-today-dot-or-underline-background-color);
        }

        &:hover:not(.disabled) {
            color: var(--calendar-today-color-hover);

            &::before {
                background-color: var(--calendar-today-background-color-hover);
            }

            &::after {
                background-color: var(--calendar-today-dot-or-underline-background-color-hover);
            }
        }
    }

    // Date sélectionnée
    &.day.selected {
        color: var(--calendar-day-active-color);

        &::before {
            background-color: var(--calendar-day-active-background-color);
        }

        &::after {
            background-color: var(--calendar-day-active-dot-or-underline-background-color);
        }

        &:hover {
            color: var(--calendar-day-active-color-hover);

            &::before {
                background-color: var(--calendar-day-active-background-color-hover);
            }

            &::after {
                background-color: var(--calendar-day-active-dot-or-underline-background-color-hover);
            }
        }
    }

    // Dates des mois précédents ou suivant (.prev, .next)
    &.day.prev,
    &.day.next {
        color: var(--calendar-day-previous-or-next-color);

        &::before {
            background-color: var(--calendar-day-previous-or-next-background-color);
        }

        &::after {
            background-color: var(--calendar-day-previous-or-next-dot-or-underline-background-color);
        }
    }

    &.month,
    &.year,
    &.decade {

        &.prev,
        &.next {
            color: var(--calendar-month-year-previous-or-next-color);
        }

        &.selected,
        &:hover {
            color: var(--calendar-month-year-color-hover);
        }
    }
}

.datepicker-view {
    .days .datepicker-cell {
        flex-basis: percentage(math.div(1, 7));
    }

    &.months .datepicker-cell,
    &.years .datepicker-cell,
    &.decades .datepicker-cell {
        flex-basis: 25%;
        height: var(--calendar-main-month-year-cell-size);
        line-height: var(--calendar-main-month-year-cell-size);
    }
}
