:root {
    --checkbox-field__master-label-color: var(--color-grayscale-gray4);

    --checkbox-field__label-color: var(--color-blue-marine);

    --checkbox-field__box-border: 1px solid var(--color-grayscale-gray8);
    --checkbox-field__box-border-active: 1px solid var(--color-blue-light-blue);
    --checkbox-field__box-color: var(--color-base-white);
    --checkbox-field__box-color-active: var(--color-base-white);
    --checkbox-field__box-background-color: var(--color-base-white);
    --checkbox-field__box-background-color-active: var(--color-blue-light-blue);
}


.checkbox-field {
    &__main-label {
        display: block;
        font-size: 1.5rem;
        line-height: 1em;
        color: var(--checkbox-field__master-label-color);
        margin-bottom: 30px;
    }

    &__label {
        display: flex;
        align-items: center;
        position: relative;
        margin-bottom: 20px;
        cursor: pointer;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__text {
        flex: 1;
        font-size: 1.7rem;
        color: var(--checkbox-field__label-color);
        line-height: 1em;
        order: 2;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    &__input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }

    &__box {
        border-radius: 3px;
        border: var(--checkbox-field__box-border);
        background-color: var(--checkbox-field__box-background-color);
        position: relative;
        width: 18px;
        height: 18px;
        margin-right: 15px;
        order: 1;
        transition: background-color 225ms, border 225ms;

        &::before {
            content: '';
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%) scale(0);
            transition: transform 225ms;
            width: 12px;
            height: 12px;
            background: url(../medias/images/icons/checkmark.svg) no-repeat center/contain;
        }
    }

    &__error {
        position: absolute;
    }

    &__input:checked ~ &__box {
        border: var(--checkbox-field__box-border-active);
        background-color: var(--checkbox-field__box-background-color-active);
    }
    &__input:checked ~ &__box::before {
        transform: translate(-50%, -50%) scale(1);
    }

    &--inline &__options {
        margin-bottom: -20px;
    }
    &--inline &__label {
        display: inline-flex;
        margin: 0 30px 20px 0;
    }
}

.checkbox-field__header + .checkbox-field__options {
    margin-top: 15px;
}
