@use 'components';


//--------------------
// Variables globales
//--------------------
:root {
    
}


//-------------------
// Variables locales
//-------------------
.overlay-menu {
    --left-section-width: 440px;
    --image-width-mobile: 440px;

    @media screen and (max-width: 900px) { --image-width-mobile: 420px; }
    @media screen and (max-width: 800px) { --image-width-mobile: 400px; }
    @media screen and (max-width: 700px) { --image-width-mobile: 380px; }
}


// ----------------
// Overlay | Menu
// ----------------
.overlay-menu {
    pointer-events: none;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 30;
    
    $ovm: '.overlay-menu'; // Variable désignant l'overlay menu


    // Fond transparent
    #{$ovm}__background {
        background: transparent;
        pointer-events: none;
        position: absolute;
        top: 0;
        right: 500px;
        bottom: 0;
        left: 0;
        z-index: -1;
    }


    // Conteneur
    #{$ovm}__container {
        height: 0;
        background: var(--color-blue-marine);
        transition: height 600ms var(--ease-out-cubic);
        pointer-events: none;
        display: flex;
        overflow: hidden;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;

        @media screen and (max-width: 1000px) { 
            flex-direction: column; 
            overflow-y: auto;
            overflow-x: hidden;
            scrollbar-width: none; // Enlève la barre de défilement

            &::-webkit-scrollbar { display: none; } // Enlève la barre de défilement 
        }
    }


    // Bouton de fermeture
    #{$ovm}__close {
        height: 95px;
        background: var(--color-blue-darker-marine);
        padding: var(--x-spacing-40);
        padding-right: calc(var(--x-spacing-40) * 2);
        cursor: pointer;
        display: flex;
        align-items: center;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 5;

        &::after, &::before {
            content: "";
            width: 14px;
            height: 1px;
            background: var(--color-base-white);
            opacity: 1;
            transition: opacity 300ms;
            position: absolute;
            top: 48%;
            right: var(--x-spacing-40);
        }

        &::after { transform: translateY(-50%) rotate(-45deg); }
        &::before { transform: translateY(-50%) rotate(45deg); }

        &:hover {
            &::after, &::before {
                opacity: 0.5;
            }
        }
    }


    // Texte du bouton de fermeture
    #{$ovm}__close-text {
        font-family: var(--font-family-1); // Maison Neue
        font-size: 1.1rem;
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: 0.1em;
        line-height: 12px;
        color: var(--color-base-white);
        transition: color 300ms;
    }


    // Effet d'hover du bouton de fermeture sur le texte du bouton de fermeture
    #{$ovm}__close:hover #{$ovm}__close-text {
        color: rgba($color: #fff, $alpha: 0.5);
    }
    

    // Section de gauche
    #{$ovm}__left {
        height: 100vh;
        width: var(--left-section-width);

        @media screen and (max-width: 1000px) { 
            width: 100%; 
            display: flex;
            order: 2;
        }

        @media screen and (max-width: 600px) { flex-direction: column; }
    }


    // Zone de texte
    #{$ovm}__text-box {
        min-height: 50%;
        background: var(--color-base-white);
        padding: 25px var(--x-spacing-60);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        @media screen and (max-width: 1000px) { 
            width: calc(100% - var(--image-width-mobile));
            height: auto;
            order: 1;
        }

        @media screen and (max-width: 600px) { width: 100%; }
    }


    // Surtitre
    #{$ovm}__surtitle {
        font-family: var(--font-family-1); // Maison Neue
        font-size: 1.4rem;
        font-weight: 500;
        line-height: 1.6;
        letter-spacing: 0.2em;
        text-transform: uppercase;
        color: var(--color-blue-light-blue);
        margin-bottom: 25px;

        @media screen and (max-width: 500px) { margin-bottom: 20px; }
    }


    // Titre
    #{$ovm}__title {
        font-family: var(--font-family-1); // Maison Neue
        font-size: var(--medium-title-font-size); // 3rem
        line-height: 1.3em;
        color: var(--color-blue-marine);
        margin-bottom: var(--y-spacing-40);
    }


    // Lien
    #{$ovm}__link {
        font-family: var(--font-family-1); // Maison Neue
        font-size: 1.1em;
        font-weight: 700;
        letter-spacing: 0.1em;
        color: var(--color-blue-marine);
        text-transform: uppercase;
        text-decoration: none;
        transition: color 300ms, box-shadow 0.3s, transform 800ms var(--ease-out-cubic);
        padding-bottom: 6px;
        display: block;
        position: relative;

        &::after {
            content: '';
            width: 100%;
            transition: width 300ms;
            height: 2px;
            background: var(--color-blue-light-blue);
            position: absolute;
            bottom: 0;
            left: 0;
        }
    }


    // Effet d'hover du conteneur sur le lien
    #{$ovm}__left:hover #{$ovm}__link {
        color: var(--color-blue-light-blue);

        &::after {  
            width: 75%;
        }
    }


    // Conteneur englobant le 'picture'(Nécéssaire lorsqu'on utilise un padding en %)
    #{$ovm}__picture-container {
        width: 100%;
        height: 50%;

        @media screen and (max-width: 1000px) { 
            width: var(--image-width-mobile); 
            height: auto;
        }

        @media screen and (max-width: 600px) { 
            width: 100%; 
            order: 2;
        }
    }


    // Conteneur de l'image
    #{$ovm}__picture {
        padding-top: 102.5%;
        min-height: 100%;
        position: relative;
        overflow: hidden;
    }


    // Image
    #{$ovm}__img {
        transform: scale(1);
        transition: transform 600ms var(--ease-out-quart);
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }


    // Effet d'hover du conteneur sur l'image
    #{$ovm}__left:hover #{$ovm}__img {
        transform: scale(1.1);
    }


    // Section de droite
    #{$ovm}__right {
        width: calc(100% - var(--left-section-width));
        padding-top: var(--y-spacing-130);
        padding-right: var(--x-spacing-120);
        padding-bottom: var(--y-spacing-80);
        padding-left: var(--x-spacing-120);
        opacity: 0;
        transform: translateY(50px);
        transition: opacity 800ms, transform 800ms var(--ease-out-quart);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        overflow-y: auto;
        overflow-x: hidden;
        scrollbar-width: none; // Enlève la barre de défilement

        &::-webkit-scrollbar { display: none; } // Enlève la barre de défilement 

        @media screen and (max-width: 1000px) { 
            width: 100%;
            overflow: initial; 
        }
        @media screen and (max-width: 768px) { padding-top: 100px; }
    }


    // Premier niveau
    #{$ovm}__main-level-li {
        border-bottom: 1px solid rgba($color: #fff, $alpha: 0.3);
    }


    // Bouton du premier niveau
    #{$ovm}__main-level-button {
        min-height: 140px;
        padding: var(--y-spacing-40) var(--x-spacing-30);
        padding-right: 55px;
        cursor: pointer;
        display: flex;
        align-items: center;
        position: relative;

        @media screen and (max-width: 700px) { min-height: 0px; }
        @media screen and (max-width: 400px) { padding-left: 0; }
    }


    // Texte du premier niveau
    #{$ovm}__main-level-text {
        display: flex;
    }


    // Numéro du premier niveau
    #{$ovm}__main-level-number {
        font-family: var(--font-family-1); // Maison Neue
        font-size: 1.4rem;
        font-weight: 700;
        color: var(--color-base-white);
        transition: color 300ms;
        margin-right: var(--x-spacing-40);
        align-self: flex-start;
        transform: translateY(20px);

        @media screen and (max-width: 1024px) { transform: translateY(16px); }
        @media screen and (max-width: 768px) { transform: translateY(15px); }
        @media screen and (max-width: 600px) { transform: translateY(13px); }
        @media screen and (max-width: 500px) { transform: translateY(12px); }
        @media screen and (max-width: 440px) { transform: translateY(11px); }
        @media screen and (max-width: 400px) { transform: translateY(10px); }
        @media screen and (max-width: 320px) { transform: translateY(9px); }
    }


    // Effet d'hover du premier niveau sur le numéro
    #{$ovm}__main-level-li:hover #{$ovm}__main-level-number {
        color: var(--color-blue-light-blue);
    }


    // Titre du premier niveau
    #{$ovm}__main-level-title {
        font-family: var(--font-family-2); // Canela
        font-size: 5rem;
        font-weight: 300;
        line-height: 1.1em;
        color: var(--color-base-white);
        transition: color 300ms;
        align-self: center;

        @media screen and (max-height: 700px) { font-size: 4.8rem; }
        @media screen and (max-width: 1024px) { font-size: 4.2rem; }
        @media screen and (max-width: 768px) { font-size: 4rem; }
        @media screen and (max-width: 600px) { font-size: 3.8rem; }
        @media screen and (max-width: 500px) { font-size: 3.6rem; }
        @media screen and (max-width: 440px) { font-size: 3.4rem; }
        @media screen and (max-width: 400px) { font-size: 3.2rem; }
        @media screen and (max-width: 320px) { font-size: 3rem; }
    }


    // Effet d'hover du premier niveau sur le titre
    #{$ovm}__main-level-li:hover #{$ovm}__main-level-title {
        color: var(--color-blue-light-blue);
    }


    // + du premier niveau
    #{$ovm}__main-level-plus {
        width: 16px;
        height: 16px;
        position: absolute;
        top: 50%;
        right: var(--x-spacing-30);
        transform: translateY(-50%);

        &::before,
        &::after {
            content: '';
            width: 16px;
            height: 2px;
            background: var(--color-base-white);
            transition: background 300ms;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
        }

        &::after {
            transform: translate(-50%,-50%) rotate(90deg);
            transition: opacity 300ms;
        }
    }


    // Effet d'hover du premier niveau sur le +
    #{$ovm}__main-level-li:hover #{$ovm}__main-level-plus {
        &::before,
        &::after {
            background: var(--color-blue-light-blue);
        }
    }


    // Conteneur du second niveau 
    #{$ovm}__second-level {
        height: 0;
        opacity: 0;
        transition: opacity 700ms var(--ease-out-quart);
        overflow: hidden;
    }


    // Second niveau
    #{$ovm}__second-level-li {
        padding-bottom: 20px;

        &:last-child {
            padding-bottom: var(--y-spacing-60);
        }
    }


    // Bouton du second niveau
    #{$ovm}__second-level-button {
        font-family: var(--font-family-1); // Maison Neue
        font-size: 3rem;
        font-weight: 700;
        line-height: 1.3em;
        color: var(--color-base-white);
        transition: color 300ms;

        &:hover {
            color: var(--color-blue-light-blue3);
        }

        @media screen and (max-height: 700px) { font-size: 2.8rem; }
        @media screen and (max-width: 768px) { font-size: 2.6rem; }
        @media screen and (max-width: 425px) { font-size: 2.4rem; }
    }


    // Conteneur du troisième niveau 
    #{$ovm}__third-level {
        height: 0;
        opacity: 0;
        margin-top: 0;
        transition: opacity 700ms var(--ease-out-quart), margin 700ms var(--ease-out-quart);
        overflow: hidden;
    }


    // Troisème niveau
    #{$ovm}__third-level-li {
        padding-bottom: 15px;

        &:last-child {
            padding-bottom: var(--y-spacing-40);
        }
    }


    // Lien du troisième niveau
    #{$ovm}__third-level-link {
        font-family: var(--font-family-1); // Maison Neue
        font-size: 2rem;
        line-height: 1.4;
        color: var(--color-base-white);
        position: relative;
        transition: color 300ms;

        &:hover {
            color: var(--color-blue-light-blue);
        }

        @media screen and (max-height: 700px) { font-size: 1.8rem; }
    }


    // Zone de contact
    #{$ovm}__contact-box {
        display: flex;
        align-items: flex-end;
        margin-top: var(--y-spacing-70);

        @media screen and (max-width: 500px) {
            flex-direction: column;
            align-items: flex-start;
        }
    }


    // Boîte contenant le numéro de téléphone et l'adresse courriel 
    #{$ovm}__phone-and-email {
        margin-right: var(--x-spacing-70);

        @media screen and (max-width: 500px) { 
            margin-right: 0; 
            margin-bottom: 30px;
        }
    }


    // Téléphone et courriel 
    #{$ovm}__phone, #{$ovm}__email {
        font-family: var(--font-family-1);  // Maison Neue
        font-size: 1.7rem;
        color: var(--color-base-white);
        display: block;
    }


    // Téléphone 
    #{$ovm}__phone {
        margin-bottom: 15px;
        transition: color 300ms;

        &:hover {
            color: var(--color-blue-light-blue);
        }
    }


    // Courriel
    #{$ovm}__email {
        transition: color 300ms;

        &:hover {
            color: var(--color-blue-light-blue);
        }
    }
}


// Overlay | Actif
.show-overlay-menu {


    // Overlay menu | Actif
    .overlay-menu {
        $ovm: '.overlay-menu'; // Variable désignant l'overlay menu


        // Fond transparent | Actif
        #{$ovm}__background {
            pointer-events: all;
        }


        // Conteneur | Actif
        #{$ovm}__container {
            height: calc((var(--vh, 1vh) * 100));
            pointer-events: all;
        }


        // Section de droite | Actif
        #{$ovm}__right {
            opacity: 1;
            transform: translateY(0);
        }
    }
}


// Overlay | Fermeture
.show-overlay-menu.closing-overlay-menu {
    .overlay-menu {
        $ovm: '.overlay-menu'; // Variable désignant l'overlay menu


        // Fond transparent | Fermeture
        #{$ovm}__background {
            pointer-events: none;
        }


        // Conteneur | Fermeture
        #{$ovm}__container {
            height: 0;
            pointer-events: none;
        }


        // Section de droite | Fermeture
        #{$ovm}__right {
            opacity: 0;
            transform: translateY(50px);
        }
    }
}


// Overlay | Sous-menu ouvert
.overlay-menu {
    $ovm: '.overlay-menu'; // Variable désignant l'overlay menu


    // Premier niveau | Ouvert
    .js-accordion-opened-main-level { 


        // Numéro du premier niveau
        #{$ovm}__main-level-number { color: var(--color-blue-light-blue); } 


        // Titre du premier niveau
        #{$ovm}__main-level-title { color: var(--color-blue-light-blue); } 


        // + du premier niveau
        #{$ovm}__main-level-plus {
            &::before, &:after {
                background: var(--color-blue-light-blue);
            }

            &::after {
                opacity: 0;
            }
        }


        // Conteneur du second niveau
        #{$ovm}__second-level {
            opacity: 1;
        }
    }


    // Second niveau | Ouvert
    .js-accordion-opened-second-level { 
        #{$ovm}__second-level-button { color: var(--color-blue-light-blue3); }


        // Conteneur du troisième niveau
        #{$ovm}__third-level {
            opacity: 1;
            margin-top: 20px;
        }
    }
}