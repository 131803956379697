@use 'components';


//--------------------
// Variables globales
//--------------------
:root {
    --mobile-slider-text-height: 0;
}


//-------------------
// Variables locales
//-------------------
.home-banner {
    --tiny-slider-dimensions: 166px;

    @media screen and (max-width: 900px) { --tiny-slider-dimensions: 133px }
    

    --mobile-nav-height: 56px;
}


// ----------------
// Home banner
// ----------------
.home-banner {
    background: var(--color-blue-marine);
    
    $hb: '.home-banner'; // Variable désignant le home-banner


    // Conteneur
    #{$hb}__container {
        width: 100%;
        height: calc((var(--vh, 1vh) * 100));
        min-height: calc(500px + var(--header-height));
        display: flex;
        position: relative;
        overflow: hidden;

        @media screen and (max-width: 800px) { 
            padding-top: calc(var(--y-spacing-50) + var(--header-height));
            flex-direction: column;
            justify-content: flex-end;
        }
    }


    // Slider du texte
    #{$hb}__text-slider {
        width: calc(50% + 120px);
        margin-bottom: 0!important;
        padding-left: var(--x-spacing-160);
        position: absolute;
        top: var(--header-height);
        bottom: 0;
        left: 0;
        z-index: 5;

        .slick-slide div {
            font-size: 0;
        }

        @media screen and (max-width: 800px) { 
            width: 100%;
            padding-right: var(--x-spacing-160);
            top: calc(var(--y-spacing-50) + var(--header-height));
            bottom: initial;

            .slick-list,
            .slick-track,
            .slick-slide {
                height: 100%!important;
            }

            .slick-slide div {
                height: 100%!important;
            }
        }
    }


    // Élément du slider
    #{$hb}__text-slider-slide {
        width: 100%;
        height: calc((var(--vh, 1vh) * 100) - var(--header-height));
        min-height: 500px;
        padding-bottom: var(--tiny-slider-dimensions);
        display: flex!important;
        align-items: center;

        @media screen and (max-width: 800px) { 
            height: 100%; 
            min-height: 0px; 
            align-items: initial;
            padding-bottom: 0;
        }
    }


    // Bloc de texte 
    #{$hb}__text-block {
        position: relative;

        @media screen and (max-width: 800px) { 
            display: flex; 
            flex-direction: column;
            align-items: flex-start;
        }
    }


    // Titre
    #{$hb}__title {
        @extend .huge-title;
        color: var(--color-base-white);
        margin-bottom: 55px;

        @media screen and (max-width: 1100px) { margin-bottom: 20px; }

        @media screen and (max-height: 700px) { 
            font-size: var(--huge-title-smaller-font-size);
            margin-bottom: 20px; 
        }

        @media screen and (max-height: 600px) { font-size: 3.5rem; }
        
        @media screen and (max-height: 500px) { font-size: 3rem; }

        @media screen and (max-width: 800px) and (max-height: 700px) { margin-bottom: 20px; }
    }


    // Classe qui s'applique si le titre est trop long
    .smaller {
        font-size: var(--huge-title-smaller-font-size); // 70px
    }


    // Slider de l'image
    #{$hb}__image-slider {
        width: 50%;
        margin-left: 50%;
        margin-bottom: 0!important;

        .slick-slide div {
            font-size: 0;
        }

        @media screen and (max-width: 800px) { 
            height: 50%;
            width: 100%; 
            margin-left: 0;
            position: relative;
            z-index: 1;

            .slick-list,
            .slick-track,
            .slick-slide {
                height: 100%!important;
            }

            .slick-slide div {
                height: 100%!important;
            }
        }
    }


    // Slide
    #{$hb}__image-slider-slide {
        position: relative;
    }


    // Filtre de l'image
    #{$hb}__filter {
        background: rgb(0,0,0);
        background: linear-gradient(270deg, rgba(0,0,0,0) 77%, rgba(0,0,0,0.5) 100%);
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        @media screen and (max-width: 800px) { background: linear-gradient(0deg, rgba(0,0,0,0) 77%, rgba(0,0,0,0.5) 100%); }
    }


    // Conteneur de l'image de bannière
    #{$hb}__picture {
        width: 100%;
        height: calc((var(--vh, 1vh) * 100));
        min-height: calc(500px + var(--header-height));

        @media screen and (max-width: 800px) { 
            height: 100%;
            min-height: 0px; 
        }
    }

    // Credit photo
    #{$hb}__credit {
        font-family: var(--font-family-1); // Maison Neue
        font-size: 1.4rem;
        font-weight: 700;
        color: var(--color-base-white);
        position: absolute;
        right: 0;
        bottom: 0;
        background: rgba(37, 46, 63, .7);
        padding: 10px;
    }


    // Conteneur du petit slider
    #{$hb}__tiny-slider-container {
        display: flex;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        z-index: 10;

        @media screen and (max-width: 800px) {
            width: 100%;
            position: relative;
            bottom: initial;
            left: initial;
            transform: none;
        }
    }


    // Slider
    #{$hb}__tiny-slider {
        .slick-list,
        .slick-track,
        .slick-slide {
            height: 100%!important;
        }

        .slick-slide div {
            height: 100%!important;
        }

        .slick-slide div {
            font-size: 0;
        }
    }


    // Petit slider (Nav)
    #{$hb}__tiny-slider {
        width: var(--tiny-slider-dimensions);
        height: var(--tiny-slider-dimensions);
        @media screen and (max-width: 800px) { display: none; }
    }


    // Conteneur de l'image (petit slider)
    #{$hb}__next-picture {
        width: 100%;
        height: 100%;
    }


    // Nav
    #{$hb}__tiny-slider-nav {
        width: var(--tiny-slider-dimensions);
        height: var(--tiny-slider-dimensions);
        padding-left: 30px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background: var(--color-blue-light-blue);

        @media screen and (max-width: 900px) { padding-left: 20px; }
        @media screen and (max-width: 800px) {
            width: 100%;
            height: 56px;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            padding: 0 var(--x-spacing-50);
        }
    }


    // Les nombres du nav
    #{$hb}__slider-number {
        margin-bottom: 20px;

        // Tous les éléments
        .slideNumber .current,
        .slash,
        .slideNumber .total {
            font-family: var(--font-family-2); // Canela
            font-weight: 500;
            color: var(--color-base-white);
        }

        // Page active
        .slideNumber .current {
            font-size: 4rem;

            @media screen and (max-width: 900px) { font-size: 3rem; }
        }

        // Séparateur
        .slash {
            font-size: 2rem;

            @media screen and (max-width: 900px) { font-size: 1.7rem; }
        }

        // Total des slides
        .slideNumber .total {
            font-size: 2rem;

            @media screen and (max-width: 900px) { font-size: 1.7rem; }
        }

        @media screen and (max-width: 800px) { 
            margin-bottom: 0; 
            margin-right: 20px;
        }
    }


    // Conteneur des flèches
    #{$hb}__arrows-box {
        display: flex;
        align-items: center;
    }


    // Flèche | Précédent
    #{$hb}__arrows-box-arrow-prev {
        width: 45px;
        height: 19px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        transition: transform 400ms var(--ease-out-cubic);

        svg {
            transform: rotate(90deg);
        }

        &:hover {
            transform: translateX(-10px);
        }

        @media screen and (max-width: 900px) { width: 35px; }
    }


    // Séparateur
    #{$hb}__arrows-box-separator {
        width: 1px;
        height: 19px;
        background: var(--color-base-white);
    }


    // Flèche | Suivant
    #{$hb}__arrows-box-arrow-next {
        width: 45px;
        height: 19px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        transition: transform 400ms var(--ease-out-cubic);

        svg {
            transform: rotate(-90deg);
        }

        &:hover {
            transform: translateX(10px);
        }

        @media screen and (max-width: 900px) { width: 35px; }
    }


    // Les svg
    #{$hb}__svg {
        stroke: var(--color-base-white);

        // Chevron
        &--chevron {
            width: 19px;
            height: 11px;
            stroke-width: 2px;
            fill: none;
        }

        // Flèche
        &--arrow {
            width: 8px;
            height: 24px;
            fill: var(--color-base-white);
            position: absolute;
            top: 0;
            right: 0;

            animation-name: bouncing;
            animation-duration: 1s;
            animation-iteration-count: infinite;
            animation-timing-function: ease-out;
        }

        // Animation de la flèche
        @keyframes bouncing {
            0% { top: 0%; }
            50% { top: 25%; }
            100% { top: 0; }
        }
    }


    // Le lien vers les actus
    #{$hb}__link {
        height: 24px;
        font-family: var(--font-family-1); // Maison Neue
        font-size: 1.7rem;
        color: var(--color-base-white);
        opacity: 1;
        transition: opacity 300ms;
        padding-right: 30px;
        display: flex;
        align-items: center;
        position: absolute;
        bottom: var(--y-spacing-60);
        left: var(--x-spacing-160);
        z-index: 10;

        &:hover {
            opacity: 0.5;
        }

        @media screen and (max-width: 800px) { display: none; }

        @media screen and (max-height: 700px) { bottom: 30px; }
        @media screen and (max-height: 600px) { bottom: 20px; }
    }
}


// Quand l'alerte mobile est présente
.show-alerts {
    @media screen and (max-width: 800px) {
        .home-banner {

            $hb: '.home-banner'; // Variable désignant le home-banner


            // Conteneur
            #{$hb}__container {
                height: calc((var(--vh, 1vh) * 100) - var(--alert-mobile-height));
            }


            // Élément du slider
            #{$hb}__text-slider-slide {
                height: calc((var(--vh, 1vh) * 100) - var(--header-height) - var(--alert-mobile-height));
            }
        }
    }
}