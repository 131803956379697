@use 'components';


//--------------------
// Variables globales
//--------------------
:root {
    
}


//-------------------
// Variables locales
//-------------------
.showcase {

}


// ----------------
// Vitrine
// ----------------
.showcase {
    padding-top: 20px;
    padding-right: var(--x-spacing-120);
    padding-left: var(--x-spacing-120);
    padding-bottom: var(--y-spacing-150);


    // Seulement dans la page de contact
    &--contact {
        position: relative;

        &::before {
            content: '';
            width: var(--side-section-contact-width);
            height: calc(100% + var(--y-spacing-150));
            background: var(--color-blue-marine);
            position: absolute;
            top: 0;
            right: 0;
        }

        @media screen and (max-width: 1000px) {
            &::before {
                display: none;
            }
        }
    }


    // Seulement dans la page d'accueil
    &--home {
        position: relative;

        &:before {
            content: '';
            width: 100%;
            height: 215px;
            background: var(--color-grayscale-gray14);
            position: absolute;
            top: 0;
            left: 0;
        }
    }

    $sh: '.showcase'; // Variable désignant le showcase

    // Conteneur
    #{$sh}__container {
        min-height: 560px;
        position: relative;
        display: block;
        box-shadow: 0px 0px 15px 10px rgba(0,0,0,0.04);
        -webkit-box-shadow: 0px 0px 15px 10px rgba(0,0,0,0.04);
        -moz-box-shadow: 0px 0px 15px 10px rgba(0,0,0,0.04);

        @media screen and (max-height: 800px) { min-height: 540px; }
        @media screen and (max-height: 700px) { min-height: 520px; }
        @media screen and (max-width: 1100px) { min-height: 0; }
    }


    // Filtre de couleur sur l'image
    #{$sh}__filter {
        background: var(--color-blue-marine);
        background: linear-gradient(0deg, rgba(37,46,63,1) 0%, rgba(255,255,255,0) 70%);
        pointer-events: none;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 5;

        @media screen and (max-width: 400px) { 
            right: 0;
            left: 0;
        }
    }


    // Conteneur de l'image
    #{$sh}__picture {
        background: var(--color-blue-marine);
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        overflow: hidden;

        @media screen and (max-width: 400px) { 
            right: 0;
            left: 0;
        }
    }


    // Image 
    #{$sh}__img {
        opacity: 0.7;
        transform: scale(1);
        transition: transform 600ms var(--ease-out-quart);
    }


    // Effet d'hover du conteneur sur l'image
    #{$sh}__container:hover #{$sh}__img {
        transform: scale(1.1);
    }


    // Boîte de texte
    #{$sh}__text-box {
        padding: 80px 60px;
        position: relative;
        z-index: 10;

        @media screen and (max-width: 1024px) { padding: 70px 50px; }
        @media screen and (max-width: 768px) { padding: 60px 30px; }
        @media screen and (max-width: 600px) { padding: 50px 25px; }
    }


    // Catégorie
    #{$sh}__category {
        font-family: var(--font-family-1); // Maison Neue
        font-size: 1.2em;
        font-weight: 700;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: var(--color-base-white);
        padding-top: 30px;
        border-top: 1px solid var(--color-base-white);
        margin-bottom: 150px;

        @media screen and (max-height: 800px) { margin-bottom: 110px; }
        @media screen and (max-height: 700px) { margin-bottom: 100px; }
        @media screen and (max-width: 1100px) { margin-bottom: 130px; }
        @media screen and (max-width: 1000px) { margin-bottom: 120px; }
        @media screen and (max-width: 900px) { margin-bottom: 110px; }
        @media screen and (max-width: 800px) { margin-bottom: 100px; }
        @media screen and (max-width: 700px) { margin-bottom: 90px; }
        @media screen and (max-width: 600px) { margin-bottom: 80px; }
        @media screen and (max-width: 500px) { margin-bottom: 50px; }
        @media screen and (max-width: 400px) { margin-bottom: 30px; }
    }


    // Titre
    #{$sh}__title {
        @extend .large-title;
        font-weight: 400;
        color: var(--color-base-white);
        margin-bottom: 35px;
    }

    //Credit
    #{$sh}__credit {
        font-family: var(--font-family-1);
        font-size: 1.4rem;
        font-weight: 700;
        color: var(--color-base-white);
        position: absolute;
        right: 0;
        bottom: -20px;
        padding: 15px;
    }


    // Bouton
    #{$sh}__button {
        pointer-events: none;
    }


    // Effet d'hover du conteneur sur le bouton
    #{$sh}__container:hover #{$sh}__button {
        color: var(--button-color-hover);

        &::after { 
            top: 0; 
            border: 1px solid var(--button-border-color-hover);
        }

        svg {
            fill: var(--button-color-hover);
        }
    }
}