:root{
    --select-field__label-font-size: 1.7rem;
    --select-field__label-color: var(--color-blue-marine);
    --select-field__label-color-active: var(--color-grayscale-gray4);
    --select-field__label-background: #edf0f3;
    --select-field__label-background-active: var(--color-base-white);
    --select-field__label-background-hover: rgba(200, 218, 232, 0.7);
    --select-field__label-pointer-color: var(--color-blue-marine);
    --select-field__label-pointer-color-active: var(--color-blue-marine);

    --select-field__option-color: var(--color-blue-marine);
    --select-field__option-background: rgba(200, 218, 232, 0.3);
    --select-field__option-color-hover: var(--color-blue-marine);
    --select-field__option-background-hover: rgba(200, 218, 232, 0.6);
    --select-field__option-color-selected: var(--color-blue-marine);
    --select-field__option-background-selected: var(--color-blue-light-blue2);

    --select-field__scrollbar-color: var(--color-blue-marine);
    --select-field__scrollbar-background-color: var(--select-field__option-background);
}

.select-field {

    max-width: calc(50% - var(--form__grid-gap-x)/2);

    .tail-select {
        position: relative;
        z-index: 1;

        svg {
            position: absolute;
            width: 14px;
            height: 14px;
            top: 50%;
            right: 30px;
            transform: translateY(-50%);
            transition: transform 500ms, stroke 500ms;
            stroke: var(--select-field__label-pointer-color);
            stroke-width: 4px;
            fill: none;
        }
    }

    .form-label {
        padding-bottom: 15px;
    }

    .select-label {
        padding: 20px 60px 20px 40px;
        //border: 1px solid var(--color-blue-marine);
        background: #edf0f3;
        transition: background 500ms, border-color 500ms;
        cursor: pointer;
        user-select: none;

        &:hover { background: rgba(200, 218, 232, 0.7); }
    }

    .label-inner {
        display: block;
        position: relative;
        font-size: var(--select-field__label-font-size);
        line-height: 1.5em;
        color: var(--select-field__label-color);
        transition: color 500ms;
    }

    .select-dropdown {
        width: auto;
        padding-top: 65px;
        opacity: 0;
        background: white;
        z-index: -1;
        visibility: hidden;
        pointer-events: none;
        box-shadow: 0px 0px 15px 2px rgba(0,0,0,0.04);
        -webkit-box-shadow: 0px 0px 15px 2px rgba(0,0,0,0.04);
        -moz-box-shadow: 0px 0px 15px 2px rgba(0,0,0,0.04);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        transition: opacity 500ms var(--ease-in-out-quart), visibility 500ms;
    }

    .dropdown-inner {
        overflow: auto;
        max-height: 0;
        transition: max-height 500ms var(--ease-in-out-quart);

        &::-webkit-scrollbar {
            -webkit-appearance: none;
            width: 5px;
            height: 5px;
            background-color: var(--select-field__scrollbar-background-color);
        }
        &::-webkit-scrollbar-thumb{
            background-color: var(--select-field__scrollbar-color);
        }
    }

    .dropdown-option {
        padding: 20px 40px;
        font-family: var(--select-field__option-font-family);
        font-size: 1.5rem;
        font-weight: 500;
        line-height: 1.5em;
        color: var(--select-field__option-color);
        background: var(--select-field__option-background);
        cursor: pointer;
        transition: background-color 300ms, color 300ms;
        user-select: none;

        &:hover {
            color: var(--select-field__option-color-hover);
            background: var(--select-field__option-background-hover);
        }

        &.selected {
            color: var(--select-field__option-color-selected);
            background: var(--select-field__option-background-selected);
        }
    }

    // Select natif
    select {
        width: 100%;
        padding: 20px 60px 20px 40px;
        font-size: var(--select-field__label-font-size);
        font-weight: 500;
        line-height: 1.5em;
        text-transform: var(--select-field__label-text-transform);
        letter-spacing: var(--select-field__label-letter-spacing);
        color: var(--select-field__label-color);
        border: 0;
        outline: none;
        -webkit-appearance: none;
        background: url('../medias/images/icons/chevron.svg') no-repeat calc(100% - 20px) 50% / 12px 12px var(--select-field__label-background);

        // Permet de pouvoir scroller au select si le champs est requis et vide
        &[data-select-hidden="0"]{
            display: block !important;
            height: 0;
            opacity: 0;
            padding: 0;
            margin: 0;
        }
    }

    select::-ms-expand { display: none; }

    .tail-select.active {

        svg {
            transform: translateY(-50%) rotate(-180deg);
            stroke: var(--select-field__label-pointer-color-active);
        }

        .select-label {
            background: rgba(200, 218, 232, 0.7);
            //border-bottom: 1px solid var(--color-grayscale-gray2);
        }
        .label-inner{
            //color: var(--select-field__label-color-active);
        }
        .select-dropdown {
            opacity: 1;
            visibility: visible;
            pointer-events: all;
        }
        .dropdown-inner {
            max-height: 195px!important;
        }
    }

    &__error{
        position: absolute;
    }
}
