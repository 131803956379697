:root{
    --form-note__color: var(--color-grayscale-gray4);
}

.form-note{
    margin-top: 8px;
    color: var(--form-note__color);
    font-size: 1.4rem;

    &--bottom-padding {
        margin-bottom: 13px;
    }
}
