@use 'components';


//--------------------
// Variables globales
//--------------------
:root {

}


//-------------------
// Variables locales
//-------------------
.overlay-share {
    
}


// ----------------
// Overlay | Share
// ----------------
.overlay-share {
    pointer-events: none;
    position: fixed;
    top: 0; 
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 30;


    $ovsh: '.overlay-share'; // Variable désignant l'overlay share


    // Fond transparent
    #{$ovsh}__background {
        background: rgba($color: #000000, $alpha: 0.3);
        opacity: 0;
        visibility: hidden;
        transition: opacity 300ms, visibility 300ms;
        pointer-events: none;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
    }


    // Conteneur
    #{$ovsh}__container {
        padding: 85px;
        width: 520px;
		background: #fff;
        text-align: center;
        opacity: 0;
        box-shadow: 0px 0px 15px 10px rgba(0,0,0,0.04);
        -webkit-box-shadow: 0px 0px 15px 10px rgba(0,0,0,0.04);
        -moz-box-shadow: 0px 0px 15px 10px rgba(0,0,0,0.04);
        visibility: hidden;
        pointer-events: none;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%);
        transition: opacity 800ms, transform 800ms var(--ease-in-out-quart), visibility 800ms;

        @media screen and (max-width: 1200px) { width: 490px; }
        @media screen and (max-width: 1024px) { width: 400px; padding: 50px; }
        @media screen and (max-width: 600px)  {
            width: auto;
            right: 20px;
            left: 20px;
            padding: 50px 20px;
            transform: none;
        }
    }


    // Titre
    #{$ovsh}__title {
        @extend .medium-title;
        color: var(--color-blue-marine);
        margin-bottom: 20px;
    }


    // Bouton de fermeture
    #{$ovsh}__close {
        width: 16px;
        height: 16px;
        cursor: pointer;
        position: absolute;
        top: 20px;
        right: 20px;

        &:hover { &:before, &:after { background: var(--color-blue-light-blue); }}

        &:before, &:after {
            content: '';
            width: 16px;
            height: 2px;
            background: var(--color-blue-marine);
            transition: background 300ms;
            position: absolute;
            top: 50%;
            left: 50%;
        }
        &:before { transform: translate(-50%, -50%) rotate(45deg) ; }
        &:after { transform: translate(-50%, -50%) rotate(-45deg) ; }
    }


    // Liste de réseaux sociaux
    #{$ovsh}__list {
        margin-top: 16px;
        position: relative;
    }


    // Réseaux sociaux
    #{$ovsh}__list-item {
        display: inline-block;

        &:not(:last-child) {
            margin-right: 20px;

            @media screen and (max-width: 600px) { margin-right: 10px; }
        }
    }


    // Lien
    #{$ovsh}__link {
        width: 45px;
        height: 45px;
        background: var(--color-blue-marine);
        transition: background 300ms;
        cursor: pointer;
        display: block;
        position: relative;

        &:hover { background: var(--color-blue-light-blue); }
    }

    #{$ovsh}__svg {
        width: 16px;
        height: 16px;
        fill: #fff;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}


// Overlay | Actif
.show-overlay-share {


    // Overlay de recherche | Actif
    .overlay-share {
        $ovsh: '.overlay-share'; // Variable désignant l'overlay share


        // Fond transparent | Actif
        #{$ovsh}__background {
            opacity: 1;
            visibility: visible;
            pointer-events: all;
        }


        // Conteneur | Actif
        #{$ovsh}__container {
            opacity: 1;
            visibility: visible;
            pointer-events: all;
            transform: translate(-50%, -50%);

            @media screen and (max-width: 600px) { transform: translateY(-50%); }
        }
    }
}


// Overlay | Fermeture
.show-overlay-share.closing-overlay-share {


    // Overlay de partage | Fermeture
    .overlay-share {
        $ovsh: '.overlay-share'; // Variable désignant l'overlay share


        // Fond transparent | Fermeture
        #{$ovsh}__background {
            opacity: 0;
            visibility: hidden;
            pointer-events: none;
        }


        // Conteneur | Fermeture
        #{$ovsh}__container {
            opacity: 0;
            visibility: hidden;
            pointer-events: none;
            transform: translateX(-50%);

            @media screen and (max-width: 600px) { transform: none; }
        }
    }
}