:root {
    --rubric__font-size: 2.5rem;
    --rubric__subtitle-font-size: 1.2rem;

    --rubric__height: 145px;
    --rubric__thumb-width: 100px;
    --rubric__thumb-height: 100px;

    --rubric__color: var(--color-blue-marine);
    --rubric__color-hover: var(--color-blue-light-blue);

    --rubric__plus-size: 20px;
    --rubric__plus-color:var(--color-blue-marine);
    --rubric__plus-color-hover: var(--color-blue-light-blue);

    @media screen and (max-width: 1024px) {
        --rubric__font-size: 2.3rem;
        --rubric__height: 120px;
    }

    @media screen and (max-width: 768px) {
        --rubric__font-size: 2.2rem;
        --rubric__height: 100px;
    }
}

.rubric {
    position: relative;
    border-bottom: 1px solid var(--color-grayscale-gray3);
    background: transparent;
    transition: border-bottom 700ms, background 700ms var(--ease-in-out-quart) 100ms;

    &:first-child { border-top: 1px solid var(--color-grayscale-gray3); }

    &__header {
        position: relative;
        cursor: pointer;
        padding: 20px 80px 20px 40px;
        padding-right: 115px;
        min-height: var(--rubric__height);
        display: flex;
        align-items: center;

        @media screen and (max-width: 600px) { padding: 20px 60px 20px 20px; }
    }

    &__picture {
        width: var(--rubric__thumb-width);
        height: var(--rubric__thumb-height);
        margin-right: 40px;
        overflow: hidden;
        flex-shrink: 0;

        @media screen and (max-width: 600px)  { display: none; }
    }

    &__picture-img {
        transition: transform 0.3s;
        transform: scale(1);
    }

    &__subtitle {
        margin-bottom: 4px;
        font-size: var(--rubric__subtitle-font-size);
        color: var(--color-blue-blue);
        font-weight: 700;
        line-height: 1.4em;
        text-transform: uppercase;
        letter-spacing: 0.1em;
    }

    &__title {
        font-size: var(--rubric__font-size);
        line-height: 1.6;
        color: var(--rubric__color);
        font-weight: 500;
        transition: color 0.3s;
    }

    &__plus {
        position: absolute;
        width: var(--rubric__plus-size);
        height: var(--rubric__plus-size);
        top: 50%;
        right: 60px;
        transform: translateY(-50%);

        @media screen and (max-width: 600px) { right: 20px; }

        &::before,
        &::after {
            content: '';
            position: absolute;
            width: var(--rubric__plus-size);
            height: 2px;
            background-color: var(--rubric__plus-color);
            left: 50%;
            top: 50%;
            transition: background-color 0.3s, opacity 0.3s;
            transform: translate(-50%,-50%);
            opacity: 1;
        }

        &::after {
            transform: translate(-50%,-50%) rotate(90deg);
        }
    }

    &__header:hover {
        .rubric__picture-img {
            transform: scale(1.1);
        }
        .rubric__title {
            color:var(--rubric__color-hover);
        }
        .rubric__plus {
            &::before,
            &::after {
                background-color: var(--rubric__plus-color-hover);
            }
        }
    }

    &__content {
        height: 0;
        will-change: height;
        overflow: hidden;
        @media screen and (max-width: 1024px) { padding: 0; }
    }

    &__transition {
        padding: 0 40px;
        opacity: 0;
        transform: translateY(100px);
        transition: opacity 700ms var(--ease-in-out-quart), transform 700ms var(--ease-in-out-quart);

        @media screen and (max-width: 600px) { padding: 0 20px; }
    }
    
    &__transition > :first-child { margin-top: 0; }

    &__dynamic {
        h3 {
            margin-top: 50px;
            margin-bottom: 25px;
            line-height: 1.45em;
            font-weight: 500;
            font-size: 2.2rem;
            
            @media screen and (max-width: 1024px) { font-size: 2.1rem; }
            @media screen and (max-width: 768px) { font-size: 2rem; }
        }
        
        h4 {
            margin-top: 50px;
            margin-bottom: 25px;
            line-height: 1.5em;
            font-size: 1.8rem;
            font-weight: 700;
        }

        .table-wrapper table tbody tr {
            td {
                &.header{
                  background-color: var(--dynamic__table-header-background);
                }  
                &.secondary-header{
                  background-color: var(--color-base-white);
                }  
              }
        }
    }

    &__dynamic, &__document, &__gallery, &__share {
        padding-bottom: var(--y-spacing-60);
    }

    &__document:last-child{
        padding-bottom: var(--y-spacing-90);
    }

    &__share {
        padding-bottom: 45px;

        .share .share__text {
            color: var(--rubric__color);
        }
    }

}


/*
|--------------------------------------------------------------------------
| RUBRIQUE OUVERTE
|--------------------------------------------------------------------------
*/
.rubric.js-accordion-opened {
    background: var(--color-grayscale-gray1);

    .rubric__transition {
        opacity: 1;
        transform: none;
        transition: opacity 700ms var(--ease-in-out-quart) 100ms, transform 700ms var(--ease-in-out-quart) 100ms;
    }

    .rubric__plus {
        &::after{
            opacity: 0;
        }
    }
}