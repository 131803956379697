//--------------------
// Variables globales
//--------------------
:root {
    
}


//-------------------
// Variables locales
//-------------------
.share {

}


//-------------------
// Share
//-------------------
.share {
    $shr: '.share'; // Variable désignant le share

    display: inline-flex;
    align-items: center;


    // Conteneur
    #{$shr}__svg-container {
        width: 50px;
        height: 50px;
        background: var(--color-base-white);
        transition: background 300ms;
        margin-right: 17px;
        position: relative;

        &::after {
            content: '';
            background: var(--button-background-hover);
            border: 0px solid var(--button-border-color-hover);
            transition: border 300ms;
            position: absolute;
            top: 100%;
            right: 0;
            bottom: 0;
            left: 0;
            transition: top 300ms;
            z-index: 1;
        }

        @media screen and (max-width: 600px) {
            width: 40px;
            height: 40px;
        }
    }


    // Effet du hover du share sur le conteneur du svg
    &:hover #{$shr}__svg-container {
        &::after { 
            top: 0; 
            border: 1px solid var(--color-blue-light-blue);
        }
    }


    // Svg
    #{$shr}__svg {
        width: 14px;
        height: 14px;
        fill: var(--color-blue-light-blue);
        transition: fill 300ms;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        z-index: 2;
    }


    // Effet du hover du share sur le svg
    &:hover #{$shr}__svg {
        fill: var(--color-base-white);
    }


    // Texte
    #{$shr}__text {
        font-family: var(--font-family-1); // Maison Neue
        font-size: 1.4rem;
        font-weight: 700;
        color: var(--color-base-white);
    }
}