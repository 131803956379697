@use 'components';


//--------------------
// Variables globales
//--------------------
:root {
    --sections-list-gap: 47px;

    @media screen and (max-width: 1400px) { --sections-list-gap: 55px; }
    @media screen and (max-width: 1300px) { --sections-list-gap: 40px; }
    @media screen and (max-width: 1200px) { --sections-list-gap: 30px; }
    @media screen and (max-width: 950px)  { --sections-list-gap: 20px; }
    @media screen and (max-width: 500px)  { --sections-list-gap: 0px; }
}

.sections {
    $ss: '.sections'; // Variable désignant la liste d'actualités


    // Conteneur
    #{$ss}__container {
        padding-top: var(--y-spacing-100);
        padding-right: var(--x-spacing-160);
        padding-bottom: var(--y-spacing-100);
        padding-left: var(--x-spacing-160);

        @media screen and (max-height: 800px) { padding-top: var(--y-spacing-80); }
        @media screen and (max-height: 700px) { padding-top: var(--y-spacing-50); }
    }


    // Contenu
    #{$ss}__list {
        width: calc(100% + (var(--sections-list-gap) * 2));
        margin-right: calc(0px - var(--sections-list-gap));
        margin-bottom: calc(0px - var(--y-spacing-130));
        margin-left: calc(0px - var(--sections-list-gap));
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
    }


    #{$ss}__list-item {
        width: 50%;
        padding: 0 var(--sections-list-gap);
        margin-bottom: var(--y-spacing-130);
        @media screen and (max-width: 1024px) { margin-bottom: var(--y-spacing-110); }
        @media screen and (max-width: 900px) { margin-bottom: var(--y-spacing-80); }
        @media screen and (max-width: 768px) { margin-bottom: var(--y-spacing-70); }
        @media screen and (max-width: 768px) { width: 100%; }
        @media screen and (max-width: 600px) { margin-bottom: var(--y-spacing-60); }
    }


    #{$ss}__list-link {
        width: 100%;
        display: block;
    }


    #{$ss}__picture-container {
        width: 100%;
    }

    #{$ss}__picture {
        width: 100%;
        padding-top: 51%;
        position: relative;
        overflow: hidden;
    }

    #{$ss}__img {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transform: scale(1);
        transition: transform 600ms var(--ease-out-quart);
    }
    
    // Effet d'hover du conteneur sur l'image
    #{$ss}__list-item:hover #{$ss}__img {
        transform: scale(1.1);
    }

    #{$ss}__text-block {
        width: 100%;
        padding-top: 40px;
        padding-right: 25px;
    }

    #{$ss}__text {
        font-family: var(--font-family-2); // Canela
        font-size: 3.5rem;
        font-weight: 500;
        line-height: 1.25em;
        color: var(--color-blue-marine);
        @media screen and (max-width: 1024px) { font-size: 3.2rem; }
        @media screen and (max-width: 900px) { font-size: 3rem; }
        @media screen and (max-width: 768px) { font-size: 2.8rem; }
        @media screen and (max-width: 600px) { font-size: 2.6rem; }
    }
    
    // Effet d'hover du conteneur sur le titre
    #{$ss}__list-item:hover #{$ss}__text {
        color: var(--color-blue-blue);
    }
}