//--------------------
// Variables globales
//--------------------
:root {
    
}


//-------------------
// Variables locales
//-------------------
.side-pagination {

}


// ----------------
// Side-pagination
// ----------------
.side-pagination {
    $sp: '.side-pagination'; // Variable désignant la side-pagination
    
    
    // Fait descendre side-menu avec la page
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 50px;


    // Conteneur
    #{$sp}__container {
        width: 100%;
        padding: 45px 20px 60px 40px;
        box-shadow: 0px 0px 15px 10px rgba(0,0,0,0.04);
        -webkit-box-shadow: 0px 0px 15px 10px rgba(0,0,0,0.04); 
        -moz-box-shadow: 0px 0px 15px 10px rgba(0,0,0,0.04);
    }


    // Titre
    #{$sp}__title {
        font-family: var(--font-family-2); // Canela
        font-size: 2.5rem;
        color: var(--color-blue-marine);
        border-bottom: 1px solid var(--color-grayscale-gray3);
        padding-bottom: 20px;
    }


    // Conteneur des flèches
    #{$sp}__arrows-box {
        display: flex;
        align-items: center;
        margin-top: 20px; 
        position: relative;

        &:after {
            content: '';
            width: 1px;
            height: 16px;
            background: var(--color-grayscale-gray3);
            position: absolute;
            top: 50%;
            left: 40px;
            transform: translateY(-50%);
        }
    }


    // Flèches - Inactif
    #{$sp}__inactive {
        opacity: 0.3;
        pointer-events: none;
    }

    // Flèche | Précédent - Conteneur
    #{$sp}__prev {
        padding-right: 25px;
    }


    // Flèche | Suivant - Conteneur
    #{$sp}__next {
        padding-left: 25px;
    }


    // Flèche | Précédent
    #{$sp}__prev #{$sp}__svg {
        transform: rotate(90deg) translateX(1px);
    }


    // Flèche | Suivant
    #{$sp}__next #{$sp}__svg {
        transform: rotate(-90deg) translateX(-1px);
    }


    // Flèches
    #{$sp}__svg {
        width: 16px;
        height: 13px;
        stroke: var(--color-blue-marine);
        stroke-width: 2px;
        fill: none;
        transition: stroke 300ms;

        &:hover {
            stroke: var(--color-blue-light-blue);
        }
    }


    // Bouton
    #{$sp}__button {
        margin-top: 65px;
    }
}