//--------------------
// Variables globales
//--------------------
:root {
    
}


//-------------------
// Variables locales
//-------------------
.filter {
    --filter-height: 80px;
}


// ----------------
// Filter
// ----------------
.filter {
    $f: '.filter'; // Variable désignant le filter

    box-shadow: 0px 0px 15px 10px rgba(0,0,0,0.04);
    -webkit-box-shadow: 0px 0px 15px 10px rgba(0,0,0,0.04);
    -moz-box-shadow: 0px 0px 15px 10px rgba(0,0,0,0.04);
    display: flex;
    align-items: center;
    padding-right: 90px;
    position: relative;


    // Conteneur
    #{$f}__container {
        width: 100%;
        background: var(--color-base-white);
        padding-left: 25px;
        display: flex;
        align-items: center;
        overflow: hidden;
    }


    // Titre
    #{$f}__title {
        font-family: var(--font-family-1); // Maison Neue
        font-size: 1.1em;
        font-weight: 700;
        letter-spacing: 0.2em;
        color: var(--color-blue-marine);
        text-transform: uppercase;
        opacity: 0.6;
        white-space: nowrap;
        margin-right: 30px;

        @media screen and (max-width: 600px) { display: none; }
    }


    #{$f}__slider-container {
        min-width: 0;
    }


    #{$f}__slider {
        white-space: nowrap;
    }


    // Conteneur de chaque catégorie
    #{$f}__slider-slide {
        width: auto!important;
        height: var(--filter-height);
        margin-right: 45px!important;
        display: inline-flex!important;
        align-items: center;
        position: relative;

        &::after {
            content: "";
            width: 100%;
            opacity: 0;
            visibility: hidden;
            height: 3px;
            background: var(--color-blue-light-blue);
            position: absolute;
            left: 0;
            bottom: 0;
        }

        &.--active {  
            &::after {
                opacity: 1;
                visibility: visible;
            }

            #{$f}__category {
                opacity: 1;
            }
        }
    }


    // Catégorie
    #{$f}__category {
        font-family: var(--font-family-1); // Maison Neue
        font-size: 1.4em;
        font-weight: 500;
        line-height: 1.6;
        color: var(--color-blue-marine);
        opacity: 0.6;
        transition: color 300ms, opacity 300ms;

        &:hover {
            color: var(--color-blue-marine);
            opacity: 1;
        }
    }


    // Conteneur des flèches
    #{$f}__arrows {
        position: absolute;
        top: 50%;
        right: 25px;
        transform: translate3d(0, -50%, 0);
        display: none;

        &.--visible {
            display: block;
        }
    }


    // Contenu des flèches
    #{$f}__arrows-container {
        display: flex;
        align-items: center;
    }


    // Flèche | Précédent
    #{$f}__prev {
        transform: rotate(90deg) translateX(-1px);
        margin-right: 15px;
    }


    // Flèche | Suivant
    #{$f}__next {
        transform: rotate(-90deg) translateX(1px);
    }


    #{$f}__prev,
    #{$f}__next {
        &.--disable {
            opacity: 0.5;
            pointer-events: none;
        }
    }


    // Flèches du slider
    #{$f}__svg {

        &--chevron {
            width: 16px;
            height: 10px;
            stroke: var(--color-blue-marine);
            stroke-width: 2px;
            fill: none;
        }
    }


    // Bouton du calendrier 
    #{$f}__calendar-button {
        height: var(--filter-height);
        background: var(--color-blue-light-blue);
        transition: background-color 300ms;
        color: var(--color-base-white);
        padding: 0 55px;
        margin-right: -35px;
        margin-left: 35px;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
            background: var(--color-blue-darker-marine);
        }

        @media screen and (max-width: 768px) { 
            padding: 0 30px; 
            margin-right: -30px;
            margin-left: 30px;
        }
        @media screen and (max-width: 600px) { 
            padding: 0 25px; 
            margin-right: -25px;
            margin-left: 25px;
        }
        @media screen and (max-width: 600px) { margin-left: 15px; }
    }


    // Svg du calendrier
    #{$f}__svg {

        // Calendrier
        &--calendar {
            width: 16px;
            height: 16px;
            stroke: var(--color-base-white);
            stroke-width: 2px;
            fill: none;
            margin-right: 15px;

            @media screen and (max-width: 768px) { 
                width: 18px;
                height: 18px;
                margin-right: 0; 
            }
        }
    }


    // Texte du bouton de calendrier
    #{$f}__calendar-button-text {
        font-family: var(--font-family-1);
        font-size: 1.1rem;
        font-weight: 700;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: var(--color-base-white);

        @media screen and (max-width: 768px) { display: none; }
    }
}