//--------------------
// Variables globales
//--------------------
:root {
    
}


//-------------------
// Variables locales
//-------------------
.jobs-list {
    
}


// ----------------
// Jobs - List
// ----------------
.jobs-list {
    $jl: '.jobs-list';


    // Conteneur
    #{$jl}__container {
        padding-right: var(--x-spacing-160);
        padding-bottom: var(--y-spacing-120);
        padding-left: var(--x-spacing-160);
    }
}