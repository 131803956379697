@use 'components';


//--------------------
// Variables globales
//--------------------
:root {
    --news-list-gap: 47px;

    @media screen and (max-width: 1400px) { --news-list-gap: 40px; }
    @media screen and (max-width: 1300px) { --news-list-gap: 30px; }
    @media screen and (max-width: 1200px) { --news-list-gap: 20px; }
    @media screen and (max-width: 950px)  { --news-list-gap: 10px; }
    @media screen and (max-width: 500px)  { --news-list-gap: 0px; }
}


//-------------------
// Variables locales
//-------------------
.news-list {
    
}


// ----------------
// News - List
// ----------------
.news-list {
    $nl: '.news-list'; // Variable désignant la liste d'actualités


    // Conteneur
    #{$nl}__container {
        padding-top: var(--y-spacing-100);
        padding-right: var(--x-spacing-160);
        padding-bottom: var(--y-spacing-40);
        padding-left: var(--x-spacing-160);

        @media screen and (max-height: 800px) { padding-top: var(--y-spacing-80); }
        @media screen and (max-height: 700px) { padding-top: var(--y-spacing-50); }
    }


    // Contenu
    #{$nl}__content {
        width: calc(100% + (var(--news-list-gap) * 2));
        margin-left: calc(0px - var(--news-list-gap));
        margin-right: calc(0px - var(--news-list-gap));
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
    }
}