:root{
    --file-field__background: var(--color-grayscale-gray1);
    --file-field__background-hover: var(--color-blue-light-blue);

    --file-field__color: var(--color-blue-marine);
    --file-field__color-hover: var(--color-base-white);

    --file-field__font-size: 1.4rem;
    --file-field__svg-size: 14px;
}

.file-field {
    position: relative;

    // File
    &__input {
        position: absolute;
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        z-index: -1;
    }

    &__label {
        display: block;
        position: relative;
        width: 100%;
        padding: 25px 60px 25px 40px;
        cursor: pointer;
        background-color: var(--file-field__background);
        transition: color 300ms, background 300ms;

        &:hover {
            background: var(--file-field__background-hover);
        }
    }
    
    &__text {
        font-family: var(--font-family-1); // Maison Neue
        font-size: var(--file-field__font-size);
        font-weight: 500;
        line-height: 1.5em;
        color: var(--file-field__color);
        transition: color 300ms;
    }

    &__svg {
        position: absolute;
        width: var(--file-field__svg-size);
        height: var(--file-field__svg-size);
        top: 50%;
        right: 30px;
        transform: translate(0,-50%);
        fill: var(--file-field__color);
        transition: fill 300ms, opacity 300ms;
    }

    &__x {
        position: absolute;
        width: var(--file-field__svg-size);
        height: var(--file-field__svg-size);
        top: 50%;
        transform: translate(0, -50%);
        right: 20px;
        opacity: 0;
        z-index: 1;
        cursor: pointer;
        pointer-events: none;
        transition: opacity 300ms, visibility 0ms ease 300ms;
    }

    &__x:before,  &__x:after {
        content: '';
        position: absolute;
        width: 16px;
        height: 2px;
        top: 50%;
        left: 50%;
        background: var(--file-field__color);
        transition: background 300ms;

        @media screen and (max-width: 600px) { width: 20px; }
    }

    &__error{
        position: absolute;
    }

    &__x:before {
        transform: translate(-50%, -50%) rotate(45deg);
    } 

    &__x:after {
        transform: translate(-50%, -50%) rotate(-45deg);
    } 

    [data-file]:not([data-file=""]) &__label {
        pointer-events: none;
    }

    [data-file]:not([data-file=""]) &__svg {
        opacity: 0;
    }

    [data-file]:not([data-file=""]) &__x {
        opacity: 1;
        visibility: visible;
        pointer-events: all;
    }

    &__label:hover &__text {
        color: var(--file-field__color-hover);
    }

    &__label:hover &__svg {
        fill: var(--file-field__color-hover);
    }

    &__x:hover:before,  &__x:hover:after {
        background: var(--file-field__color-hover);
    }
}