:root{
    --title-field__size: 2.5em;
    --subtitle-field__size: 2em;
}

.title-field {
    margin-bottom: -20px; // Mettre un peu moins de marge sous les titres dans les forms

    &__heading{
        font-size: var(--title-field__size);
        line-height: 1.4em;
        font-weight: 700;
        color: var(--color-blue-blue);
    }
}

.subtitle-field {
    margin-bottom: -20px; // Mettre un peu moins de marge sous les titres dans les forms

    &__heading{
        font-size: var(--subtitle-field__size);
        line-height: 1.4em;
        font-weight: 700;
        color: var(--color-blue-marine);
    }
}
