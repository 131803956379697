@use 'components';


//--------------------
// Variables globales
//--------------------
:root {
    --side-section-width: 360px;
    --side-section-contact-width: 520px;

    @media screen and (max-width: 1300px) { --side-section-contact-width: 500px; }
    @media screen and (max-width: 1200px) { --side-section-contact-width: 480px; }
    @media screen and (max-width: 1100px) { --side-section-contact-width: 460px; }
    @media screen and (max-width: 1024px) { --side-section-contact-width: 440px; }
}


//-------------------
// Variables locales
//-------------------
.side-section {
    
}


// ----------------
// Side | Section
// ----------------
.side-section {
    $ss: '.side-section'; // Variable désignant la side-section

    @media screen and (max-width: 1000px) { display: none; }


    // Conteneur
    #{$ss}__container {
        width: var(--side-section-width);
        height: 100%;
        padding-top: 95px;
        padding-right: 40px;
        padding-bottom: var(--y-spacing-100);
        position: relative;
        

        // Seulement dans la page de contact
        &--contact {
            height: calc(100% + 190px);
            width: var(--side-section-contact-width);
            background: var(--color-blue-marine);
            padding-top: var(--y-spacing-80);
            padding-right: 0;
            margin-top: -190px;
            z-index: 5;
        }
    }
}