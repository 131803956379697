// |--------------------------------------------------------------------------
// | Les placeholders de Scrollfire
// |--------------------------------------------------------------------------
// |
// | Placeholders Créant les effets scrollfire, Utiliser le state par défaut
// | sur un sélecteur (fade), puis utiliser le fire lorsque l'effet est
// | déclenché (fade--fire)
// |--------------------------------------------------------------------------


// -----
// Fade
// -----
.fade {
    opacity: 0;
    transition-property: opacity;
    transition-duration: 800ms;
}

.fade--fire {
    opacity: 1;
}


// --------
// Fade up
// --------
.fade-up {
    opacity: 0;
    transform: translateY(50px);
    transition-property: transform,opacity;
    transition-duration: 800ms;
    transition-timing-function: var(--ease-out-cubic);

    @media screen and (max-width: 768px) { transform: translateY(30px); }
}

.fade-up--fire {
    opacity: 1;
    transform: none;
}



// ----------
// Fade down
// ----------
.fade-down {
    opacity: 0;
    transform: translateY(-50px);
    transition-property: transform,opacity;
    transition-duration: 1000ms;
    transition-timing-function: var(--ease-out-quart);
}

.fade-down--fire {
    opacity: 1;
    transform: none;
}