// |--------------------------------------------------------------------------
// | Les bases du site
// |--------------------------------------------------------------------------
// |
// | Le scss de base affectant le site au complet.
// |--------------------------------------------------------------------------
@use '../abstracts' as *;


html {
    font-size: 62.5%;
    overflow-y: scroll;
    font-family: 'Roboto';
}

.fancybox-caption__body {
    font-family: 'Roboto';
}

picture {
    display: block;
    font-size: 0;

    img { width: 100%; height: 100%; }

    [data-object-fit='cover'] {
        object-fit: cover;
        font-family: 'object-fit: cover;';
    }
}

// Générer les variables CSS des espacements sur l'axe des Y
.js-scrollfire-delay-enabled {
    @each $scrollfire-delay, $value in $scrollfire-delays {
        .scrollfire-delay-#{$scrollfire-delay} {
            transition-delay: $value;
        }
    }
}
