//--------------------
// Variables globales
//--------------------
:root {
    
}


//-------------------
// Variables locales
//-------------------
.pagination-mobile {

}


// ----------------
// Pagination (mobile)
// ----------------

.pagination-mobile {
    width: 100%;
    padding-right: var(--x-spacing-120);
    padding-left: var(--x-spacing-120);
    margin-bottom: var(--y-spacing-160);
    display: none;

    @media screen and (max-width: 1000px) { display: block; }

    $pm: '.pagination-mobile'; // Variable désignant la pagination-mobile


    // Conteneur
    #{$pm}__container {
        border-top: 1px solid var(--color-grayscale-gray3);
        padding-top: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media screen and (max-width: 400px) {
            flex-direction: column;
            justify-content: initial;
            align-items: flex-start;
        }
    }


    // Conteneur des flèches
    #{$pm}__arrows-box {
        display: flex;
        align-items: center;
        position: relative;

        &:after {
            content: '';
            width: 2px;
            height: 16px;
            background: var(--color-grayscale-gray3);
            position: absolute;
            top: 50%;
            left: 40px;
            transform: translateY(-50%);
        }
    }
    
    // Flèches - Inactif
    #{$pm}__inactive {
        opacity: 0.3;
        pointer-events: none;
    }

    // Flèche | Précédent - Conteneur
    #{$pm}__prev {
        padding-right: 25px;
    }


    // Flèche | Suivant - Conteneur
    #{$pm}__next {
        padding-left: 25px;
    }


    // Flèche | Précédent
    #{$pm}__prev #{$pm}__svg {
        transform: rotate(90deg) translateX(1px);
    }


    // Flèche | Suivant
    #{$pm}__next #{$pm}__svg {
        transform: rotate(-90deg) translateX(-1px);
    }


    // Flèches
    #{$pm}__svg {
        width: 16px;
        height: 13px;
        stroke: var(--color-blue-marine);
        stroke-width: 2px;
        fill: none;
        transition: stroke 300ms;

        &:hover {
            stroke: var(--color-blue-light-blue);
        }
    }


    // Bouton
    #{$pm}__button {
        @media screen and (max-width: 400px) { margin-top: 40px; }
    }
}