//--------------------
// Variables globales
//--------------------
:root {
    
}


//-------------------
// Variables locales
//-------------------
.side-menu {

}


// ----------------
// Side-menu
// ----------------
.side-menu {
    $sm: '.side-menu'; // Variable désignant le side-menu
    

    // Fait descendre side-menu avec la page
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 50px;


    // Conteneur
    #{$sm}__container {
        width: 100%;
        padding: 45px 20px 60px 40px;
        box-shadow: 0px 0px 15px 10px rgba(0,0,0,0.04);
        -webkit-box-shadow: 0px 0px 15px 10px rgba(0,0,0,0.04);
        -moz-box-shadow: 0px 0px 15px 10px rgba(0,0,0,0.04);
    }


    // Titre
    #{$sm}__title {
        font-family: var(--font-family-2); // Canela
        font-size: 2.5rem;
        color: var(--color-blue-marine);
        border-bottom: 1px solid var(--color-grayscale-gray3);
        padding-bottom: 20px;
        transition: color 300ms;
    }

    #{$sm}__title-container:hover #{$sm}__title {
        color: var(--color-grayscale-gray5);
    }


    // Liste de liens
    #{$sm}__list {
        padding: 35px 15px 0px;
        display: flex;
        flex-direction: column;
    }


    // Liens | Conteneur
    #{$sm}__list-item {
        &:not(:last-child) {
            margin-bottom: 20px;
        }
    }


    // Liens
    #{$sm}__link {
        font-family: var(--font-family-1); // Maison Neue
        font-size: 1.5em;
        color: var(--color-grayscale-gray5);
        transition: color 300ms;
        padding: 5px 0;

        &:hover {
            color: var(--color-blue-marine);
        }

        &--active {
            color: var(--color-blue-light-blue);
            font-weight: 700;
            position: relative;

            &::before {
                content: '';
                width: 4px;
                height: 4px;
                background: var(--color-blue-light-blue);
                border-radius: 50%;
                position: absolute;
                top: 50%;
                left: -15px;
                transform: translateY(-50%);
            }
        }
    }
}