@use 'components';


//--------------------
// Variables globales
//--------------------
:root {
    
}


//-------------------
// Variables locales
//-------------------
.events-list {
    
}


// ----------------
// Events - List
// ----------------
.events-list {
    $el: '.events-list'; // Variable désignant la liste d'événements


    // Conteneur
    #{$el}__container {
        padding-top: var(--y-spacing-100);
        padding-right: var(--x-spacing-120);
        padding-bottom: var(--y-spacing-40);
        padding-left: var(--x-spacing-120);
        
        @media screen and (max-height: 800px) { padding-top: var(--y-spacing-80); }
        @media screen and (max-height: 700px) { padding-top: var(--y-spacing-50); }
    }

    &__date {
        display: flex;
        justify-content: space-between;
        color: var(--color-blue-base);
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 50px;
        
        &--label {
            @extend .medium-title;
        }
        
        &--btn {
            padding-left: 30px;
        }

        @media( max-width: 500px ) {
            &--label {
                width: 100%;
            }
    
            &--btn {
                width: 100%;
                padding-left: 0;
                padding-top: 30px;

                .button {
                    display: inline-block;
                    text-align: center;
                    width: 100%;
                }
            }
        }
    }
}