:root {
    --search-field__color: var(--color-blue-marine);
    --search-field__color-placeholder: var(--color-grayscale-gray6);
    --search-field__font-size: 4rem;

    @media screen and (max-width: 1200px) { --search-field__font-size: 3.5rem; }
    @media screen and (max-width: 1100px) { --search-field__font-size: 3.4rem; }
    @media screen and (max-width: 1000px) { --search-field__font-size: 3.3rem; }
    @media screen and (max-width: 900px) { --search-field__font-size: 3.2rem; }
    @media screen and (max-width: 800px) { --search-field__font-size: 3.1rem; }
    @media screen and (max-width: 700px) { --search-field__font-size: 3rem; }
    @media screen and (max-width: 600px) { --search-field__font-size: 2.9rem; }
    @media screen and (max-width: 500px) { --search-field__font-size: 2.8rem; }
    @media screen and (max-width: 400px) { --search-field__font-size: 2.5rem; }
    @media screen and (max-width: 360px) { --search-field__font-size: 2.4rem; }

    --search-field__background: var(--color-base-white);

    --search-btn-width: 60px;

    @media screen and (max-width: 600px) { --search-btn-width: 50px }
}

.search-field {
    height: 105px;
    text-align: right;
    margin-bottom: 0;
    position: relative;
    padding-bottom: 20px;
    border-bottom: 1px solid var(--color-blue-marine);
    display: flex;
    align-items: flex-end;

    &__input {
        width: 100%;
        padding-right: 125px;
        font-family: var(--font-family-2);  // Canela
        font-size: var(--search-field__font-size);
        color: var(--search-field__color);
        border: 0;
        border-radius: 0; // Nécéssaire même si 0 pour éviter les coins ronds en iOS
        border: none;
        background-color: var(--search-field__background);
        -webkit-tap-highlight-color: rgba(0,0,0,0);
        -webkit-appearance: none;

        @media screen and (max-width: 700px) { padding-right: 115px; }
        @media screen and (max-width: 600px) { padding-right: 100px; }
        @media screen and (max-width: 500px) { padding-right: 80px; }
    }

    &[data-inputvalue]:not([data-inputvalue=""]) &__x {
        opacity: 1;
        visibility: visible;
        transition: opacity 300ms, background-color 300ms, visibility 0ms ease 300ms;
    }
    
    &__x {
        width: 25px;
        height: 25px;
        opacity: 0;
        visibility: hidden;
        cursor: pointer;
        position: absolute;
        top: 50%;
        right: calc(25px + var(--search-btn-width));
        transform: translateY(-50%);
        
        &:before, &:after {
            content: '';
            position: absolute;
            width: 12px;
            height: 1px;
            top: 50%;
            left: 50%;
            background: var(--search-field__color);
        }
        &:before { transform: translate(-50%, -50%) rotate(45deg); }
        &:after  { transform: translate(-50%, -50%) rotate(-45deg); }

        @media screen and (max-width: 600px) {  
            top: initial;
            bottom: 20px;
        }
    }

    &__button {
        width: var(--search-btn-width);
        height: var(--search-btn-width);
        background: var(--search-field__background);
        transition: background-color 300ms;
        position: absolute;
        right: 0;
        bottom: 20px;

        &:hover {
            background: var(--search-field__color);
        }
    }

    &__svg {
        
        // Flèche dans la recherche(Résultats de recherche) et dans l'overlay de recherche
        &--arrow, &--arrow-overlay {
            width: 9px;
            height: 24px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%) rotate(-90deg);
        }

        // Flèche dans la recherche(Résultats de recherche)
        &--arrow {
            fill: var(--color-blue-light-blue);
            stroke: var(--color-blue-light-blue);
        }

        // Flèche dans l'overlay de recherche
        &--arrow-overlay {
            fill: var(--color-base-white);
            stroke: var(--color-base-white);
        }
    }

    // Couleur des Placeholders
    ::-webkit-input-placeholder { color: var(--search-field__color-placeholder); }
    ::-moz-placeholder { color: var(--search-field__color-placeholder); }
    :-ms-input-placeholder {  color: var(--search-field__color-placeholder); }
    :-moz-placeholder { color: var(--search-field__color-placeholder); }

    :-webkit-autofill,
    :-webkit-autofill:hover, 
    :-webkit-autofill:focus, 
    :-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 100px var(--search-field__background) inset !important;
        -webkit-text-fill-color: var(--search-field__color);
        font-size: var(--search-field__font-size) !important;

        border-bottom: 2px solid var(--color-blue-light-blue) !important;
        margin-bottom: 0 !important;
    }

    :-webkit-autofill::first-line {
        font-size: var(--search-field__font-size);
        font-family: var(--font-family-2); // Maison Neue
    }
}