:root {
    --text-field__color: var(--color-blue-marine);
    --text-field__color-placeholder: var(--color-grayscale-gray6);
    --text-field__font-size: 1.6rem;

    --text-field__background: var(--color-base-white);
}

.text-field {
    position: relative;

    &__input {
        width: 100%;
        padding: 8px 0;
        font-size: var(--text-field__font-size);
        line-height: 1.6em;
        color: var(--text-field__color);
        border: 0;
        border-radius: 0; // Nécéssaire même si 0 pour éviter les coins ronds en iOS
        border-bottom: 1px solid var(--color-blue-marine);
        margin-bottom: 1px;
        font-family: var(--font-family-1); // Maison Neue
        background-color: var(--text-field__background);
        -webkit-tap-highlight-color: transparent;
        -webkit-appearance: none;

        &:focus {
            border-bottom: 2px solid var(--color-blue-light-blue);
            margin-bottom: 0px; // Pour éviter le 'skip' de 1px lorsque le border passe de 1px à 2px au focus
        }
    }

    &__input {
        box-shadow: 0 0 0 100px var(--text-field__background) inset; // Choisir la couleur voulu pour le input (Cache le background bleu de l'autofill)
    }

    // Styles spécifiques au Textarea
    textarea {
        height: 45px;
        outline: none;
        resize: none;
        overflow: hidden;

        &[style]{ // Éviter le skip quand le textarea a une hauteur donnée par la fonction 'textareaHeight'
            margin-bottom: 0;
        }
    }

    &__error {
        position: absolute;
        left: 0;
        top: 100%;
    }

    // Style pour erreurs
    &__input.error {
        border-color: var(--color-error-base);
    }

    // Couleur des Placeholders
    ::-webkit-input-placeholder { color: var(--text-field__color-placeholder); }
    ::-moz-placeholder { color: var(--text-field__color-placeholder); }
    :-ms-input-placeholder {  color: var(--text-field__color-placeholder); }
    :-moz-placeholder { color: var(--text-field__color-placeholder); }

    :-webkit-autofill,
    :-webkit-autofill:hover,
    :-webkit-autofill:focus,
    :-webkit-autofill:active {
        -webkit-text-fill-color: var(--text-field__color);
        font-size: var(--text-field__font-size) !important;

        border-bottom: 2px solid var(--color-blue-light-blue) !important;
        margin-bottom: 0 !important;
    }

    :-webkit-autofill::first-line {
        font-size: var(--text-field__font-size);
        font-family: var(--font-family-1); // Maison Neue
    }


    .datepickerWrapper {
        width: 350px;
        position: absolute;
        top: calc(100% + 10px);
        left: 0;
        background-color: #fff;
        opacity: 0;
        box-shadow: 0 0 10px 2px rgb(0 0 0 / 15%);
        z-index: 100;
        visibility: hidden;
        pointer-events: none;
        transition: opacity 300ms,visibility 0ms ease 300ms;

        &.show {
            opacity: 1;
            visibility: visible;
            pointer-events: all;
            transition: opacity 300ms;
        }
    }

}
